//5、双吊轮孔排
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HangWheelHoleTwoRow(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,X=inputParam.X,W=inputParam.W,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   d = D*1*myZoom,
   w = W*1*myZoom,
    x0 = X*1*myZoom,
    y0 = Y*1*myZoom;
   
   //d = D*1,
   //x0 = X*1,
  // y0 = Y*1;
   if(d!==0){
      var TxtD="∮"+D;
     }
   if(w!==0){
    var TxtW=W;
   }
   if(x0!==0){          
   var TxtX=X;
   }
   if(y0!==0){          
    var TxtY=Y;
   }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
//双吊轮孔排 A位置孔
      //标记双吊轮孔排 A位置孔排上
      var myHwhtrHolesAUp= new makerjs.models.Holes(d/2, [[xa, ya]]);
      var myHwhtrHolesMarkAUp = new makerjs.models.Square(0); //孔的原点       
      myHwhtrHolesMarkAUp.origin = [xa, ya];      
      var myHwhtrXMarkAUp = new makerjs.models.Square(0);      
      myHwhtrXMarkAUp.origin =[xa, ya];      
      var myHwhtrYMarkAUp = new makerjs.models.Square(0);      
      myHwhtrYMarkAUp.origin = [xa, ya]; 
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkAUp, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHwhtrHolesMarkAUp, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHwhtrHolesMarkAUp, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHwhtrHolesMarkAUp, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkAUp, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtrHolesMarkAUp, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwhtrXMarkAUp, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtrXMarkAUp, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHwhtrYMarkAUp, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myHwhtrYMarkAUp, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
      //标记双吊轮孔排 A位置孔排下
      var myHwhtrHolesADn= new makerjs.models.Holes(d/2, [[xa, ya-w]]);
      var myHwhtrHolesMarkADn = new makerjs.models.Square(0); //孔的原点       
      myHwhtrHolesMarkADn.origin = [xa, ya-w];      
      var myHwhtrXMarkADn = new makerjs.models.Square(0);      
      myHwhtrXMarkADn.origin =[xa, ya-w];      
      var myHwhtrYMarkADn = new makerjs.models.Square(0);      
      myHwhtrYMarkADn.origin = [xa, ya-w]; 
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkADn, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHwhtrHolesMarkADn, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkADn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtrHolesMarkADn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwhtrXMarkADn, new makerjs.paths.Line([0,0], [0, w]));//W短标线
makerjs.model.addCaption(myHwhtrXMarkADn, TxtW, [0-markShift,0], [0-markShift, w]);//W文字标注
/*****************************************************************************/
 //标记双吊轮孔排 G位置孔排上
  //画孔
     var myHwhtrHolesGUp= new makerjs.models.Holes(d/2, [[xg, yg]]);
      var myHwhtrHolesMarkGUp = new makerjs.models.Square(0); //孔的原点       
      myHwhtrHolesMarkGUp.origin = [xg, yg];      
      var myHwhtrXMarkGUp = new makerjs.models.Square(0);      
      myHwhtrXMarkGUp.origin = [xg, yg];      
      var myHwhtrYMarkGUp = new makerjs.models.Square(0);      
      myHwhtrYMarkGUp.origin = [xg, yg];  
/*****************************************************************************/  
//makerjs.model.addPath(myHwhtrHolesMarkGUp, new makerjs.paths.Line([x0,y0], [x0+markShiftTwo, y0]));//Y轴加长标线
//makerjs.model.addPath(myHwhtrHolesMarkGUp, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//X轴加长标线
makerjs.model.addPath(myHwhtrXMarkGUp, new makerjs.paths.Line([0,0], [0, -y0]));////从孔圆心出发Y方向横向标线
makerjs.model.addPath(myHwhtrXMarkGUp, new makerjs.paths.Line([0,0], [-x0, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkGUp, new makerjs.paths.Line([0,0], [d, d]));//直径标线
makerjs.model.addCaption(myHwhtrHolesMarkGUp, TxtD, [0, 0], [d+markHolesOneD,d+markHolesOneD]);//直径文字标注
/*****************************************************************************/ 
//makerjs.model.addPath(myHwhtrXMarkGUp, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtrXMarkGUp, TxtX, [-x0,markShift], [0, markShift]);//X文字标注
/*****************************************************************************/    
//makerjs.model.addPath(myHwhtrYMarkGUp, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
makerjs.model.addCaption(myHwhtrYMarkGUp, TxtY, [-markShiftTwo, -y0], [-markShiftTwo,0]);//Y文字标注
/*****************************************************************************/ 
 //标记双吊轮孔排 G位置孔排下
  //画孔
  var myHwhtrHolesGDn= new makerjs.models.Holes(d/2, [[xg, yg+w]]);
      var myHwhtrHolesMarkGDn = new makerjs.models.Square(0); //孔的原点       
      myHwhtrHolesMarkGDn.origin = [xg, yg+w];      
      var myHwhtrXMarkGDn = new makerjs.models.Square(0);      
      myHwhtrXMarkGDn.origin = [xg, yg+w];      
      var myHwhtrYMarkGDn = new makerjs.models.Square(0);      
      myHwhtrYMarkGDn.origin = [xg, yg+w];  
/*****************************************************************************/  
makerjs.model.addPath(myHwhtrXMarkGDn, new makerjs.paths.Line([0,0], [0, -w]));////从孔圆心出发Y方向横向标线
//makerjs.model.addPath(myHwhtrXMarkGDn, new makerjs.paths.Line([0,0], [x0+markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkGDn, new makerjs.paths.Line([0,0], [d, d]));//直径标线
makerjs.model.addCaption(myHwhtrHolesMarkGDn, TxtD, [0, 0], [d+markHolesOneD,d+markHolesOneD]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrXMarkGDn, new makerjs.paths.Line([0,0], [0, -w]));//W短标线
makerjs.model.addCaption(myHwhtrXMarkGDn, TxtW, [0-markShift, -w], [0-markShift,0]);//W文字标注
/*****************************************************************************/ 
plate.models["myHwhtrHolesAUp"+myHolesNum]=myHwhtrHolesAUp;
markers.models["myHwhtrHolesAUp"+myHolesNum]=myHwhtrHolesAUp;
markers.models["myHwhtrHolesMarkAUp"+myHolesNum]=myHwhtrHolesMarkAUp;
markers.models["myHwhtrXMarkAUp"+myHolesNum]=myHwhtrXMarkAUp;
markers.models["myHwhtrYMarkAUp"+myHolesNum]=myHwhtrYMarkAUp;
/*****************************************************************************/
plate.models["myHwhtrHolesADn"+myHolesNum]=myHwhtrHolesADn;
markers.models["myHwhtrHolesADn"+myHolesNum]=myHwhtrHolesADn;
markers.models["myHwhtrHolesMarkADn"+myHolesNum]=myHwhtrHolesMarkADn;
markers.models["myHwhtrXMarkADn"+myHolesNum]=myHwhtrXMarkADn;
markers.models["myHwhtrYMarkADn"+myHolesNum]=myHwhtrYMarkADn;
/*****************************************************************************/ 
plate.models["myHwhtrHolesGUp"+myHolesNum]=myHwhtrHolesGUp;
markers.models["myHwhtrHolesGUp"+myHolesNum]=myHwhtrHolesGUp;
markers.models["myHwhtrHolesMarkGUp"+myHolesNum]=myHwhtrHolesMarkGUp;
markers.models["myHwhtrXMarkGUp"+myHolesNum]=myHwhtrXMarkGUp;
markers.models["myHwhtrYMarkGUp"+myHolesNum]=myHwhtrYMarkGUp;
/*****************************************************************************/ 
plate.models["myHwhtrHolesGDn"+myHolesNum]=myHwhtrHolesGDn;
markers.models["myHwhtrHolesGDn"+myHolesNum]=myHwhtrHolesGDn;
markers.models["myHwhtrHolesMarkGDn"+myHolesNum]=myHwhtrHolesMarkGDn;
markers.models["myHwhtrXMarkGDn"+myHolesNum]=myHwhtrXMarkGDn;
markers.models["myHwhtrYMarkGDn"+myHolesNum]=myHwhtrYMarkGDn;
/*****************************************************************************/  

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default HangWheelHoleTwoRow;