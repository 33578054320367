//角3、方角
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function Corner(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var R=inputParam.R,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   r = R*1*myZoom,
   w = W*1*myZoom,
      h = H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        r = R*1,
        w = W*1,
           h = H*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(r!==0){
         var TxtR="R="+R;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画3、方角 A位置
    /*
    var myRagGapARoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
    myRagGapARoundRectangle.origin=[xa,ya-h]; 
    */
   /*
    var myRectangleCornerA=new makerjs.models.Rectangle(w+5, h+5,r);
    myRectangleCornerA.origin=[xa-5,ya-h-5+5];
////标记3、方角 A位置
var myCornerAMakerW = new makerjs.models.Square(0); //孔的原点       
myCornerAMakerW.origin = [xa,ya];
var myCornerAMakerH = new makerjs.models.Square(0); //孔的原点       
myCornerAMakerH.origin = [xa,ya];
*/
var myRectangleCornerA=new makerjs.models.RoundRectangle(w+r, h+r,r);
    myRectangleCornerA.origin=[xa-r,ya-h];
////标记3、方角 A位置
var myCornerAMakerW = new makerjs.models.Square(0); //孔的原点       
myCornerAMakerW.origin = [xa,ya];
var myCornerAMakerH = new makerjs.models.Square(0); //孔的原点       
myCornerAMakerH.origin = [xa,ya];
var myCornerAMakerR = new makerjs.models.Square(0); //孔的原点       
myCornerAMakerR.origin = [xa,ya];
/*****************************************************************************/
makerjs.model.addPath(myCornerAMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
makerjs.model.addPath(myCornerAMakerW, new makerjs.paths.Line([w,0], [w,0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myCornerAMakerW, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
makerjs.model.addPath(myCornerAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftThree, 0-h]));//X轴方向短线
/*****************************************************************************/ 
makerjs.model.addPath(myCornerAMakerR, new makerjs.paths.Line([w-markShiftThree,-h+markShiftThree], [w-r/2, -h+r/2]));//R短线标注
makerjs.model.addCaption(myCornerAMakerR, TxtR, [w-markShiftFour-r,-h+5+markShiftFour], [w-markShift, -h+5+markShiftFour]);//R文字标注 
/*****************************************************************************/   
makerjs.model.addPath(myCornerAMakerW, new makerjs.paths.Line([0,0+markShift], [0+w, 0+markShift]));//W短线标注
makerjs.model.addCaption(myCornerAMakerW, TxtW, [0,0+markShiftThree], [0+w, 0+markShiftThree]);//W文字标注 
/*****************************************************************************/    
makerjs.model.addPath(myCornerAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myCornerAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myRectangleCornerA"+myHolesNum]=myRectangleCornerA;
markers.models["myRectangleCornerA"+myHolesNum]=myRectangleCornerA;
markers.models["myCornerAMakerW"+myHolesNum]=myCornerAMakerW;
markers.models["myCornerAMakerH"+myHolesNum]=myCornerAMakerH;
markers.models["myCornerAMakerR"+myHolesNum]=myCornerAMakerR;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRectangleCornerA"+myHolesNum]);
/*****************************************************************************/ 
  
    }else if(area==2){
      
    }else if(area==3){
      //画3、方角 C位置
      
//var myRectangleCornerA=new makerjs.models.RoundRectangle(w+5, h+5,r);
 //var myRectangleCornerC=new makerjs.models.Rectangle(w+5, h+5);
 var myRectangleCornerC=new makerjs.models.RoundRectangle(w+r, h+r,r);
    myRectangleCornerC.origin=[xc-w,yc-h];
////标记3、方角 C位置
var myCornerCMakerW = new makerjs.models.Square(0); //孔的原点       
myCornerCMakerW.origin = [xc,yc];
var myCornerCMakerH = new makerjs.models.Square(0); //孔的原点       
myCornerCMakerH.origin = [xc,yc];
var myCornerCMakerR = new makerjs.models.Square(0); //孔的原点       
myCornerCMakerR.origin = [xc,yc];
/*****************************************************************************/
/*****************************************************************************/
makerjs.model.addPath(myCornerCMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
makerjs.model.addPath(myCornerCMakerW, new makerjs.paths.Line([-w,0], [-w,0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myCornerCMakerW, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
makerjs.model.addPath(myCornerCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftThree, 0-h]));//X轴方向短线

/*****************************************************************************/
/*****************************************************************************/   
makerjs.model.addPath(myCornerCMakerW, new makerjs.paths.Line([0,0+markShift], [0-w, 0+markShift]));//W短线标注
makerjs.model.addCaption(myCornerCMakerW, TxtW, [0-w,0+markShiftThree], [0, 0+markShiftThree]);//W文字标注 

/*****************************************************************************/  
makerjs.model.addPath(myCornerCMakerR, new makerjs.paths.Line([-w+markShiftThree,-h+markShiftThree], [-w+r/2, -h+r/2]));//R短线标注
makerjs.model.addCaption(myCornerCMakerR, TxtR, [-w+markShift, -h+5+markShiftFour], [-w+markShiftFour+r,-h+5+markShiftFour]);//R文字标注 
/*****************************************************************************/   
/*****************************************************************************/   
makerjs.model.addPath(myCornerCMakerH, new makerjs.paths.Line([0+markShiftTwo,0-h], [0+markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myCornerCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 
plate.models["myRectangleCornerC"+myHolesNum]=myRectangleCornerC;
markers.models["myRectangleCornerC"+myHolesNum]=myRectangleCornerC;
markers.models["myCornerCMakerW"+myHolesNum]=myCornerCMakerW;
markers.models["myCornerCMakerH"+myHolesNum]=myCornerCMakerH;
markers.models["myCornerCMakerR"+myHolesNum]=myCornerCMakerR;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRectangleCornerC"+myHolesNum])
/*****************************************************************************/ 

    }else if(area==4){
      
    }else if(area==5){
      
    }else if(area==6){
      
    }else if(area==7){

  //画3、方角 G位置
  //var myRectangleCornerG=new makerjs.models.Rectangle(w+5, h+5);
  
  var myRectangleCornerG=new makerjs.models.RoundRectangle(w+r, h+r,r);
    myRectangleCornerG.origin=[xg-r,yg-r];
////标记3、方角 C位置
var myCornerGMakerW = new makerjs.models.Square(0); //孔的原点       
myCornerGMakerW.origin = [xg,yg];
var myCornerGMakerH = new makerjs.models.Square(0); //孔的原点       
myCornerGMakerH.origin = [xg,yg];
var myCornerGMakerR = new makerjs.models.Square(0); //孔的原点       
myCornerGMakerR.origin = [xg,yg];
/*****************************************************************************/
/*****************************************************************************/
makerjs.model.addPath(myCornerGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftThree]));//Y轴加长标线
makerjs.model.addPath(myCornerGMakerW, new makerjs.paths.Line([w,0], [w,0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myCornerGMakerW, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
makerjs.model.addPath(myCornerGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftThree, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/   
makerjs.model.addPath(myCornerGMakerW, new makerjs.paths.Line([0,0-markShiftTwo], [0+w, 0-markShiftTwo]));//W短线标注
makerjs.model.addCaption(myCornerGMakerW, TxtW, [0,0-markShiftFour], [0+w, 0-markShiftFour]);//W文字标注 
/*****************************************************************************/ 
makerjs.model.addPath(myCornerGMakerR, new makerjs.paths.Line([w-markShiftThree,h-markShiftThree], [w-r/2, h-r/2]));//R短线标注
makerjs.model.addCaption(myCornerGMakerR, TxtR, [w-markShiftFour-r,h-5-markShiftFour], [w-markShift, h-5-markShiftFour]);//R文字标注  
/*****************************************************************************/   
makerjs.model.addPath(myCornerGMakerH, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, h]));//H短线标注
makerjs.model.addCaption(myCornerGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, h]);//H文字标注 
/*****************************************************************************/  
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myRectangleCornerG"+myHolesNum]=myRectangleCornerG;
markers.models["myRectangleCornerG"+myHolesNum]=myRectangleCornerG;
markers.models["myCornerGMakerW"+myHolesNum]=myCornerGMakerW;
markers.models["myCornerGMakerH"+myHolesNum]=myCornerGMakerH;
markers.models["myCornerGMakerR"+myHolesNum]=myCornerGMakerR;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRectangleCornerG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/       
    }else if(area==8){
      
    }else if(area==9){
     //画3、方角 I位置
     
  var myRectangleCornerI=new makerjs.models.RoundRectangle(w+r, h+r,r);
  //var myRectangleCornerI=new makerjs.models.Rectangle(w+5, h+5);
  myRectangleCornerI.origin=[xi-w,yi-r];
////标记3、方角 C位置
var myCornerIMakerW = new makerjs.models.Square(0); //孔的原点       
myCornerIMakerW.origin = [xi,yi];
var myCornerIMakerH = new makerjs.models.Square(0); //孔的原点       
myCornerIMakerH.origin = [xi,yi];
var myCornerIMakerR = new makerjs.models.Square(0); //孔的原点       
myCornerIMakerR.origin = [xi,yi];
/*****************************************************************************/
/*****************************************************************************/
makerjs.model.addPath(myCornerIMakerW, new makerjs.paths.Line([0,0], [0, 0-markShiftThree]));//Y轴加长标线
makerjs.model.addPath(myCornerIMakerW, new makerjs.paths.Line([0-w,0], [0-w,0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myCornerIMakerW, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
makerjs.model.addPath(myCornerIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftThree, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/   
makerjs.model.addPath(myCornerIMakerW, new makerjs.paths.Line([0-w,0-markShiftTwo], [0, 0-markShiftTwo]));//W短线标注
makerjs.model.addCaption(myCornerIMakerW, TxtW, [0-w,0-markShiftFour], [0, 0-markShiftFour]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myCornerIMakerR, new makerjs.paths.Line([-w+markShiftThree,h-markShiftThree], [-w+r/2, h-r/2]));//R短线标注
makerjs.model.addCaption(myCornerIMakerR, TxtR, [-w+markShift, h-5-markShiftFour], [-w+markShiftFour+r,h-5-markShiftFour]);//R文字标注  
/*****************************************************************************/   
makerjs.model.addPath(myCornerIMakerH, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, h]));//H短线标注
makerjs.model.addCaption(myCornerIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, h]);//H文字标注 
/*****************************************************************************/  
/*****************************************************************************/
plate.models["myRectangleCornerI"+myHolesNum]=myRectangleCornerI;
markers.models["myRectangleCornerI"+myHolesNum]=myRectangleCornerI;
markers.models["myCornerIMakerW"+myHolesNum]=myCornerIMakerW;
markers.models["myCornerIMakerH"+myHolesNum]=myCornerIMakerH;
markers.models["myCornerIMakerR"+myHolesNum]=myCornerIMakerR;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRectangleCornerI"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/
    }

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};


export default Corner;