
<template>
  <div class="contanerBox">
    <!-- 头部Header -->
    <div class="conHeader">
      <div class="logoIcon">
        <img src="../assets/200.png" width="40px" alt="" />
      </div>
      <div class="MenuBtns">
        <div class="openMenu">
          <!-- <i class="iconfont icon-drawdakai"></i> -->
          <label class="open-svg">
            <i class="iconfont icon-drawdakai"></i>
            <span style="font-size: 12px">打开</span>
            <input
              type="file"
              accept="image/png,image/svg+xml,application/json"
              id="openSVG"
              @change="handleFileChange"
            />
          </label>
        </div>
        <div @click="dialogFormVisible = true">
          <i class="iconfont icon-drawxinjian10"></i>
          <span style="font-size: 12px">新建</span>
        </div>
       
        <div @click="saveMyPng">
          <el-tooltip
            class="item"
            effect="dark"
            content="保存PNG图"
            placement="bottom"
          >
            <i class="iconfont icon-drawPNG"></i>
          </el-tooltip>
        </div>       
 <!--
        <div @click="saveMySvg">
          <el-tooltip
            class="item"
            effect="dark"
            content="保存SVG"
            placement="bottom"
          >
            <i class="iconfont icon-drawsvg7"></i>
          </el-tooltip>
        </div>

        <div @click="saveMyDXF">
          <el-tooltip
            class="item"
            effect="dark"
            content="保存DXF"
            placement="bottom"
          >
            <i class="iconfont icon-drawdaochudxf1"></i>
          </el-tooltip>
        </div>
        -->
        <div @click="canUndo ? drawUndo(stateIndex - 1) : null">
          <el-tooltip
            class="item"
            effect="dark"
            content="撤销"
            placement="bottom"
          >
            <i
              :style="{ color: canUndo ? '' : '#878889' }"
              class="iconfont icon-drawnishizhen"
            ></i>
          </el-tooltip>
        </div>
        <div @click="canRedo ? drawRedo(stateIndex + 1) : null">
          <el-tooltip
            class="item"
            effect="dark"
            content="恢复"
            placement="bottom"
          >
            <i
              :style="{ color: canRedo ? '' : '#878889' }"
              class="iconfont icon-drawshunshizhen"
            ></i>
          </el-tooltip>
        </div>
        <div v-if="isClearSvg" @click="clearSvg">
          <el-tooltip
            class="item-test"
            effect="dark"
            content="清空"
            placement="bottom"
          >
            <i class="iconfont icon-drawqingkong"></i>
          </el-tooltip>
        </div>
        <!-- <div @click="saveHoles">
          <el-tooltip
            class="item-test"
            effect="dark"
            content="导出打孔"
            placement="bottom"
          >
            <i
              class="iconfont icon-drawhuituxiadezicaidantubiao_tihuanyuanxingdingweikongweigulidian1"
            ></i>
          </el-tooltip>          
        </div> -->
       
        <div>
          <el-dropdown  @command="handleHolesCommand">
  <span class="el-dropdown-link">
    <i class="iconfont icon-drawhuituxiadezicaidantubiao_tihuanyuanxingdingweikongweigulidian1"></i>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item command="boliang">博亮打孔机</el-dropdown-item>
    <el-dropdown-item command="jingrui">精锐打孔机</el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown>          
        </div>        
        <div @click="myTest0724">
          <el-tooltip
            class="item-test"
            effect="dark"
            content="导出水刀"
            placement="bottom"
          >
            <i class="iconfont icon-drawqiege"></i>
          </el-tooltip>
        </div>
        <div @click="exMould">
          <el-tooltip
            class="item-test"
            effect="dark"
            content="保存模板"
            placement="bottom"
          >
            <i class="iconfont icon-drawmobanpeizhi"></i>
          </el-tooltip>
        </div>
        <div @click="changeRect">
          <el-tooltip
            class="item-test"
            effect="dark"
            content="修改玻璃尺寸"
            placement="bottom"
          >
            <i class="iconfont icon-drawjuxing"></i>
          </el-tooltip>
        </div>

        <!-- <div @click="creatFile">
          <el-tooltip
            class="item-test"
            effect="dark"
            content="测试创建本地文件夹"
            placement="bottom"
          >
            <i class="iconfont icon-drawjuxing"></i>
          </el-tooltip>
        </div> -->

        <!--                
        <div    @click="sendDataToERP">       
            <el-tooltip class="item-test" effect="dark" content="测试" placement="bottom">
              <i class="iconfont icon-drawhuituxiadezicaidantubiao_tihuanyuanxingdingweikongweigulidian1"></i>
           </el-tooltip>
          </div>
           -->
      </div>

      <div @click="systemSetVisible = true" class="MenuBtns-right">
        <el-tooltip
          class="item-test"
          effect="dark"
          content="系统设置"
          placement="bottom"
        >
          <i class="iconfont icon-drawshezhi"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 内容conMain -->
    <div class="conMain">
      <!-- 右侧中间内容 conMainCon -->
      <div class="conMainCon" id="content">
        <div id="view" class="drawBack">
          <div id="drawCanvas" class="draw"></div>
        </div>
      </div>

      <div class="conMainRight">
        <div>
          <el-tabs type="border-card" @tab-click="handleClickCard">
            <el-tab-pane label="孔" v-model="activeName">
              <el-tabs
                :tab-position="tabPosition"
                v-model="activeName"
                @tab-click="handleClickHole"
                style="height: 80vh"
              >
                <el-tab-pane label="单孔" name="first">
                  <!-- 单孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/SingleHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">请输入孔的直径D：</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'SingleHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 单孔 -->
                </el-tab-pane>
                <el-tab-pane label="吊轮孔2" name="second">
                  <!-- 吊轮孔2 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HangWheelHoleTwo.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">请输入孔的直径D：</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangWheelHoleTwo')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 吊轮孔2 -->
                </el-tab-pane>
                <el-tab-pane label="双固定孔列" name="eight">
                  <!-- 双固定孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/DoubleFixHolesCol.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'DoubleFixHolesCol')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 双固定孔 -->
                </el-tab-pane>
                <el-tab-pane label="双固定孔" name="nine">
                  <!-- 双固定孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/DoubleFixHoles.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'DoubleFixHoles')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 双固定孔 -->
                </el-tab-pane>
                <el-tab-pane label="双吊轮孔列" name="fourth">
                  <!-- 双吊轮孔列 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HangWheelHoleTwoCol.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangWheelHoleTwoCol')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 双吊轮孔列 -->
                </el-tab-pane>
                <el-tab-pane label="双吊轮孔排" name="five">
                  <!-- 双吊轮孔排 -->

                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HangWheelHoleTwoRow.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangWheelHoleTwoRow')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 双吊轮孔排 -->
                </el-tab-pane>
                <el-tab-pane label="三孔" name="seventeen">
                  <!-- 三孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img :src="threeHolePic" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <!--  类型选择 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >选择类型</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row
                    class="row-bg hole-lineH"
                    type="flex"
                    justify="center"
                  >
                    <el-col :span="12"
                      ><el-radio
                        @change="threeHolesChange"
                        v-model="threeHoleType"
                        label="A"
                        >类型A</el-radio
                      ></el-col
                    >
                    <el-col :span="12"
                      ><el-radio
                        @change="threeHolesChange"
                        v-model="threeHoleType"
                        label="B"
                        >类型B</el-radio
                      ></el-col
                    >
                  </el-row>
                  <el-row
                    class="row-bg hole-lineH"
                    type="flex"
                    justify="center"
                  >
                    <el-col :span="12"
                      ><el-radio
                        @change="threeHolesChange"
                        v-model="threeHoleType"
                        label="C"
                        >类型C</el-radio
                      ></el-col
                    >
                    <el-col :span="12"
                      ><el-radio
                        @change="threeHolesChange"
                        v-model="threeHoleType"
                        label="D"
                        >类型D</el-radio
                      ></el-col
                    >
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="threeHoleFnClick()"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 三孔 -->
                </el-tab-pane>
                <el-tab-pane label="拉手孔" name="eleven">
                  <!-- 拉手孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/HandleHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HandleHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 拉手孔 -->
                </el-tab-pane>
                <el-tab-pane label="吊轮孔列" name="fourteen">
                  <!-- 吊轮孔列 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HangWheelHoleCol.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangWheelHoleCol')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 吊轮孔列 -->
                </el-tab-pane>
                <el-tab-pane label="吊轮孔3" name="third">
                  <!-- 吊轮孔3 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HangWheelHoleThree.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">请输入孔的直径D：</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangWheelHoleThree')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 吊轮孔3 -->
                </el-tab-pane>
                <el-tab-pane label="晾物孔" name="six">
                  <!-- 晾物孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/HangHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> D:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> W:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> H1:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入孔的参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> H2:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H2"
                          placeholder="请输入孔的参数H2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 晾物孔 -->
                </el-tab-pane>
                <el-tab-pane label="拉手孔T字" name="seven">
                  <!-- 拉手孔T字 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HandleHoleTShaped.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> D:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> W:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> H1:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入孔的参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> H2:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H2"
                          placeholder="请输入孔的参数H2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HandleHoleTShaped')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 拉手孔T字 -->
                </el-tab-pane>
                <el-tab-pane label="相对吊轮孔" name="ten">
                  <!-- 相对吊轮孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/RelSuWheelHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'RelSuWheelHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 相对吊轮孔 -->
                </el-tab-pane>
                <el-tab-pane label="旋转锁孔" name="twelve">
                  <!-- 旋转锁孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/RotatLockHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="3">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="5">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="5">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="5">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'RotatLockHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 旋转锁孔 -->
                </el-tab-pane>
                <el-tab-pane label="拉手孔3" name="thirteen">
                  <!-- 拉手孔3 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/HandleHole3.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HandleHole3')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 拉手孔3 -->
                </el-tab-pane>
                <el-tab-pane label="7字拉手孔" name="fifteen">
                  <!-- 7字拉手孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/7ShapedHandleHole.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'Shaped7HandleHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 7字拉手孔 -->
                </el-tab-pane>
                <el-tab-pane label="锁孔" name="sixteen">
                  <!-- 锁孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/KeyHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">D1：</div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="D1"
                          placeholder="请输入孔的直径D1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">D2：</div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="D2"
                          placeholder="请输入孔的直径D2"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="false"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'KeyHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 锁孔 -->
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="缺" v-model="activeName">
              <!-- <el-divider><i class="el-icon-more-outline"></i></el-divider> -->
              <el-tabs
                :tab-position="tabPosition"
                v-model="activeName"
                @tab-click="handleClickGap"
                style="height: 80vh"
              >
                <el-tab-pane label="蝴蝶" name="first">
                  <!-- 蝴蝶 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/ButterflyGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >蝴蝶参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入蝴蝶参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入蝴蝶参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入蝴蝶参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'ButterflyGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 蝴蝶 -->
                </el-tab-pane>
                <el-tab-pane label="蝴蝶2" name="second">
                  <!-- 蝴蝶 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/ButterflyGap2.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >蝴蝶参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入蝴蝶参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入蝴蝶参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入蝴蝶参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入蝴蝶参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入蝴蝶参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'ButterflyGap2')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 蝴蝶 -->
                </el-tab-pane>
                <el-tab-pane label="蝴蝶3" name="third">
                  <!-- 蝴蝶 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/ButterflyGap3.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >蝴蝶参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R1"
                          placeholder="请输入蝴蝶参数R1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">R2：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R2"
                          placeholder="请输入蝴蝶参数R2"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入蝴蝶参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入蝴蝶参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入蝴蝶参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入蝴蝶参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">w2：</div>
                    </el-col>
                    <el-col :span="22">
                      <div class="grid-content">
                        <el-input
                          v-model="W2"
                          placeholder="请输入蝴蝶参数W2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'ButterflyGap3')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 蝴蝶 -->
                </el-tab-pane>
                <el-tab-pane label="U型" name="fourth">
                  <!-- U型 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/UShapeGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >U型缺参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入U型缺参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入U型缺参数参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'UShapeGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- U型 -->
                </el-tab-pane>
                <el-tab-pane label="应力孔" name="five">
                  <!-- 应力孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/StressHoleGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >应力孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入应力孔的参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入应力孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入应力孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'StressHoleGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 应力孔 -->
                </el-tab-pane>
                <el-tab-pane label="跑道缺" name="six">
                  <!-- 跑道缺 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/RunwayGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >跑道缺参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">H:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="跑道缺参数H"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">H1:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="跑道缺参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">W:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="跑道缺参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">W1:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="跑道缺参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'RunwayGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 跑道缺 -->
                </el-tab-pane>
                <el-tab-pane label="方缺" name="seven">
                  <!-- 方缺 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/SquareGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >方缺参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入方缺参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入方缺参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入方缺参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'SquareGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 方缺 -->
                </el-tab-pane>
                <el-tab-pane label="矩形缺" name="eight">
                  <!-- 方缺 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/RectAngleGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >矩形缺参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入矩形缺参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入矩形缺参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入矩形缺参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'RectAngleGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 方缺 -->
                </el-tab-pane>
                <el-tab-pane label="铜夹" name="nine">
                  <!-- 铜夹 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/CopperClipGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >铜夹参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">D:</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入铜夹参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">R:</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入铜夹参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">W:</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入铜夹参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">H:</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入铜夹参数H"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">W1:</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入铜夹参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'CopperClipGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 铜夹 -->
                </el-tab-pane>
                <el-tab-pane label="长条夹" name="ten">
                  <!-- 长条夹 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/LongClipGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >长条夹参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入长条夹参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">D1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D1"
                          placeholder="请输入长条夹参数D1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入长条夹参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入长条夹参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'LongClipGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 长条夹 -->
                </el-tab-pane>
                <el-tab-pane label="椭圆" name="eleven">
                  <!-- 长条夹 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/EllipseGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >椭圆参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">Rx：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Rx"
                          placeholder="请输入椭圆参数Rx"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Ry：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Ry"
                          placeholder="请输入椭圆参数Ry"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'EllipseGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 长条夹 -->
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="角" v-model="activeName">
              <!-- <el-divider><i class="el-icon-more-outline"></i></el-divider> -->
              <el-tabs
                :tab-position="tabPosition"
                v-model="activeName"
                @tab-click="handleClickCorner"
                style="height: 80vh"
              >
                <el-tab-pane label="斜角" name="first">
                  <!-- 斜角 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/ObliqueAngle.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >斜角参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="斜角参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="斜角参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'ObliqueAngle')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 斜角 -->
                </el-tab-pane>
                <el-tab-pane label="圆角" name="second">
                  <!-- 圆角 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/Fillet.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >圆角参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入圆角参数R"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'Fillet')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 圆角 -->
                </el-tab-pane>
                <el-tab-pane label="方角" name="third">
                  <!-- 方角 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/Corner.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >方角参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入方角参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入方角参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入方角参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'Corner')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 方角 -->
                </el-tab-pane>
                <el-tab-pane label="圆角2" name="fourth">
                  <!-- 圆角2 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/Fillet2.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >圆角2参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入圆角2参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入圆角2参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入圆角2参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'Fillet2')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 圆角2 -->
                </el-tab-pane>
                <el-tab-pane label="地弹簧" name="five">
                  <!-- 地弹簧 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/FloorSpring.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >地弹簧参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入地弹簧参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入地弹簧参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入地弹簧参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入地弹簧参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入地弹簧参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W2：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W2"
                          placeholder="请输入地弹簧参数W2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">R1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="R1"
                          placeholder="请输入地弹簧参数R1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">R2：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="R2"
                          placeholder="请输入地弹簧参数R2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'FloorSpring')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 地弹簧 -->
                </el-tab-pane>
                <el-tab-pane label="地弹簧2" name="six">
                  <!-- 地弹簧 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/FloorSpring2.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >地弹簧参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入地弹簧参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入地弹簧参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入地弹簧参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入地弹簧参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入地弹簧参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W2：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W2"
                          placeholder="请输入地弹簧参数W2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入地弹簧参数R"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'FloorSpring2')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 地弹簧 -->
                </el-tab-pane>
                <el-tab-pane label="地弹簧3" name="seven">
                  <!-- 地弹簧 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/FloorSpring3.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >地弹簧参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入地弹簧参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入地弹簧参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入地弹簧参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入地弹簧参数R"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入地弹簧参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W2：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W2"
                          placeholder="请输入地弹簧参数W2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'FloorSpring3')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 地弹簧 -->
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>

            <el-tab-pane label="异形" v-model="activeName">
              <el-tabs
                :tab-position="tabPosition"
                v-model="activeName"
                @tab-click="handleClickSpecialShaped"
                style="height: 80vh"
              >
                <el-tab-pane label="楼梯" name="first">
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <img
                          src="../assets/image/specialShaped.jpg"
                          alt=""
                        /></div
                    ></el-col>
                    <el-col :span="12"
                      ><div class="grid-content txtHeight">
                        <el-row>
                          <el-col :span="12" class=""> 选择楼梯层数</el-col>
                        </el-row>
                        <el-row>
                          <el-select
                            v-model="spacelOptionsValue"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in spacelOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-row></div
                    ></el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >从左到右输入每个点坐标</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input v-model="W" placeholder="坐标点X"></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input v-model="Y" placeholder="坐标点Y"></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne(type, shape)"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- 矩形尺寸参数设置弹出框 -->
    <div>
      <el-dialog
        title="玻璃尺寸设置"
        :visible.sync="dialogFormVisible"
        width="18%"
        center
      >
        <el-form :model="form">
          <el-form-item label="长度X(mm)" :label-width="formLabelWidth">
            <el-input v-model="form.glassW" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="宽度Y(mm)" :label-width="formLabelWidth">
            <el-input v-model="form.glassH" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="厚度(mm)" :label-width="formLabelWidth">
            <el-input v-model="GlassThickness" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="creatRect">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 矩形尺寸参数设置弹出框 -->
    <!-- 矩形尺寸修改参数弹出框 -->
    <div>
      <el-dialog
        title="玻璃尺寸修改"
        :visible.sync="changeGlassVisible"
        width="18%"
        center
      >
        <el-form :model="form">
          <el-form-item label="长度X(mm)" :label-width="formLabelWidth">
            <el-input v-model="form.glassW" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="宽度Y(mm)" :label-width="formLabelWidth">
            <el-input v-model="form.glassH" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="厚度(mm)" :label-width="formLabelWidth">
            <el-input v-model="GlassThickness" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="changeGlassVisible = false">取 消</el-button>
          <el-button type="primary" @click="changeSetRect">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 矩形尺寸修改参数弹出框 -->
    <!-- 系统设置参数弹出框 -->
    <div>
      <el-dialog
        title="系统设置"
        :visible.sync="systemSetVisible"
        width="18%"
        center
      >
        <el-form>
          <el-form-item label="字体" :label-width="formLabelWidth">
            <el-select v-model="renderOptions.fontSize" placeholder="请选择">
              <el-option
                v-for="item in SystemSetFontSizeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="线条粗细" :label-width="formLabelWidth">
            <el-select v-model="renderOptions.strokeWidth" placeholder="请选择">
              <el-option
                v-for="item in SystemSetStrokeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图形放大倍数" :label-width="formLabelWidth">
            <el-select v-model="zoomM" placeholder="请选择">
              <el-option
                v-for="item in zoomOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="systemSetVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveSystemSet">保 存</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 系统设置参数弹出框 -->
    <!-- 厚度参数弹出框 -->
    <div>
      <el-dialog
        title="玻璃厚度设置"
        :visible.sync="thicknessSetVisible"
        width="18%"
        center
      >
        <el-form>
          <el-form-item label="厚度X(mm)" :label-width="formLabelWidth">
            <el-input
              v-model="GlassThickness"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="thicknessSetVisible = false">取 消</el-button>
          <el-button type="primary" @click="changSetThickness">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 厚度参数弹出框 -->
  </div>
</template>
 <script>
import makerjs from "makerjs";
export default {
  data() {
    return {
      //水刀基准点位置
      benchMark:"I",
      tabLabel:"",
      changeGlassVisible: false, //修改玻璃尺寸弹窗可见性
      //画基本图形相关参数
      /*
      梯形相关参数
      */
      spacelOptions: [
        {
          value: "1",
          label: "3",
        },
        {
          value: "2",
          label: "4",
        },
        {
          value: "3",
          label: "5",
        },
        {
          value: "4",
          label: "6",
        },
        {
          value: "5",
          label: "7",
        },
      ],
      spacelOptionsValue: "",
      /*
      梯形相关参数
      */
      holeD: "", //导出孔文件中的所有孔坐标 数组
      toERPParams: false, //标记是否有数据从ERP传过来
      setSysStorage: {
        fontSize: "16px",
      }, //需要缓存数据
      SystemSetStrokeOptions: [
        {
          value: "0.3mm",
          label: "0.3mm",
        },
        {
          value: "0.6mm",
          label: "0.6mm",
        },
        {
          value: "1.0mm",
          label: "1.0mm",
        },
        {
          value: "1.2mm",
          label: "1.2mm",
        },
        {
          value: "1.5mm",
          label: "1.5mm",
        },
      ],
      SystemSetFontSizeOptions: [
        {
          value: "16px",
          label: "16px",
        },
        {
          value: "18px",
          label: "18px",
        },
        {
          value: "20px",
          label: "20px",
        },
        {
          value: "22px",
          label: "22px",
        },
        {
          value: "24px",
          label: "24px",
        },
        {
          value: "28px",
          label: "28px",
        },
        {
          value: "32px",
          label: "32px",
        },
        {
          value: "36px",
          label: "36px",
        },
        {
          value: "42px",
          label: "42px",
        },
        {
          value: "48px",
          label: "48px",
        },
      ],
      zoomM: 1,
      zoomOptions: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
      ],
      isLoadCanvas: false,
      stateIndex: 0,
      isDrawUndo: true,
      isDrawRedo: true,
      isClearSvg: true,
      canvasState: [],
      //初始化固定参数不会发生变化
      disabled: false, //区域位置是否可选择性 禁用
      //矩形框相关参数
      dialogFormVisible: false,
      //矩形框相关参数
      systemSetVisible: false,
      thicknessSetVisible: false, //厚度参数设置弹窗
      formLabelWidth: "100px",
      //矩形框相关参数
      springOffset: 0,
      direction: "rtl",
      tabPosition: "left",
      //x、y标记偏移位置 孔
      markShiftF: 5, //矩形偏移量 第一层偏移量（最靠近矩形框的里层）
      markShift: 10, //矩形偏移量 第一层偏移量（最靠近矩形框的里层）
      markShiftTow: 15, //矩形偏移量 第二层偏移量（最靠近矩形框的里层）
      markShiftTwo: 15, //矩形偏移量 第二层偏移量（最靠近矩形框的里层）
      markShiftThree: 20, //矩形偏移量 第三层偏移量（最靠近矩形框的里层）
      markShiftFour: 25, //矩形偏移量 第四层偏移量（最靠近矩形框的里层）
      //圆形直径标注线加长设置 孔
      markHolesOneD: 15,

      /*撤销和恢复/反撤销操作*/
      myStep: 0,
      history: [], // 存储历史记录
      future: [], // 存储未来的操作记录
      currentState: {}, // 当前状态  Initial state 初始状态
      operationHistory: [], // 存储历史记录的参数记录
      operationFuture: [], // 存储未来的参数记录
      operationCurrent: {}, // 当前状态  Initial state 初始参数状态
      /*撤销和恢复/反撤销操作*/
      showX: false,
      showY: false,
      activeName: "first",
      form: {
        glassW: "", //长度
        glassH: "", //宽度
      },
      //区域位置选择单选
      radio: "7",
      //三孔不同类型选择单选
      threeHoleType: "A",
      //三孔示意图参数
      threeHolePic: require("../assets/image/ThreeHoles.jpg"),
      //三孔调用函数名称变化参数
      threeHoleFn: "creatThreeHoles",
      //水刀是否选择
      checked: false,
      //X、Y偏移值 初始值
      X: 0,
      Y: 0,
      D: 0,
      R: 0,
      Rx: 0,
      Ry: 0,
      R1: 0,
      R2: 0,
      D1: 0,
      D2: 0,
      H: 0,
      H1: 0,
      H2: 0,
      W: 0,
      W1: 0,
      W2: 0,
      //画图相关参数
      myHolesNum: 0, //孔的数量
      plate: "", //只有路径数据 供转换DXF格式使用
      markers: "", //包含标记数据 给到SVG
      dxf: "",
      MyJSON: {
        plate: "", //初始只包含图 不包含出入刀线等信息
        cutPlate: "", // 包含出入刀线 和水刀相关
        markers: "", //包含标注的图片信息
        Rect: "", //玻璃尺寸
      },
      /****************/
      draw: "",
      svg: "",
      metaParameters: [
        { title: "width", type: "range", min: 1, max: 200, value: 50 },
        { title: "height", type: "range", min: 1, max: 100, value: 30 },
        { title: "hole radius", type: "range", min: 0, max: 10, value: 3 },
        { title: "rim", type: "range", min: 1, max: 20, value: 2 },
        { title: "fillet", type: "range", min: 0, max: 10, value: 5 },
      ], //画图参数
      operationHistory: [], //操作记录
      operationParam: "",
      dxfOptions: {
        //  units:'4',
      },
      renderOptions: {
        accuracy: 0.01,
        // origin: [0,0],
        strokeLineCap: "round",
        annotate: true,
        flow: { size: 8 },
        units: makerjs.unitType.Millimeter,
        svgAttrs: {
          id: "myDrawing",
          // style: "padding-left:" + 10 + "px; padding-top:" + 10 + "px",
        },
        strokeWidth: "0.3mm",
        fontSize: "16px",
        scale: 1,
        useSvgPathOnly: true,
        viewBox: true,
      },
      //todxf option
      /**
       * 对接水刀相关参数
       */
      isWaterJet: false,
      θCut: 45, //出入刀线角度，默认45
      rCut: 2.5, //出入刀线 切线圆的半径
      cutPlate: "",
      cutParam:{
        θCut: 45, //出入刀线角度，默认45
      rCut: 2.5, //出入刀线 切线圆的半径

      },//水刀相关参数
      /**
       * 对接水刀相关参数
       */
      /**
       * 对接博亮打孔机 参数
       */
      GlasslD: "GYID001", //玻璃ID
      Pieces: "1", //   未知
      GlassThickness: "5", //玻璃厚度
      gyName: "", //操作人员
      gySerNum: "", //服务器序号
      gySerCusName: "", //客户服务器名称
      HolesBrand:"",//打孔机品牌

      /**
       * 对接博亮打孔机 参数
       */
      gyErpParam: { OrderChildId: "", Name: "", Thick: "" },
      gyErpUrl: [
        "http://www.gyglass.cn/ghscan",
        "https://www.xd475.com/ghscan",
        "https://www.gy429.cn/ghscan",
        "https://www.gyxd475.cn/ghscan",
        "https://www.xd475.cn/ghscan",
        "https://www.w35db.cn/ghscan",
        "https://www.w35fb.cn/ghscan",
        "https://www.wn35fb.cn/ghscan",
        "https://www.wn35db.cn/ghscan",
        "https://www.gy429.com/ghscan",
        "https://www.gy065.cn/ghscan",
        "https://www.gy066.cn/ghscan",
      ],

      upUrl: "",
    };
  },
  computed: {
    canUndo() {
      console.log("this.stateIndex > 0=", this.stateIndex > 0);
      return this.stateIndex > 0;
    },
    canRedo() {
      console.log("this.stateIndex =", this.stateIndex);
      console.log("this.history.length - 1=", this.history.length - 1);
      return this.stateIndex < this.myStep;
    },
    cachedUserList() {
      // 如果缓存中已有数据，直接返回缓存数据
      if (localstorage.getItem("setSysStorage")) {
        return JsON.parse(localstorage.getItem("setSysStorage"));
      } else {
        //如果缓存中没有数据，发送网络请求获取数据
        //并将数据缓存到本地存储
        /*
axios.get("/api/userList").then((res)=>{
this.userList =res.data;
localstorage.setItem("userList",JsoN.stringify(res.data));});
return this.userList;
*/
      }
    },
  },
  mounted() {
    //创建SVG画布
    //this.$myutils.demos.demo(30,50,15);
    /**
 mounted() {
    const params = new URLSearchParams(window.location.search);
    let name = params.get('name'); // John
    let age = params.get('age'); // 30
    console.log(name, age);
  }
  */
    ////获取URL地址参数
    //http://localhost:8082/index.html?id=gy123&gyLen=800&gyWid=600&gyThick=8&gyName=8&gySer=9_ycyss2024
    const params = new URLSearchParams(window.location.search);
    //toERPParams
    console.log("params.size=", params.size);
    if (params.size == 0) {
      this.toERPParams = false;
    } else if (params.size > 0) {
      this.toERPParams = true;
      let id = params.get("id"); // ID
      this.GlasslD = id;
      console.log("id=", id);
      console.log("this.GlasslD=", this.GlasslD);
      //比较 值大的为长度，小的为宽度。

      let gyLen = params.get("gyLen"); // gyLen
      let gyWid = params.get("gyWid"); // gyWid
      if (gyLen * 1 >= gyWid * 1) {
        console.log("长=长");
        this.form.glassW = gyLen; // gyLen
        this.form.glassH = gyWid; // gyWid
      } else {
        this.form.glassW = gyWid; // gyLen
        this.form.glassH = gyLen; // gyWid
      }
      let gyThick = params.get("gyThick"); // gyThick
      let gyName = params.get("gyName"); // gyName
      let gySer = params.get("gySer"); // gySer
      this.GlassThickness = gyThick;
      this.gySer = gySer;
      this.gyName = gyName;
      let arr = gySer.split("_");
      //跨域
      /*************/
      /*************/
      // this.gySerNum=arr[0];//服务器序号
      // this.gySerCusName=arr[1];//客户服务器名称
      // this.upUrl='/nine/ghscan'+this.gySerCusName+'/ghscan.asmx/GetOkFromHuaTu';
      /*************/
      /*************/
      //同域
      /*************/
      /*************/
      let index = arr[0] - 1;
      let upSer = arr[1];
      this.upUrl = this.gyErpUrl[index] + upSer + "/ghscan.asmx/GetOkFromHuaTu";
      /*************/
      /*************/
      //var UPURL='/api'+this.gySerNum+'/ghscan'+this.gySerCusName+'/ghscan.asmx/GetOkFromHuaTu';
    }
  },
  // created(){
  //  console.log("this.axios",this.axios);

  //     //const data = this.gyErpParam;// 要传递给后端的数据
  //     //http://localhost:8082/?id=e0fcdd1afc0e4b5786140b2fa800d91f&gyLen=900&gyWid=800&gyThick=6&gyName=%E6%B5%8B%E8%AF%95&gySer=9_y

  //     this.gyErpParam.OrderChildId=this.GlasslD;
  //     this.gyErpParam.Name=this.gyName;
  //     this.gyErpParam.Thick=this.GlassThickness;
  //     const mystrOk = JSON.stringify(this.gyErpParam);// 要传递给后端的数据 ;// 要传递给后端的数据
  //     console.log("strOk==",mystrOk);
  //     console.log("upUrl==0621.000",this.upUrl);
  //     console.log("gySerNum=",this.gySerNum);
  //     console.log("gySerCusName",this.gySerCusName);
  //     console.log("'/api'+this.gySer+'/ghscan.asmx/GetOkFromHuaTu'=",'/api'+this.gySerNum+'/ghscan'+this.gySerCusName+'/ghscan.asmx/GetOkFromHuaTu')
  //     var UPURL='/api'+this.gySerNum+'/ghscan'+this.gySerCusName+'/ghscan.asmx/GetOkFromHuaTu';
  //     console.log("UPURL=",UPURL);
  //   this.axios({
  //     //url:'/api'+this.gySer+'/ghscan.asmx/GetOkFromHuaTu',
  //     url:UPURL,
  //     method:"POST",
  //     data:{strOk:mystrOk},
  //     headers: {
  //       'content-type': 'application/x-www-form-urlencoded'
  //     },
  //   }).then(res=>{
  //     console.log("res=",res);
  //     console.log("res=",res.data)
  //   })

  // },
  methods: {
    //不足两位用0补足两位
    //如果没有孔弹出消息提示框
    myOpenMsg(cont, title) {
      this.$alert(cont, title, {
        confirmButtonText: "确定",
        /*
          callback: action => {
            this.$message({
              type: 'info',
             message: `action: ${ action }`
            });
          }
          */
      });
    },

    /**上传数据到ERP当中 */
    sendDataToERP() {
      var _this = this;
      //const data = this.gyErpParam;// 要传递给后端的数据
      //http://localhost:8082/?id=e0fcdd1afc0e4b5786140b2fa800d91f&gyLen=900&gyWid=800&gyThick=6&gyName=%E6%B5%8B%E8%AF%95&gySer=9_y
      _this.gyErpParam.OrderChildId = _this.GlasslD;
      _this.gyErpParam.Name = _this.gyName;
      _this.gyErpParam.Thick = _this.GlassThickness;
      //const strOk = _this.gyErpParam;// 要传递给后端的数据 ;// 要传递给后端的数据
      const mystrOk = JSON.stringify(_this.gyErpParam); // 要传递给后端的数据 ;// 要传递给后端的数据
      console.log("strOk==", mystrOk);
      console.log("upUrl==0621.000", _this.upUrl);

      _this
        .axios({
          url: _this.upUrl,
          method: "POST",
          data: { strOk: mystrOk },
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          var mystringArr = res.data.split(">");
          console.log("test_mystringArr0" + mystringArr[0]);
          console.log("test_mystringArr1" + mystringArr[1]);
          var mystringArrs = mystringArr[2].split("<");
          var mydata = mystringArrs[0];
          console.log("mydata" + mydata);
        })
        .catch((err) => {
          console.log("*****0621.1345=", err);
        });
    },

    /*
和打孔机对接
*/
/**
 * 保存模板内容到数据库
 * 1、图片保存路径
 * 2、参数以json数据保存
 * 3、保存时间
 */
upLoadMould(){

},
    downloadHoles(filename, text) {
      // 创建一个临时的<a>标签
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      // 触发下载
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },

    // 使用函数生成.holes文件
    changSetThickness() {
      //   isType(null);//'[object null]'
      // const isType =(value)=>0bject,prototype,toString.call(value).toLowerCase();
      // isType(undefined);//'[object null]'isType([]);//'[object array]'
      // isType({});//'[object object]
      // isType(1);//'[object number]'
      // isType("1");//'[object string]'isType(true);//[object boolean]'
      
      if (this.operationHistory.length == 1) {
        this.thicknessSetVisible = false;
      } else if (this.operationHistory.length > 1) {
        this.thicknessSetVisible = false;
        if(this.HolesBrand=="boliang"){
          var headData = "[Glassspec]\n";
        var headID = "GlasslD=" + this.GlasslD + "\n";
        var headNThick =
          "GlassThickness=" +
          this.GlassThickness +
          "\n" +
          "Pieces=" +
          this.Pieces +
          "\n";
        //var rectL=`WID=${this.form.glassW}\nLEN=${this.form.glassH}\n[Holes]\n`;
        //var rectL="WID="+(this.form.glassW*1).toFixed(1)+"\nLEN="+(this.form.glassH*1).toFixed(1)+"\n[Holes]\n";
        var rectL =
          "WID=" +
          (this.form.glassH * 1).toFixed(1) +
          "\nLEN=" +
          (this.form.glassW * 1).toFixed(1) +
          "\n[Holes]\n";
        //var holesData=`X=100.0 Y=200.0 D=10.0 P=0\n`; // 这里应该是你的.holes文件的内容
        var holesData = `${headData}${headID}${headNThick}${rectL}${this.holeD}`;
        console.log("holesData=", holesData);
        this.downloadHoles(
          this.GlasslD + "_" + this.GlassThickness.padStart(2, "0") + ".holes",
          holesData
        );
      }else if(this.HolesBrand=="jingrui"){
        var headData = "[PicName]\n";
        var headID = "Name=001\n\n";
        var rectL =
          "[GlassWide]\nValue=" +
          (this.form.glassW * 1).toFixed(1) +
          "\n\n[GlassHeight]\nValue=" +
          (this.form.glassH * 1).toFixed(1) +
          "\n\n[Holes]\n";
          var Depth="[Depth]\nValue=\n";
        //var holesData=`X=100.0 Y=200.0 D=10.0 P=0\n`; // 这里应该是你的.holes文件的内容
        var holesData = `${headData}${headID}${rectL}${this.holeD}${Depth}`;
        console.log("holesData=", holesData);
        this.downloadHoles(
          this.GlasslD + "_" + this.GlassThickness.padStart(2, "0") + ".ini",
          holesData
        );
      }
       
        this.saveHolesPng();
        if (this.toERPParams == true) {
          this.sendDataToERP();
        }
      }
    },
    //系统设置
    saveSystemSet() {
      // renderOptions.fontSize
      // renderOptions.strokeWidth
      // zoomM
      /*
  setSysStorage:{
        fontSize:'16px',
        strokeWidth:'0.3mm',
        zoomM:1
      },//需要缓存数据
      */
      this.setSysStorage.fontSize = this.renderOptions.fontSize;
      this.setSysStorage.strokeWidth = this.renderOptions.strokeWidth;
      this.setSysStorage.zoomM = this.zoomM;

      global.localStorage.setItem(
        "sysStorage",
        JSON.stringify(this.setSysStorage)
      );
      const syStorData = JSON.parse(global.localStorage.getItem("sysStorage"));
      console.log("storage=", global.localStorage.getItem("sysStorage"));
      this.renderOptions.fontSize = syStorData.fontSize;
      this.renderOptions.strokeWidth = syStorData.strokeWidth;
      this.zoomM = syStorData.zoomM;
      this.systemSetVisible = false;
      console.log("this.zoomM=", this.zoomM);
    },
    handleHolesCommand(command){
      //this.$message('click on item ' + command);      
      this.HolesBrand=command;
      if(command=="boliang"){
        this.saveHolesBoliang();
      }else if(command=="jingrui"){
        this.saveHolesJingrui();
      }
    },
    //精锐打孔机
    saveHolesJingrui(){
      console.log(
        "this.operationHistory.length=",
        this.operationHistory.length
      );
      if (this.operationHistory.length <= 1) {
        this.myOpenMsg("没有图形，请先画图。", "提示：");
      } else {
        var arrH = [];
        console.log("this.operationHistory=", this.operationHistory);
        //console.log("typeOfthis.operationHistory=",typeof(this.operationHistory));
        console.log(
          "this.operationHistory.length=",
          this.operationHistory.length
        );
        for (let i = 1; i < this.operationHistory.length; i++) {
          if (this.operationHistory[i].type == "Hole") {
            /** 孔*/
            if (this.operationHistory[i].shape == "SingleHole") {
              /**
               * 单孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" + Hx + "mm\n" + "HoleY=" + Hy + "mm\n" + "HoleD=" + Hd + "mm\n" + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" + Hx + "mm\n" + "HoleY=" + Hy + "mm\n" + "HoleD=" + Hd + "mm\n" + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" + Hx + "mm\n" + "HoleY=" + Hy + "mm\n" + "HoleD=" + Hd + "mm\n" + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" + Hx + "mm\n" + "HoleY=" + Hy + "mm\n" + "HoleD=" + Hd + "mm\n" + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" + Hx + "mm\n" + "HoleY=" + Hy + "mm\n" + "HoleD=" + Hd + "mm\n" + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" + Hx + "mm\n" + "HoleY=" + Hy + "mm\n" + "HoleD=" + Hd + "mm\n" + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" + Hx + "mm\n" + "HoleY=" + Hy + "mm\n" + "HoleD=" + Hd + "mm\n" + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" + Hx + "mm\n" + "HoleY=" + Hy + "mm\n" + "HoleD=" + Hd + "mm\n" + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" + Hx + "mm\n" + "HoleY=" + Hy + "mm\n" + "HoleD=" + Hd + "mm\n" + "P=0\n";
              }

              /**
               * 单孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleTwo") {
              console.log("吊轮孔2");
              /**
               * 吊轮孔2
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                console.log("吊轮孔22222");
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }else if (this.operationHistory[i].area * 1 == 6) {
                console.log("吊轮孔22222");
                var Hx = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 吊轮孔2
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleThree") {
              /**
               * 吊轮孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hy3 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy3 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 吊轮孔3
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleTwoCol") {
              /**
               * 4、双吊轮孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);

                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }else if (this.operationHistory[i].area * 1 == 6) {
                var Hx1 = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)).toFixed(0);
                var Hx2 = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)-(this.operationHistory[i].H * 1)).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (this.form.glassH * 1 -this.operationHistory[i].Y * 1).toFixed(0);

                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 4、双吊轮孔列
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleTwoRow") {
              /**
               * 5、双吊轮孔排
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  +(this.operationHistory[i].W * 1) +
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy3 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy4 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy3 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy4 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 5、双吊轮孔排
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangHole") {
              /**
               * 6、晾物孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 6、晾物孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHoleTShaped") {
              /**
               * 7、拉手孔T字
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 7、拉手孔T字
               *
               *
               */
            } else if (this.operationHistory[i].shape == "DoubleFixHoles") {
              /**
               * 8、双固定孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 8、双固定孔
               *
               *
               */
            }else if (this.operationHistory[i].shape == "DoubleFixHolesCol") {
              /**
               * 8、双固定孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 =((this.form.glassH * 1)-(this.operationHistory[i].Y * 1)).toFixed(1);
                var Hy2 = ((this.form.glassH * 1)-(this.operationHistory[i].Y * 1)-(this.operationHistory[i].H * 1)).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = ((this.form.glassH * 1) -(this.operationHistory[i].Y * 1)).toFixed(1);
                var Hy2 = ((this.form.glassH * 1) -(this.operationHistory[i].Y * 1) -(this.operationHistory[i].H * 1)).toFixed(1);
                var Hx = ((this.form.glassW * 1)/2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (this.form.glassH * 1 -this.operationHistory[i].Y * 1 -this.operationHistory[i].H * 1).toFixed(1);
                var Hy2 = (this.form.glassH * 1 -this.operationHistory[i].Y * 1).toFixed(1);
                var Hx = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = ((this.form.glassH * 1) / 2+(this.operationHistory[i].H * 1/2)).toFixed(1);
                var Hy2 = ((this.form.glassH * 1) / 2-(this.operationHistory[i].H * 1/2)).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = ((this.form.glassH * 1) / 2 +(this.operationHistory[i].H * 1) / 2).toFixed(1);
                var Hy2 = ((this.form.glassH * 1) / 2 -(this.operationHistory[i].H * 1) / 2).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = ((this.form.glassH * 1) / 2 +(this.operationHistory[i].H * 1) / 2).toFixed(1);
                var Hy2 = ((this.form.glassH * 1) / 2 -(this.operationHistory[i].H * 1) / 2).toFixed(1);
                var Hx = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (this.operationHistory[i].Y * 1 +this.operationHistory[i].H * 1).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1) .toFixed(1);
                var Hy2 = ((this.operationHistory[i].Y * 1) +(this.operationHistory[i].H * 1) ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1 ).toFixed(1);
                var Hy2 = (this.operationHistory[i].Y * 1 +this.operationHistory[i].H * 1).toFixed(1);
                var Hx = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 8、双固定孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "RelSuWheelHole") {
              /**
               * 9、相对吊轮孔
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy3 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy4 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy5 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy6 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy3 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy4 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy5 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy6 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 9、相对吊轮孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHole") {
              /**
               * 10、拉手孔
               *
               */
              if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 10、拉手孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "RotatLockHole") {
              /**
               * 11、旋转锁孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 11、旋转锁孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHole3") {
              /**
               * 12、拉手孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx3 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 12、拉手孔3
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleCol") {
              /**
               * 13、吊轮孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 13、吊轮孔列
               *
               *
               */
            } else if (this.operationHistory[i].shape == "Shaped7HandleHole") {
              /**
               * 14、7字拉手孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(0);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 14、7字拉手孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "KeyHole") {
              /**
               * 15、锁孔
               *
               */
              if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
              }

              var Hd1 = (this.operationHistory[i].D1 * 1).toFixed(0);
              var Hd2 = (this.operationHistory[i].D2 * 1).toFixed(0);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd1 +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd2 +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx3 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd1 +
                "mm\n" +
                "P=0\n";

              /**
               * 15、锁孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles") {
              /**
               * 16、三孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = ((this.operationHistory[i].X * 1) + (this.operationHistory[i].W * 1)).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = ((this.form.glassW * 1) -(this.operationHistory[i].X * 1)).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) -(this.operationHistory[i].X * 1)-(this.operationHistory[i].W * 1)).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                 this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1)/2-(this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2+(this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)-
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = ((this.form.glassW * 1)-
                (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1)-
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 16、三孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles2") {
              /**
               * 17、三孔2
               *
               */
               if (this.operationHistory[i].area * 1 == 1) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx2 = (                 
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                 this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1)/2 -
                  (this.operationHistory[i].W * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1)/2 +
                  (this.operationHistory[i].W * 1)/2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1
                  ).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 17、三孔2
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles3") {
              /**
               * 18、三孔3
               *
               */
               if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy2 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy2 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy1 = (                  
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy2 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1)-
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1) 
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 18、三孔3
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles4") {
              /**
               * 19、三孔4
               *
               */
               if (this.operationHistory[i].area * 1 == 1) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                 this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1)-
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1)-
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                 this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 19、三孔4
               *
               *
               */
            }
            console.log("i=", i);
            arrH.push(holesDatas);
            /** */
          }
        }
        this.holeD= arrH.join("");
        console.log("arrH===", arrH);
        console.log("arrH.length", arrH.length);
        console.log("this.holeD.length", this.holeD.length);
        console.log("this.holeD", this.holeD);
        var arr=this.holeD.split("P=0\n"); 
        arr=arr.slice(0, arr.length - 1);       
        console.log("arr", arr);       
        console.log("arr.length", arr.length);
        var arrHole=[];
        for (let i = 0; i < arr.length; i++){
          var j=i*1+1;
          const targetString = "Hole";
         const replacementString = "Hole"+j;
         const regex = new RegExp(targetString, "g");
         const arrs=arr[i];
           var str=arrs.replace(regex, replacementString);
        console.log("j=", j);
        arrHole.push(str);   
        }  
        //this.holeD=                      
       // console.log("arrDate", arr);   
        console.log("arrHole=", arrHole); 
        this.holeD= arrHole.join("");      
        if (arrHole.length == 0) {
          this.myOpenMsg("您画的图中无孔，导出无效，请确认！", "提示：");
        } else {
          this.thicknessSetVisible = true;
        }
      }
    },
    //博亮打孔机
    saveHolesBoliang() {
      console.log(
        "this.operationHistory.length=",
        this.operationHistory.length
      );
      if (this.operationHistory.length <= 1) {
        this.myOpenMsg("没有图形，请先画图。", "提示：");
      } else {
        var arrH = [];
        console.log("this.operationHistory=", this.operationHistory);
        //console.log("typeOfthis.operationHistory=",typeof(this.operationHistory));
        console.log(
          "this.operationHistory.length=",
          this.operationHistory.length
        );
        for (let i = 1; i < this.operationHistory.length; i++) {
          if (this.operationHistory[i].type == "Hole") {
            /** 孔*/

            if (this.operationHistory[i].shape == "SingleHole") {
              /**
               * 单孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              }

              /**
               * 单孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleTwo") {
              console.log("吊轮孔2");
              /**
               * 吊轮孔2
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                console.log("吊轮孔22222");
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }else if (this.operationHistory[i].area * 1 == 6) {
                console.log("吊轮孔22222");
                var Hx = (( this.form.glassW * 1)-(this.operationHistory[i].X * 1)).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 吊轮孔2
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleThree") {
              /**
               * 吊轮孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hy3 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy3 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 吊轮孔3
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleTwoCol") {
              /**
               * 4、双吊轮孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);

                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }else if (this.operationHistory[i].area * 1 == 6){
                var Hx1 = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)).toFixed(1);
                var Hx2 = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)-(this.operationHistory[i].H * 1)).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (this.form.glassH * 1 -this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 4、双吊轮孔列
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleTwoRow") {
              /**
               * 5、双吊轮孔排
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  +(this.operationHistory[i].W * 1) +
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy3 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy4 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy3 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy4 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 5、双吊轮孔排
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangHole") {
              /**
               * 6、晾物孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 6、晾物孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHoleTShaped") {
              /**
               * 7、拉手孔T字
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 7、拉手孔T字
               *
               *
               */
            } else if (this.operationHistory[i].shape == "DoubleFixHoles") {
              /**
               * 8、双固定孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 8、双固定孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "DoubleFixHolesCol") {
              /**
               * 8、双固定孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 =((this.form.glassH * 1)-(this.operationHistory[i].Y * 1)).toFixed(1);
                var Hy2 = ((this.form.glassH * 1)-(this.operationHistory[i].Y * 1)-(this.operationHistory[i].H * 1)).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = ((this.form.glassH * 1) -(this.operationHistory[i].Y * 1)).toFixed(1);
                var Hy2 = ((this.form.glassH * 1) -(this.operationHistory[i].Y * 1) -(this.operationHistory[i].H * 1)).toFixed(1);
                var Hx = ((this.form.glassW * 1)/2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (this.form.glassH * 1 -this.operationHistory[i].Y * 1 -this.operationHistory[i].H * 1).toFixed(1);
                var Hy2 = (this.form.glassH * 1 -this.operationHistory[i].Y * 1).toFixed(1);
                var Hx = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = ((this.form.glassH * 1) / 2+(this.operationHistory[i].H * 1/2)).toFixed(1);
                var Hy2 = ((this.form.glassH * 1) / 2-(this.operationHistory[i].H * 1/2)).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = ((this.form.glassH * 1) / 2 +(this.operationHistory[i].H * 1) / 2).toFixed(1);
                var Hy2 = ((this.form.glassH * 1) / 2 -(this.operationHistory[i].H * 1) / 2).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = ((this.form.glassH * 1) / 2 +(this.operationHistory[i].H * 1) / 2).toFixed(1);
                var Hy2 = ((this.form.glassH * 1) / 2 -(this.operationHistory[i].H * 1) / 2).toFixed(1);
                var Hx = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (this.operationHistory[i].Y * 1 +this.operationHistory[i].H * 1).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1) .toFixed(1);
                var Hy2 = ((this.operationHistory[i].Y * 1) +(this.operationHistory[i].H * 1) ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1 ).toFixed(1);
                var Hy2 = (this.operationHistory[i].Y * 1 +this.operationHistory[i].H * 1).toFixed(1);
                var Hx = ((this.form.glassW * 1)-(this.operationHistory[i].X * 1)).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 8、双固定孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "RelSuWheelHole") {
              /**
               * 9、相对吊轮孔
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy3 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy4 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy5 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy6 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy3 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy4 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy5 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy6 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 9、相对吊轮孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHole") {
              /**
               * 10、拉手孔
               *
               */
              if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 10、拉手孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "RotatLockHole") {
              /**
               * 11、旋转锁孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 11、旋转锁孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHole3") {
              /**
               * 12、拉手孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx3 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 12、拉手孔3
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleCol") {
              /**
               * 13、吊轮孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 13、吊轮孔列
               *
               *
               */
            } else if (this.operationHistory[i].shape == "Shaped7HandleHole") {
              /**
               * 14、7字拉手孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";

              /**
               * 14、7字拉手孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "KeyHole") {
              /**
               * 15、锁孔
               *
               */
              if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              }

              var Hd1 = (this.operationHistory[i].D1 * 1).toFixed(1);
              var Hd2 = (this.operationHistory[i].D2 * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd1 +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd2 +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd1 +
                " " +
                "P=0\n";

              /**
               * 15、锁孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles") {
              /**
               * 16、三孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = ((this.operationHistory[i].X * 1) + (this.operationHistory[i].W * 1)).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  (this.form.glassH * 1) -
                  (this.operationHistory[i].Y * 1)
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) -
                  (this.operationHistory[i].Y * 1) -
                  (this.operationHistory[i].H * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = ((this.form.glassW * 1) -(this.operationHistory[i].X * 1)).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) -(this.operationHistory[i].X * 1)-(this.operationHistory[i].W * 1)).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                 this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1)/2-(this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2+(this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)-
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1)-
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1)-
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";

              /**
               * 16、三孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles2") {
              /**
               * 17、三孔2
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx2 = (                 
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                 this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1)/2 -
                  (this.operationHistory[i].W * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1)/2 +
                  (this.operationHistory[i].W * 1)/2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1
                  ).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              }
              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";

              /**
               * 17、三孔2
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles3") {
              /**
               * 18、三孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy2 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy2 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy1 = (                  
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy2 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1)-
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1) 
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";

              /**
               * 18、三孔3
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles4") {
              /**
               * 19、三孔4
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                 this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1)/2 +
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1)/2 -
                  (this.operationHistory[i].H * 1)/2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1)-
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1)-
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                 this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.operationHistory[i].X * 1) +
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1)
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) -
                  (this.operationHistory[i].X * 1) -
                  (this.operationHistory[i].W * 1)
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";

              /**
               * 19、三孔4
               *
               *
               */
            }
            console.log("i=", i);
            arrH.push(holesDatas);
            /** */
          }
        }
        this.holeD = arrH.join("");
        console.log("arrH===", arrH);
        console.log("arrH.length", arrH.length);
        console.log("this.holeD.length", this.holeD.length);
        if (arrH.length == 0) {
          this.myOpenMsg("您画的图中无孔，导出无效，请确认！", "提示：");
        } else {
          this.thicknessSetVisible = true;
        }
      }
    },
    saveHolesPng() {
      var svg = document.getElementById("myDrawing");
      console.log("svg=", svg.setAttribute.viewBox);
      const _this = this;
      var img = _this.export2Base64Img(svg, null, {
        //watermark: 'copyright reserved 2020 版权所有',
        background: "#fff",
      });
      img.then(function (base64src) {
        //console.log('路径:::', base64src)
        // console.log("this1=",_this)
        _this.downLoad(
          base64src,
          _this.GlasslD + "_" + _this.GlassThickness.padStart(2, "0") + ".png"
        );
        //console.log("this2=",_this)
      });
    },
    /*和打孔机对接*/
    // maker/js.exporter.toDXF
    //根据矩形的大小设置画面的大小及缩放比例
    setCanvas() {
      var w = this.form.glassW * 1, //1200  68vw
        h = this.form.glassH * 1; //600   75vh
      var myScaleW = (1200 / w).toFixed(1);
      var myScaleH = (600 / h).toFixed(1);
      console.log("wwwwwww=", w, "HHHH====", h);
      var drawCanvas = document.getElementById("drawCanvas");
      drawCanvas.style.display = "flex";
      drawCanvas.style.justifyContent = "center";
      drawCanvas.style.alignItems = "center";
      drawCanvas.style.height = 75 + "vh";
      drawCanvas.style.width = 68 + "vw";
      if (myScaleW < 1 || myScaleH < 1) {
        console.log("myScaleW<1||myScaleH<1");
        if (myScaleW <= myScaleH) {
          console.log("myScaleW<=myScaleH");
          drawCanvas.style.transform = "scale(" + myScaleW + ")"; // 设置缩小比例
        } else if (myScaleW > myScaleH) {
          console.log("myScaleW>myScaleH");
          drawCanvas.style.transform = "scale(" + myScaleH + ")"; // 设置缩小比例
        }
      } else {
        console.log("myScaleW>=1||myScaleH>=1");
        drawCanvas.style.transform = "scale(1)"; // 设置缩小比例
      }
      console.log("myScaleW==", myScaleW);
      console.log("myScaleH==", myScaleH);
    },
  
    //画出入刀线 出三（3mm）进五(5mm)  角度θ计算  S  出入刀线弧长 r0(弧度代表的圆坐标)
    /**
     * 孔坐标:(x0,y0) 半径 r
     * 1、和孔的交点:交点坐标(x1,y1); x1:孔的圆心X坐标值x0-r;y1:孔的圆心X坐标值y0;
     * 2、进刀线:s=Math.sqrt(2*r*r)  起点X坐标(x0-r+r0)起点Y坐标(y0-r0); 终点坐标  X坐标(x0-r)Y坐标(y0)
     * 3、出刀线:s=Math.sqrt(2*r*r)  起点X坐标(x0-r)起点Y坐标(y0); 终点坐标  X坐标(x0-r+r0)Y坐标(y0+r0)
     * 4、new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
     * 5、计算三个点的坐标
     */
   
    //判断对象属性是否存在
    objIsEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    //判断对象属性值是否为0
    objIsZero(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          console.log("obj[key]=", obj[key]);
          if (obj[key] == 0) {
            return true;
          } else {
            return false;
          }
          // 对属性值进行操作
        }
      }
    },

    /**
     * 只修改玻璃外框
     */
    changeRect() {
      this.changeGlassVisible = true;
    },
    //只修改玻璃尺寸，重新画图
    changeSetRect() {
      this.changeGlassVisible = false;
    console.log("this.markers=", this.markers);
    console.log("this.markers宽=", this.markers.models.myWidthMark.caption.text);
    console.log("this.markers高=", this.markers.models.myHeightMark.caption.text);
    this.markers.models.myWidthMark.caption.text="宽="+this.form.glassW;
    this.markers.models.myHeightMark.caption.text="高="+this.form.glassH;
      //console.log("this.operationHistory=", this.operationHistory);
      this.creatSvg(this.form.glassW, this.form.glassH, this.markers);
    },
     /**
     * 只修改玻璃外框
     */
    myTest0724() {
      console.log("this.plate=", this.plate);
      console.log("this.plate.models=", this.plate.models);
      console.log("this.cutPlate.models=", this.cutPlate.models);
      console.log("this.plate.models=", this.objIsEmpty(this.plate.models)); //如果返回true则表明为空，如果为false则不空。
      console.log("this.dxf=", this.dxf);
      /**
       * 20240724 展示图的位置，手动调整图的切割顺序
       *
       */
      //导出DXF之前把画图的示意图显示出来

      console.log("this.plate.models=", this.cutPlate.models);
      var objData = this.cutPlate.models;
      var arrs = [];
      for (let key in objData) {
        if (objData.hasOwnProperty(key)) {
          console.log(key); // 输出每个属性的名称
          var result = key.split("Area");
          console.log("result=", result);
          arrs.push(result[1]);
        }
      }
      console.log("arrs=", arrs);

      //this.dxf=makerjs.exporter.toDXF(this.model);
      // this.dxf=makerjs.exporter.toDXF(model);
      
    if(this.objIsEmpty(this.cutPlate.models)){
this.myOpenMsg("图形为空，请先画图。","提示：");
    }else{
      //基准点（Benchmark）位置 四个角 A、C、G、I （可以和区域位置进行对照）默认在G位置
      console.log("******************");
//var strings="0\nSECTION\n2\nHEADER\n9\n$ACADVER\n1\nAC1018\n9\n$ACADMAINTVER\n70\n104\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n"+this.form.glassW+"\n20\n"+this.form.glassW+"\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";
var strings="0\nSECTION\n2\nHEADER\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n"+this.form.glassW+"\n20\n"+this.form.glassW+"\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";

var splitStr="0\nSECTION\n2\nENTITIES\n";
      if(this.benchMark=="G"){
        var splitStrP="0\nPOINT\n8\n0\n10\n0\n20\n0\n";//加入基点坐标
      }else if(this.benchMark=="I"){
        var splitStrP="0\nPOINT\n8\n0\n10\n"+this.form.glassW+"\n20\n0\n";//加入基点坐标
      }
var arrs=this.dxf.split(splitStr);
var arrStr=splitStr+splitStrP;
console.log("arrStr=",arrStr);
console.log("arrs[0]=",arrs[0],"arrs[1]=",arrs[1]);
this.dxf=strings+arrStr+arrs[1];
console.log("this.dxf******=",this.dxf);
this.saveMyDXF();
    }

    },
    creatDraw(myMarkers, myPlate, cutPlate, glassW, glassH) {
      console.log("测试添加是否调用0" + JSON.stringify(this.plate));
      console.log("测试添加是否调用0+this.stateIndex" + this.stateIndex);
      console.log("测试添加是否调用0+this.myStep" + this.myStep);
      document.getElementById("drawCanvas").innerHTML = "";
      console.log("测试添加是否调用*************" + JSON.stringify(myMarkers));
      var svg = makerjs.exporter.toSVG(myMarkers, this.renderOptions);
      console.log("测试添加是否调用*************");

      /*
      var objB = new ClassB(myPlate, "", "John");
      objB.sayName();
      */
      //console.log("myPlate====20240617****",this.operationHistory);
      //console.log("myPlate====20240722****",myPlate);
      //console.log("cutPlate====20240722",cutPlate);
      // console.log("this.dxf+01=",makerjs.exporter.toDXF(myPlate));
      //console.log("this.dxf+02=",makerjs.exporter.toDXF(cutPlate));

      //myPlate.origin=[0,0]
      //this.dxf = makerjs.exporter.toDXF(myPlate);
      this.dxf = makerjs.exporter.toDXF(cutPlate);
      document.getElementById("drawCanvas").innerHTML = svg;
      //重写SVG
      var myDrawing = document.getElementById("myDrawing").innerHTML;
      // console.log("myDrawing===",myDrawing);
      var html =
        '<svg width="' +
        (glassW * 1 + 100) +
        '" height="' +
        (glassH * 1 + 100) +
        '" viewBox="-50 -50 ' +
        (glassW * 1 + 100) +
        " " +
        (glassH * 1 + 100) +
        '" xmlns="http://www.w3.org/2000/svg" id="myDrawing">';
      var mySVGHtml = html + myDrawing + "</svg>";
      // console.log("mySVGHtml===",mySVGHtml) ;
      document.getElementById("drawCanvas").innerHTML = "";
      document.getElementById("drawCanvas").innerHTML = mySVGHtml;
    },
    //删除画布中的所有内容，
    clearSvg() {
      document.getElementById("drawCanvas").innerHTML = "";
      this.plate = {}; //当前状态
      this.markers = {}; //当前状态
      this.history = []; // 存储历史记录
      this.future = []; // 存储未来的操作记录
      this.currentState = {}; // 当前状态  Initial state 初始状态
      this.operationCurrent = {}; // 当前状态  Initial state 初始参数状态
      this.operationHistory = []; // 存储历史记录的参数记录
      this.operationFuture = []; // 存储未来的参数记录
      this.myHolesNum = 0;
    },
    //单选位置事件
    pationChange(e) {
      console.log("e=", e);
      this.showX = false;
          this.showY = false;
           switch (e) {
        case "1":
          this.showX = false;
          this.showY = false;
          break;
        case "2":
          console.log("this.tabLabel======",this.tabLabel)
          if(this.tabLabel=="拉手孔"){
            this.showX = true;
          this.showY = true;
          this.X = 0;
          this.Y = 0;
          }else{
            console.log("this.tabLabel******",this.tabLabel)
            this.showX = true;
          this.showY = false;
          this.X = 0;
          }
          break;
        case "3":
          this.showX = false;
          this.showY = false;
          break;
        case "4":
        console.log("this.tabLabel======",this.tabLabel)
          if(this.tabLabel=="吊轮孔2"||this.tabLabel=="双吊轮孔列"){
            this.showX = false;
          this.showY = false;
          }else{
            console.log("this.tabLabel******",this.tabLabel)
            this.showX = false;
          this.showY = true;
          this.Y = 0;
          }
          break;
        case "5":
          this.showX = true;
          this.showY = true;
          this.X = 0;
          this.Y = 0;
          break;
        case "6":
        console.log("this.tabLabel======",this.tabLabel)
          if(this.tabLabel=="吊轮孔2"||this.tabLabel=="双吊轮孔列"){
            this.showX = false;
          this.showY = false;
          }else{
            console.log("this.tabLabel******",this.tabLabel)
            this.showX = false;
          this.showY = true;
          this.Y = 0;
          }
          break;
        case "7":
          this.showX = false;
          this.showY = false;
          break;
        case "8":         
        if(this.tabLabel=="拉手孔"){
            this.showX = true;
          this.showY = true;
          this.X = 0;
          this.Y = 0;
          }else{
            console.log("case8this.tabLabel******",this.tabLabel)
            this.showX = true;
          this.showY = false;
          this.X = 0;
          }
          break;
        case "9":
          this.showX = false;
          this.showY = false;
          break;
        default:
        // code block
      }
    },
    //初始状态
    initState() {
      this.dialogFormVisible = false;
      this.setCanvas();
      this.plate = {}; //当前状态
      this.markers = {}; //当前状态
      this.history = []; // 存储历史记录
      this.future = []; // 存储未来的操作记录
      this.currentState = {}; // 当前状态  Initial state 初始状态
      this.operationCurrent = {}; // 当前状态  Initial state 初始参数状态
      this.operationHistory = []; // 存储历史记录的参数记录
      this.operationFuture = []; // 存储未来的参数记录
      this.myHolesNum = 0;
      this.dialogFormVisible = true;
    },
    //深层拷贝
    deepCopy(obj) {
      if (obj === null || typeof obj !== "object") {
        return obj; // 基本类型，直接返回
      }

      if (obj instanceof Date) {
        return new Date(obj.getTime());
      }

      if (obj instanceof Array) {
        return obj.reduce((arr, item, i) => {
          arr[i] = this.deepCopy(item);
          return arr;
        }, []);
      }

      if (obj instanceof Object) {
        return Object.keys(obj).reduce((newObj, key) => {
          newObj[key] = this.deepCopy(obj[key]);
          return newObj;
        }, {});
      }
    },
    // 用于更新状态并添加到历史记录中
    updateState() {
      this.currentState = {
        showX: this.showX,
        showY: this.showY,
        activeName: this.activeName,
        form: {
          glassW: this.form.glassW,
          glassH: this.form.glassH,
        },
        //区域位置选择单选
        radio: this.radio,
        //三孔不同类型选择单选
        threeHoleType: this.threeHoleType,
        //三孔示意图参数
        threeHolePic: this.threeHolePic,
        //三孔调用函数名称变化参数
        threeHoleFn: this.threeHoleFn,
        //水刀是否选择
        checked: this.checked,
        //X、Y偏移值 初始值
        X: this.X,
        Y: this.Y,
        D: this.D,
        R: this.R,
        Rx: this.Rx,
        Ry: this.Ry,
        R1: this.R1,
        R2: this.R2,
        D1: this.D1,
        D2: this.D2,
        H: this.H,
        H1: this.H1,
        H2: this.H2,
        W: this.W,
        W1: this.W1,
        W2: this.W2,
        //画图相关参数
        myHolesNum: this.myHolesNum, //孔的数量
        plate: this.plate, //只有路径数据 供转换DXF格式使用
        markers: this.markers, //包含标记数据 给到SVG
        dxf: this.dxf,
      };
      const cur = JSON.parse(JSON.stringify(this.currentState));
      const curOpt = JSON.parse(JSON.stringify(this.operationCurrent));
      this.history.push(cur); //每次操作记录保存
      this.operationHistory.push(curOpt); //每次操作参数记录保存
      this.future = []; // 更新状态后，清空未来操作记录
      this.operationFuture = []; // 更新状态后，清空未来参数记录
      this.stateIndex = this.history.length;
      this.myStep++;
      console.log("this.stateIndex=", this.stateIndex);
    },
    //撤销操作 用于从历史记录中弹出状态，并将当前状态放入未来记录中。
    /*
      isDrawUndo:true,
      isDrawRedo:true,
      isClearSvg:true,
*/
    //撤销操作
    drawUndo(index) {
      console.log("drawUndo");
      this.stateIndex = index;
      const cur = JSON.parse(JSON.stringify(this.currentState));
      const curOpt = JSON.parse(JSON.stringify(this.operationCurrent));
      this.future.push(cur); // 当前状态放入未来记录
      this.operationFuture.push(curOpt); //每次操作参数记录保存
      this.history.pop(); // 从历史记录中移除最后一项
      const operationHistory = this.operationHistory.pop(); // 从历史记录中移除最后一项参数
      if (this.history.length === 0) {
        document.getElementById("drawCanvas").innerHTML = "";
      } else {
        this.currentState = JSON.parse(
          JSON.stringify(this.history[this.history.length - 1])
        );
        console.log("this.currentState=", this.currentState);
        this.operationCurrent = JSON.parse(JSON.stringify(operationHistory));
        var _this = JSON.parse(JSON.stringify(this.currentState));
        this.creatDraw(
          _this.markers,
          _this.plate,
          _this.form.glassW,
          _this.form.glassH
        );
      }
      console.log("drawUndothis.history=", this.history);
    },
    //恢复操作 将未来记录中的状态弹出，并放入历史记录中。
    drawRedo(index) {
      console.log("drawRedo");
      this.stateIndex = index;
      const cur = JSON.parse(JSON.stringify(this.currentState));
      const curOpt = JSON.parse(JSON.stringify(this.operationCurrent));
      console.log("cur==", cur);
      this.history.push(cur); // 当前状态放入历史记录
      this.operationHistory.push(curOpt); // 当前参数放入历史记录
      const fut = this.future.pop();
      const oper = this.operationFuture.pop();
      this.currentState = JSON.parse(JSON.stringify(fut)); // 从未来记录中弹出状态
      this.operationCurrent = JSON.parse(JSON.stringify(oper)); // 从未来记录中弹出参数
      var _this = JSON.parse(JSON.stringify(this.currentState));
      this.creatDraw(
        _this.markers,
        _this.plate,
        _this.form.glassW,
        _this.form.glassH
      );
      console.log("drawRedothis.history=", this.history);
    },
    //单孔不同选项切换
    handleClickHole(tab, event) {
      console.log("tab, event");
      console.log("tab==", tab._props.name);
      console.log("tab.label=",tab.label);
      console.log(tab);
      console.log(event);
      this.tabLabel=tab.label;
      this.showX = false;
      this.showY = false;
      if (
        tab.label == "吊轮孔2" ||
        tab.label == "吊轮孔3" ||
        tab.label == "双吊轮孔列" ||
        tab.label == "拉手孔" ||
        tab.label == "双吊轮孔排" ||
        tab.label == "相对吊轮孔"
      ) {
        this.radio = "4";
        this.showX = false;
        this.showY = true;
        this.Y = 0;
        this.pationChange(2);
      } else if (
        tab.label == "锁孔" 
      //   ||
      //  tab.label == "三孔" ||
      //   tab.label == "三孔2" ||
      //   tab.label == "三孔3" ||
      //  tab.label == "三孔4"
      ) {
        this.radio = "8";
        this.X = 0;
        this.pationChange(2);
      } else {
        this.radio = "7";
        this.pationChange(7);
      }
    },
    //缺不同选项切换
    handleClickGap(tab, event) {
      console.log("tab, event");      
      this.tabLabel=tab.label;
      this.showX = false;
      this.showY = true;
      this.Y = 0;
      if (tab.label == "矩形缺" || tab.label == "椭圆") {
        this.radio = "7";
        this.showY = false;
        this.showX = false;
        this.pationChange(7);
      } else if (tab.label == "蝴蝶") {
        this.R = "7";
        this.H = "58";
        this.W = "37.5";
      } else if (tab.label == "蝴蝶2") {
        this.R = "8";
        this.H = "58";
        this.H1 = "61";
        this.W = "37.5";
        this.W1 = "40";
      } else if (tab.label == "蝴蝶3") {
        this.R1 = "3";
        this.R2 = "7";
        this.H = "58";
        this.H1 = "58";
        this.W = "28";
        this.W1 = "34";
        this.W2 = "37";
      } else {
        this.R1 = "0";
        this.R2 = "0";
        this.H = "0";
        this.H1 = "0";
        this.W = "0";
        this.W1 = "0";
        this.W2 = "0";
      }
    },
    //角不同选项切换
    handleClickCorner(tab, event) {      
      this.tabLabel=tab.label;
      this.radio = "7";
      this.pationChange(1);
      if (tab.label == "地弹簧3") {
        this.D = "20";
        this.H = "36";
        this.H1 = "35";
        this.R = "75";
        this.W1 = "146";
        this.W2 = "125";
      } else if (tab.label == "地弹簧2") {
        this.D = "20";
        this.H = "36";
        this.W = "68";
        this.H1 = "36";
        this.R = "80";
        this.W1 = "146";
        this.W2 = "161";
      } else if (tab.label == "地弹簧") {
        this.D = "20";
        this.H = "37";
        this.W = "68";
        this.H1 = "36";
        this.R1 = "80";
        this.R2 = "55";
        this.W1 = "146";
        this.W2 = "161";
      } else {
        this.D = "0";
        this.H = "0";
        this.W = "0";
        this.H1 = "0";
        this.R1 = "0";
        this.R = "0";
        this.R2 = "0";
        this.W1 = "0";
        this.W2 = "0";
      }
      console.log("tab, event");
      console.log("tab==", tab);
      console.log("tab.label==", tab.label);
      console.log("tab._props.label==", tab._props.label);
      console.log("event==", event);
    },
    handleClickCard(tab, event) {      
      this.tabLabel=tab.label;
      this.activeName = "first";
      this.radio = "7";
      if (tab.label == "缺") {
        this.R = "7";
        this.H = "58";
        this.W = "37.5";
        this.X = 0;
        this.showX = false;
        this.showY = true;
      } else if (tab.label == "孔") {
        this.R = "0";
        this.H = "0";
        this.W = "0";
        this.showX = false;
        this.showY = false;
      } else {
        this.R = "0";
        this.H = "0";
        this.W = "0";
        this.showX = true;
        this.showY = true;
        this.X = 0;
        this.Y = 0;
      }
    },
    /*****画图*****/
    // 矩形玻璃   
    creatRect() {
      this.dialogFormVisible = false;
      this.setCanvas();
      this.plate = {};
      this.cutPlate = {};
      this.markers = {};
      this.history = []; // 存储历史记录
      this.future = []; // 存储未来的操作记录

      this.currentState = {}; // 当前状态  Initial state 初始状态
      this.operationCurrent = {}; // 当前状态  Initial state 初始参数状态
      this.operationHistory = []; // 存储历史记录的参数记录
      this.operationFuture = []; // 存储未来的参数记录
      this.myHolesNum = 0;
      var w = this.form.glassW * 1,
        TxtW = "宽=" + w.toString(),
        h = this.form.glassH * 1,
        TxtH = "高=" + h.toString();
      console.log("w=", w, "h=", h);
      if (w == h) {
        var myRect = new makerjs.models.Square(w);
      } else {
        var myRect = new makerjs.models.Rectangle(w, h);
      }
      this.plate = {
        models: {
          //myRect:myRect
        },
      };
      this.cutPlate = {
        models: {
          //myRect:myRect
        },
      };
      var myWidthMark = new makerjs.models.Square(0);
      var myHeightMark = new makerjs.models.Square(0);

      makerjs.model.addPath(
        myWidthMark,
        new makerjs.paths.Line(
          [0, -this.markShift + 5],
          [0, -this.markShift - 5]
        )
      ); //长度左竖短标线
      makerjs.model.addPath(
        myWidthMark,
        new makerjs.paths.Line(
          [w, -this.markShift + 5],
          [w, -this.markShift - 5]
        )
      ); //长度右竖短标线
      makerjs.model.addPath(
        myWidthMark,
        new makerjs.paths.Line([0, -this.markShift], [w, -this.markShift])
      ); //长度标线
      makerjs.model.addCaption(
        myWidthMark,
        TxtW,
        [0, -this.markShift - 8],
        [w, -this.markShift - 8]
      ); //长度文字

      makerjs.model.addPath(
        myHeightMark,
        new makerjs.paths.Line(
          [-this.markShift + 5, 0],
          [-this.markShift - 5, 0]
        )
      ); //宽度标线
      makerjs.model.addPath(
        myHeightMark,
        new makerjs.paths.Line(
          [-this.markShift + 5, h],
          [-this.markShift - 5, h]
        )
      ); //宽度标线
      makerjs.model.addPath(
        myHeightMark,
        new makerjs.paths.Line([-this.markShift, 0], [-this.markShift, h])
      ); //宽度标线
      makerjs.model.addCaption(
        myHeightMark,
        TxtH,
        [-this.markShift - 8, 0],
        [-this.markShift - 8, h]
      ); //宽度文字
      this.markers = {
        models: {
          myWidthMark: myWidthMark,
          myHeightMark: myHeightMark,
          myRect: myRect,
        },
      };
      var svg = makerjs.exporter.toSVG(this.markers, this.renderOptions);
      //20240722 this.dxf = makerjs.exporter.toDXF(this.plate);
      this.dxf = makerjs.exporter.toDXF(this.cutPlate);
      document.getElementById("drawCanvas").innerHTML = svg;

      this.creatSvg(this.form.glassW, this.form.glassH, this.markers);

      //       //重写SVG    2024.07.25
      //       var myDrawing=document.getElementById("myDrawing").innerHTML;
      //       console.log("myDrawing===",myDrawing);
      //       var html='<svg width="'+(this.form.glassW * 1+100)+'" height="'+(this.form.glassH * 1+100)+'" viewBox="-50 -50 '+(this.form.glassW * 1+100)+' '+(this.form.glassH * 1+100)+'" xmlns="http://www.w3.org/2000/svg" id="myDrawing">';
      //       var mySVGHtml=html+myDrawing+'</svg>';
      //       console.log("mySVGHtml===",mySVGHtml) ;
      //       document.getElementById("drawCanvas").innerHTML = "";
      //       document.getElementById("drawCanvas").innerHTML = mySVGHtml;
      //       this.updateState();
      // console.log("renderOptions======",this.renderOptions);
    },
    //重新画图
    creatSvg(w, h, maker) {
      var x = document.getElementById("drawCanvas");
      x.innerHTML = "";
      var svg = makerjs.exporter.toSVG(maker, this.renderOptions);
      document.getElementById("drawCanvas").innerHTML = svg;
      //重写SVG
      var myDrawing = document.getElementById("myDrawing").innerHTML;
      console.log("myDrawing===", myDrawing);
      var html =
        '<svg width="' +
        (w * 1 + 100) +
        '" height="' +
        (h * 1 + 100) +
        '" viewBox="-50 -50 ' +
        (w * 1 + 100) +
        " " +
        (h * 1 + 100) +
        '" xmlns="http://www.w3.org/2000/svg" id="myDrawing">';
      var mySVGHtml = html + myDrawing + "</svg>";
      document.getElementById("drawCanvas").innerHTML = "";
      document.getElementById("drawCanvas").innerHTML = mySVGHtml;
      this.updateState();

      //         var x = document.getElementById("drawCanvas");
      //         x.innerHTML = "";
      //         x.innerHTML = content;

      //         var svg = makerjs.exporter.toSVG(this.markers,this.renderOptions);
      //      //20240722 this.dxf = makerjs.exporter.toDXF(this.plate);
      //      this.dxf = makerjs.exporter.toDXF(this.cutPlate);
      //       document.getElementById("drawCanvas").innerHTML = svg;
      //       //重写SVG
      //       var myDrawing=document.getElementById("myDrawing").innerHTML;
      //       console.log("myDrawing===",myDrawing);
      //       var html='<svg width="'+(this.form.glassW * 1+100)+'" height="'+(this.form.glassH * 1+100)+'" viewBox="-50 -50 '+(this.form.glassW * 1+100)+' '+(this.form.glassH * 1+100)+'" xmlns="http://www.w3.org/2000/svg" id="myDrawing">';
      //       var mySVGHtml=html+myDrawing+'</svg>';
      //       console.log("mySVGHtml===",mySVGHtml) ;
      //       document.getElementById("drawCanvas").innerHTML = "";
      //       document.getElementById("drawCanvas").innerHTML = mySVGHtml;
      //       this.updateState();
      // console.log("renderOptions======",this.renderOptions);
    },
    /***孔***/
    //一级调用，在页面中用
    drawLevelOne(type, shape) {
      /**
       *  shape:'SingleHole',
       *  type:'Hole'  'Gap'  'corner'
       *
       *
       *
       *
       */

      //调用判断参数是否为0的函数  不能为0的参数进行判断，参数除了XY和输入参数数量相同
      //var arr=this.isParamZero(type,shape);
      //console.log("arr[0]=",arr[0]);
      //console.log("arr[1]=",arr[1]);
      this.isParamZero(type, shape);
      console.log("this.operationParam=", this.operationParam);
      if (this.history.length === 0) {
        this.initState();
      } else {
        if (this.plate) {
          if (this.isParamZero(type, shape)) {
            this.$message({
              showClose: false,
              message: "贵友提示您：请输入孔的直径D！",
              type: "warning",
              offset: "200",
            });
            return;
          }
          this.operationCurrent = this.deepCopy(this.operationParam);
          if (
            JSON.stringify(this.operationHistory).includes(
              JSON.stringify(this.operationParam)
            ) === false
          ) {
            this.callDrawFun(type, shape);
          } else {
            console.log("已存在");
            this.$message({
              showClose: false,
              message: "贵友提示您：不能重复添加相同图形，请检查参数！",
              type: "warning",
              offset: "200",
            });
          }
        } else {
          this.dialogFormVisible = true;
        }
      }
    },
    //判断参数是否为0
    isParamZero(type, shape) {
      /**
       *  shape:'SingleHole',
       *  type:'Hole'  'Gap'  'corner'
       */

      console.log("isParamZero");
      console.log("type=", type);
      console.log("shape=", shape);
      var ParamObj = {
        //1、 单孔
        SingleHole: {
          D: this.D,
        },
        //2、吊轮孔2
        HangWheelHoleTwo: {
          D: this.D,
        },
        //3、吊轮孔3
        HangWheelHoleThree: {
          D: this.D,
        },
        //4、双吊轮孔列
        HangWheelHoleTwoCol: {
          D: this.D,
          H: this.H,
        },
        //5、双吊轮孔排
        HangWheelHoleTwoRow: {
          D: this.D,
          W: this.W,
        },
        //6、晾物孔
        HangHole: {
          D: this.D,
          W: this.W,
          H1: this.H1,
          H2: this.H2,
        },
        //7、拉手孔T字
        HandleHoleTShaped: {
          D: this.D,
          W: this.W,
          H1: this.H1,
          H2: this.H2,
        },
        //8、双固定孔
        DoubleFixHoles: {
          D: this.D,
          W: this.W,
        },
        //8、双固定孔列
        DoubleFixHolesCol: {
          D: this.D,
          H: this.H,
        },
        //9、相对吊轮孔
        RelSuWheelHole: {
          D: this.D,
          W: this.W,
        },
        //10、拉手孔
        HandleHole: {
          D: this.D,
          W: this.W,
        },
        //11、旋转锁孔
        RotatLockHole: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //12、拉手孔3
        HandleHole3: {
          D: this.D,
          H: this.H,
        },
        //13、吊轮孔列
        HangWheelHoleCol: {
          D: this.D,
          H: this.H,
        },
        //14、7字拉手孔
        Shaped7HandleHole: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //15、锁孔
        KeyHole: {
          D1: this.D1,
          D2: this.D2,
          W: this.W,
          H: this.H,
        },
        //16、三孔
        ThreeHoles: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //17、三孔2
        ThreeHoles2: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //18、三孔3
        ThreeHoles3: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //19、三孔4
        ThreeHoles4: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        // *****缺*****
        //1、蝴蝶
        ButterflyGap: {
          R: this.R,
          W: this.W,
          H: this.H,
        },
        //1.2、蝴蝶2
        ButterflyGap2: {
          R: this.R,
          W: this.W,
          H: this.H,
          W1: this.W1,
          H1: this.H1,
        },
        //1.3、蝴蝶3
        ButterflyGap3: {
          R1: this.R1,
          R2: this.R2,
          W: this.W,
          H: this.H,
          W1: this.W1,
          W2: this.W2,
          H1: this.H1,
        },
        //2、U型
        UShapeGap: {
          D: this.D,
          W: this.W,
        },
        //3、应力孔
        StressHoleGap: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //4、跑道缺
        RunwayGap: {
          W: this.W,
          W1: this.W1,
          H: this.H,
          H1: this.H1,
        },
        //5、1方缺
        SquareGap: {
          W: this.W,
          H: this.H,
          R: this.R,
        },
        //5、2矩形缺
        RectAngleGap: {
          W: this.W,
          H: this.H,
          R: this.R,
        },
        //6、铜夹
        CopperClipGap: {
          D: this.D,
          W: this.W,
          W1: this.W1,
          H: this.H,
          R: this.R,
        },
        //7、长条夹
        LongClipGap: {
          D: this.D,
          D1: this.D1,
          W: this.W,
          W1: this.W1,
        },
        //8、椭圆
        EllipseGap: {
          Rx: this.Rx,
          Ry: this.Ry,
        },

        //********角*******
        //1、斜角 corner
        ObliqueAngle: {
          W: this.W,
          H: this.H,
        },
        //2、圆角
        Fillet: {
          R: this.R,
        },
        //3、方角
        Corner: {
          R: this.R,
          W: this.W,
          H: this.H,
        },
        //4、圆角2
        Fillet2: {
          R: this.R,
          W: this.W,
          H: this.H,
        },
        //5、地弹簧
        FloorSpring: {
          D: this.D,
          H: this.H,
          H1: this.H1,
          R1: this.R1,
          R2: this.R2,
          W: this.W,
          W1: this.W1,
          W2: this.W2,
        },
        //6、地弹簧2
        FloorSpring2: {
          D: this.D,
          H: this.H,
          H1: this.H1,
          R: this.R,
          W: this.W,
          W1: this.W1,
          W2: this.W2,
        },
        //7、地弹簧3
        FloorSpring3: {
          D: this.D,
          H: this.H,
          H1: this.H1,
          R: this.R,
          W1: this.W1,
          W2: this.W2,
        },
      };
      var SameObj = {
        X: this.X,
        Y: this.Y,
        area: this.radio,
        shape: shape,
        type: type,
      };
      //this.objIsEmpty();
      console.log("ParamObj[shape]=", ParamObj[shape]);
      var obj = ParamObj[shape];
      //var arr=[];
      this.operationParam = { ...ParamObj[shape], ...SameObj };
      //arr[1]=this.objIsZero(obj);
      //console.log("this.objIsZero=",this.objIsZero(obj));
      return this.objIsZero(obj);
      /*
   if(this.history.length===0){
  this.initState();
    }else{
      if(this.plate){
        if(this.D==0) {
        this.$message({
          showClose: false,
          message: '贵友提示您：请输入孔的直径D！',
          type: 'warning',
          offset:'200'
        });
        return
      }
      */
    },
    //根据实际情况调用基本图形函数
    callDrawFun(type, shape) {
      var model = {
        plate: this.plate,
        markers: this.markers,
        cutPlate: this.cutPlate,
      };
      var fixedParam = {
        markShift: this.markShift,
        markShiftTwo: this.markShiftTwo,
        markShiftThree: this.markShiftThree,
        markShiftFour: this.markShiftFour,
        markHolesOneD: this.markHolesOneD,
      };

      console.log("callDrawFun");
      console.log("this.operationParam", this.operationParam);
      //console.log("this.model",this.model);
      console.log("this.myHolesNum", this.myHolesNum);
      console.log("this.form", this.form);
      // console.log("this.fixedParam",this.fixedParam);
      console.log("this.zoomM", this.zoomM);
      console.log("this.θCut", this.θCut);
      console.log("this.rCut", this.rCut);
      console.log("callDrawFun");

      var myobj = this.$myutils[shape](
        this.operationParam,
        model,
        this.myHolesNum,
        this.form,
        fixedParam,
        this.zoomM,
        this.θCut,
        this.rCut
      );
      console.log(
        "myobj.plate******=",
        JSON.parse(JSON.stringify(myobj.plate.models))
      );
      console.log(
        "myobj.markers******=",
        JSON.parse(JSON.stringify(myobj.markers.models))
      );
      /*
      console.log(
        "myobj.cutPlate******=",
        JSON.parse(JSON.stringify(myobj.cutPlate.models))
      );
      */
      this.creatDraw(
        myobj.markers,
        myobj.plate,
        myobj.cutPlate,
        this.form.glassW,
        this.form.glassH
      );
      this.plate = this.deepCopy(myobj.plate);
      this.markers = this.deepCopy(myobj.markers);
      this.cutPlate = this.deepCopy(myobj.cutPlate);
      this.myHolesNum = this.deepCopy(myobj.myHolesNum);
      // this.myHolesNum=JSON.parse(JSON.stringify(myobj.myHolesNum));
      this.updateState();
    },
    //三孔类型单选事件
    threeHolesChange(e) {
      console.log("e=", e);
      switch (e) {
        case "A":
          //三孔不同类型选择单选
          this.threeHoleType = "A";
          //三孔示意图参数
          this.threeHolePic = require("../assets/image/ThreeHoles.jpg");
          //三孔调用函数名称变化参数
          this.threeHoleFn = "drawLevelOne('Hole','ThreeHoles')";
          break;
        case "B":
          //三孔不同类型选择单选
          this.threeHoleType = "B";
          //三孔示意图参数
          this.threeHolePic = require("../assets/image/ThreeHoles2.jpg");
          //三孔调用函数名称变化参数
          this.threeHoleFn = "drawLevelOne('Hole','ThreeHoles2')";
          break;
        case "C":
          //三孔不同类型选择单选
          this.threeHoleType = "C";
          //三孔示意图参数
          this.threeHolePic = require("../assets/image/ThreeHoles3.jpg");
          //三孔调用函数名称变化参数
          this.threeHoleFn = "drawLevelOne('Hole','ThreeHoles3')";
          break;
        case "D":
          //三孔不同类型选择单选
          this.threeHoleType = "D";
          //三孔示意图参数
          this.threeHolePic = require("../assets/image/ThreeHoles4.jpg");
          //三孔调用函数名称变化参数
          this.threeHoleFn = "drawLevelOne('Hole','ThreeHoles4')";
          break;
        default:
        // code block
      }
    },
    threeHoleFnClick() {
      if (this.threeHoleType == "A") {
        this.drawLevelOne("Hole", "ThreeHoles");
      }
      if (this.threeHoleType == "B") {
        this.drawLevelOne("Hole", "ThreeHoles2");
      }
      if (this.threeHoleType == "C") {
        this.drawLevelOne("Hole", "ThreeHoles3");
      }
      if (this.threeHoleType == "D") {
        this.drawLevelOne("Hole", "ThreeHoles4");
      }
    },
    //***************异形*************/
    handleClickSpecialShaped() {},
    saveMySvg() {
      var fileNames=this.creatDate();
      var x = document.getElementById("drawCanvas").innerHTML;
      // 创建一个 Blob 对象并设置 MIME 类型为 image/svg+xml
      var blob = new Blob([x]);
      console.log("blob====", blob);
      // 创建一个 URL 对象来引用 Blob 数据
      var url = window.URL || window.webkitURL;
      var downloadUrl = url.createObjectURL(blob);
      // 创建一个链接元素并设置其属性
      var link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileNames+".svg"; // 指定下载时的文件名
      // 模拟点击链接进行下载
      link.click();
      // 清除 URL 对象
      url.revokeObjectURL(downloadUrl);
    },
    saveMyDXF() {
      //var square = new makerjs.models.Square(100);
      //var svg = makerjs.exporter.toSVG(square);
      //this.dxf = makerjs.exporter.toDXF(square);
      // 创建一个 Blob 对象并设置 MIME 类型为 image/svg+xml
      
      var fileNames=this.creatDate();
      var blob = new Blob([this.dxf]);
      console.log("blob====", blob);
      // 创建一个 URL 对象来引用 Blob 数据
      var url = window.URL || window.webkitURL;
      var downloadUrl = url.createObjectURL(blob);

      // 创建一个链接元素并设置其属性
      var link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileNames+".dxf"; // 指定下载时的文件名
      // 模拟点击链接进行下载
      link.click();
      // 清除 URL 对象
      url.revokeObjectURL(downloadUrl);
    },
    exMould(){
      console.log(
        "this.operationHistory.length=",
        this.operationHistory.length
      );
      if (this.operationHistory.length <= 1) {
        this.myOpenMsg("没有图形，请先画图。", "提示：");
      }else{
        //console.log("this.plate=", this.plate);
      //console.log("this.plate.models=", this.plate.models);
      this.MyJSON.plate = this.plate;
      this.MyJSON.cutPlate = this.cutPlate;
      this.MyJSON.markers = this.markers;
      this.MyJSON.Rect = this.form;
      this.MyJSON.operationHistory = this.operationHistory;
      this.MyJSON = JSON.stringify(this.MyJSON);
      console.log("this.MyJSON=", this.MyJSON);
        this.saveMyJSON();
        this.saveJsonPng();
      }
    },
    saveMyJSON() {
      //var square = new makerjs.models.Square(100);
      //var svg = makerjs.exporter.toSVG(square);
      //this.dxf = makerjs.exporter.toDXF(square);
      // 创建一个 Blob 对象并设置 MIME 类型为 image/svg+xml
      var fileNames=this.creatDate();
      var blob = new Blob([this.MyJSON]);
      console.log("blob====", blob);
      // 创建一个 URL 对象来引用 Blob 数据
      var url = window.URL || window.webkitURL;
      var downloadUrl = url.createObjectURL(blob);

      // 创建一个链接元素并设置其属性
      var link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileNames+".json"; // 指定下载时的文件名
      // 模拟点击链接进行下载
      link.click();
      // 清除 URL 对象
      url.revokeObjectURL(downloadUrl);     
    },    
    saveJsonPng() {
      var fileNames=this.creatDate();
      var svg = document.getElementById("myDrawing");
      console.log("svg=", svg.setAttribute.viewBox);
      const _this = this;
      var img = _this.export2Base64Img(svg, null, {
        //watermark: 'copyright reserved 2020 版权所有',
        background: "#fff",
      });
      img.then(function (base64src) {
        //console.log('路径:::', base64src)
        // console.log("this1=",_this)
        _this.downLoad(
          base64src,
          fileNames + ".png"
        );
        //console.log("this2=",_this)
      });
    },
    _saveMyPng() {
      /*
      var svg=document.getElementById("myDrawing");
      console.log("svg=",svg.setAttribute.viewBox)
      const _this=this;
      var img = _this.export2Base64Img(svg, null, {
                //watermark: 'copyright reserved 2020 版权所有',
                background: '#fff'
            });
            img.then(function (base64src) {
             console.log('路径:::', base64src)
                console.log("this1=",_this)
                _this.downLoad(base64src, 'c:/guiyou/00.png')
                console.log("this2=",_this)
            })
            */
           
      //var fileNames=creatDate();
      var svg = document.getElementById("myDrawing");
      console.log("svg=", svg.setAttribute.viewBox);
      const _this = this;
      var img = _this.export2Base64Img(svg, null, {
        //watermark: 'copyright reserved 2020 版权所有',
        background: "#fff",
      });
      var data = img;
      var blob = new Blob([data], { type: "application/octet-stream" });
      var url = window.URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = url;
      link.download = "filename.png ";
      // 创建一个<input type="file”>元萧，并置其vebkitdirectory属性为下载路径
      var fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.style.display = "none";
      fileInput.webkitdirectory = "E:/vueObject/Draw/vue2draw/pic";
      document.body.appendChild(fileInput);
      link.click();
      document.body.removeChild(fileInput);
      document.body.removeChild(link);
      window.URL.revokeobjectURL(ur1);
    },
    saveMyPng() {
      var fileNames=creatDate();
      var svg = document.getElementById("myDrawing");
      console.log("svg=", svg.setAttribute.viewBox);
      const _this = this;
      var img = _this.export2Base64Img(svg, null, {
        //watermark: 'copyright reserved 2020 版权所有',
        background: "#fff",
      });
      img.then(function (base64src) {
        console.log("路径:::", base64src);
        console.log("this1=", _this);
        _this.downLoad(base64src, fileNames+".png");
        console.log("this2=", _this);
      });
    },
    handleFileChange(event) {
      const file = event.target.files[0]; // 获取第一个选定的文件对象
      if (file) {
        this.readFileContent(file); // 调用自定义的读取文件内容的方法
      } else {
        console.log("未选择任何文件");
      }
    },
    readFileContent(file) {
      const reader = new FileReader();

      reader.onload = () => {
        const content = JSON.parse(reader.result); // 读取到的文件内容

        // 进行后续操作，比如打印或者保存文件内容等
        console.log("测试读取的内容" + content);
        //获取节点 插入读取的内容
        //innerHTML

        this.form.glassW = content.Rect.glassW;
        this.form.glassH = content.Rect.glassH;
        this.plate = content.plate;
        this.cutPlate = content.cutPlate;
        this.markers = content.markers;
        this.operationHistory = content.operationHistory;
        this.creatSvg(
          content.Rect.glassW,
          content.Rect.glassH,
          content.markers
        );
      };

      reader.readAsText(file); // 以文本形式读取文件内容
    },
    //导出png图片格式相关
    reEncode(data) {
      return decodeURIComponent(
        encodeURIComponent(data).replace(/%([0-9A-F]{2})/g, (match, p1) => {
          const c = String.fromCharCode(`0x${p1}`);
          return c === "%" ? "%25" : c;
        })
      );
    },
    export2Base64Img(svgDom, MIMEType, option) {
      console.log("**");
      var serializer = new XMLSerializer();
      console.log("**3=", typeof svgDom);
      var source = serializer.serializeToString(svgDom);
      // 方式一: unescape(encodeURIComponent(txt))
      // var path = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(source)));
      // 方式二: decodeURIComponent(encodeURIComponent(txt))
      var path =
        "data:image/svg+xml;base64," + window.btoa(this.reEncode(source));
      var canvas = document.createElement("canvas"),
        context = canvas.getContext("2d"),
        img = new Image(),
        pixelRatio = window.devicePixelRatio || 1,
        _exportPath,
        handler;
      option = option || {};

      canvas.width = parseFloat(svgDom.getAttribute("width")); //  * pixelRatio
      canvas.height = parseFloat(svgDom.getAttribute("height")); //  * pixelRatio
      img.src = path;
      img.onload = function () {
        // 增加底色
        if (option.background) {
          context.beginPath();
          context.rect(0, 0, canvas.width, canvas.height);
          context.fillStyle = option.background;
          context.fill();
          context.closePath();
        }
        //
        context.drawImage(img, 0, 0);

        var marker = option.watermark || "";

        if (marker) {
          context.font = "18px 微软雅黑";
          context.fillStyle = "rgba(12, 0, 70, 0.5)";

          var textWidth = context.measureText(marker).width,
            textHegith = 50,
            pk = 1.2,
            rotate = ((option.rotation || -45) * Math.PI) / 180,
            sinReg = Math.sin(rotate),
            cosReg = Math.cos(rotate),
            width =
              Math.abs(canvas.width * cosReg) +
              Math.abs(canvas.height * sinReg),
            height =
              Math.abs(canvas.height * cosReg) +
              Math.abs(canvas.width * sinReg);

          var xf = Math.ceil((width / textWidth) * pk);
          var yf = Math.ceil(height / textHegith);

          context.rotate(rotate);

          for (var i = 0; i < yf; i++) {
            for (var k = 0; k < xf; k++) {
              context.fillText(
                marker,
                textWidth * k * pk - canvas.height * cosReg,
                textHegith * i
              );
            }
          }
        }

        document.body.appendChild(canvas);
        _exportPath = canvas.toDataURL(MIMEType || "image/png", 1);
        typeof handler === "function" && handler(_exportPath);
        document.body.removeChild(canvas);
      };

      return new Promise(function (resolve, reject) {
        handler = resolve;
      });
    },
    downLoad(url, fileName) {
      var oA = document.createElement("a");
      oA.download = fileName || "";
      oA.style.display = "none";
      oA.href = url;
      document.body.appendChild(oA);
      oA.click();
      oA.remove();
    },
    //导出png图片格式相关
    //获取当前时间 用来生成默认文件名称
    creatDate(){
      var currentDate = new Date();
var year = currentDate.getFullYear(); // 获取年份
var month = currentDate.getMonth() + 1; // 获取月份，月份从0开始，需加1
var day = currentDate.getDate(); // 获取日期
var hours = currentDate.getHours(); // 获取小时
var minutes = currentDate.getMinutes(); // 获取分钟
var seconds = currentDate.getSeconds(); // 获取秒数
var currentDateTime = year + '' + month + '' + day + '' + hours + '' + minutes + '' + seconds;
  //var currentDateTime = year + month + day + hours + minutes + seconds;
console.log("currentDateTime=",currentDateTime);
return currentDateTime;
    },
    //创建本地文件夹
    creatFile(){
      var fso = new ActiveXObject("Scripting.FileSystemObject");  
var f1 = fso.createtextfile("D:\\myjstest.txt",true);  
    },
  },
};
</script>
<style lang="less">
 .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
.txtHeight {
  line-height: 45px;
  text-align: center;
}
.iconfont {
  font-size: 48px;
}
.contanerBox {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0 auto;
  padding: 0px;
  .conHeader {
    background-color: #b3c0d1;
    color: #333;
    height: 7vh;
    line-height: 40px;
    display: flex;
    .logoIcon {
      width: 40px;
      height: 40px;
      padding-top: 15px;
      flex: 1;
      justify-items: center;
      display: flex;
      justify-content: space-around;
    }
    .MenuBtns {
      align-self: center;
      justify-items: center;
      flex: 5;
      display: flex;
      justify-content: space-around;
      .openMenu {
        .open-svg {
          display: inline-block;
          cursor: pointer;
        }
        #openSVG {
          display: none;
        }
      }
    }
    .MenuBtns-right {
      //background-color: red;
      align-self: center;
      justify-items: center;
      flex: 15;
      text-align: right;
      padding-right: 15px;
    }
  }
  .conMain {
    display: flex;
    background-color: #e9eef3;
    .conMainCon {
      position: relative;
      #view {
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        #drawCanvas {
          margin: 2em;
        }
      }
      flex: 14;
      background-color: #e9eef3;
      color: #333;
      .drawBack {
        background-color: #fff;
        margin: 20px 20px;
        .draw {
          box-sizing: border-box;
          svg {
            overflow: visible;
          }
        }
      }
    }
    .conMainRight {
      margin-top: 15px;
      flex: 5;
      background-color: #e9eef3;
      .hole-position {
        border: 1px solid #909399;
        padding-top: 5px;
        padding-bottom: 5px;
        .hole-lineH {
          height: 8px;
          line-height: 80px;
        }
      }
    }
  }
}
</style>