/*
import  from "./test1";  //引入单个函数方法
import test2 from "./test2";   //引入单个函数方法
import * as test3 from "./test3";  //引入多个函数方法
import classtest from "./classtest";   //引入class类
*/
// import makerjs from "makerjs";
import Rect from "./Rect.js";                                 //1、单孔
import SingleHole from "./SingleHole.js";                     //1、单孔
import HangWheelHoleTwo from "./HangWheelHoleTwo.js";         //2、吊轮孔2
import HangWheelHoleThree from "./HangWheelHoleThree.js";     //3、吊轮孔3
import HangWheelHoleTwoCol from "./HangWheelHoleTwoCol.js";   //4、双吊轮孔列
import HangWheelHoleTwoRow from "./HangWheelHoleTwoRow.js";   //5、双吊轮孔排
import HangHole from "./HangHole.js";                         //6、晾物孔
import HandleHoleTShaped from "./HandleHoleTShaped.js";       //7、拉手孔T字
import DoubleFixHoles from "./DoubleFixHoles.js";             //8、双固定孔
import DoubleFixHolesCol from "./DoubleFixHolesCol.js";       //8、双固定孔列
import RelSuWheelHole from "./RelSuWheelHole.js";             //9、相对吊轮孔
import HandleHole from "./HandleHole.js";                     //10、拉手孔
import RotatLockHole from "./RotatLockHole.js";               //11、旋转锁孔
import HandleHole3 from "./HandleHole3.js";                   //12、拉手孔3 
import HangWheelHoleCol from "./HangWheelHoleCol.js";         //13、吊轮孔列 
import Shaped7HandleHole from "./Shaped7HandleHole.js";       //14、7字拉手孔 
import KeyHole from "./KeyHole.js";                           //15、锁孔  
import ThreeHoles from "./ThreeHoles.js";                     //16、三孔  
import ThreeHoles2 from "./ThreeHoles2.js";                   //17、三孔2  
import ThreeHoles3 from "./ThreeHoles3.js";                   //18、三孔3  
import ThreeHoles4 from "./ThreeHoles4.js";                   //19、三孔4  
import ButterflyGap from "./ButterflyGap.js";                 //缺1、蝴蝶  
import ButterflyGap2 from "./ButterflyGap2.js";               //缺1.2、蝴蝶  
import ButterflyGap3 from "./ButterflyGap3.js";               //缺1.3、蝴蝶  
import UShapeGap from "./UShapeGap.js";                       //缺2、U型  
import StressHoleGap from "./StressHoleGap.js";               //缺3、应力孔  
import RunwayGap from "./RunwayGap.js";                       //缺4、跑道缺  
import SquareGap from "./SquareGap.js";                       //缺5、方缺  
import RectAngleGap from "./RectAngleGap.js";                 //缺5、5 矩形缺  
import CopperClipGap from "./CopperClipGap.js";               //缺6、铜夹  
import LongClipGap from "./LongClipGap.js";                   //缺7、长条夹  
import EllipseGap from "./EllipseGap.js";                     //缺8、椭圆   
import ObliqueAngle from "./ObliqueAngle.js";                 //角1、斜角   
import Fillet from "./Fillet.js";                             //角2、圆角  
import Corner from "./Corner.js";                             //角3、方角     
import Fillet2 from "./Fillet2.js";                           //角4、圆角2   
import FloorSpring from "./FloorSpring.js";                   //角5、地弹簧   
import FloorSpring2 from "./FloorSpring2.js";                 //角6、地弹簧2  
import FloorSpring3 from "./FloorSpring3.js";                 //角7、地弹簧3
export default {
    Rect:Rect,
    SingleHole: SingleHole,
    HangWheelHoleTwo: HangWheelHoleTwo,
    HangWheelHoleThree: HangWheelHoleThree,
    HangWheelHoleTwoCol: HangWheelHoleTwoCol,
    HangWheelHoleTwoRow: HangWheelHoleTwoRow,
    HangHole: HangHole,
    HandleHoleTShaped: HandleHoleTShaped,
    DoubleFixHoles: DoubleFixHoles,
    DoubleFixHolesCol: DoubleFixHolesCol,
    RelSuWheelHole: RelSuWheelHole,
    HandleHole: HandleHole,
    RotatLockHole: RotatLockHole,
    HandleHole3: HandleHole3,
    HangWheelHoleCol: HangWheelHoleCol,
    Shaped7HandleHole: Shaped7HandleHole,
    KeyHole:KeyHole,
    ThreeHoles:ThreeHoles,
    ThreeHoles2:ThreeHoles2,
    ThreeHoles3:ThreeHoles3,
    ThreeHoles4:ThreeHoles4,
    ButterflyGap:ButterflyGap,
    ButterflyGap2:ButterflyGap2,
    ButterflyGap3:ButterflyGap3,
    UShapeGap:UShapeGap,
    StressHoleGap:StressHoleGap,
    RunwayGap:RunwayGap,
    SquareGap:SquareGap,
    RectAngleGap:RectAngleGap,
    CopperClipGap:CopperClipGap,
    LongClipGap:LongClipGap,
    EllipseGap:EllipseGap,
    ObliqueAngle:ObliqueAngle,
    Fillet:Fillet,
    Corner:Corner,
    Fillet2:Fillet2,
    FloorSpring:FloorSpring,
    FloorSpring2:FloorSpring2,
    FloorSpring3:FloorSpring3,
    
    
};
