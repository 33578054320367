//15、锁孔
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function KeyHole(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D1=inputParam.D1,D2=inputParam.D2,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d1 = D1*1*myZoom,
      d2 = D2*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d1 = D1*1,
           d2 = D2*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d1!==0){
         var TxtD1="∮"+D1;
        }
        if(d2!==0){
         var TxtD2="∮"+D2;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==2){
     //画孔 B位置孔
          //标记锁孔B位置孔左上
          var myKhHolesBLeftup= new makerjs.models.Holes(d1/2, [[xb-w, yb]]);
          var myKhHolesMarkBLeftup = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkBLeftup.origin = [xb-w, yb];      
          var myKhHolesXMarkBLeftup = new makerjs.models.Square(0);      
          myKhHolesXMarkBLeftup.origin =[xb-w, yb];      
          var myKhHolesYMarkBLeftup = new makerjs.models.Square(0);      
          myKhHolesYMarkBLeftup.origin = [xb-w, yb]; 
    /*****************************************************************************/ 
    //makerjs.model.addPath(myKhHolesMarkBLeftup, new makerjs.paths.Line([0,0], [0, y0]));//Y轴加长标线
    //makerjs.model.addPath(myKhHolesMarkBLeftup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    //makerjs.model.addPath(myKhHolesMarkBLeftup, new makerjs.paths.Line([-x0,yb-h], [-x0-markShiftThree, yb-h]));//X轴加长标线
    //makerjs.model.addPath(myKhHolesMarkBLeftup, new makerjs.paths.Line([0,0], [0, yb-h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkBLeftup, new makerjs.paths.Line([0,0], [d1, -d1]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkBLeftup, TxtD1, [0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesXMarkBLeftup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
    makerjs.model.addCaption(myKhHolesXMarkBLeftup, TxtY,[0-markShift,0], [0-markShift, y0]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesYMarkBLeftup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
    makerjs.model.addCaption(myKhHolesYMarkBLeftup, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
    /*****************************************************************************/ 
    //  //标记锁孔B位置孔右中
     var myKhHolesBRightmd= new makerjs.models.Holes(d2/2, [[xb, yb-h]]);
          var myKhHolesMarkBRightmd = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkBRightmd.origin = [xb, yb-h];      
          var myKhHolesXMarkBRightmd = new makerjs.models.Square(0);      
          myKhHolesXMarkBRightmd.origin =[xb, yb-h];      
          var myKhHolesYMarkBRightmd = new makerjs.models.Square(0);      
          myKhHolesYMarkBRightmd.origin = [xb, yb-h];  
    /*****************************************************************************/ 
    //makerjs.model.addPath(myKhHolesMarkBRightmd, new makerjs.paths.Line([0,-h], [0, 0]));//从孔圆心出发X方向横向标线
    //makerjs.model.addPath(myKhHolesMarkBRightmd, new makerjs.paths.Line([-w,0], [0, 0]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkBRightmd, new makerjs.paths.Line([0,0], [d2, -d2]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkBRightmd, TxtD2, [0,0], [d2+markHolesOneD, -d2-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesXMarkBRightmd, new makerjs.paths.Line([0,0+h], [w, 0+h]));//X短标线
    makerjs.model.addCaption(myKhHolesXMarkBRightmd, TxtW, [0,0+markShift+h], [w, 0+markShift+h]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myKhHolesYMarkBRightmd, new makerjs.paths.Line([0,h], [0, 0]));//H短标线
    makerjs.model.addCaption(myKhHolesYMarkBRightmd, TxtH,[0-markShift,0], [0-markShift, h]);//H文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记锁孔B位置孔左下
     var myKhHolesBLeftdn= new makerjs.models.Holes(d1/2, [[xb+w, yb]]);     
     var myKhHolesMarkBLeftdn = new makerjs.models.Square(0); //孔的原点       
     myKhHolesMarkBLeftdn.origin = [xb+w, yb];      
     makerjs.model.addPath(myKhHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [d1, -d1]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkBLeftdn, TxtD1, [0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    plate.models["myKhHolesBLeftup"+myHolesNum]=myKhHolesBLeftup;
    markers.models["myKhHolesBLeftup"+myHolesNum]=myKhHolesBLeftup;
    markers.models["myKhHolesMarkBLeftup"+myHolesNum]=myKhHolesMarkBLeftup;
    markers.models["myKhHolesXMarkBLeftup"+myHolesNum]=myKhHolesXMarkBLeftup;
    markers.models["myKhHolesYMarkBLeftup"+myHolesNum]=myKhHolesYMarkBLeftup;
    /*****************************************************************************/
    plate.models["myKhHolesBLeftdn"+myHolesNum]=myKhHolesBLeftdn;
    markers.models["myKhHolesBLeftdn"+myHolesNum]=myKhHolesBLeftdn;
    markers.models["myKhHolesMarkBLeftdn"+myHolesNum]=myKhHolesMarkBLeftdn;
    /*****************************************************************************/ 
    plate.models["myKhHolesBRightmd"+myHolesNum]=myKhHolesBRightmd;
    markers.models["myKhHolesBRightmd"+myHolesNum]=myKhHolesBRightmd;
    markers.models["myKhHolesMarkBRightmd"+myHolesNum]=myKhHolesMarkBRightmd;
    markers.models["myKhHolesXMarkBRightmd"+myHolesNum]=myKhHolesXMarkBRightmd;
    markers.models["myKhHolesYMarkBRightmd"+myHolesNum]=myKhHolesYMarkBRightmd;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
   
    }else if(area==8){
    //画孔H位置孔
          //标记锁孔H位置孔右上
          var myKhHolesHRightup= new makerjs.models.Holes(d1/2, [[xh-w, yh]]);
          var myKhHolesMarkHRightup = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkHRightup.origin = [xh-w, yh];      
          var myKhHolesXMarkHRightup = new makerjs.models.Square(0);      
          myKhHolesXMarkHRightup.origin =[xh-w, yh];      
          var myKhHolesWMarkHRightup = new makerjs.models.Square(0);      
          myKhHolesWMarkHRightup.origin =[xh-w, yh]; 
    /*****************************************************************************/ 
    //makerjs.model.addPath(myKhHolesMarkHRightup, new makerjs.paths.Line([x0,yb-h], [x0, yb-h+markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(myKhHolesMarkHRightup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myKhHolesMarkHRightup, new makerjs.paths.Line([0,0], [w, 0]));//X轴加长标线
    //makerjs.model.addPath(myKhHolesMarkHRightup, new makerjs.paths.Line([0,0], [0, yd-h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkHRightup, new makerjs.paths.Line([0,0], [d1, -d1]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkHRightup, TxtD1, [0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesXMarkHRightup, new makerjs.paths.Line([0,-y0], [0, 0]));//X短标线
    makerjs.model.addCaption(myKhHolesXMarkHRightup, TxtY,[0-markShift,-y0], [0-markShift, 0]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesWMarkHRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
    makerjs.model.addCaption(myKhHolesWMarkHRightup, TxtW,[0,0+markShift], [w, 0+markShift]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    //  //标记锁孔H位置孔左中
     var myKhHolesHLeftmd= new makerjs.models.Holes(d2/2, [[xh, yh+h]]);
          var myKhHolesMarkHLeftmd = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkHLeftmd.origin = [xh, yh+h];      
          var myKhHolesXMarkHLeftmd = new makerjs.models.Square(0);      
          myKhHolesXMarkHLeftmd.origin =[xh, yh+h];      
          var myKhHolesYMarkHLeftmd = new makerjs.models.Square(0);      
          myKhHolesYMarkHLeftmd.origin = [xh, yh+h];  
    /*****************************************************************************/ 
    //makerjs.model.addPath(myKhHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [-d2-markShiftFour, 0]));//从孔圆心出发X方向横向标线
   // makerjs.model.addPath(myKhHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [0, h]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [d2, -d2]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkHLeftmd, TxtD2, [0,0], [d2+markHolesOneD, -d2-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesXMarkHLeftmd, new makerjs.paths.Line([0,0-h], [w, 0-h]));//X短标线
    makerjs.model.addCaption(myKhHolesXMarkHLeftmd, TxtW, [0,0-h+markShift], [w, 0-h+markShift]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myKhHolesYMarkHLeftmd, new makerjs.paths.Line([0,-h], [0, 0]));//H短标线
    makerjs.model.addCaption(myKhHolesYMarkHLeftmd, TxtH, [0+markShift,-h], [0+markShift, 0]);//H文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记锁孔H位置孔右下
     var myKhHolesHRightdn= new makerjs.models.Holes(d1/2, [[xh+w, yh]]);
          var myKhHolesMarkHRightdn = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkHRightdn.origin = [xh+w, yh];        
          //var myKhHolesYMarkHRightdn = new makerjs.models.Square(0);      
          //myKhHolesYMarkHRightdn.origin = [xh+w, yh]; 
    /*****************************************************************************/ 
    //makerjs.model.addPath(myKhHolesMarkHRightdn, new makerjs.paths.Line([0,0], [-w-d2-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    //makerjs.model.addPath(myKhHolesMarkHRightdn, new makerjs.paths.Line([0,0], [0, h+h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkHRightdn, new makerjs.paths.Line([0,0], [d1, -d1]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkHRightdn, TxtD1, [0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    //makerjs.model.addPath(myKhHolesYMarkHRightdn, new makerjs.paths.Line([-w-d2-markShiftTwo,0], [-w-d2-markShiftTwo, h]));//Y短标线
    //makerjs.model.addCaption(myKhHolesYMarkHRightdn, TxtH, [-w-d2-markShiftFour,0], [-w-d2-markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myKhHolesHRightup"+myHolesNum]=myKhHolesHRightup;
    markers.models["myKhHolesHRightup"+myHolesNum]=myKhHolesHRightup;
    markers.models["myKhHolesMarkHRightup"+myHolesNum]=myKhHolesMarkHRightup;
    markers.models["myKhHolesXMarkHRightup"+myHolesNum]=myKhHolesXMarkHRightup;
    markers.models["myKhHolesWMarkHRightup"+myHolesNum]=myKhHolesWMarkHRightup;    
    /*****************************************************************************/
    plate.models["myKhHolesHRightdn"+myHolesNum]=myKhHolesHRightdn;
    markers.models["myKhHolesHRightdn"+myHolesNum]=myKhHolesHRightdn;
    markers.models["myKhHolesMarkHRightdn"+myHolesNum]=myKhHolesMarkHRightdn;
    //markers.models["myKhHolesYMarkHRightdn"+myHolesNum]=myKhHolesYMarkHRightdn;
    /*****************************************************************************/ 
    plate.models["myKhHolesHLeftmd"+myHolesNum]=myKhHolesHLeftmd;
    markers.models["myKhHolesHLeftmd"+myHolesNum]=myKhHolesHLeftmd;
    markers.models["myKhHolesMarkHLeftmd"+myHolesNum]=myKhHolesMarkHLeftmd;
    markers.models["myKhHolesXMarkHLeftmd"+myHolesNum]=myKhHolesXMarkHLeftmd;
    markers.models["myKhHolesYMarkHLeftmd"+myHolesNum]=myKhHolesYMarkHLeftmd;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    }
/*****************************************************************************/ 

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default KeyHole;