//缺1.2、蝴蝶
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ButterflyGap2(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var R=inputParam.R,W=inputParam.W,W1=inputParam.W1,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   h = H*1*myZoom,
   w= W*1*myZoom,
   h1 = H1*1*myZoom,
   w1= W1*1*myZoom,
   r= R*1*myZoom,
   dd=r/Math.sqrt(2),
   wd=w1-w,
   hd=(h1-h)/2,
     y0 = Y*1*myZoom,
     x0 = X*1*myZoom;
     /*
     h = H*1,
     w= W*1,
     h1 = H1*1,
     w1= W1*1,
     r= R*1,
     dd=r/Math.sqrt(2),
     wd=w1-w,
     hd=(h1-h)/2,
       y0 = Y*1,
       x0 = X*1;
       */
     if(r!==0){
      var TxtR="R="+R;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(h1!==0){
      var TxtH1=H1;
     }
     if(w1!==0){
      var TxtW1=W1;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
     if(x0!==0){          
      var TxtX=X;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //1、2蝴蝶2 A位置   H W D  width=w-d/2        
        var myBfg2GapARect=new makerjs.models.Rectangle(h1,w1+r); 
        myBfg2GapARect.origin=[xa,ya-w1];     
        var myBfg2GapABoltRectangle=new makerjs.models.BoltRectangle(h,w+r,r);
        myBfg2GapABoltRectangle.origin=[xa+hd,ya-w];
  var myBfg2GapA=makerjs.model.combineUnion(myBfg2GapARect, myBfg2GapABoltRectangle);  
     //画1、2蝴蝶2标记 A位置 
  var myBfg2GapAMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapAMakerW.origin = [xa,ya+y0];
  var myBfg2GapAMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapAMakerH.origin = [xa,ya+y0];   
  var myBfg2GapAMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapAMakerW1.origin = [xa,ya+y0];
  var myBfg2GapAMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapAMakerH1.origin = [xa,ya+y0];
  var myBfg2GapAMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapAMakerR.origin = [xa,ya+y0];
  var myBfg2GapAMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapAMakerX.origin = [xa,ya+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([0+hd,-w], [0+hd,-w-markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([0+h+hd,-w], [0+h+hd,-w-markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([h1,0], [h1,0+markShiftFour]));//Y轴方向短线 w1
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([-x0,0], [-x0, 0+markShiftFour]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([0,0-w], [0-markShiftThree, 0-w]));//X轴方向短线
 makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([-r+h,0-y0-wd-w], [h+markShiftFour+hd, 0-y0-wd-w]));//X轴方向短线 H  up
  //makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([h,0-y0-wd-w], [h+markShiftFour+hd, 0-y0-wd-w]));//X轴方向短线 H  dn
  
  makerjs.model.addPath(myBfg2GapAMakerR, new makerjs.paths.Line([h+hd,0-y0-w1],[h+hd+dd+2*markHolesOneD,0-y0-w1-dd-2*markHolesOneD]));//R相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapAMakerR,TxtR,[h+hd+markShift,0-y0-w1], [h+hd+markShift+dd+2*markHolesOneD,0-y0-w1-dd-2*markHolesOneD]);//R相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg2GapAMakerW1, new makerjs.paths.Line([0+h1+markShift,0-y0], [0+h1+markShift, 0-y0-w1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapAMakerW1,TxtW1, [0+h1+markShiftThree,0-y0-w1], [0+h1+markShiftThree, 0-y0]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([0-markShift,0-y0], [0-markShift, 0-y0-w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapAMakerW,TxtW, [0-markShiftThree, 0-y0-w], [0-markShiftThree,0-y0]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapAMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapAMakerX,TxtX,  [-x0, 0+markShiftFour],[0,0+markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapAMakerH, new makerjs.paths.Line([0+hd,0-markShiftTwo-w], [h+hd, 0-markShiftTwo-w]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapAMakerH,TxtH, [0+hd,0-markShiftFour-w], [h+hd, 0-markShiftFour-w]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapAMakerH1, new makerjs.paths.Line([0,0+markShiftTwo], [h1, 0+markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapAMakerH1,TxtH1, [0,0+markShiftFour], [h1,0+markShiftFour]);//W文字标注
  // /*****************************************************************************/
  plate.models["myBfg2GapA"+myHolesNum]=myBfg2GapA;
  markers.models["myBfg2GapA"+myHolesNum]=myBfg2GapA;
  markers.models["myBfg2GapAMakerW1"+myHolesNum]=myBfg2GapAMakerW1;
  markers.models["myBfg2GapAMakerH1"+myHolesNum]=myBfg2GapAMakerH1;
  markers.models["myBfg2GapAMakerW"+myHolesNum]=myBfg2GapAMakerW;
  markers.models["myBfg2GapAMakerH"+myHolesNum]=myBfg2GapAMakerH;
  markers.models["myBfg2GapAMakerR"+myHolesNum]=myBfg2GapAMakerR;
  markers.models["myBfg2GapAMakerX"+myHolesNum]=myBfg2GapAMakerX;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapA"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
        }else if(area==2){
          //画缺 B位置
        //1、2蝴蝶2 B位置   H W D  width=w-d/2        
        var myBfg2GapBRect=new makerjs.models.Rectangle(h1,w1+r); 
        myBfg2GapBRect.origin=[xb-h1/2,yb-w1];     
        var myBfg2GapBBoltRectangle=new makerjs.models.BoltRectangle(h,w+r,r);
        myBfg2GapBBoltRectangle.origin=[xb+hd-h/2,yb-w];
  var myBfg2GapB=makerjs.model.combineUnion(myBfg2GapBRect, myBfg2GapBBoltRectangle);  
     //画1、2蝴蝶2标记 B位置 
  var myBfg2GapBMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapBMakerW.origin = [xb-h1/2,yb+y0];
  var myBfg2GapBMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapBMakerH.origin = [xb-h1/2,yb+y0];   
  var myBfg2GapBMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapBMakerW1.origin = [xb-h1/2,yb+y0];
  var myBfg2GapBMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapBMakerH1.origin = [xb-h1/2,yb+y0];
  var myBfg2GapBMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapBMakerR.origin = [xb-h1/2,yb+y0];
  var myBfg2GapBMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapBMakerX.origin = [xb-h1/2,yb+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapBMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myBfg2GapBMakerW, new makerjs.paths.Line([0+hd,-w], [0+hd,-w-markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapBMakerW, new makerjs.paths.Line([0+h,-w], [0+h,-w-markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapBMakerW, new makerjs.paths.Line([h1,0], [h1,0+markShiftFour]));//Y轴方向短线 w1
  makerjs.model.addPath(myBfg2GapBMakerW, new makerjs.paths.Line([-x0,0], [-x0, 0+markShiftFour]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapBMakerW, new makerjs.paths.Line([0,0-w], [0-markShiftThree, 0-w]));//X轴方向短线
 makerjs.model.addPath(myBfg2GapBMakerW, new makerjs.paths.Line([-r+h,0-y0-wd-w], [h+markShiftFour+hd, 0-y0-wd-w]));//X轴方向短线 H  up
  //makerjs.model.addPath(myBfg2GapBMakerW, new makerjs.paths.Line([h,0-y0-wd-w], [h+markShiftFour+hd, 0-y0-wd-w]));//X轴方向短线 H  dn
  
  makerjs.model.addPath(myBfg2GapBMakerR, new makerjs.paths.Line([h,0-y0-w1],[h+dd+2*markHolesOneD,0-y0-w1-dd-2*markHolesOneD]));//R相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapBMakerR,TxtR,[h+markShift,0-y0-w1], [h+markShift+dd+2*markHolesOneD,0-y0-w1-dd-2*markHolesOneD]);//R相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg2GapBMakerW1, new makerjs.paths.Line([0+h1+markShift,0-y0], [0+h1+markShift, 0-y0-w1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapBMakerW1,TxtW1, [0+h1+markShiftThree,0-y0-w1], [0+h1+markShiftThree, 0-y0]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapBMakerW, new makerjs.paths.Line([0-markShift,0-y0], [0-markShift, 0-y0-w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapBMakerW,TxtW, [0-markShiftThree, 0-y0-w], [0-markShiftThree,0-y0]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapBMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapBMakerX,TxtX,  [-x0, 0+markShiftFour],[0,0+markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapBMakerH, new makerjs.paths.Line([0+hd,0-markShiftTwo-w], [h, 0-markShiftTwo-w]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapBMakerH,TxtH, [0+hd,0-markShiftFour-w], [h, 0-markShiftFour-w]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapBMakerH1, new makerjs.paths.Line([0,0+markShiftTwo], [h1, 0+markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapBMakerH1,TxtH1, [0,0+markShiftFour], [h1,0+markShiftFour]);//W文字标注
  // /*****************************************************************************/
  plate.models["myBfg2GapB"+myHolesNum]=myBfg2GapB;
  markers.models["myBfg2GapB"+myHolesNum]=myBfg2GapB;
  markers.models["myBfg2GapBMakerW1"+myHolesNum]=myBfg2GapBMakerW1;
  markers.models["myBfg2GapBMakerH1"+myHolesNum]=myBfg2GapBMakerH1;
  markers.models["myBfg2GapBMakerW"+myHolesNum]=myBfg2GapBMakerW;
  markers.models["myBfg2GapBMakerH"+myHolesNum]=myBfg2GapBMakerH;
  markers.models["myBfg2GapBMakerR"+myHolesNum]=myBfg2GapBMakerR;
  markers.models["myBfg2GapBMakerX"+myHolesNum]=myBfg2GapBMakerX;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapB"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
          
        }else if(area==3){
           //画缺 C位置
        //1、2蝴蝶2 C位置   H W D  width=w-d/2        
        var myBfg2GapCRect=new makerjs.models.Rectangle(h1,w1+r); 
        myBfg2GapCRect.origin=[xc-h1,yc-w1];     
        var myBfg2GapCBoltRectangle=new makerjs.models.BoltRectangle(h,w+r,r);
        myBfg2GapCBoltRectangle.origin=[xc-h,yc-w];
  var myBfg2GapC=makerjs.model.combineUnion(myBfg2GapCRect, myBfg2GapCBoltRectangle);  
     //画1、2蝴蝶2标记 C位置 
  var myBfg2GapCMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapCMakerW.origin = [xc-h1,yc+y0];
  var myBfg2GapCMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapCMakerH.origin = [xc-h1,yc+y0];   
  var myBfg2GapCMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapCMakerW1.origin = [xc-h1,yc+y0];
  var myBfg2GapCMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapCMakerH1.origin = [xc-h1,yc+y0];
  var myBfg2GapCMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapCMakerR.origin = [xc-h1,yc+y0];
  var myBfg2GapCMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapCMakerX.origin = [xc-h1,yc+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([0+hd,-w], [0+hd,-w-markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([0+h,-w], [0+h,-w-markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([h1,0], [h1,0+markShiftFour]));//Y轴方向短线 w1
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([x0+h1,0], [x0+h1, 0+markShiftFour]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([0,0-w], [0-markShiftThree, 0-w]));//X轴方向短线
 makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([-r+h,0-y0-wd-w], [h+markShiftFour+hd, 0-y0-wd-w]));//X轴方向短线 H  up
  //makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([h,0-y0-wd-w], [h+markShiftFour+hd, 0-y0-wd-w]));//X轴方向短线 H  dn
  
  makerjs.model.addPath(myBfg2GapCMakerR, new makerjs.paths.Line([h,0-y0-w1],[h+dd+2*markHolesOneD,0-y0-w1-dd-2*markHolesOneD]));//R相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapCMakerR,TxtR,[h+markShift,0-y0-w1], [h+markShift+dd+2*markHolesOneD,0-y0-w1-dd-2*markHolesOneD]);//R相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg2GapCMakerW1, new makerjs.paths.Line([0+h1+markShift,0-y0], [0+h1+markShift, 0-y0-w1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapCMakerW1,TxtW1, [0+h1+markShiftThree,0-y0-w1], [0+h1+markShiftThree, 0-y0]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([0-markShift,0-y0], [0-markShift, 0-y0-w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapCMakerW,TxtW, [0-markShiftThree, 0-y0-w], [0-markShiftThree,0-y0]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapCMakerX, new makerjs.paths.Line([h1,0+markShiftTwo], [h1+x0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapCMakerX,TxtX,  [h1, 0+markShiftFour],[h1+x0,0+markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapCMakerH, new makerjs.paths.Line([0+hd,0-markShiftTwo-w], [h, 0-markShiftTwo-w]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapCMakerH,TxtH, [0+hd,0-markShiftFour-w], [h, 0-markShiftFour-w]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapCMakerH1, new makerjs.paths.Line([0,0+markShiftTwo], [h1, 0+markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapCMakerH1,TxtH1, [0,0+markShiftFour], [h1,0+markShiftFour]);//W文字标注
  // /*****************************************************************************/
  plate.models["myBfg2GapC"+myHolesNum]=myBfg2GapC;
  markers.models["myBfg2GapC"+myHolesNum]=myBfg2GapC;
  markers.models["myBfg2GapCMakerW1"+myHolesNum]=myBfg2GapCMakerW1;
  markers.models["myBfg2GapCMakerH1"+myHolesNum]=myBfg2GapCMakerH1;
  markers.models["myBfg2GapCMakerW"+myHolesNum]=myBfg2GapCMakerW;
  markers.models["myBfg2GapCMakerH"+myHolesNum]=myBfg2GapCMakerH;
  markers.models["myBfg2GapCMakerR"+myHolesNum]=myBfg2GapCMakerR;
  markers.models["myBfg2GapCMakerX"+myHolesNum]=myBfg2GapCMakerX;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapC"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/
  /*****************************************************************************/ 
        }else if(area==7){
          //画缺 G位置
        //1、2蝴蝶2 G位置   H W D  width=w-d/2        
        var myBfg2GapGRect=new makerjs.models.Rectangle(h1,w1+r); 
        myBfg2GapGRect.origin=[xg,yg-r];     
        var myBfg2GapGBoltRectangle=new makerjs.models.BoltRectangle(h,w+r,r);
        myBfg2GapGBoltRectangle.origin=[xg+hd,yg-r];
  var myBfg2GapG=makerjs.model.combineUnion(myBfg2GapGRect, myBfg2GapGBoltRectangle);  
     //画1、2蝴蝶2标记 G位置 
  var myBfg2GapGMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapGMakerW.origin = [xg,yg];
  var myBfg2GapGMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapGMakerH.origin = [xg,yg];   
  var myBfg2GapGMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapGMakerW1.origin = [xg,yg];
  var myBfg2GapGMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapGMakerH1.origin = [xg,yg];
  var myBfg2GapGMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapGMakerR.origin = [xg,yg];
  var myBfg2GapGMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapGMakerX.origin = [xg,yg];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([0+hd,w], [0+hd,w+markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([0+h+hd,w], [0+h+hd,w+markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([h1,0], [h1,0-markShiftFour]));//Y轴方向短线 w1
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([-x0,0], [-x0, 0-markShiftFour]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([0,0+w], [0-markShiftThree, 0+w]));//X轴方向短线
 makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([-r+h,0-y0+wd+w], [h+markShiftFour+hd, 0-y0+wd+w]));//X轴方向短线 H  up
  //makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([h,0-y0-wd-w], [h+markShiftFour+hd, 0-y0-wd-w]));//X轴方向短线 H  dn
  
  makerjs.model.addPath(myBfg2GapGMakerR, new makerjs.paths.Line([h+hd,0+y0+w1],[h+hd+dd+2*markHolesOneD,0+y0+w1+dd+2*markHolesOneD]));//R相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapGMakerR,TxtR,[h+hd+markShift,0+y0+w1], [h+hd+markShift+dd+2*markHolesOneD,0+y0+w1+dd+2*markHolesOneD]);//R相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg2GapGMakerW1, new makerjs.paths.Line([0+h1+markShift,0+y0], [0+h1+markShift, 0+y0+w1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapGMakerW1,TxtW1, [0+h1+markShiftThree, 0+y0], [0+h1+markShiftThree,0+y0+w1]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([0-markShift,0+y0], [0-markShift, 0+y0+w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapGMakerW,TxtW, [0-markShiftThree,0+y0], [0-markShiftThree, 0+y0+w]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapGMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapGMakerX,TxtX,  [-x0, 0-markShiftFour],[0,0-markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapGMakerH, new makerjs.paths.Line([0+hd,0+markShiftTwo+w], [h+hd, 0+markShiftTwo+w]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapGMakerH,TxtH, [0+hd,0+markShiftFour+w], [h+hd, 0+markShiftFour+w]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapGMakerH1, new makerjs.paths.Line([0,0-markShiftTwo], [h1, 0-markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapGMakerH1,TxtH1, [0,0-markShiftFour], [h1,0-markShiftFour]);//W文字标注
  // /*****************************************************************************/
  plate.models["myBfg2GapG"+myHolesNum]=myBfg2GapG;
  markers.models["myBfg2GapG"+myHolesNum]=myBfg2GapG;
  markers.models["myBfg2GapGMakerW1"+myHolesNum]=myBfg2GapGMakerW1;
  markers.models["myBfg2GapGMakerH1"+myHolesNum]=myBfg2GapGMakerH1;
  markers.models["myBfg2GapGMakerW"+myHolesNum]=myBfg2GapGMakerW;
  markers.models["myBfg2GapGMakerH"+myHolesNum]=myBfg2GapGMakerH;
  markers.models["myBfg2GapGMakerR"+myHolesNum]=myBfg2GapGMakerR;
  markers.models["myBfg2GapGMakerX"+myHolesNum]=myBfg2GapGMakerX;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapG"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/           
        }else if(area==8){
       
         //画缺 H位置
        //1、2蝴蝶2 H位置   H W D  width=w-d/2        
        var myBfg2GapHRect=new makerjs.models.Rectangle(h1,w1+r); 
        myBfg2GapHRect.origin=[xh-h1/2,yh-r];     
        var myBfg2GapHBoltRectangle=new makerjs.models.BoltRectangle(h,w+r,r);
        myBfg2GapHBoltRectangle.origin=[xh+hd-h1/2,yh-r];
  var myBfg2GapH=makerjs.model.combineUnion(myBfg2GapHRect, myBfg2GapHBoltRectangle);  
     //画1、2蝴蝶2标记 H位置 
  var myBfg2GapHMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapHMakerW.origin = [xh-h1/2,yh];
  var myBfg2GapHMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapHMakerH.origin = [xh-h1/2,yh];   
  var myBfg2GapHMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapHMakerW1.origin = [xh-h1/2,yh];
  var myBfg2GapHMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapHMakerH1.origin = [xh-h1/2,yh];
  var myBfg2GapHMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapHMakerR.origin = [xh-h1/2,yh];
  var myBfg2GapHMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapHMakerX.origin = [xh-h1/2,yh];
  /*****************************************************************************/
  //makerjs.model.addPath(myBfg2GapHMakerW, new makerjs.paths.Line([0,0], [0, -markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myBfg2GapHMakerW, new makerjs.paths.Line([0+hd,w], [0+hd,w+markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapHMakerW, new makerjs.paths.Line([0+h+hd,w], [0+h+hd,w+markShiftFour]));//Y轴方向短线 w
 // makerjs.model.addPath(myBfg2GapHMakerW, new makerjs.paths.Line([h1,0], [h1,0-markShiftFour]));//Y轴方向短线 w1
  makerjs.model.addPath(myBfg2GapHMakerW, new makerjs.paths.Line([-x0,0], [-x0, 0-markShiftFour]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapHMakerW, new makerjs.paths.Line([0,0+w], [0-markShiftThree, 0+w]));//X轴方向短线
 makerjs.model.addPath(myBfg2GapHMakerW, new makerjs.paths.Line([-r+h,0-y0+wd+w], [h+markShiftFour+hd, 0-y0+wd+w]));//X轴方向短线 H  up
  //makerjs.model.addPath(myBfg2GapHMakerW, new makerjs.paths.Line([h,0-y0-wd-w], [h+markShiftFour+hd, 0-y0-wd-w]));//X轴方向短线 H  dn
  
  makerjs.model.addPath(myBfg2GapHMakerR, new makerjs.paths.Line([h+hd,0+y0+w1],[h+hd+dd+2*markHolesOneD,0+y0+w1+dd+2*markHolesOneD]));//R相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapHMakerR,TxtR,[h+hd+markShift,0+y0+w1], [h+hd+markShift+dd+2*markHolesOneD,0+y0+w1+dd+2*markHolesOneD]);//R相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg2GapHMakerW1, new makerjs.paths.Line([0+h1+markShift,0+y0], [0+h1+markShift, 0+y0+w1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapHMakerW1,TxtW1, [0+h1+markShiftThree, 0+y0], [0+h1+markShiftThree,0+y0+w1]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapHMakerW, new makerjs.paths.Line([0-markShift,0+y0], [0-markShift, 0+y0+w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapHMakerW,TxtW, [0-markShiftThree,0+y0], [0-markShiftThree, 0+y0+w]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapHMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapHMakerX,TxtX,  [-x0, 0-markShiftFour],[0,0-markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapHMakerH, new makerjs.paths.Line([0+hd,0+markShiftTwo+w], [h+hd, 0+markShiftTwo+w]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapHMakerH,TxtH, [0+hd,0+markShiftFour+w], [h+hd, 0+markShiftFour+w]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapHMakerH1, new makerjs.paths.Line([0,0+markShiftTwo], [h1, 0+markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapHMakerH1,TxtH1, [0,0+markShiftFour], [h1,0+markShiftFour]);//W文字标注
  // /*****************************************************************************/
  plate.models["myBfg2GapH"+myHolesNum]=myBfg2GapH;
  markers.models["myBfg2GapH"+myHolesNum]=myBfg2GapH;
  markers.models["myBfg2GapHMakerW1"+myHolesNum]=myBfg2GapHMakerW1;
  markers.models["myBfg2GapHMakerH1"+myHolesNum]=myBfg2GapHMakerH1;
  markers.models["myBfg2GapHMakerW"+myHolesNum]=myBfg2GapHMakerW;
  markers.models["myBfg2GapHMakerH"+myHolesNum]=myBfg2GapHMakerH;
  markers.models["myBfg2GapHMakerR"+myHolesNum]=myBfg2GapHMakerR;
  markers.models["myBfg2GapHMakerX"+myHolesNum]=myBfg2GapHMakerX;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapH"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/

        }else if(area==9){
        //画缺 I位置
        //1、2蝴蝶2 I位置   H W D  width=w-d/2        
        var myBfg2GapIRect=new makerjs.models.Rectangle(h1,w1+r); 
        myBfg2GapIRect.origin=[xi-h1,yi-r];     
        var myBfg2GapIBoltRectangle=new makerjs.models.BoltRectangle(h,w+r,r);
        myBfg2GapIBoltRectangle.origin=[xi-h,yi-r];
  var myBfg2GapI=makerjs.model.combineUnion(myBfg2GapIRect, myBfg2GapIBoltRectangle);  
     //画1、2蝴蝶2标记 I位置 
  var myBfg2GapIMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapIMakerW.origin = [xi-h1,yi];
  var myBfg2GapIMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapIMakerH.origin = [xi-h1,yi];   
  var myBfg2GapIMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapIMakerW1.origin = [xi-h1,yi];
  var myBfg2GapIMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapIMakerH1.origin = [xi-h1,yi];
  var myBfg2GapIMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapIMakerR.origin = [xi-h1,yi];
  var myBfg2GapIMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapIMakerX.origin = [xi-h1,yi];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([0+hd,+w], [0+hd,+w+markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([0+h,+w], [0+h,+w+markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([h1,0], [h1,0-markShiftFour]));//Y轴方向短线 w1
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([x0+h1,0], [x0+h1, 0-markShiftFour]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([0,0+w], [0-markShiftThree, 0+w]));//X轴方向短线
 makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([-r+h,0+y0+wd+w], [h+markShiftFour+hd, 0+y0+wd+w]));//X轴方向短线 H  up
  //makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([h,0-y0-wd-w], [h+markShiftFour+hd, 0-y0-wd-w]));//X轴方向短线 H  dn
  
  makerjs.model.addPath(myBfg2GapIMakerR, new makerjs.paths.Line([h,0+y0+w1],[h+dd+2*markHolesOneD,0+y0+w1+dd+2*markHolesOneD]));//R相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapIMakerR,TxtR,[h+markShift,0+y0+w1], [h+markShift+dd+2*markHolesOneD,0+y0+w1+dd+2*markHolesOneD]);//R相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg2GapIMakerW1, new makerjs.paths.Line([0+h1+markShift,0-+y0], [0+h1+markShift, 0+y0+w1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapIMakerW1,TxtW1, [0+h1+markShiftThree,0+y0+w1], [0+h1+markShiftThree, 0+y0]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([0-markShift,0+y0], [0-markShift, 0+y0+w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapIMakerW,TxtW, [0-markShiftThree, 0+y0+w], [0-markShiftThree,0+y0]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapIMakerX, new makerjs.paths.Line([h1,0-markShiftTwo], [h1+x0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapIMakerX,TxtX,  [h1, 0-markShiftFour],[h1+x0,0-markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapIMakerH, new makerjs.paths.Line([0+hd,0+markShiftTwo+w], [h, 0+markShiftTwo+w]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapIMakerH,TxtH, [0+hd,0+markShiftFour+w], [h, 0+markShiftFour+w]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapIMakerH1, new makerjs.paths.Line([0,0-markShiftTwo], [h1, 0-markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapIMakerH1,TxtH1, [0,0-markShiftFour], [h1,0-markShiftFour]);//W文字标注
  // /*****************************************************************************/
  plate.models["myBfg2GapI"+myHolesNum]=myBfg2GapI;
  markers.models["myBfg2GapI"+myHolesNum]=myBfg2GapI;
  markers.models["myBfg2GapIMakerW1"+myHolesNum]=myBfg2GapIMakerW1;
  markers.models["myBfg2GapIMakerH1"+myHolesNum]=myBfg2GapIMakerH1;
  markers.models["myBfg2GapIMakerW"+myHolesNum]=myBfg2GapIMakerW;
  markers.models["myBfg2GapIMakerH"+myHolesNum]=myBfg2GapIMakerH;
  markers.models["myBfg2GapIMakerR"+myHolesNum]=myBfg2GapIMakerR;
  markers.models["myBfg2GapIMakerX"+myHolesNum]=myBfg2GapIMakerX;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapI"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/
    
        }

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};


export default ButterflyGap2;