//缺6、铜夹
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function CopperClipGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var R=inputParam.R,W=inputParam.W,W1=inputParam.W1,H=inputParam.H,D=inputParam.D,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   w= W*1*myZoom,
      h= H*1*myZoom,
      d= D*1*myZoom,
      w1= W1*1*myZoom,
      r=R*1*myZoom,
      rd=r/Math.sqrt(2),
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        
   w= W*1,
      h= H*1,
      d= D*1,
      w1= W1*1,
      r=R*1,
      rd=r/Math.sqrt(2),
        x0 = X*1,
        y0 = Y*1;
        */
        if(d!==0){
         var TxtD="∮"+d;
        }
        if(r!==0){
         var TxtR="R="+R;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(w1!==0){
         var TxtW1=W1;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //6、铜夹 A位置 
        var myCcgGapARoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        myCcgGapARoundRectangle.origin=[xa,ya-w];
        delete myCcgGapARoundRectangle.paths.TopRight; 
        delete myCcgGapARoundRectangle.paths.TopLeft;  
        delete myCcgGapARoundRectangle.paths.Top; 
        var myCcgGapARoundHoles= new makerjs.models.Holes(d/2, [[xa, ya]]);  
        myCcgGapARoundHoles.origin=[h/2,-w1]; 
        var myCcgGapA=makerjs.model.combineUnion(myCcgGapARoundRectangle, myCcgGapARoundHoles);
        /*
        var myCcgGapARoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        myCcgGapARoundRectangle.origin=[xa-r,ya-h];      
    var myCcgGapARoundHoles= new makerjs.models.Holes(d/2, [[xa, ya]]);  
    myCcgGapARoundHoles.origin=[w1,-h/2];   
    var myCcgGapA=makerjs.model.combineUnion(myCcgGapARoundRectangle, myCcgGapARoundHoles); 
 */

  //    //画6、铜夹标记 A位置 
  var myCcgGapAMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapAMakerW.origin = [xa,ya];
  var myCcgGapAMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapAMakerH.origin = [xa,ya];
  var myCcgGapAMakerX = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapAMakerX.origin = [xa,ya];
  var myCcgGapAMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapAMakerD.origin = [xa+h/2,ya-w1];
  var myCcgGapAMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapAMakerR.origin = [xa,ya];
  var myCcgGapAMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapAMakerW1.origin = [xa,ya];
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([-x0,0], [-x0, markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([h,0], [h, markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([0,-w], [0-markShiftFour, -w]));//X轴加长标线 w

  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([h,-w1], [h+markShiftFour+markShift, -w1]));//X轴方向短线w1
  makerjs.model.addPath(myCcgGapAMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapAMakerH,TxtH,[0,0+markShiftThree], [h, 0+markShiftThree] );//H文字标注
  
  makerjs.model.addPath(myCcgGapAMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapAMakerX,TxtX, [-x0,0+markShiftFour], [0, 0+markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([0-markShift,-w], [0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(myCcgGapAMakerW, TxtW,[0-markShiftFour,-w], [0-markShiftFour, 0]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapAMakerW1, new makerjs.paths.Line([h+markShiftFour,-w1], [h+markShiftFour, 0]));//W1短线标注
  makerjs.model.addCaption(myCcgGapAMakerW1, TxtW1, [h+markShiftFour+markShift,-w1], [h+markShiftFour+markShift, 0]);//W1文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapAMakerD, new makerjs.paths.Line([0,0], [0+d, 0-d]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapAMakerD, TxtD, [0,0], [0+d+markHolesOneD, 0-d-markHolesOneD]);///D文字标注直径文字标注
  /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapAMakerR, new makerjs.paths.Line([h-r,-w+r], [h-r+rd,-w+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapAMakerR, TxtR, [h-r+markHolesOneD,-w+r+r-markHolesOneD], [h-r+rd+r+markHolesOneD, -w+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/ 
  plate.models["myCcgGapA"+myHolesNum]=myCcgGapA;
  markers.models["myCcgGapA"+myHolesNum]=myCcgGapA;
  markers.models["myCcgGapAMakerW"+myHolesNum]=myCcgGapAMakerW;
  markers.models["myCcgGapAMakerH"+myHolesNum]=myCcgGapAMakerH;
  markers.models["myCcgGapAMakerX"+myHolesNum]=myCcgGapAMakerX;
  markers.models["myCcgGapAMakerD"+myHolesNum]=myCcgGapAMakerD;
  markers.models["myCcgGapAMakerR"+myHolesNum]=myCcgGapAMakerR;
  markers.models["myCcgGapAMakerW1"+myHolesNum]=myCcgGapAMakerW1;
  /*****************************************************************************/
 // makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myCcgGapA"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
      
  }else if(area==2){
     //画缺 B位置
        //6、铜夹 B位置 
        var myCcgGapBRoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        myCcgGapBRoundRectangle.origin=[xb-h/2,yb-w];
        delete myCcgGapBRoundRectangle.paths.TopRight; 
        delete myCcgGapBRoundRectangle.paths.TopLeft;  
        delete myCcgGapBRoundRectangle.paths.Top; 
        var myCcgGapBRoundHoles= new makerjs.models.Holes(d/2, [[xb, yb]]);  
        myCcgGapBRoundHoles.origin=[h/2-h/2,-w1]; 
        var myCcgGapB=makerjs.model.combineUnion(myCcgGapBRoundRectangle, myCcgGapBRoundHoles);
 
  //    //画6、铜夹标记 B位置 
  var myCcgGapBMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapBMakerW.origin = [xb-h/2,yb];
  var myCcgGapBMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapBMakerH.origin = [xb-h/2,yb];
  var myCcgGapBMakerX = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapBMakerX.origin = [xb-h/2,yb];
  var myCcgGapBMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapBMakerD.origin = [xb,yb-w1];
  var myCcgGapBMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapBMakerR.origin = [xb-h/2,yb];
  var myCcgGapBMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapBMakerW1.origin = [xb-h/2,yb];
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapBMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapBMakerW, new makerjs.paths.Line([-x0,0], [-x0, markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapBMakerW, new makerjs.paths.Line([h,0], [h, markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapBMakerW, new makerjs.paths.Line([0,-w], [0-markShiftFour, -w]));//X轴加长标线 w

  makerjs.model.addPath(myCcgGapBMakerW, new makerjs.paths.Line([h,-w1], [h+markShiftFour+markShift, -w1]));//X轴方向短线w1
  makerjs.model.addPath(myCcgGapBMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapBMakerH,TxtH,[0,0+markShiftThree], [h, 0+markShiftThree] );//H文字标注
  
  makerjs.model.addPath(myCcgGapBMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapBMakerX,TxtX, [-x0,0+markShiftFour], [0, 0+markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapBMakerW, new makerjs.paths.Line([0-markShift,-w], [0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(myCcgGapBMakerW, TxtW,[0-markShiftFour,-w], [0-markShiftFour, 0]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapBMakerW1, new makerjs.paths.Line([h+markShiftFour,-w1], [h+markShiftFour, 0]));//W1短线标注
  makerjs.model.addCaption(myCcgGapBMakerW1, TxtW1, [h+markShiftFour+markShift,-w1], [h+markShiftFour+markShift, 0]);//W1文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapBMakerD, new makerjs.paths.Line([0,0], [0+d, 0-d]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapBMakerD, TxtD, [0,0], [0+d+markHolesOneD, 0-d-markHolesOneD]);///D文字标注直径文字标注
  /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapBMakerR, new makerjs.paths.Line([h-r,-w+r], [h-r+rd,-w+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapBMakerR, TxtR, [h-r+markHolesOneD,-w+r+r-markHolesOneD], [h-r+rd+r+markHolesOneD, -w+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/ 
  plate.models["myCcgGapB"+myHolesNum]=myCcgGapB;
  markers.models["myCcgGapB"+myHolesNum]=myCcgGapB;
  markers.models["myCcgGapBMakerW"+myHolesNum]=myCcgGapBMakerW;
  markers.models["myCcgGapBMakerH"+myHolesNum]=myCcgGapBMakerH;
  markers.models["myCcgGapBMakerX"+myHolesNum]=myCcgGapBMakerX;
  markers.models["myCcgGapBMakerD"+myHolesNum]=myCcgGapBMakerD;
  markers.models["myCcgGapBMakerR"+myHolesNum]=myCcgGapBMakerR;
  markers.models["myCcgGapBMakerW1"+myHolesNum]=myCcgGapBMakerW1;
  /*****************************************************************************/
          
  }else if(area==3){
  //画缺 C位置
        //6、铜夹 C位置 
        var myCcgGapCRoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        myCcgGapCRoundRectangle.origin=[xc-h,yc-w];
        delete myCcgGapCRoundRectangle.paths.TopRight; 
        delete myCcgGapCRoundRectangle.paths.TopLeft;  
        delete myCcgGapCRoundRectangle.paths.Top; 
        var myCcgGapCRoundHoles= new makerjs.models.Holes(d/2, [[xc, yc]]);  
        myCcgGapCRoundHoles.origin=[h/2-h,-w1]; 
        var myCcgGapC=makerjs.model.combineUnion(myCcgGapCRoundRectangle, myCcgGapCRoundHoles);
 
  //    //画6、铜夹标记 C位置 
  var myCcgGapCMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapCMakerW.origin = [xc-h,yc];
  var myCcgGapCMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapCMakerH.origin = [xc-h,yc];
  var myCcgGapCMakerX = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapCMakerX.origin = [xc-h,yc];
  var myCcgGapCMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapCMakerD.origin = [xc,yc-w1];
  var myCcgGapCMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapCMakerR.origin = [xc-h,yc];
  var myCcgGapCMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapCMakerW1.origin = [xc-h,yc];
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([h+x0,0], [h+x0, markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([h,0], [h, markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([0,-w], [0-markShiftFour, -w]));//X轴加长标线 w

  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([h,-w1], [h+markShiftFour+markShift, -w1]));//X轴方向短线w1
  makerjs.model.addPath(myCcgGapCMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapCMakerH,TxtH,[0,0+markShiftThree], [h, 0+markShiftThree] );//H文字标注
  
  makerjs.model.addPath(myCcgGapCMakerX, new makerjs.paths.Line([h,0+markShiftTwo], [h+x0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapCMakerX,TxtX, [h,0+markShiftFour], [h+x0, 0+markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([0-markShift,-w], [0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(myCcgGapCMakerW, TxtW,[0-markShiftFour,-w], [0-markShiftFour, 0]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapCMakerW1, new makerjs.paths.Line([h+markShiftFour,-w1], [h+markShiftFour, 0]));//W1短线标注
  makerjs.model.addCaption(myCcgGapCMakerW1, TxtW1, [h+markShiftFour+markShift,-w1], [h+markShiftFour+markShift, 0]);//W1文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapCMakerD, new makerjs.paths.Line([0-h/2,0], [0+d-h/2, 0-d]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapCMakerD, TxtD, [0-h/2,0], [0+d-h/2+markHolesOneD, 0-d-markHolesOneD]);///D文字标注直径文字标注
  /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapCMakerR, new makerjs.paths.Line([h-r,-w+r], [h-r+rd,-w+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapCMakerR, TxtR, [h-r+markHolesOneD,-w+r+r-markHolesOneD], [h-r+rd+r+markHolesOneD, -w+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/ 
  plate.models["myCcgGapC"+myHolesNum]=myCcgGapC;
  markers.models["myCcgGapC"+myHolesNum]=myCcgGapC;
  markers.models["myCcgGapCMakerW"+myHolesNum]=myCcgGapCMakerW;
  markers.models["myCcgGapCMakerH"+myHolesNum]=myCcgGapCMakerH;
  markers.models["myCcgGapCMakerX"+myHolesNum]=myCcgGapCMakerX;
  markers.models["myCcgGapCMakerD"+myHolesNum]=myCcgGapCMakerD;
  markers.models["myCcgGapCMakerR"+myHolesNum]=myCcgGapCMakerR;
  markers.models["myCcgGapCMakerW1"+myHolesNum]=myCcgGapCMakerW1;
  /*****************************************************************************/
    
  }else if(area==7){
   //画缺 G位置
        //6、铜夹 G位置 
        var myCcgGapGRoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        myCcgGapGRoundRectangle.origin=[xg,yg-r];
        delete myCcgGapGRoundRectangle.paths.BottomRight; 
        delete myCcgGapGRoundRectangle.paths.BottomLeft;  
        delete myCcgGapGRoundRectangle.paths.Bottom; 
        var myCcgGapGRoundHoles= new makerjs.models.Holes(d/2, [[xg, yg]]);  
        myCcgGapGRoundHoles.origin=[h/2,+w1]; 
        var myCcgGapG=makerjs.model.combineUnion(myCcgGapGRoundRectangle, myCcgGapGRoundHoles);
        /*
        var myCcgGapGRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        myCcgGapGRoundRectangle.origin=[xg-r,yg-h];      
    var myCcgGapGRoundHoles= new makerjs.models.Holes(d/2, [[xg, yg]]);  
    myCcgGapGRoundHoles.origin=[w1,-h/2];   
    var myCcgGapG=makerjs.model.combineUnion(myCcgGapGRoundRectangle, myCcgGapGRoundHoles); 
 */

  //    //画6、铜夹标记 G位置 
  var myCcgGapGMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapGMakerW.origin = [xg,yg];
  var myCcgGapGMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapGMakerH.origin = [xg,yg];
  var myCcgGapGMakerX = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapGMakerX.origin = [xg,yg];
  var myCcgGapGMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapGMakerD.origin = [xg+h/2,yg];
  var myCcgGapGMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapGMakerR.origin = [xg,yg];
  var myCcgGapGMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapGMakerW1.origin = [xg,yg];
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([-x0,0], [-x0, -markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([h,0], [h, -markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([0,+w], [0-markShiftFour, +w]));//X轴加长标线 w

  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([h,+w1], [h+markShiftFour+markShift, +w1]));//X轴方向短线w1
  makerjs.model.addPath(myCcgGapGMakerH, new makerjs.paths.Line([0,0-markShift], [h, 0-markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapGMakerH,TxtH,[0,0-markShiftThree], [h, 0-markShiftThree] );//H文字标注
  
  makerjs.model.addPath(myCcgGapGMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapGMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([0-markShift,0], [0-markShift, w]));//W短线标注
  makerjs.model.addCaption(myCcgGapGMakerW, TxtW,[0-markShiftFour,0], [0-markShiftFour, w]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapGMakerW1, new makerjs.paths.Line([h+markShiftFour,0], [h+markShiftFour, w1]));//W1短线标注
  makerjs.model.addCaption(myCcgGapGMakerW1, TxtW1, [h+markShiftFour+markShift,0], [h+markShiftFour+markShift, w1]);//W1文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapGMakerD, new makerjs.paths.Line([0,w1], [0+d, w1+d]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapGMakerD, TxtD, [0,w1], [0+d+markHolesOneD, w1+d+markHolesOneD]);///D文字标注直径文字标注
  /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapGMakerR, new makerjs.paths.Line([h-r,+w-r], [h-r+rd,+w-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapGMakerR, TxtR, [h-r+markHolesOneD,+w-r-r+markHolesOneD], [h-r+rd+r+markHolesOneD, +w-r+rd+r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/ 
  plate.models["myCcgGapG"+myHolesNum]=myCcgGapG;
  markers.models["myCcgGapG"+myHolesNum]=myCcgGapG;
  markers.models["myCcgGapGMakerW"+myHolesNum]=myCcgGapGMakerW;
  markers.models["myCcgGapGMakerH"+myHolesNum]=myCcgGapGMakerH;
  markers.models["myCcgGapGMakerX"+myHolesNum]=myCcgGapGMakerX;
  markers.models["myCcgGapGMakerD"+myHolesNum]=myCcgGapGMakerD;
  markers.models["myCcgGapGMakerR"+myHolesNum]=myCcgGapGMakerR;
  markers.models["myCcgGapGMakerW1"+myHolesNum]=myCcgGapGMakerW1;
  /*****************************************************************************/
             
  }else if(area==8){
   //画缺 H位置
        //6、铜夹 H位置 
        var myCcgGapHRoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        myCcgGapHRoundRectangle.origin=[xh-h/2,yh-r];
        delete myCcgGapHRoundRectangle.paths.BottomRight; 
        delete myCcgGapHRoundRectangle.paths.BottomLeft;  
        delete myCcgGapHRoundRectangle.paths.Bottom; 
        var myCcgGapHRoundHoles= new makerjs.models.Holes(d/2, [[xh,yh]]);  
        myCcgGapHRoundHoles.origin=[h/2-h/2,+w1]; 
        var myCcgGapH=makerjs.model.combineUnion(myCcgGapHRoundRectangle, myCcgGapHRoundHoles);
        
  //    //画6、铜夹标记 G位置 
  var myCcgGapHMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapHMakerW.origin = [xh-h/2,yh];
  var myCcgGapHMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapHMakerH.origin = [xh-h/2,yh];
  var myCcgGapHMakerX = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapHMakerX.origin = [xh-h/2,yh];
  var myCcgGapHMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapHMakerD.origin = [xh+h/2-h/2,yh];
  var myCcgGapHMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapHMakerR.origin = [xh-h/2,yh];
  var myCcgGapHMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapHMakerW1.origin = [xh-h/2,yh];
  /*****************************************************************************/
  //makerjs.model.addPath(myCcgGapHMakerW, new makerjs.paths.Line([0,0], [0, -2*markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapHMakerW, new makerjs.paths.Line([-x0,0], [-x0, -markShiftFour]));//Y轴短标线

  //makerjs.model.addPath(myCcgGapHMakerW, new makerjs.paths.Line([h,0], [h, -2*markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapHMakerW, new makerjs.paths.Line([0,+w], [0-markShiftFour, +w]));//X轴加长标线 w

  makerjs.model.addPath(myCcgGapHMakerW, new makerjs.paths.Line([h,+w1], [h+markShiftFour+markShift, +w1]));//X轴方向短线w1

  makerjs.model.addPath(myCcgGapHMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapHMakerH,TxtH,[0,0+markShiftFour], [h, 0+markShiftFour] );//H文字标注
  
  makerjs.model.addPath(myCcgGapHMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapHMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapHMakerW, new makerjs.paths.Line([0-markShift,0], [0-markShift, w]));//W短线标注
  makerjs.model.addCaption(myCcgGapHMakerW, TxtW,[0-markShiftFour,0], [0-markShiftFour, w]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapHMakerW1, new makerjs.paths.Line([h+markShiftFour,0], [h+markShiftFour, w1]));//W1短线标注
  makerjs.model.addCaption(myCcgGapHMakerW1, TxtW1, [h+markShiftFour+markShift,0], [h+markShiftFour+markShift, w1]);//W1文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapHMakerD, new makerjs.paths.Line([0,w1], [0+d, w1+d]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapHMakerD, TxtD, [0,w1], [0+d+markHolesOneD, w1+d+markHolesOneD]);///D文字标注直径文字标注
  /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapHMakerR, new makerjs.paths.Line([h-r,+w-r], [h-r+rd,+w-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapHMakerR, TxtR, [h-r+markHolesOneD,+w-r-r+markHolesOneD], [h-r+rd+r+markHolesOneD, +w-r+rd+r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/ 
  plate.models["myCcgGapH"+myHolesNum]=myCcgGapH;
  markers.models["myCcgGapH"+myHolesNum]=myCcgGapH;
  markers.models["myCcgGapHMakerW"+myHolesNum]=myCcgGapHMakerW;
  markers.models["myCcgGapHMakerH"+myHolesNum]=myCcgGapHMakerH;
  markers.models["myCcgGapHMakerX"+myHolesNum]=myCcgGapHMakerX;
  markers.models["myCcgGapHMakerD"+myHolesNum]=myCcgGapHMakerD;
  markers.models["myCcgGapHMakerR"+myHolesNum]=myCcgGapHMakerR;
  markers.models["myCcgGapHMakerW1"+myHolesNum]=myCcgGapHMakerW1;
  /*****************************************************************************/       
  }else if(area==9){
  //画缺 I位置
        //6、铜夹 I位置 
        var myCcgGapIRoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        myCcgGapIRoundRectangle.origin=[xi-h,yi-r];
        delete myCcgGapIRoundRectangle.paths.BottomRight; 
        delete myCcgGapIRoundRectangle.paths.BottomLeft;  
        delete myCcgGapIRoundRectangle.paths.Bottom; 
        var myCcgGapIRoundHoles= new makerjs.models.Holes(d/2, [[xi,yi]]);  
        myCcgGapIRoundHoles.origin=[h/2-h,+w1]; 
        var myCcgGapI=makerjs.model.combineUnion(myCcgGapIRoundRectangle, myCcgGapIRoundHoles);
        
  //    //画6、铜夹标记 I位置 
  var myCcgGapIMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapIMakerW.origin = [xi-h,yi];
  var myCcgGapIMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapIMakerH.origin = [xi-h,yi];
  var myCcgGapIMakerX = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapIMakerX.origin = [xi-h,yi];
  var myCcgGapIMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapIMakerD.origin = [xi-h,yi];
  var myCcgGapIMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapIMakerR.origin = [xi-h,yi];
  var myCcgGapIMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapIMakerW1.origin = [xi-h,yi];
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([h+x0,0], [h+x0, -markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([h,0], [h, -markShiftFour]));//Y轴短标线

  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([0,+w], [0-markShiftFour, +w]));//X轴加长标线 w

  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([h,+w1], [h+markShiftFour+markShift, +w1]));//X轴方向短线w1

  makerjs.model.addPath(myCcgGapIMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapIMakerH,TxtH,[0,0+markShiftFour], [h, 0+markShiftFour] );//H文字标注
  
  makerjs.model.addPath(myCcgGapIMakerX, new makerjs.paths.Line([h,0-markShiftTwo], [h+x0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapIMakerX,TxtX, [h,0-markShiftFour], [h+x0, 0-markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([0-markShift,0], [0-markShift, w]));//W短线标注
  makerjs.model.addCaption(myCcgGapIMakerW, TxtW,[0-markShiftFour,0], [0-markShiftFour, w]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapIMakerW1, new makerjs.paths.Line([h+markShiftFour,0], [h+markShiftFour, w1]));//W1短线标注
  makerjs.model.addCaption(myCcgGapIMakerW1, TxtW1, [h+markShiftFour+markShift,0], [h+markShiftFour+markShift, w1]);//W1文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapIMakerD, new makerjs.paths.Line([0+h/2,w1], [0+h/2+d, w1+d]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapIMakerD, TxtD, [0+h/2,w1], [0+h/2+d+markHolesOneD, w1+d+markHolesOneD]);///D文字标注直径文字标注
  /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapIMakerR, new makerjs.paths.Line([h-r,+w-r], [h-r+rd,+w-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapIMakerR, TxtR, [h-r+markHolesOneD,+w-r-r+markHolesOneD], [h-r+rd+r+markHolesOneD, +w-r+rd+r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/ 
  plate.models["myCcgGapI"+myHolesNum]=myCcgGapI;
  markers.models["myCcgGapI"+myHolesNum]=myCcgGapI;
  markers.models["myCcgGapIMakerW"+myHolesNum]=myCcgGapIMakerW;
  markers.models["myCcgGapIMakerH"+myHolesNum]=myCcgGapIMakerH;
  markers.models["myCcgGapIMakerX"+myHolesNum]=myCcgGapIMakerX;
  markers.models["myCcgGapIMakerD"+myHolesNum]=myCcgGapIMakerD;
  markers.models["myCcgGapIMakerR"+myHolesNum]=myCcgGapIMakerR;
  markers.models["myCcgGapIMakerW1"+myHolesNum]=myCcgGapIMakerW1;
  /*****************************************************************************/       
  }

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};


export default CopperClipGap;