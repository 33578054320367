//缺4、跑道缺
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function RunwayGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var H=inputParam.H,H1=inputParam.H1,W=inputParam.W,W1=inputParam.W1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = H*1*myZoom,
      w= W*1*myZoom,
      w1= W1*1*myZoom,
      h1= H1*1*myZoom,
      h= H*1*myZoom,
      r=h/2,
      dd=Math.sqrt(r*r-h1/2*h1/2),
      ww=w1-w-dd,
      wd=w1-w,
      h0=(h-h1)/2,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = H*1,
           w= W*1,
           w1= W1*1,
           h1= H1*1,
           h= H*1,
           r=h/2,
           dd=Math.sqrt(r*r-h/2*h/2),
           ww=w1-w-dd,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="∮"+H;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(w1!==0){
         var TxtW1=W1;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //4、跑道缺 A位置   H W D  width=w-d/2   
        /*   
        var myRwgGapARect=new makerjs.models.Rectangle(ww+5, h1); 
            myRwgGapARect.origin=[xa-5,ya-h1];     
        var myRwgGapAOval=new makerjs.models.Oval(w+h, h);
            myRwgGapAOval.origin=[xa+ww-r,ya-h1/2-h/2];
        var myRwgGapA=makerjs.model.combineUnion(myRwgGapARect, myRwgGapAOval);
        */
        //重新画20240709  
        var myRwgGapA = {
  
            paths: {
              // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
              //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
              "LeftTopLine": new makerjs.paths.Line([xa, ya], [xa, ya-ww]),
              "TopLeftArc": new makerjs.paths.Arc([xa, ya-ww], [xa-h0, ya-wd],r,false,false),
              "LeftLine": new makerjs.paths.Line([xa-h0, ya-wd], [xa-h0, ya-w1]),
              "BottomArc": new makerjs.paths.Arc([xa-h0, ya-w1], [xa-h0+h, ya-w1],r,false,false),
              "RightLine": new makerjs.paths.Line([xa-h0+h, ya-w1],[xa-h0+h, ya-wd]),
              "TopRightArc": new makerjs.paths.Arc([xa-h0+h, ya-wd],[xa+h1, ya-ww],r,false,false),
              "TopRightLine": new makerjs.paths.Line([xa+h1, ya-ww], [xa+h1, ya])
            }
            
            };   
        
  //重新画20240709      
  myRwgGapA.origin = [0,0];

//      //画4、跑道缺标记 A位置 
  var myRwgGapAMakerW = new makerjs.models.Square(0); //D的起始点       
  myRwgGapAMakerW.origin = [xa,ya];
  var myRwgGapAMakerW1 = new makerjs.models.Square(0); //W的起始点        
  myRwgGapAMakerW1.origin = [xa,ya];
  var myRwgGapAMakerH = new makerjs.models.Square(0); //H的起始点        
  myRwgGapAMakerH.origin = [xa,ya];
  var myRwgGapAMakerH1 = new makerjs.models.Square(0); //H的起始点        
  myRwgGapAMakerH1.origin = [xa,ya];
  var myRwgGapAMakerX = new makerjs.models.Square(0); //Y的起始点        
  myRwgGapAMakerX.origin = [xa,ya];
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([-x0,0], [-x0,0+markShiftFour]));//Y轴方向短线

  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([-h0,-w1-markShiftFour], [-h0,-w1+markShiftTwo]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([h1+h0,-w1-markShiftFour], [h1+h0,-w1+markShiftTwo]));//Y轴方向短线

  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([h1,0], [h1, 0+markShiftFour]));//X轴加长标线

  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([0+h1+h0,-w1], [0+h1+h0+markShiftFour, -w1]));//W1轴方向短线

  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([0-h0,-wd], [0-h0-markShiftFour,-wd]));//W轴方向短线
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([0-h0,-w1], [0-h0-markShiftFour,-w1]));//W轴方向短线
  
  
  makerjs.model.addPath(myRwgGapAMakerH, new makerjs.paths.Line([0-h0,-w1], [0+h1+h0,-w1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRwgGapAMakerH,TxtD, [0-h0,-w1+markShift], [0+h1+h0,-w1+markShift]);//H文字标注
  
  
  makerjs.model.addPath(myRwgGapAMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRwgGapAMakerX,TxtX, [-x0,0+markShiftFour], [0, 0+markShiftFour]);//Y文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapAMakerH1, new makerjs.paths.Line([0,0+markShiftTwo], [h1, 0+markShiftTwo]));//H1轴方向标注短线 
  makerjs.model.addCaption(myRwgGapAMakerH1,TxtH1, [0,0+markShiftFour], [h1, 0+markShiftFour]);//H1文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapAMakerW1, new makerjs.paths.Line([0+h1+h0+markShift,-w1], [0+h1+h0+markShift, 0]));//W1短线标注
  makerjs.model.addCaption(myRwgGapAMakerW1, TxtW1, [0+h1+h0+markShiftThree,-w1], [0+h1+h0+markShiftThree, 0]);//W1文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([0-h0-markShift,-w1], [0-h0-markShift, -wd]));//W短线标注
  makerjs.model.addCaption(myRwgGapAMakerW, TxtW, [0-h0-markShiftTwo,-w1], [0-h0-markShiftTwo, -wd]);//W文字标注
  /*****************************************************************************/
plate.models["myRwgGapA"+myHolesNum]=myRwgGapA;
  markers.models["myRwgGapA"+myHolesNum]=myRwgGapA;
  markers.models["myRwgGapAMakerW"+myHolesNum]=myRwgGapAMakerW;
  markers.models["myRwgGapAMakerW1"+myHolesNum]=myRwgGapAMakerW1;
  markers.models["myRwgGapAMakerH"+myHolesNum]=myRwgGapAMakerH;
  markers.models["myRwgGapAMakerH1"+myHolesNum]=myRwgGapAMakerH1;
  markers.models["myRwgGapAMakerX"+myHolesNum]=myRwgGapAMakerX;
  /*****************************************************************************/
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRwgGapA"+myHolesNum]);
  /*****************************************************************************/ 
     
  }else if(area==2){
   //画缺 A位置
        //4、跑道缺 A位置   H W D  width=w-d/2   
        /*   
        var myRwgGapBRect=new makerjs.models.Rectangle(ww+5, h1); 
            myRwgGapBRect.origin=[xa-5,ya-h1];     
        var myRwgGapBOval=new makerjs.models.Oval(w+h, h);
            myRwgGapBOval.origin=[xa+ww-r,ya-h1/2-h/2];
        var myRwgGapB=makerjs.model.combineUnion(myRwgGapBRect, myRwgGapBOval);
        */
        //重新画20240709  
        var myRwgGapB = {
  
            paths: {
              // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
              //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
              "LeftTopLine": new makerjs.paths.Line([xb, yb], [xb, yb-ww]),
              "TopLeftArc": new makerjs.paths.Arc([xb, yb-ww], [xb-h0, yb-wd],r,false,false),
              "LeftLine": new makerjs.paths.Line([xb-h0, yb-wd], [xb-h0, yb-w1]),
              "BottomArc": new makerjs.paths.Arc([xb-h0, yb-w1], [xb-h0+h, yb-w1],r,false,false),
              "RightLine": new makerjs.paths.Line([xb-h0+h, yb-w1],[xb-h0+h, yb-wd]),
              "TopRightArc": new makerjs.paths.Arc([xb-h0+h, yb-wd],[xb+h1, yb-ww],r,false,false),
              "TopRightLine": new makerjs.paths.Line([xb+h1, yb-ww], [xb+h1, yb])
            }
            
            };   
        
  //重新画20240709      
  myRwgGapB.origin = [0-h1/2,0];

//      //画4、跑道缺标记 A位置 
  var myRwgGapBMakerW = new makerjs.models.Square(0); //D的起始点       
  myRwgGapBMakerW.origin = [xb-h1/2,yb];
  var myRwgGapBMakerW1 = new makerjs.models.Square(0); //W的起始点        
  myRwgGapBMakerW1.origin = [xb-h1/2,yb];
  var myRwgGapBMakerH = new makerjs.models.Square(0); //H的起始点        
  myRwgGapBMakerH.origin = [xb-h1/2,yb];
  var myRwgGapBMakerH1 = new makerjs.models.Square(0); //H的起始点        
  myRwgGapBMakerH1.origin = [xb-h1/2,yb];
  var myRwgGapBMakerX = new makerjs.models.Square(0); //Y的起始点        
  myRwgGapBMakerX.origin = [xb-h1/2,yb];
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapBMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRwgGapBMakerW, new makerjs.paths.Line([-x0,0], [-x0,0+markShiftFour]));//Y轴方向短线
  

  makerjs.model.addPath(myRwgGapBMakerW, new makerjs.paths.Line([-h0,-w1-markShiftFour], [-h0,-w1+markShiftTwo]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapBMakerW, new makerjs.paths.Line([h1+h0,-w1-markShiftFour], [h1+h0,-w1+markShiftTwo]));//Y轴方向短线


  //makerjs.model.addPath(myRwgGapBMakerW, new makerjs.paths.Line([0,0+markShiftTwo], [h1,0+markShiftTwo]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapBMakerW, new makerjs.paths.Line([h1,0], [h1, 0+markShiftFour]));//X轴加长标线

  makerjs.model.addPath(myRwgGapBMakerW, new makerjs.paths.Line([0+h1+h0,-w1], [0+h1+h0+markShiftFour, -w1]));//W1轴方向短线

  makerjs.model.addPath(myRwgGapBMakerW, new makerjs.paths.Line([0-h0,-wd], [0-h0-markShiftFour,-wd]));//W轴方向短线
  makerjs.model.addPath(myRwgGapBMakerW, new makerjs.paths.Line([0-h0,-w1], [0-h0-markShiftFour,-w1]));//W轴方向短线
  
  
  makerjs.model.addPath(myRwgGapBMakerH, new makerjs.paths.Line([0-h0,-w1], [0+h1+h0,-w1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRwgGapBMakerH,TxtD, [0-h0,-w1+markShift], [0+h1+h0,-w1+markShift]);//H文字标注
  
  
  makerjs.model.addPath(myRwgGapBMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRwgGapBMakerX,TxtX, [-x0,0+markShiftFour], [0, 0+markShiftFour]);//Y文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapBMakerH1, new makerjs.paths.Line([0,0+markShiftTwo], [h1, 0+markShiftTwo]));//H1轴方向标注短线 
  makerjs.model.addCaption(myRwgGapBMakerH1,TxtH1, [0,0+markShiftFour], [h1, 0+markShiftFour]);//H1文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapBMakerW1, new makerjs.paths.Line([0+h1+h0+markShift,-w1], [0+h1+h0+markShift, 0]));//W1短线标注
  makerjs.model.addCaption(myRwgGapBMakerW1, TxtW1, [0+h1+h0+markShiftThree,-w1], [0+h1+h0+markShiftThree, 0]);//W1文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapBMakerW, new makerjs.paths.Line([0-h0-markShift,-w1], [0-h0-markShift, -wd]));//W短线标注
  makerjs.model.addCaption(myRwgGapBMakerW, TxtW, [0-h0-markShiftTwo,-w1], [0-h0-markShiftTwo, -wd]);//W文字标注
  /*****************************************************************************/
plate.models["myRwgGapB"+myHolesNum]=myRwgGapB;
  markers.models["myRwgGapB"+myHolesNum]=myRwgGapB;
  markers.models["myRwgGapBMakerW"+myHolesNum]=myRwgGapBMakerW;
  markers.models["myRwgGapBMakerW1"+myHolesNum]=myRwgGapBMakerW1;
  markers.models["myRwgGapBMakerH"+myHolesNum]=myRwgGapBMakerH;
  markers.models["myRwgGapBMakerH1"+myHolesNum]=myRwgGapBMakerH1;
  markers.models["myRwgGapBMakerX"+myHolesNum]=myRwgGapBMakerX;
  /*****************************************************************************/
    
  }else if(area==3){
  //画缺 C位置
        //4、跑道缺 C位置   H W D  width=w-d/2          
        //重新画20240709  
        var myRwgGapC = {
  
          paths: {
            // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
            //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
            "LeftTopLine": new makerjs.paths.Line([xc, yc], [xc, yc-ww]),
            "TopLeftArc": new makerjs.paths.Arc([xc, yc-ww], [xc-h0, yc-wd],r,false,false),
            "LeftLine": new makerjs.paths.Line([xc-h0, yc-wd], [xc-h0, yc-w1]),
            "BottomArc": new makerjs.paths.Arc([xc-h0, yc-w1], [xc-h0+h, yc-w1],r,false,false),
            "RightLine": new makerjs.paths.Line([xc-h0+h, yc-w1],[xc-h0+h, yc-wd]),
            "TopRightArc": new makerjs.paths.Arc([xc-h0+h, yc-wd],[xc+h1, yc-ww],r,false,false),
            "TopRightLine": new makerjs.paths.Line([xc+h1, yc-ww], [xc+h1, yc])
          }
          
          };   
      
//重新画20240709      
myRwgGapC.origin = [0-h1,0];

//      //画4、跑道缺标记 C位置 
var myRwgGapCMakerW = new makerjs.models.Square(0); //D的起始点       
myRwgGapCMakerW.origin = [xc-h1,yc];
var myRwgGapCMakerW1 = new makerjs.models.Square(0); //W的起始点        
myRwgGapCMakerW1.origin = [xc-h1,yc];
var myRwgGapCMakerH = new makerjs.models.Square(0); //H的起始点        
myRwgGapCMakerH.origin = [xc-h1,yc];
var myRwgGapCMakerH1 = new makerjs.models.Square(0); //H的起始点        
myRwgGapCMakerH1.origin = [xc-h1,yc];
var myRwgGapCMakerX = new makerjs.models.Square(0); //Y的起始点        
myRwgGapCMakerX.origin = [xc-h1,yc];
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([x0+h1,0], [x0+h1,0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([-h0,-w1-markShiftFour], [-h0,-w1+markShiftTwo]));//Y轴方向短线
makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([h1+h0,-w1-markShiftFour], [h1+h0,-w1+markShiftTwo]));//Y轴方向短线

//makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0,0+markShiftTwo], [h1,0+markShiftTwo]));//Y轴方向短线
makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([h1,0], [h1, 0+markShiftFour]));//X轴加长标线

makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0+h1+h0,-w1], [0+h1+h0+markShiftFour, -w1]));//W1轴方向短线

makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0-h0,-wd], [0-h0-markShiftFour,-wd]));//W轴方向短线
makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0-h0,-w1], [0-h0-markShiftFour,-w1]));//W轴方向短线


makerjs.model.addPath(myRwgGapCMakerH, new makerjs.paths.Line([0-h0,-w1], [0+h1+h0,-w1]));//H相关 Y轴方向短线
makerjs.model.addCaption(myRwgGapCMakerH,TxtD, [0-h0,-w1+markShift], [0+h1+h0,-w1+markShift]);//H文字标注


makerjs.model.addPath(myRwgGapCMakerX, new makerjs.paths.Line([h1,0+markShift], [h1+x0, 0+markShift]));//Y轴方向标注短线 
makerjs.model.addCaption(myRwgGapCMakerX,TxtX, [-h1,0+markShiftThree], [h1+x0, 0+markShiftThree]);//Y文字标注
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapCMakerH1, new makerjs.paths.Line([0,0+markShiftTwo], [h1, 0+markShiftTwo]));//H1轴方向标注短线 
makerjs.model.addCaption(myRwgGapCMakerH1,TxtH1, [0,0+markShiftFour], [h1, 0+markShiftFour]);//H1文字标注
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapCMakerW1, new makerjs.paths.Line([0+h1+h0+markShift,-w1], [0+h1+h0+markShift, 0]));//W1短线标注
makerjs.model.addCaption(myRwgGapCMakerW1, TxtW1, [0+h1+h0+markShiftThree,-w1], [0+h1+h0+markShiftThree, 0]);//W1文字标注
// /*****************************************************************************/ 
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0-h0-markShift,-w1], [0-h0-markShift, -wd]));//W短线标注
makerjs.model.addCaption(myRwgGapCMakerW, TxtW, [0-h0-markShiftTwo,-w1], [0-h0-markShiftTwo, -wd]);//W文字标注
/*****************************************************************************/
plate.models["myRwgGapC"+myHolesNum]=myRwgGapC;
markers.models["myRwgGapC"+myHolesNum]=myRwgGapC;
markers.models["myRwgGapCMakerW"+myHolesNum]=myRwgGapCMakerW;
markers.models["myRwgGapCMakerW1"+myHolesNum]=myRwgGapCMakerW1;
markers.models["myRwgGapCMakerH"+myHolesNum]=myRwgGapCMakerH;
markers.models["myRwgGapCMakerH1"+myHolesNum]=myRwgGapCMakerH1;
markers.models["myRwgGapCMakerX"+myHolesNum]=myRwgGapCMakerX;
/*****************************************************************************/
            
  }else if(area==7){
    //画缺 G位置
        //4、跑道缺 G位置   H W D  width=w-d/2 
        //重新画20240709  
        var myRwgGapG = {
          paths: {
            // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
            //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
            "BottomLeftLine": new makerjs.paths.Line([xg, yg], [xg, yg+ww]),
            "BottomLeftArc": new makerjs.paths.Arc([xg, yg+ww], [xg-h0, yg+wd],r,false,true),
            "LeftLine": new makerjs.paths.Line([xg-h0, yg+wd], [xg-h0, yg+w1]),
            "TopArc": new makerjs.paths.Arc([xg-h0, yg+w1], [xg-h0+h, yg+w1],r,false,true),
            "RightLine": new makerjs.paths.Line([xg-h0+h, yg+w1],[xg-h0+h, yg+wd]),
            "BottomRightArc": new makerjs.paths.Arc([xg-h0+h, yg+wd],[xg+h1, yg+ww],r,false,true),
            "BottomRightLine": new makerjs.paths.Line([xg+h1, yg+ww], [xg+h1, yg])
          }
          };   
      
//重新画20240709      
myRwgGapG.origin = [0,0];
//      //画4、跑道缺标记 G位置 
var myRwgGapGMakerW = new makerjs.models.Square(0); //D的起始点       
myRwgGapGMakerW.origin = [xg,yg];
var myRwgGapGMakerW1 = new makerjs.models.Square(0); //W的起始点        
myRwgGapGMakerW1.origin = [xg,yg];
var myRwgGapGMakerH = new makerjs.models.Square(0); //H的起始点        
myRwgGapGMakerH.origin = [xg,yg];
var myRwgGapGMakerH1 = new makerjs.models.Square(0); //H的起始点        
myRwgGapGMakerH1.origin = [xg,yg];
var myRwgGapGMakerX = new makerjs.models.Square(0); //Y的起始点        
myRwgGapGMakerX.origin = [xg,yg];
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([-x0,0], [-x0,0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([-h0,+w1+markShiftFour], [-h0,+w1-markShiftTwo]));//Y轴方向短线
makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([h1+h0,+w1+markShiftFour], [h1+h0,+w1-markShiftTwo]));//Y轴方向短线
makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([h1,0], [h1, 0-markShiftFour]));//X轴加长标线
makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([0+h1+h0,+w1], [0+h1+h0+markShiftFour, +w1]));//W1轴方向短线
makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([0-h0,+wd], [0-h0-markShiftFour,+wd]));//W轴方向短线
makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([0-h0,+w1], [0-h0-markShiftFour,+w1]));//W轴方向短线
makerjs.model.addPath(myRwgGapGMakerH, new makerjs.paths.Line([0-h0,+w1], [0+h1+h0,+w1]));//H相关 Y轴方向短线
makerjs.model.addCaption(myRwgGapGMakerH,TxtD, [0-h0,+w1-markShift], [0+h1+h0,+w1-markShift]);//H文字标注
makerjs.model.addPath(myRwgGapGMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
makerjs.model.addCaption(myRwgGapGMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour]);//Y文字标注
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapGMakerH1, new makerjs.paths.Line([0,0-markShiftTwo], [h1, 0-markShiftTwo]));//H1轴方向标注短线 
makerjs.model.addCaption(myRwgGapGMakerH1,TxtH1, [0,0-markShiftFour], [h1, 0-markShiftFour]);//H1文字标注
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapGMakerW1, new makerjs.paths.Line([0+h1+h0+markShift,+w1], [0+h1+h0+markShift, 0]));//W1短线标注
makerjs.model.addCaption(myRwgGapGMakerW1, TxtW1, [0+h1+h0+markShiftThree,0], [0+h1+h0+markShiftThree, w1]);//W1文字标注
// /*****************************************************************************/ 
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([0-h0-markShift,+w1], [0-h0-markShift, +wd]));//W短线标注
makerjs.model.addCaption(myRwgGapGMakerW, TxtW, [0-h0-markShiftTwo,+wd], [0-h0-markShiftTwo, +w1]);//W文字标注
/*****************************************************************************/
plate.models["myRwgGapG"+myHolesNum]=myRwgGapG;
markers.models["myRwgGapG"+myHolesNum]=myRwgGapG;
markers.models["myRwgGapGMakerW"+myHolesNum]=myRwgGapGMakerW;
markers.models["myRwgGapGMakerW1"+myHolesNum]=myRwgGapGMakerW1;
markers.models["myRwgGapGMakerH"+myHolesNum]=myRwgGapGMakerH;
markers.models["myRwgGapGMakerH1"+myHolesNum]=myRwgGapGMakerH1;
markers.models["myRwgGapGMakerX"+myHolesNum]=myRwgGapGMakerX;
/*****************************************************************************/
             
  }else if(area==8){
    //画缺 H位置
        //4、跑道缺 H位置   H W D  width=w-d/2 
        //重新画20240709  
        var myRwgGapH = {
          paths: {
            // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
            //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
            "BottomLeftLine": new makerjs.paths.Line([xh, yh], [xh, yh+ww]),
            "BottomLeftArc": new makerjs.paths.Arc([xh, yh+ww], [xh-h0, yh+wd],r,false,true),
            "LeftLine": new makerjs.paths.Line([xh-h0, yh+wd], [xh-h0, yh+w1]),
            "TopArc": new makerjs.paths.Arc([xh-h0, yh+w1], [xh-h0+h, yh+w1],r,false,true),
            "RightLine": new makerjs.paths.Line([xh-h0+h, yh+w1],[xh-h0+h, yh+wd]),
            "BottomRightArc": new makerjs.paths.Arc([xh-h0+h, yh+wd],[xh+h1, yh+ww],r,false,true),
            "BottomRightLine": new makerjs.paths.Line([xh+h1, yh+ww], [xh+h1, yh])
          }
          };   
      
//重新画20240709      
myRwgGapH.origin = [0-h1/2,0];
//      //画4、跑道缺标记 H位置 
var myRwgGapHMakerW = new makerjs.models.Square(0); //D的起始点       
myRwgGapHMakerW.origin = [xh-h1/2,yh];
var myRwgGapHMakerW1 = new makerjs.models.Square(0); //W的起始点        
myRwgGapHMakerW1.origin = [xh-h1/2,yh];
var myRwgGapHMakerH = new makerjs.models.Square(0); //H的起始点        
myRwgGapHMakerH.origin = [xh-h1/2,yh];
var myRwgGapHMakerH1 = new makerjs.models.Square(0); //H的起始点        
myRwgGapHMakerH1.origin = [xh-h1/2,yh];
var myRwgGapHMakerX = new makerjs.models.Square(0); //Y的起始点        
myRwgGapHMakerX.origin = [xh-h1/2,yh];
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapHMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myRwgGapHMakerW, new makerjs.paths.Line([-x0,0], [-x0,0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myRwgGapHMakerW, new makerjs.paths.Line([-h0,+w1+markShiftFour], [-h0,+w1-markShiftTwo]));//Y轴方向短线
makerjs.model.addPath(myRwgGapHMakerW, new makerjs.paths.Line([h1+h0,+w1+markShiftFour], [h1+h0,+w1-markShiftTwo]));//Y轴方向短线
makerjs.model.addPath(myRwgGapHMakerW, new makerjs.paths.Line([h1,0], [h1, 0-markShiftFour]));//X轴加长标线
makerjs.model.addPath(myRwgGapHMakerW, new makerjs.paths.Line([0+h1+h0,+w1], [0+h1+h0+markShiftFour, +w1]));//W1轴方向短线
makerjs.model.addPath(myRwgGapHMakerW, new makerjs.paths.Line([0-h0,+wd], [0-h0-markShiftFour,+wd]));//W轴方向短线
makerjs.model.addPath(myRwgGapHMakerW, new makerjs.paths.Line([0-h0,+w1], [0-h0-markShiftFour,+w1]));//W轴方向短线
makerjs.model.addPath(myRwgGapHMakerH, new makerjs.paths.Line([0-h0,+w1], [0+h1+h0,+w1]));//H相关 Y轴方向短线
makerjs.model.addCaption(myRwgGapHMakerH,TxtD, [0-h0,+w1-markShift], [0+h1+h0,+w1-markShift]);//H文字标注
makerjs.model.addPath(myRwgGapHMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
makerjs.model.addCaption(myRwgGapHMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour]);//Y文字标注
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapHMakerH1, new makerjs.paths.Line([0,0-markShiftTwo], [h1, 0-markShiftTwo]));//H1轴方向标注短线 
makerjs.model.addCaption(myRwgGapHMakerH1,TxtH1, [0,0-markShiftFour], [h1, 0-markShiftFour]);//H1文字标注
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapHMakerW1, new makerjs.paths.Line([0+h1+h0+markShift,+w1], [0+h1+h0+markShift, 0]));//W1短线标注
makerjs.model.addCaption(myRwgGapHMakerW1, TxtW1, [0+h1+h0+markShiftThree,0], [0+h1+h0+markShiftThree, w1]);//W1文字标注
// /*****************************************************************************/ 
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapHMakerW, new makerjs.paths.Line([0-h0-markShift,+w1], [0-h0-markShift, +wd]));//W短线标注
makerjs.model.addCaption(myRwgGapHMakerW, TxtW, [0-h0-markShiftTwo,+wd], [0-h0-markShiftTwo, +w1]);//W文字标注
/*****************************************************************************/
plate.models["myRwgGapH"+myHolesNum]=myRwgGapH;
markers.models["myRwgGapH"+myHolesNum]=myRwgGapH;
markers.models["myRwgGapHMakerW"+myHolesNum]=myRwgGapHMakerW;
markers.models["myRwgGapHMakerW1"+myHolesNum]=myRwgGapHMakerW1;
markers.models["myRwgGapHMakerH"+myHolesNum]=myRwgGapHMakerH;
markers.models["myRwgGapHMakerH1"+myHolesNum]=myRwgGapHMakerH1;
markers.models["myRwgGapHMakerX"+myHolesNum]=myRwgGapHMakerX;
/*****************************************************************************/       
  }else if(area==9){
   //画缺 I位置
        //4、跑道缺 I位置   H W D  width=w-d/2 
        //重新画20240709  
        var myRwgGapI = {
          paths: {
            // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
            //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
            "BottomLeftLine": new makerjs.paths.Line([xi, yi], [xi, yi+ww]),
            "BottomLeftArc": new makerjs.paths.Arc([xi, yi+ww], [xi-h0, yi+wd],r,false,true),
            "LeftLine": new makerjs.paths.Line([xi-h0, yi+wd], [xi-h0, yi+w1]),
            "TopArc": new makerjs.paths.Arc([xi-h0, yi+w1], [xi-h0+h, yi+w1],r,false,true),
            "RightLine": new makerjs.paths.Line([xi-h0+h, yi+w1],[xi-h0+h, yi+wd]),
            "BottomRightArc": new makerjs.paths.Arc([xi-h0+h, yi+wd],[xi+h1, yi+ww],r,false,true),
            "BottomRightLine": new makerjs.paths.Line([xi+h1, yi+ww], [xi+h1, yi])
          }
          };   
      
//重新画20240709      
myRwgGapI.origin = [0-h1,0];
//      //画4、跑道缺标记 I位置 
var myRwgGapIMakerW = new makerjs.models.Square(0); //D的起始点       
myRwgGapIMakerW.origin = [xi-h1,yi];
var myRwgGapIMakerW1 = new makerjs.models.Square(0); //W的起始点        
myRwgGapIMakerW1.origin = [xi-h1,yi];
var myRwgGapIMakerH = new makerjs.models.Square(0); //H的起始点        
myRwgGapIMakerH.origin = [xi-h1,yi];
var myRwgGapIMakerH1 = new makerjs.models.Square(0); //H的起始点        
myRwgGapIMakerH1.origin = [xi-h1,yi];
var myRwgGapIMakerX = new makerjs.models.Square(0); //Y的起始点        
myRwgGapIMakerX.origin = [xi-h1,yi];
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([h1+x0,0], [h1+x0,0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([-h0,+w1+markShiftFour], [-h0,+w1-markShiftTwo]));//Y轴方向短线
makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([h1+h0,+w1+markShiftFour], [h1+h0,+w1-markShiftTwo]));//Y轴方向短线
makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([h1,0], [h1, 0-markShiftFour]));//X轴加长标线
makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([0+h1+h0,+w1], [0+h1+h0+markShiftFour, +w1]));//W1轴方向短线
makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([0-h0,+wd], [0-h0-markShiftFour,+wd]));//W轴方向短线
makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([0-h0,+w1], [0-h0-markShiftFour,+w1]));//W轴方向短线
makerjs.model.addPath(myRwgGapIMakerH, new makerjs.paths.Line([0-h0,+w1], [0+h1+h0,+w1]));//H相关 Y轴方向短线
makerjs.model.addCaption(myRwgGapIMakerH,TxtD, [0-h0,+w1-markShift], [0+h1+h0,+w1-markShift]);//H文字标注
makerjs.model.addPath(myRwgGapIMakerX, new makerjs.paths.Line([h1,0-markShiftTwo], [h1+x0, 0-markShiftTwo]));//Y轴方向标注短线 
makerjs.model.addCaption(myRwgGapIMakerX,TxtX, [h1,0-markShiftFour], [h1+x0, 0-markShiftFour]);//Y文字标注
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapIMakerH1, new makerjs.paths.Line([0,0-markShiftTwo], [h1, 0-markShiftTwo]));//H1轴方向标注短线 
makerjs.model.addCaption(myRwgGapIMakerH1,TxtH1, [0,0-markShiftFour], [h1, 0-markShiftFour]);//H1文字标注
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapIMakerW1, new makerjs.paths.Line([0+h1+h0+markShift,+w1], [0+h1+h0+markShift, 0]));//W1短线标注
makerjs.model.addCaption(myRwgGapIMakerW1, TxtW1, [0+h1+h0+markShiftThree,0], [0+h1+h0+markShiftThree, w1]);//W1文字标注
// /*****************************************************************************/ 
// /*****************************************************************************/
makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([0-h0-markShift,+w1], [0-h0-markShift, +wd]));//W短线标注
makerjs.model.addCaption(myRwgGapIMakerW, TxtW, [0-h0-markShiftTwo,+wd], [0-h0-markShiftTwo, +w1]);//W文字标注
/*****************************************************************************/
plate.models["myRwgGapI"+myHolesNum]=myRwgGapI;
markers.models["myRwgGapI"+myHolesNum]=myRwgGapI;
markers.models["myRwgGapIMakerW"+myHolesNum]=myRwgGapIMakerW;
markers.models["myRwgGapIMakerW1"+myHolesNum]=myRwgGapIMakerW1;
markers.models["myRwgGapIMakerH"+myHolesNum]=myRwgGapIMakerH;
markers.models["myRwgGapIMakerH1"+myHolesNum]=myRwgGapIMakerH1;
markers.models["myRwgGapIMakerX"+myHolesNum]=myRwgGapIMakerX;
/*****************************************************************************/       
  }

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default RunwayGap;