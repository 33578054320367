//缺7、长条夹  
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function LongClipGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,D1=inputParam.D1,W1=inputParam.W1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
   r = d/2,
      d1 = D1*1*myZoom,
      r1 = d1/2,
      w= W*1*myZoom,
      w1= W1*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
        d1 = D1*1,
        w= W*1,
        w1= W1*1,
          x0 = X*1,
          y0 = Y*1;
          */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(d1!==0){
         var TxtD1="∮"+D1;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(w1!==0){
         var TxtW1=W1;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置孔
        //画7、长条夹 A位置
        /*
   var myLcgGapARoundRectangle = new makerjs.models.RoundRectangle(d, w+d, r)
   myLcgGapARoundRectangle.origin=[xa,ya-w-d/2];
   delete myLcgGapARoundRectangle.paths.TopRight; 
        delete myLcgGapARoundRectangle.paths.TopLeft;  
  var myLcgGapARoundHoles= new makerjs.models.Holes(d1/2, [[xa, ya]]);  
  myLcgGapARoundHoles.origin=[0+d/2,-w1]; 
 var   myLcgGapA={
      myLcgGapARoundRectangle,
      myLcgGapARoundHoles
 }
 */
 var   myLcgGapA={
 paths: {
      // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
      //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
      "LeftLine": new makerjs.paths.Line([xa, ya], [xa, ya-w]),
      "BottomArc": new makerjs.paths.Arc([xa, ya-w], [xa+d, ya-w],r,false,false),
      "RightLine": new makerjs.paths.Line([xa+d, ya-w], [xa+d, ya]),
      "BottomCircle": new makerjs.paths.Circle([xa+d/2, ya-w1],r1),
    }};
    myLcgGapC.origin = [0,0];
  //var   myLcgGapA=makerjs.model.combineUnion(myLcgGapARoundRectangle, myLcgGapARoundHoles);    
  ////标记U型缺 A位置
  var myLcgGapAMakerW = new makerjs.models.Square(0); //孔的原点       
  myLcgGapAMakerW.origin = [xa,ya];
  var myLcgGapAMakerD = new makerjs.models.Square(0); //孔的原点       
  myLcgGapAMakerD.origin = [xa,ya];
  var myLcgGapAMakerX = new makerjs.models.Square(0); //孔的原点       
  myLcgGapAMakerX.origin = [xa,ya]; 
  var myLcgGapAMakerD1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapAMakerD1.origin = [xa+d/2,ya-w1]; 
  var myLcgGapAMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapAMakerW1.origin = [xa,ya]; 
  /*****************************************************************************/
  /*****************************************************************************/  
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([-x0,0], [-x0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴方向短线
  //makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([d,0], [d, markShiftFour]));//Y轴方向短线

  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([d,-w], [d+markShiftFour,-w]));//X轴加长标线 w
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([d/2,-w1], [0-2*markShiftFour,-w1]));//X轴加长标线 w1

  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([0,-w], [0, -w-markShiftFour]));//X轴方向短线
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([d,-w], [d, -w-markShiftFour]));//X轴方向短线
  
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([0,-w], [d, -w]));//D轴方向标注短线 
  makerjs.model.addCaption(myLcgGapAMakerD,TxtD, [0,-w+markShiftTwo], [d, -w+markShiftTwo]);//D文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapAMakerX, new makerjs.paths.Line([-x0,0+markShift], [0, 0+markShift]));//Y轴方向标注短线 
  makerjs.model.addCaption(myLcgGapAMakerX,TxtX, [-x0,0+markShiftThree], [0, 0+markShiftThree]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapAMakerW, new makerjs.paths.Line([d+markShift,-w], [d+markShift, 0]));//W短线标注
  makerjs.model.addCaption(myLcgGapAMakerW, TxtW, [d+markShiftThree,-w], [d+markShiftThree, 0]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapAMakerW1, new makerjs.paths.Line([0-markShiftFour,-w1], [0-markShiftFour, 0]));//W1短线标注
  makerjs.model.addCaption(myLcgGapAMakerW1, TxtW1, [0-markShiftFour-markShift,-w1], [0-markShiftFour-markShift, 0]);//W1文字标注
  /*****************************************************************************/
  /*****************************************************************************/   
  makerjs.model.addPath(myLcgGapAMakerD1, new makerjs.paths.Line([0,0], [0+d1, -d1]));//D1短线标注
  makerjs.model.addCaption(myLcgGapAMakerD1, TxtD1,[0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//D1文字标注
  /*****************************************************************************/ 
  plate.models["myLcgGapA"+myHolesNum]=myLcgGapA;
  markers.models["myLcgGapA"+myHolesNum]=myLcgGapA;
  markers.models["myLcgGapAMakerD"+myHolesNum]=myLcgGapAMakerD;
  markers.models["myLcgGapAMakerX"+myHolesNum]=myLcgGapAMakerX;
  markers.models["myLcgGapAMakerW"+myHolesNum]=myLcgGapAMakerW;
  markers.models["myLcgGapAMakerD1"+myHolesNum]=myLcgGapAMakerD1;
  markers.models["myLcgGapAMakerW1"+myHolesNum]=myLcgGapAMakerW1;
  /*****************************************************************************/
 // makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myLcgGapA"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
    
        }else if(area==2){
        //画缺 B位置孔
        //画7、长条夹 B位置       
 var   myLcgGapB={
      paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xb, yb], [xb, yb-w]),
           "BottomArc": new makerjs.paths.Arc([xb, yb-w], [xb+d, yb-w],r,false,false),
           "RightLine": new makerjs.paths.Line([xb+d, yb-w], [xb+d, yb]),
           "BottomCircle": new makerjs.paths.Circle([xb+d/2, yb-w1],r1),
         }}; 
         myLcgGapB.origin = [0-d/2,0];
       ////标记长条夹 B位置
       var myLcgGapBMakerW = new makerjs.models.Square(0); //孔的原点       
       myLcgGapBMakerW.origin = [xb-d/2,yb];
       var myLcgGapBMakerD = new makerjs.models.Square(0); //孔的原点       
       myLcgGapBMakerD.origin = [xb-d/2,yb];
       var myLcgGapBMakerX = new makerjs.models.Square(0); //孔的原点       
       myLcgGapBMakerX.origin = [xb-d/2,yb]; 
       var myLcgGapBMakerD1 = new makerjs.models.Square(0); //孔的原点       
       myLcgGapBMakerD1.origin = [xb+d/2-d/2,yb-w1]; 
       var myLcgGapBMakerW1 = new makerjs.models.Square(0); //孔的原点       
       myLcgGapBMakerW1.origin = [xb-d/2,yb]; 
       /*****************************************************************************/
       //makerjs.model.addPath(myLcgGapBMakerD, new makerjs.paths.Line([-x0,0], [-x0, markShiftFour]));//Y轴加长标线
       //makerjs.model.addPath(myLcgGapBMakerD, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴方向短线     
       makerjs.model.addPath(myLcgGapBMakerD, new makerjs.paths.Line([d,-w], [d+markShiftFour,-w]));//X轴加长标线 w
       makerjs.model.addPath(myLcgGapBMakerD, new makerjs.paths.Line([d/2,-w1], [0-2*markShiftFour,-w1]));//X轴加长标线 w1
     
       makerjs.model.addPath(myLcgGapBMakerD, new makerjs.paths.Line([0,-w], [0, -w-markShiftFour]));//X轴方向短线
       makerjs.model.addPath(myLcgGapBMakerD, new makerjs.paths.Line([d,-w], [d, -w-markShiftFour]));//X轴方向短线
       
       makerjs.model.addPath(myLcgGapBMakerD, new makerjs.paths.Line([0,-w], [d, -w]));//D轴方向标注短线 
       makerjs.model.addCaption(myLcgGapBMakerD,TxtD, [0,-w+markShiftTwo], [d, -w+markShiftTwo]);//D文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapBMakerX, new makerjs.paths.Line([-x0,0+markShift], [0, 0+markShift]));//Y轴方向标注短线 
       makerjs.model.addCaption(myLcgGapBMakerX,TxtX, [-x0,0+markShiftThree], [0, 0+markShiftThree]);//Y文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapBMakerW, new makerjs.paths.Line([d+markShift,-w], [d+markShift, 0]));//W短线标注
       makerjs.model.addCaption(myLcgGapBMakerW, TxtW, [d+markShiftThree,-w], [d+markShiftThree, 0]);//W文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapBMakerW1, new makerjs.paths.Line([0-markShiftFour,-w1], [0-markShiftFour, 0]));//W1短线标注
       makerjs.model.addCaption(myLcgGapBMakerW1, TxtW1, [0-markShiftFour-markShift,-w1], [0-markShiftFour-markShift, 0]);//W1文字标注
       /*****************************************************************************/
       /*****************************************************************************/   
       makerjs.model.addPath(myLcgGapBMakerD1, new makerjs.paths.Line([0,0], [0+d1, -d1]));//D1短线标注
       makerjs.model.addCaption(myLcgGapBMakerD1, TxtD1,[0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//D1文字标注
       /*****************************************************************************/ 
       plate.models["myLcgGapB"+myHolesNum]=myLcgGapB;
       markers.models["myLcgGapB"+myHolesNum]=myLcgGapB;
       markers.models["myLcgGapBMakerD"+myHolesNum]=myLcgGapBMakerD;
       markers.models["myLcgGapBMakerX"+myHolesNum]=myLcgGapBMakerX;
       markers.models["myLcgGapBMakerW"+myHolesNum]=myLcgGapBMakerW;
       markers.models["myLcgGapBMakerD1"+myHolesNum]=myLcgGapBMakerD1;
       markers.models["myLcgGapBMakerW1"+myHolesNum]=myLcgGapBMakerW1;
       /*****************************************************************************/  
        }else if(area==3){        
        //画缺 C位置孔
        //画7、长条夹 C位置       
 var   myLcgGapC={
      paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xc, yc], [xc, yc-w]),
           "BottomArc": new makerjs.paths.Arc([xc, yc-w], [xc+d, yc-w],r,false,false),
           "RightLine": new makerjs.paths.Line([xc+d, yc-w], [xc+d, yc]),
           "BottomCircle": new makerjs.paths.Circle([xc+d/2, yc-w1],r1),
         }}; 
         myLcgGapC.origin = [0-d,0];
       ////标记长条夹 C位置
       var myLcgGapCMakerW = new makerjs.models.Square(0); //孔的原点       
       myLcgGapCMakerW.origin = [xc-d,yc];
       var myLcgGapCMakerD = new makerjs.models.Square(0); //孔的原点       
       myLcgGapCMakerD.origin = [xc-d,yc];
       var myLcgGapCMakerX = new makerjs.models.Square(0); //孔的原点       
       myLcgGapCMakerX.origin = [xc-d,yc]; 
       var myLcgGapCMakerD1 = new makerjs.models.Square(0); //孔的原点       
       myLcgGapCMakerD1.origin = [xc+d/2-d,yc-w1]; 
       var myLcgGapCMakerW1 = new makerjs.models.Square(0); //孔的原点       
       myLcgGapCMakerW1.origin = [xc-d,yc]; 
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([d+x0,0], [d+x0, markShiftFour]));//Y轴加长标线
       makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([d,0], [d, markShiftFour]));//Y轴方向短线     
       makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([d,-w], [d+markShiftFour,-w]));//X轴加长标线 w
       makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([d/2,-w1], [0-2*markShiftFour,-w1]));//X轴加长标线 w1
     
       makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([0,-w], [0, -w-markShiftFour]));//X轴方向短线
       makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([d,-w], [d, -w-markShiftFour]));//X轴方向短线
       
       makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([0,-w], [d, -w]));//D轴方向标注短线 
       makerjs.model.addCaption(myLcgGapCMakerD,TxtD, [0,-w+markShiftTwo], [d, -w+markShiftTwo]);//D文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapCMakerX, new makerjs.paths.Line([d,0+markShift], [d+x0, 0+markShift]));//Y轴方向标注短线 
       makerjs.model.addCaption(myLcgGapCMakerX,TxtX, [d,0+markShiftThree], [d+x0, 0+markShiftThree]);//Y文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapCMakerW, new makerjs.paths.Line([d+markShift,-w], [d+markShift, 0]));//W短线标注
       makerjs.model.addCaption(myLcgGapCMakerW, TxtW, [d+markShiftThree,-w], [d+markShiftThree, 0]);//W文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapCMakerW1, new makerjs.paths.Line([0-markShiftFour,-w1], [0-markShiftFour, 0]));//W1短线标注
       makerjs.model.addCaption(myLcgGapCMakerW1, TxtW1, [0-markShiftFour-markShift,-w1], [0-markShiftFour-markShift, 0]);//W1文字标注
       /*****************************************************************************/
       /*****************************************************************************/   
       makerjs.model.addPath(myLcgGapCMakerD1, new makerjs.paths.Line([0,0], [0+d1, -d1]));//D1短线标注
       makerjs.model.addCaption(myLcgGapCMakerD1, TxtD1,[0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//D1文字标注
       /*****************************************************************************/ 
       plate.models["myLcgGapC"+myHolesNum]=myLcgGapC;
       markers.models["myLcgGapC"+myHolesNum]=myLcgGapC;
       markers.models["myLcgGapCMakerD"+myHolesNum]=myLcgGapCMakerD;
       markers.models["myLcgGapCMakerX"+myHolesNum]=myLcgGapCMakerX;
       markers.models["myLcgGapCMakerW"+myHolesNum]=myLcgGapCMakerW;
       markers.models["myLcgGapCMakerD1"+myHolesNum]=myLcgGapCMakerD1;
       markers.models["myLcgGapCMakerW1"+myHolesNum]=myLcgGapCMakerW1;
       /*****************************************************************************/ 
        }else if(area==7){
   //画缺 G位置孔
        //画7、长条夹 G位置
 var   myLcgGapG={
      paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xg, yg], [xg, yg+w]),
           "TopArc": new makerjs.paths.Arc([xg, yg+w], [xg+d, yg+w],r,false,true),
           "RightLine": new makerjs.paths.Line([xg+d, yg+w], [xg+d, yg]),
           "TopCircle": new makerjs.paths.Circle([xg+d/2, yg+w1],r1),
         }};
         myLcgGapG.origin = [0,0];   
       ////标记长条夹缺 G位置
       var myLcgGapGMakerW = new makerjs.models.Square(0); //孔的原点       
       myLcgGapGMakerW.origin = [xg,yg];
       var myLcgGapGMakerD = new makerjs.models.Square(0); //孔的原点       
       myLcgGapGMakerD.origin = [xg,yg];
       var myLcgGapGMakerX = new makerjs.models.Square(0); //孔的原点       
       myLcgGapGMakerX.origin = [xg,yg]; 
       var myLcgGapGMakerD1 = new makerjs.models.Square(0); //孔的原点       
       myLcgGapGMakerD1.origin = [xg+d/2,yg+w1]; 
       var myLcgGapGMakerW1 = new makerjs.models.Square(0); //孔的原点       
       myLcgGapGMakerW1.origin = [xg,yg]; 
       /*****************************************************************************/
       /*****************************************************************************/  
       makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([-x0,0], [-x0, -markShiftFour]));//Y轴加长标线
       makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴方向短线
     
       makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([d,+w], [d+markShiftFour,+w]));//X轴加长标线 w
       makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([d/2,+w1], [0-2*markShiftFour,+w1]));//X轴加长标线 w1
     
       makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([0,+w], [0, +w+markShiftFour]));//X轴方向短线
       makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([d,+w], [d, +w+markShiftFour]));//X轴方向短线
       
       makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([0,+w], [d, +w]));//D轴方向标注短线 
       makerjs.model.addCaption(myLcgGapGMakerD,TxtD, [0,+w-markShiftTwo], [d, +w-markShiftTwo]);//D文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapGMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
       makerjs.model.addCaption(myLcgGapGMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour]);//Y文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapGMakerW, new makerjs.paths.Line([d+markShift,0], [d+markShift, w]));//W短线标注
       makerjs.model.addCaption(myLcgGapGMakerW, TxtW, [d+markShiftThree,0], [d+markShiftThree, w]);//W文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapGMakerW1, new makerjs.paths.Line([0-markShiftFour,0], [0-markShiftFour, w1]));//W1短线标注
       makerjs.model.addCaption(myLcgGapGMakerW1, TxtW1, [0-markShiftFour-markShift,0], [0-markShiftFour-markShift, w1]);//W1文字标注
       /*****************************************************************************/
       /*****************************************************************************/   
       makerjs.model.addPath(myLcgGapGMakerD1, new makerjs.paths.Line([0,0], [0+d1, d1]));//D1短线标注
       makerjs.model.addCaption(myLcgGapGMakerD1, TxtD1,[0,0], [d1+markHolesOneD, d1+markHolesOneD]);//D1文字标注
       /*****************************************************************************/ 
       plate.models["myLcgGapG"+myHolesNum]=myLcgGapG;
       markers.models["myLcgGapG"+myHolesNum]=myLcgGapG;
       markers.models["myLcgGapGMakerD"+myHolesNum]=myLcgGapGMakerD;
       markers.models["myLcgGapGMakerX"+myHolesNum]=myLcgGapGMakerX;
       markers.models["myLcgGapGMakerW"+myHolesNum]=myLcgGapGMakerW;
       markers.models["myLcgGapGMakerD1"+myHolesNum]=myLcgGapGMakerD1;
       markers.models["myLcgGapGMakerW1"+myHolesNum]=myLcgGapGMakerW1;
       /*****************************************************************************/
           
        }else if(area==8){
      //画缺 HH位置孔
        //画7、长条夹 H位置
 var   myLcgGapH={
      paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xh, yh], [xh, yh+w]),
           "TopArc": new makerjs.paths.Arc([xh, yh+w], [xh+d, yh+w],r,false,true),
           "RightLine": new makerjs.paths.Line([xh+d, yh+w], [xh+d, yh]),
           "TopCircle": new makerjs.paths.Circle([xh+d/2, yh+w1],r1),
         }};
         myLcgGapH.origin = [0-d/2,0];   
       ////标记长条夹缺 H位置
       var myLcgGapHMakerW = new makerjs.models.Square(0); //孔的原点       
       myLcgGapHMakerW.origin = [xh-d/2, yh];
       var myLcgGapHMakerD = new makerjs.models.Square(0); //孔的原点       
       myLcgGapHMakerD.origin = [xh-d/2, yh];
       var myLcgGapHMakerX = new makerjs.models.Square(0); //孔的原点       
       myLcgGapHMakerX.origin = [xh-d/2, yh]; 
       var myLcgGapHMakerD1 = new makerjs.models.Square(0); //孔的原点       
       myLcgGapHMakerD1.origin = [xh+d/2-d/2,yh+w1]; 
       var myLcgGapHMakerW1 = new makerjs.models.Square(0); //孔的原点       
       myLcgGapHMakerW1.origin = [xh-d/2, yh]; 
       /*****************************************************************************/
       /*****************************************************************************/  
       makerjs.model.addPath(myLcgGapHMakerD, new makerjs.paths.Line([-x0,0], [-x0, -markShiftFour]));//Y轴加长标线
       makerjs.model.addPath(myLcgGapHMakerD, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴方向短线
     
       makerjs.model.addPath(myLcgGapHMakerD, new makerjs.paths.Line([d,+w], [d+markShiftFour,+w]));//X轴加长标线 w
       makerjs.model.addPath(myLcgGapHMakerD, new makerjs.paths.Line([d/2,+w1], [0-2*markShiftFour,+w1]));//X轴加长标线 w1
     
       makerjs.model.addPath(myLcgGapHMakerD, new makerjs.paths.Line([0,+w], [0, +w+markShiftFour]));//X轴方向短线
       makerjs.model.addPath(myLcgGapHMakerD, new makerjs.paths.Line([d,+w], [d, +w+markShiftFour]));//X轴方向短线
       
       makerjs.model.addPath(myLcgGapHMakerD, new makerjs.paths.Line([0,+w], [d, +w]));//D轴方向标注短线 
       makerjs.model.addCaption(myLcgGapHMakerD,TxtD, [0,+w-markShiftTwo], [d, +w-markShiftTwo]);//D文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapHMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
       makerjs.model.addCaption(myLcgGapHMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour]);//Y文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapHMakerW, new makerjs.paths.Line([d+markShift,0], [d+markShift, w]));//W短线标注
       makerjs.model.addCaption(myLcgGapHMakerW, TxtW, [d+markShiftThree,0], [d+markShiftThree, w]);//W文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapHMakerW1, new makerjs.paths.Line([0-markShiftFour,0], [0-markShiftFour, w1]));//W1短线标注
       makerjs.model.addCaption(myLcgGapHMakerW1, TxtW1, [0-markShiftFour-markShift,0], [0-markShiftFour-markShift, w1]);//W1文字标注
       /*****************************************************************************/
       /*****************************************************************************/   
       makerjs.model.addPath(myLcgGapHMakerD1, new makerjs.paths.Line([0,0], [0+d1, d1]));//D1短线标注
       makerjs.model.addCaption(myLcgGapHMakerD1, TxtD1,[0,0], [d1+markHolesOneD, d1+markHolesOneD]);//D1文字标注
       /*****************************************************************************/ 
       plate.models["myLcgGapH"+myHolesNum]=myLcgGapH;
       markers.models["myLcgGapH"+myHolesNum]=myLcgGapH;
       markers.models["myLcgGapHMakerD"+myHolesNum]=myLcgGapHMakerD;
       markers.models["myLcgGapHMakerX"+myHolesNum]=myLcgGapHMakerX;
       markers.models["myLcgGapHMakerW"+myHolesNum]=myLcgGapHMakerW;
       markers.models["myLcgGapHMakerD1"+myHolesNum]=myLcgGapHMakerD1;
       markers.models["myLcgGapHMakerW1"+myHolesNum]=myLcgGapHMakerW1;
       /*****************************************************************************/    
        }else if(area==9){
     //画缺 I位置孔
        //画7、长条夹 I位置
 var   myLcgGapI={
      paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xi, yi], [xi, yi+w]),
           "TopArc": new makerjs.paths.Arc([xi, yi+w], [xi+d, yi+w],r,false,true),
           "RightLine": new makerjs.paths.Line([xi+d, yi+w], [xi+d, yi]),
           "TopCircle": new makerjs.paths.Circle([xi+d/2, yi+w1],r1),
         }};
         myLcgGapI.origin = [0-d,0];   
       ////标记长条夹缺 I位置
       var myLcgGapIMakerW = new makerjs.models.Square(0); //孔的原点       
       myLcgGapIMakerW.origin = [xi-d, yi];
       var myLcgGapIMakerD = new makerjs.models.Square(0); //孔的原点       
       myLcgGapIMakerD.origin = [xi-d, yi];
       var myLcgGapIMakerX = new makerjs.models.Square(0); //孔的原点       
       myLcgGapIMakerX.origin = [xi-d, yi]; 
       var myLcgGapIMakerD1 = new makerjs.models.Square(0); //孔的原点       
       myLcgGapIMakerD1.origin = [xi+d/2-d,yi+w1]; 
       var myLcgGapIMakerW1 = new makerjs.models.Square(0); //孔的原点       
       myLcgGapIMakerW1.origin = [xi-d, yi]; 
       /*****************************************************************************/
       /*****************************************************************************/  
       makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([d+x0,0], [d+x0, -markShiftFour]));//Y轴加长标线
       makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([d,0], [d, -markShiftFour]));//Y轴方向短线
     
       makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([d,+w], [d+markShiftFour,+w]));//X轴加长标线 w
       makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([d/2,+w1], [0-2*markShiftFour,+w1]));//X轴加长标线 w1
     
       makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([0,+w], [0, +w+markShiftFour]));//X轴方向短线
       makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([d,+w], [d, +w+markShiftFour]));//X轴方向短线
       
       makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([0,+w], [d, +w]));//D轴方向标注短线 
       makerjs.model.addCaption(myLcgGapIMakerD,TxtD, [0,+w-markShiftTwo], [d, +w-markShiftTwo]);//D文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapIMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
       makerjs.model.addCaption(myLcgGapIMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour]);//Y文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapIMakerW, new makerjs.paths.Line([d+markShift,0], [d+markShift, w]));//W短线标注
       makerjs.model.addCaption(myLcgGapIMakerW, TxtW, [d+markShiftThree,0], [d+markShiftThree, w]);//W文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myLcgGapIMakerW1, new makerjs.paths.Line([0-markShiftFour,0], [0-markShiftFour, w1]));//W1短线标注
       makerjs.model.addCaption(myLcgGapIMakerW1, TxtW1, [0-markShiftFour-markShift,0], [0-markShiftFour-markShift, w1]);//W1文字标注
       /*****************************************************************************/
       /*****************************************************************************/   
       makerjs.model.addPath(myLcgGapIMakerD1, new makerjs.paths.Line([0,0], [0+d1, d1]));//D1短线标注
       makerjs.model.addCaption(myLcgGapIMakerD1, TxtD1,[0,0], [d1+markHolesOneD, d1+markHolesOneD]);//D1文字标注
       /*****************************************************************************/ 
       plate.models["myLcgGapI"+myHolesNum]=myLcgGapI;
       markers.models["myLcgGapI"+myHolesNum]=myLcgGapI;
       markers.models["myLcgGapIMakerD"+myHolesNum]=myLcgGapIMakerD;
       markers.models["myLcgGapIMakerX"+myHolesNum]=myLcgGapIMakerX;
       markers.models["myLcgGapIMakerW"+myHolesNum]=myLcgGapIMakerW;
       markers.models["myLcgGapIMakerD1"+myHolesNum]=myLcgGapIMakerD1;
       markers.models["myLcgGapIMakerW1"+myHolesNum]=myLcgGapIMakerW1;
       /*****************************************************************************/        
        }

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default LongClipGap;