//12、拉手孔3 
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HandleHole3(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,H2=inputParam.H2,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
      //标记拉手孔3 A位置孔上
      var myHh3HolesAup= new makerjs.models.Holes(d/2, [[xa, ya]]);
      var myHh3HolesMarkAup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkAup.origin = [xa, ya];      
      var myHh3HolesXMarkAup = new makerjs.models.Square(0);      
      myHh3HolesXMarkAup.origin =[xa, ya];      
      var myHh3HolesYMarkAup = new makerjs.models.Square(0);      
      myHh3HolesYMarkAup.origin = [xa, ya];  

/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkAup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkAup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHh3HolesMarkAup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkAup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkAup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkAup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHh3HolesYMarkAup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkAup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
 //标记拉手孔3 A位置孔中
 var myHh3HolesAmd= new makerjs.models.Holes(d/2, [[xa+h, ya]]);
      var myHh3HolesMarkAmd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkAmd.origin = [xa+h, ya];      
      var myHh3HolesYMarkAmd = new makerjs.models.Square(0);      
      myHh3HolesYMarkAmd.origin = [xa+h, ya];  
/*****************************************************************************/ 
//makerjs.model.addPath(myHh3HolesMarkAmd, new makerjs.paths.Line([0,0], [0, -h]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkAmd, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkAmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkAmd, new makerjs.paths.Line([-h,0], [0,0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkAmd, TxtH, [-h,0+markShift], [0,0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 

 //标记拉手孔3 A位置孔下
 var myHh3HolesAdn= new makerjs.models.Holes(d/2, [[xa+h+h, ya]]);
      var myHh3HolesMarkAdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkAdn.origin = [xa+h+h, ya];       
      var myHh3HolesYMarkAdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkAdn.origin = [xa+h+h, ya];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
//makerjs.model.addPath(myHh3HolesMarkAdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkAdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkAdn, new makerjs.paths.Line([-h,0], [0,0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkAdn, TxtH,[-h,0+markShift], [0,0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesAup"+myHolesNum]=myHh3HolesAup;
markers.models["myHh3HolesAup"+myHolesNum]=myHh3HolesAup;
markers.models["myHh3HolesMarkAup"+myHolesNum]=myHh3HolesMarkAup;
markers.models["myHh3HolesXMarkAup"+myHolesNum]=myHh3HolesXMarkAup;
markers.models["myHh3HolesYMarkAup"+myHolesNum]=myHh3HolesYMarkAup;
/*****************************************************************************/
plate.models["myHh3HolesAmd"+myHolesNum]=myHh3HolesAmd;
markers.models["myHh3HolesAmd"+myHolesNum]=myHh3HolesAmd;
markers.models["myHh3HolesMarkAmd"+myHolesNum]=myHh3HolesMarkAmd;
markers.models["myHh3HolesYMarkAmd"+myHolesNum]=myHh3HolesYMarkAmd;
/*****************************************************************************/ 
plate.models["myHh3HolesAdn"+myHolesNum]=myHh3HolesAdn;
markers.models["myHh3HolesAdn"+myHolesNum]=myHh3HolesAdn;
markers.models["myHh3HolesMarkAdn"+myHolesNum]=myHh3HolesMarkAdn;
markers.models["myHh3HolesYMarkAdn"+myHolesNum]=myHh3HolesYMarkAdn;
/*****************************************************************************/ 

}
else if(area==2){
 //画孔 B位置孔
      //标记拉手孔3 B位置孔上
      var myHh3HolesBup= new makerjs.models.Holes(d/2, [[xb+h, yb]]);
      var myHh3HolesMarkBup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkBup.origin = [xb+h, yb];      
      var myHh3HolesXMarkBup = new makerjs.models.Square(0);      
      myHh3HolesXMarkBup.origin =[xb+h, yb];      
      var myHh3HolesYMarkBup = new makerjs.models.Square(0);      
      myHh3HolesYMarkBup.origin = [xb+h, yb];  

/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkBup, new makerjs.paths.Line([-h,0], [0, 0]));//Y轴加长标线
//makerjs.model.addPath(myHh3HolesMarkBup, new makerjs.paths.Line([0,0], [0, 0]));//从孔圆心出发X方向横向标线
//makerjs.model.addPath(myHh3HolesMarkBup, new makerjs.paths.Line([0,0], [-h, 0]));//从孔圆心出发X方向横向标线
// makerjs.model.addPath(myHh3HolesMarkBup, new makerjs.paths.Line([-x0,yd-h], [-x0-markShiftThree, yd-h]));//X轴加长标线
//makerjs.model.addPath(myHh3HolesMarkBup, new makerjs.paths.Line([0,0], [0, yd-h+markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkBup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkBup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkBup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkBup, TxtY, [0-markShift,0], [0-markShift, y0]);//X文字标注
/*****************************************************************************/  
 //标记拉手孔3 B位置孔中
 var myHh3HolesBmd= new makerjs.models.Holes(d/2, [[xb, yb]]);
      var myHh3HolesMarkBmd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkBmd.origin = [xb, yb];      
      var myHh3HolesYMarkBmd = new makerjs.models.Square(0);      
      myHh3HolesYMarkBmd.origin = [xb, yb];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkBmd, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkBmd, new makerjs.paths.Line([0,0], [-h, 0]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkBmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkBmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkBmd, new makerjs.paths.Line([0,0], [h, 0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkBmd, TxtH, [0,0+markShift], [h, 0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 

 //标记拉手孔3 B位置孔下
 var myHh3HolesBdn= new makerjs.models.Holes(d/2, [[xb-h, yb]]);
      var myHh3HolesMarkBdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkBdn.origin = [xb-h, yb];       
      var myHh3HolesYMarkBdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkBdn.origin = [xb-h, yb];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkBdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
//makerjs.model.addPath(myHh3HolesMarkBdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkBdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkBdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkBdn, new makerjs.paths.Line([0,0], [h, 0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkBdn, TxtH,  [0,0+markShift], [h, 0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesBup"+myHolesNum]=myHh3HolesBup;
markers.models["myHh3HolesBup"+myHolesNum]=myHh3HolesBup;
markers.models["myHh3HolesMarkBup"+myHolesNum]=myHh3HolesMarkBup;
markers.models["myHh3HolesXMarkBup"+myHolesNum]=myHh3HolesXMarkBup;
markers.models["myHh3HolesYMarkBup"+myHolesNum]=myHh3HolesYMarkBup;
/*****************************************************************************/
plate.models["myHh3HolesBmd"+myHolesNum]=myHh3HolesBmd;
markers.models["myHh3HolesBmd"+myHolesNum]=myHh3HolesBmd;
markers.models["myHh3HolesMarkBmd"+myHolesNum]=myHh3HolesMarkBmd;
markers.models["myHh3HolesYMarkBmd"+myHolesNum]=myHh3HolesYMarkBmd;
/*****************************************************************************/ 
plate.models["myHh3HolesBdn"+myHolesNum]=myHh3HolesBdn;
markers.models["myHh3HolesBdn"+myHolesNum]=myHh3HolesBdn;
markers.models["myHh3HolesMarkBdn"+myHolesNum]=myHh3HolesMarkBdn;
markers.models["myHh3HolesYMarkBdn"+myHolesNum]=myHh3HolesYMarkBdn;
/*****************************************************************************/ 
/*****************************************************************************/ 

}
else if(area==3){
 //画孔 C位置孔
      //标记拉手孔3 C位置孔上
      var myHh3HolesCup= new makerjs.models.Holes(d/2, [[xc, yc]]);
      var myHh3HolesMarkCup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkCup.origin = [xc, yc];      
      var myHh3HolesXMarkCup = new makerjs.models.Square(0);      
      myHh3HolesXMarkCup.origin =[xc, yc];      
      var myHh3HolesYMarkCup = new makerjs.models.Square(0);      
      myHh3HolesYMarkCup.origin = [xc, yc];  

/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkCup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkCup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHh3HolesMarkCup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkCup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkCup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkCup, TxtX, [0, y0+markShiftThree], [x0,y0+markShiftThree]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHh3HolesYMarkCup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkCup, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
 //标记拉手孔3 C位置孔中
 var myHh3HolesCmd= new makerjs.models.Holes(d/2, [[xc-h, yc]]);
      var myHh3HolesMarkCmd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkCmd.origin = [xc-h, yc];      
      var myHh3HolesYMarkCmd = new makerjs.models.Square(0);      
      myHh3HolesYMarkCmd.origin = [xc-h, yc];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCmd, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
//makerjs.model.addPath(myHh3HolesMarkCmd, new makerjs.paths.Line([0,0], [-h, 0]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkCmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkCmd, new makerjs.paths.Line([0,0], [h, 0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkCmd, TxtH, [0,0+markShift], [h, 0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 

 //标记拉手孔3 C位置孔下
 var myHh3HolesCdn= new makerjs.models.Holes(d/2, [[xc-h-h, yc]]);
      var myHh3HolesMarkCdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkCdn.origin = [xc-h-h, yc];       
      var myHh3HolesYMarkCdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkCdn.origin = [xc-h-h, yc];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
//makerjs.model.addPath(myHh3HolesMarkCdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkCdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkCdn, new makerjs.paths.Line([0,0], [h, 0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkCdn, TxtH, [0,0+markShift], [h, 0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesCup"+myHolesNum]=myHh3HolesCup;
markers.models["myHh3HolesCup"+myHolesNum]=myHh3HolesCup;
markers.models["myHh3HolesMarkCup"+myHolesNum]=myHh3HolesMarkCup;
markers.models["myHh3HolesXMarkCup"+myHolesNum]=myHh3HolesXMarkCup;
markers.models["myHh3HolesYMarkCup"+myHolesNum]=myHh3HolesYMarkCup;
/*****************************************************************************/
plate.models["myHh3HolesCmd"+myHolesNum]=myHh3HolesCmd;
markers.models["myHh3HolesCmd"+myHolesNum]=myHh3HolesCmd;
markers.models["myHh3HolesMarkCmd"+myHolesNum]=myHh3HolesMarkCmd;
markers.models["myHh3HolesYMarkCmd"+myHolesNum]=myHh3HolesYMarkCmd;
/*****************************************************************************/ 
plate.models["myHh3HolesCdn"+myHolesNum]=myHh3HolesCdn;
markers.models["myHh3HolesCdn"+myHolesNum]=myHh3HolesCdn;
markers.models["myHh3HolesMarkCdn"+myHolesNum]=myHh3HolesMarkCdn;
markers.models["myHh3HolesYMarkCdn"+myHolesNum]=myHh3HolesYMarkCdn;
/*****************************************************************************/ 
 
}
else if(area==7){
 //画孔 G位置孔
      //标记拉手孔3 G位置孔下
      var myHh3HolesGdn= new makerjs.models.Holes(d/2, [[xg, yg]]);
      var myHh3HolesMarkGdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkGdn.origin = [xg, yg];      
      var myHh3HolesXMarkGdn = new makerjs.models.Square(0);      
      myHh3HolesXMarkGdn.origin =[xg, yg];      
      var myHh3HolesYMarkGdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkGdn.origin = [xg, yg];  

/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGdn, new makerjs.paths.Line([-x0,y0], [-x0, -y0-markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkGdn, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkGdn, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftFour, -y0]));//X轴加长标线
makerjs.model.addPath(myHh3HolesMarkGdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkGdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkGdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkGdn, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHh3HolesYMarkGdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkGdn, TxtY, [-x0-markShiftFour, -y0], [-x0-markShiftFour,0]);//Y文字标注
/*****************************************************************************/ 
 //标记拉手孔3 G位置孔左中
 var myHh3HolesGmd= new makerjs.models.Holes(d/2, [[xg+h, yg]]);
      var myHh3HolesMarkGmd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkGmd.origin = [xg+h, yg];      
      var myHh3HolesYMarkGmd = new makerjs.models.Square(0);      
      myHh3HolesYMarkGmd.origin = [xg+h, yg];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGmd, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
//makerjs.model.addPath(myHh3HolesMarkGmd, new makerjs.paths.Line([0,0], [0, -h-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkGmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkGmd, new makerjs.paths.Line([-h,0], [0, 0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkGmd, TxtH, [-h,0+markShift], [0, 0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 
//标记拉手孔3 G位置孔左中
var myHh3HolesGrt= new makerjs.models.Holes(d/2, [[xg+h+h, yg]]);
var myHh3HolesMarkGrt = new makerjs.models.Square(0); //孔的原点       
myHh3HolesMarkGrt.origin = [xg+h+h, yg];      
var myHh3HolesYMarkGrt = new makerjs.models.Square(0);      
myHh3HolesYMarkGrt.origin = [xg+h+h, yg];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGrt, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
//makerjs.model.addPath(myHh3HolesMarkGmd, new makerjs.paths.Line([0,0], [0, -h-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGrt, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkGrt, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkGrt, new makerjs.paths.Line([-h,0], [0, 0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkGrt, TxtH, [-h,0+markShift], [0, 0+markShift]);//Y文字标注

/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesGdn"+myHolesNum]=myHh3HolesGdn;
markers.models["myHh3HolesGdn"+myHolesNum]=myHh3HolesGdn;
markers.models["myHh3HolesMarkGdn"+myHolesNum]=myHh3HolesMarkGdn;
markers.models["myHh3HolesXMarkGdn"+myHolesNum]=myHh3HolesXMarkGdn;
markers.models["myHh3HolesYMarkGdn"+myHolesNum]=myHh3HolesYMarkGdn;
/*****************************************************************************/
plate.models["myHh3HolesGmd"+myHolesNum]=myHh3HolesGmd;
markers.models["myHh3HolesGmd"+myHolesNum]=myHh3HolesGmd;
markers.models["myHh3HolesMarkGmd"+myHolesNum]=myHh3HolesMarkGmd;
markers.models["myHh3HolesYMarkGmd"+myHolesNum]=myHh3HolesYMarkGmd;
/*****************************************************************************/ 

plate.models["myHh3HolesGrt"+myHolesNum]=myHh3HolesGrt;
markers.models["myHh3HolesGrt"+myHolesNum]=myHh3HolesGrt;
markers.models["myHh3HolesMarkGrt"+myHolesNum]=myHh3HolesMarkGrt;
markers.models["myHh3HolesYMarkGrt"+myHolesNum]=myHh3HolesYMarkGrt;
/*****************************************************************************/ 
/*****************************************************************************/ 

}
else if(area==8){
//画孔 H位置孔
      //标记拉手孔3 H位置孔上
      var myHh3HolesHup= new makerjs.models.Holes(d/2, [[xh+h, yh]]);
      var myHh3HolesMarkHup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkHup.origin = [xh+h, yh];      
      var myHh3HolesXMarkHup = new makerjs.models.Square(0);      
      myHh3HolesXMarkHup.origin =[xh+h, yh];      
      var myHh3HolesYMarkHup = new makerjs.models.Square(0);      
      myHh3HolesYMarkHup.origin = [xh+h, yh];  
/*****************************************************************************/ 
//makerjs.model.addPath(myHh3HolesMarkHup, new makerjs.paths.Line([x0,yd-h], [x0, yd-h+markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkHup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkHup, new makerjs.paths.Line([0,0], [-h, 0]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkHup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkHup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkHup, new makerjs.paths.Line([0,-y0], [0, 0]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkHup, TxtY,[0+markShift,-y0], [0+markShift, 0]);//X文字标注
/*****************************************************************************/  
 //标记拉手孔3 H位置孔中
 var myHh3HolesHmd= new makerjs.models.Holes(d/2, [[xh, yh]]);
      var myHh3HolesMarkHmd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkHmd.origin = [xh, yh];      
      var myHh3HolesYMarkHmd = new makerjs.models.Square(0);      
      myHh3HolesYMarkHmd.origin = [xh, yh];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkHmd, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkHmd, new makerjs.paths.Line([0,0], [-h, 0]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkHmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkHmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkHmd, new makerjs.paths.Line([0,0], [h,0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkHmd, TxtH, [0,0+markShift], [h,0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 
 //标记拉手孔3 H位置孔下
 var myHh3HolesHdn= new makerjs.models.Holes(d/2, [[xh-h, yh]]);
      var myHh3HolesMarkHdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkHdn.origin = [xh-h, yh];       
      var myHh3HolesYMarkHdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkHdn.origin = [xh-h, yh];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkHdn, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkHdn, new makerjs.paths.Line([0,0], [h, 0]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkHdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkHdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkHdn, new makerjs.paths.Line([0,0], [h, 0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkHdn, TxtH, [0,0+markShift], [h, 0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesHup"+myHolesNum]=myHh3HolesHup;
markers.models["myHh3HolesHup"+myHolesNum]=myHh3HolesHup;
markers.models["myHh3HolesMarkHup"+myHolesNum]=myHh3HolesMarkHup;
markers.models["myHh3HolesXMarkHup"+myHolesNum]=myHh3HolesXMarkHup;
markers.models["myHh3HolesYMarkHup"+myHolesNum]=myHh3HolesYMarkHup;
/*****************************************************************************/
plate.models["myHh3HolesHmd"+myHolesNum]=myHh3HolesHmd;
markers.models["myHh3HolesHmd"+myHolesNum]=myHh3HolesHmd;
markers.models["myHh3HolesMarkHmd"+myHolesNum]=myHh3HolesMarkHmd;
markers.models["myHh3HolesYMarkHmd"+myHolesNum]=myHh3HolesYMarkHmd;
/*****************************************************************************/ 
plate.models["myHh3HolesHdn"+myHolesNum]=myHh3HolesHdn;
markers.models["myHh3HolesHdn"+myHolesNum]=myHh3HolesHdn;
markers.models["myHh3HolesMarkHdn"+myHolesNum]=myHh3HolesMarkHdn;
markers.models["myHh3HolesYMarkHdn"+myHolesNum]=myHh3HolesYMarkHdn;
/*****************************************************************************/ 
/*****************************************************************************/ 
 
}
else if(area==9){
//画孔 I位置孔
      //标记拉手孔3 I位置孔下
      var myHh3HolesIdn= new makerjs.models.Holes(d/2, [[xi, yi]]);
      var myHh3HolesMarkIdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkIdn.origin = [xi, yi];      
      var myHh3HolesXMarkIdn = new makerjs.models.Square(0);      
      myHh3HolesXMarkIdn.origin =[xi, yi];      
      var myHh3HolesYMarkIdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkIdn.origin = [xi, yi];  

/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkIdn, new makerjs.paths.Line([x0,y0], [x0, -y0-markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkIdn, new makerjs.paths.Line([0,0], [x0+markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkIdn, new makerjs.paths.Line([x0,-y0], [x0+markShiftFour, -y0]));//X轴加长标线
makerjs.model.addPath(myHh3HolesMarkIdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkIdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkIdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkIdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkIdn, TxtX, [0, -y0-markShiftFour], [x0,-y0-markShiftFour]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHh3HolesYMarkIdn, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, -y0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkIdn, TxtY, [x0+markShiftFour, -y0], [x0+markShiftFour,0]);//Y文字标注
/*****************************************************************************/ 
 //标记拉手孔3 I位置孔左中
 var myHh3HolesImd= new makerjs.models.Holes(d/2, [[xi-h, yi]]);
      var myHh3HolesMarkImd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkImd.origin = [xi-h, yi];      
      var myHh3HolesYMarkImd = new makerjs.models.Square(0);      
      myHh3HolesYMarkImd.origin = [xi-h, yi];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkImd, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
//makerjs.model.addPath(myHh3HolesMarkImd, new makerjs.paths.Line([0,0], [0, -h-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkImd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkImd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkImd, new makerjs.paths.Line([0,0], [h, 0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkImd, TxtH, [0,0+markShift], [h, 0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 
 //标记拉手孔3 I位置孔上
 var myHh3HolesIup= new makerjs.models.Holes(d/2, [[xi-h-h, yi]]);
      var myHh3HolesMarkIup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkIup.origin = [xi-h-h, yi];       
      var myHh3HolesYMarkIup = new makerjs.models.Square(0);      
      myHh3HolesYMarkIup.origin = [xi-h-h, yi];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkIup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
//makerjs.model.addPath(myHh3HolesMarkIup, new makerjs.paths.Line([0,0], [0, -h-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkIup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkIup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkIup, new makerjs.paths.Line([0,0], [h, 0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkIup, TxtH, [0,0+markShift], [h, 0+markShift]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesIdn"+myHolesNum]=myHh3HolesIdn;
markers.models["myHh3HolesIdn"+myHolesNum]=myHh3HolesIdn;
markers.models["myHh3HolesMarkIdn"+myHolesNum]=myHh3HolesMarkIdn;
markers.models["myHh3HolesXMarkIdn"+myHolesNum]=myHh3HolesXMarkIdn;
markers.models["myHh3HolesYMarkIdn"+myHolesNum]=myHh3HolesYMarkIdn;
/*****************************************************************************/
plate.models["myHh3HolesImd"+myHolesNum]=myHh3HolesImd;
markers.models["myHh3HolesImd"+myHolesNum]=myHh3HolesImd;
markers.models["myHh3HolesMarkImd"+myHolesNum]=myHh3HolesMarkImd;
markers.models["myHh3HolesYMarkImd"+myHolesNum]=myHh3HolesYMarkImd;
/*****************************************************************************/ 
plate.models["myHh3HolesIup"+myHolesNum]=myHh3HolesIup;
markers.models["myHh3HolesIup"+myHolesNum]=myHh3HolesIup;
markers.models["myHh3HolesMarkIup"+myHolesNum]=myHh3HolesMarkIup;
markers.models["myHh3HolesYMarkIup"+myHolesNum]=myHh3HolesYMarkIup;
/*****************************************************************************/ 
/*****************************************************************************/ 
  
}

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default HandleHole3;