//4、双吊轮孔列
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HangWheelHoleTwoCol(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,X=inputParam.X,H=inputParam.H,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   d = D*1*myZoom,
   h = H*1*myZoom,
    x0 = X*1*myZoom,
    y0 = Y*1*myZoom;
  
   //d = D*1,
  // h = H*1,
   if(d!==0){
      var TxtD="∮"+D;
     }
   if(h!==0){
    var TxtH=H;
   }
   if(x0!==0){          
   var TxtX=X;
   }
   if(y0!==0){          
    var TxtY=Y;
   }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==4){
//画孔 A位置孔
      //标记双吊轮孔列 A位置孔左
      var myHwhtcHolesAlf= new makerjs.models.Holes(d/2, [[xa, ya]]);
      var myHwhtcHolesMarkAlf = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkAlf.origin = [xa, ya];      
      var myHwhtcXMarkAlf = new makerjs.models.Square(0);      
      myHwhtcXMarkAlf.origin =[xa, ya];      
      var myHwhtcYMarkAlf = new makerjs.models.Square(0);      
      myHwhtcYMarkAlf.origin = [xa, ya];
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkAlf, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHwhtcHolesMarkAlf, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHwhtcHolesMarkAlf, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHwhtcHolesMarkAlf, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkAlf, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkAlf, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwhtcXMarkAlf, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtcXMarkAlf, TxtX, [-x0,y0+markShiftThree], [0, y0+markShiftThree]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHwhtcYMarkAlf, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myHwhtcYMarkAlf, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
      //标记双吊轮孔列 A位置孔右
      var myHwhtcHolesArt= new makerjs.models.Holes(d/2, [[xa+h, ya]]);
      var myHwhtcHolesMarkArt = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkArt.origin = [xa+h, ya];      
      var myHwhtcXMarkArt = new makerjs.models.Square(0);      
     myHwhtcXMarkArt.origin =[xa+h, ya];      
      var myHwhtcYMarkArt = new makerjs.models.Square(0);      
      myHwhtcYMarkArt.origin = [xa+h, ya];  
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkArt, new makerjs.paths.Line([0,0], [0, 0+y0+markShiftThree]));//从孔圆心出发Y方向横向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkArt, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkArt, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
//makerjs.model.addPath(myHwhtcXMarkArt, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcYMarkArt, new makerjs.paths.Line([0, 0], [-h,0]));//H短标线    
makerjs.model.addPath(myHwhtcYMarkArt, new makerjs.paths.Line([0, y0+markShift], [-h,y0+markShift]));//H短标线
makerjs.model.addCaption(myHwhtcYMarkArt, TxtH, [-h,y0+markShiftThree], [0,y0+markShiftThree]);//H文字标注
/*****************************************************************************/
 //标记双吊轮孔列 G位置孔左
     var myHwhtcHolesGlf= new makerjs.models.Holes(d/2, [[xg, yg]]);
      var myHwhtcHolesMarkGlf = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkGlf.origin = [xg, yg];      
      var myHwhtcXMarkGlf = new makerjs.models.Square(0);      
      myHwhtcXMarkGlf.origin = [xg, yg];      
      var myHwhtcYMarkGlf = new makerjs.models.Square(0);      
      myHwhtcYMarkGlf.origin = [xg, yg];  
/*****************************************************************************/  
//makerjs.model.addPath(myHwhtcHolesMarkGlf, new makerjs.paths.Line([0,0], [x0+markShiftTwo, y0]));//Y轴加长标线
//makerjs.model.addPath(myHwhtcHolesMarkGlf, new makerjs.paths.Line([0,0], [x0, y0+markShiftTwo]));//X轴加长标线
makerjs.model.addPath(myHwhtcXMarkGlf, new makerjs.paths.Line([0,0], [0, -y0]));////从孔圆心出发Y方向横向标线
makerjs.model.addPath(myHwhtcXMarkGlf, new makerjs.paths.Line([0,0], [-x0, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkGlf, new makerjs.paths.Line([0,0], [d, d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkGlf, TxtD, [0, 0], [d+markHolesOneD,d+markHolesOneD]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcXMarkGlf, new makerjs.paths.Line([0,0], [-x0,0]));//X短标线
makerjs.model.addCaption(myHwhtcXMarkGlf, TxtX, [-x0, -markShift], [0,-markShift]);//X文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHwhtcYMarkGlf, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
makerjs.model.addCaption(myHwhtcYMarkGlf, TxtY, [-markShift, -y0], [-markShift,0]);//Y文字标注
/*****************************************************************************/ 
//标记双吊轮孔列 G位置孔右
  var myHwhtcHolesGrt= new makerjs.models.Holes(d/2, [[xg+h, yg]]);
      var myHwhtcHolesMarkGrt = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkGrt.origin = [xg+h, yg];  
      var myHwhtcYMarkGrt = new makerjs.models.Square(0);      
      myHwhtcYMarkGrt.origin = [xg+h, yg];  
/*****************************************************************************/  
makerjs.model.addPath(myHwhtcYMarkGrt, new makerjs.paths.Line([0,0], [-h, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkGrt, new makerjs.paths.Line([0,0], [d,d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkGrt, TxtD, [0, 0], [d+markHolesOneD,d+markHolesOneD]);//直径文字标注
/*****************************************************************************/ 
//makerjs.model.addPath(myHwhtcYMarkGrt, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
/*****************************************************************************/    
makerjs.model.addPath(myHwhtcYMarkGrt, new makerjs.paths.Line([-h,0], [0, 0]));//H短标线
makerjs.model.addCaption(myHwhtcYMarkGrt, TxtH, [-h,0-markShift], [0, 0-markShift]);//H文字标注
/*****************************************************************************/
/*****************************************************************************/ 
plate.models["myHwhtcHolesAlf"+myHolesNum]=myHwhtcHolesAlf;
markers.models["myHwhtcHolesAlf"+myHolesNum]=myHwhtcHolesAlf;
markers.models["myHwhtcHolesMarkAlf"+myHolesNum]=myHwhtcHolesMarkAlf;
markers.models["myHwhtcXMarkAlf"+myHolesNum]=myHwhtcXMarkAlf;
markers.models["myHwhtcYMarkAlf"+myHolesNum]=myHwhtcYMarkAlf;
/*****************************************************************************/
plate.models["myHwhtcHolesArt"+myHolesNum]=myHwhtcHolesArt;
markers.models["myHwhtcHolesArt"+myHolesNum]=myHwhtcHolesArt;
markers.models["myHwhtcHolesMarkArt"+myHolesNum]=myHwhtcHolesMarkArt;
markers.models["myHwhtcYMarkArt"+myHolesNum]=myHwhtcYMarkArt;
/*****************************************************************************/
plate.models["myHwhtcHolesGlf"+myHolesNum]=myHwhtcHolesGlf;
markers.models["myHwhtcHolesGlf"+myHolesNum]=myHwhtcHolesGlf;
markers.models["myHwhtcHolesMarkGlf"+myHolesNum]=myHwhtcHolesMarkGlf;
markers.models["myHwhtcXMarkGlf"+myHolesNum]=myHwhtcXMarkGlf;
markers.models["myHwhtcYMarkGlf"+myHolesNum]=myHwhtcYMarkGlf; 
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHwhtcHolesGrt"+myHolesNum]=myHwhtcHolesGrt;
markers.models["myHwhtcHolesGrt"+myHolesNum]=myHwhtcHolesGrt;
markers.models["myHwhtcHolesMarkGrt"+myHolesNum]=myHwhtcHolesMarkGrt;
markers.models["myHwhtcYMarkGrt"+myHolesNum]=myHwhtcYMarkGrt; 
/*****************************************************************************/ 
  }
  else if(area==6){
//画孔 C位置孔
      //标记双吊轮孔列 C位置孔左
      var myHwhtcHolesClf= new makerjs.models.Holes(d/2, [[xc, yc]]);
      var myHwhtcHolesMarkClf = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkClf.origin = [xc, yc];      
      var myHwhtcXMarkClf = new makerjs.models.Square(0);      
      myHwhtcXMarkClf.origin =[xc, yc];      
      var myHwhtcYMarkClf = new makerjs.models.Square(0);      
      myHwhtcYMarkClf.origin = [xc, yc];
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkClf, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHwhtcHolesMarkClf, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHwhtcHolesMarkClf, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHwhtcHolesMarkClf, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkClf, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkClf, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwhtcXMarkClf, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtcXMarkClf, TxtX, [0,y0+markShiftTwo], [x0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHwhtcYMarkClf, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
makerjs.model.addCaption(myHwhtcYMarkClf, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
      //标记双吊轮孔列 C位置孔右
      var myHwhtcHolesCrt= new makerjs.models.Holes(d/2, [[xc-h, yc]]);
      var myHwhtcHolesMarkCrt = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkCrt.origin = [xc-h, yc];      
      var myHwhtcXMarkCrt = new makerjs.models.Square(0);      
     myHwhtcXMarkCrt.origin =[xc-h, yc];      
      var myHwhtcYMarkCrt = new makerjs.models.Square(0);      
      myHwhtcYMarkCrt.origin = [xc-h, yc];  
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkCrt, new makerjs.paths.Line([0,0], [0, 0+y0+markShiftThree]));//从孔圆心出发Y方向横向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkCrt, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkCrt, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
//makerjs.model.addPath(myHwhtcXMarkCrt, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcYMarkCrt, new makerjs.paths.Line([0, 0], [h,0]));//H短标线    
makerjs.model.addPath(myHwhtcYMarkCrt, new makerjs.paths.Line([0, y0+markShift], [h,y0+markShift]));//H短标线
makerjs.model.addCaption(myHwhtcYMarkCrt, TxtH, [0,y0+markShiftTwo], [h,y0+markShiftTwo]);//H文字标注
/*****************************************************************************/
 //标记双吊轮孔列 I位置孔左
     var myHwhtcHolesIlf= new makerjs.models.Holes(d/2, [[xi, yi]]);
      var myHwhtcHolesMarkIlf = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkIlf.origin = [xi, yi];      
      var myHwhtcXMarkIlf = new makerjs.models.Square(0);      
      myHwhtcXMarkIlf.origin = [xi, yi];      
      var myHwhtcYMarkIlf = new makerjs.models.Square(0);      
      myHwhtcYMarkIlf.origin = [xi, yi];  
/*****************************************************************************/  
makerjs.model.addPath(myHwhtcHolesMarkIlf, new makerjs.paths.Line([x0,0], [x0+markShiftTwo, 0]));//Y轴加长标线
makerjs.model.addPath(myHwhtcHolesMarkIlf, new makerjs.paths.Line([x0,-y0], [x0+markShiftTwo, -y0]));//X轴加长标线
makerjs.model.addPath(myHwhtcXMarkIlf, new makerjs.paths.Line([0,0], [0, -y0]));////从孔圆心出发Y方向横向标线
makerjs.model.addPath(myHwhtcXMarkIlf, new makerjs.paths.Line([0,0], [x0, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkIlf, new makerjs.paths.Line([0,0], [d, d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkIlf, TxtD, [0, 0], [d+markHolesOneD,d+markHolesOneD]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcXMarkIlf, new makerjs.paths.Line([0,0], [x0,0]));//X短标线
makerjs.model.addCaption(myHwhtcXMarkIlf, TxtX, [0, -markShift], [x0,-markShift]);//X文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHwhtcYMarkIlf, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -y0]));//Y短标线
makerjs.model.addCaption(myHwhtcYMarkIlf, TxtY, [x0+markShiftThree, -y0], [x0+markShiftThree,0]);//Y文字标注
/*****************************************************************************/ 
//标记双吊轮孔列 I位置孔右
  var myHwhtcHolesIrt= new makerjs.models.Holes(d/2, [[xi-h, yi]]);
      var myHwhtcHolesMarkIrt = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkIrt.origin = [xi-h, yi];  
      var myHwhtcYMarkIrt = new makerjs.models.Square(0);      
      myHwhtcYMarkIrt.origin = [xi-h, yi];  
/*****************************************************************************/  
makerjs.model.addPath(myHwhtcYMarkIrt, new makerjs.paths.Line([0,0], [h, 0]));////从孔圆心出发X方向竖向标线
makerjs.model.addPath(myHwhtcYMarkIrt, new makerjs.paths.Line([0,0], [0, -y0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkIrt, new makerjs.paths.Line([0,0], [d,d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkIrt, TxtD, [0, 0], [d+markHolesOneD,d+markHolesOneD]);//直径文字标注
/*****************************************************************************/ 
//makerjs.model.addPath(myHwhtcYMarkIrt, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
/*****************************************************************************/    
makerjs.model.addPath(myHwhtcYMarkIrt, new makerjs.paths.Line([h,0], [0, 0]));//H短标线
makerjs.model.addCaption(myHwhtcYMarkIrt, TxtH, [0,0-markShift], [h, 0-markShift]);//H文字标注
/*****************************************************************************/
/*****************************************************************************/ 
plate.models["myHwhtcHolesClf"+myHolesNum]=myHwhtcHolesClf;
markers.models["myHwhtcHolesClf"+myHolesNum]=myHwhtcHolesClf;
markers.models["myHwhtcHolesMarkClf"+myHolesNum]=myHwhtcHolesMarkClf;
markers.models["myHwhtcXMarkClf"+myHolesNum]=myHwhtcXMarkClf;
markers.models["myHwhtcYMarkClf"+myHolesNum]=myHwhtcYMarkClf;
/*****************************************************************************/
plate.models["myHwhtcHolesCrt"+myHolesNum]=myHwhtcHolesCrt;
markers.models["myHwhtcHolesCrt"+myHolesNum]=myHwhtcHolesCrt;
markers.models["myHwhtcHolesMarkCrt"+myHolesNum]=myHwhtcHolesMarkCrt;
markers.models["myHwhtcYMarkCrt"+myHolesNum]=myHwhtcYMarkCrt;
/*****************************************************************************/
plate.models["myHwhtcHolesIlf"+myHolesNum]=myHwhtcHolesIlf;
markers.models["myHwhtcHolesIlf"+myHolesNum]=myHwhtcHolesIlf;
markers.models["myHwhtcHolesMarkIlf"+myHolesNum]=myHwhtcHolesMarkIlf;
markers.models["myHwhtcXMarkIlf"+myHolesNum]=myHwhtcXMarkIlf;
markers.models["myHwhtcYMarkIlf"+myHolesNum]=myHwhtcYMarkIlf; 
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHwhtcHolesIrt"+myHolesNum]=myHwhtcHolesIrt;
markers.models["myHwhtcHolesIrt"+myHolesNum]=myHwhtcHolesIrt;
markers.models["myHwhtcHolesMarkIrt"+myHolesNum]=myHwhtcHolesMarkIrt;
markers.models["myHwhtcYMarkIrt"+myHolesNum]=myHwhtcYMarkIrt; 
/*****************************************************************************/ 
  }


myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default HangWheelHoleTwoCol;