//缺2、U型
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function UShapeGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
   w= W*1*myZoom,
   r=d/2,
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     if(d!==0){
      var TxtD=TxtD="∮"+D;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
     if(x0!==0){          
      var TxtX=X;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //画U型缺 A位置
        //new makerjs.models.Dome(width, height, radius, bottomless)
   var myUsgGapA = new makerjs.models.RoundRectangle(d, r+w, r)
   delete myUsgGapA.paths.TopLeft;
   delete myUsgGapA.paths.TopRight;
  myUsgGapA.origin=[xa,ya-w];
  ////标记U型缺 A位置
  var myUsgGapAMakerD = new makerjs.models.Square(0); //孔的原点       
  myUsgGapAMakerD.origin = [xa,ya];
  var myUsgGapAMakerX = new makerjs.models.Square(0); //孔的原点       
  myUsgGapAMakerX.origin = [xa,ya];
  var myUsgGapAMakerW = new makerjs.models.Square(0); //孔的原点       
  myUsgGapAMakerW.origin = [xa,ya]; 
  /*****************************************************************************/
  /*****************************************************************************/ 
  makerjs.model.addPath(myUsgGapAMakerD, new makerjs.paths.Line([0,0], [0, 0+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myUsgGapAMakerD, new makerjs.paths.Line([0+d,0], [0+d, 0+markShiftFour]));//X轴加长标线
  makerjs.model.addPath(myUsgGapAMakerD, new makerjs.paths.Line([-x0,0], [-x0, 0+markShiftFour]));//X轴方向短线
 makerjs.model.addPath(myUsgGapAMakerD, new makerjs.paths.Line([0+r,-w], [d+markShiftFour, -w]));//X轴方向短线
  /*****************************************************************************/  
  makerjs.model.addPath(myUsgGapAMakerD, new makerjs.paths.Line([0,0+markShift], [d, 0+markShift]));//D轴方向标注短线 
  makerjs.model.addCaption(myUsgGapAMakerD,TxtD, [0,0+markShiftThree], [d, 0+markShiftThree]);//D文字标注  
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapAMakerX, new makerjs.paths.Line([-x0,0+markShift], [0, 0+markShift]));//Y轴方向标注短线 
  makerjs.model.addCaption(myUsgGapAMakerX,TxtX, [-x0,0+markShiftThree], [0, 0+markShiftThree]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapAMakerW, new makerjs.paths.Line([0+d+markShift,-w], [0+d+markShift, 0]));//W短线标注
  makerjs.model.addCaption(myUsgGapAMakerW, TxtW, [0+d+markShiftThree,-w], [0+d+markShiftThree, 0]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myUsgGapA"+myHolesNum]=myUsgGapA;
  markers.models["myUsgGapA"+myHolesNum]=myUsgGapA;
  markers.models["myUsgGapAMakerD"+myHolesNum]=myUsgGapAMakerD;
  markers.models["myUsgGapAMakerX"+myHolesNum]=myUsgGapAMakerX;
  markers.models["myUsgGapAMakerW"+myHolesNum]=myUsgGapAMakerW;
  /*****************************************************************************/
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myUsgGapA"+myHolesNum])
  console.log("markers==",markers);
  /*****************************************************************************/
  /*****************************************************************************/ 
     
        }else if(area==2){        
      //画缺 B位置
        //画U型缺 B位置
        //new makerjs.models.Dome(width, height, radius, bottomless)
   var myUsgGapB = new makerjs.models.RoundRectangle(d, r+w, r)
   delete myUsgGapB.paths.TopLeft;
   delete myUsgGapB.paths.TopRight;
  myUsgGapB.origin=[xb-r,yb-w];
  ////标记U型缺 B位置
  var myUsgGapBMakerD = new makerjs.models.Square(0); //孔的原点       
  myUsgGapBMakerD.origin = [xb-r,yb];
  var myUsgGapBMakerX = new makerjs.models.Square(0); //孔的原点       
  myUsgGapBMakerX.origin = [xb-r,yb];
  var myUsgGapBMakerW = new makerjs.models.Square(0); //孔的原点       
  myUsgGapBMakerW.origin = [xb-r,yb]; 
  /*****************************************************************************/
  /*****************************************************************************/ 
  makerjs.model.addPath(myUsgGapBMakerD, new makerjs.paths.Line([0,0], [0, 0+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myUsgGapBMakerD, new makerjs.paths.Line([0+d,0], [0+d, 0+markShiftFour]));//X轴加长标线
  makerjs.model.addPath(myUsgGapBMakerD, new makerjs.paths.Line([-x0,0], [-x0, 0+markShiftFour]));//X轴方向短线
 makerjs.model.addPath(myUsgGapBMakerD, new makerjs.paths.Line([0+r,-w], [d+markShiftFour, -w]));//X轴方向短线
  /*****************************************************************************/  
  makerjs.model.addPath(myUsgGapBMakerD, new makerjs.paths.Line([0,0+markShift], [d, 0+markShift]));//D轴方向标注短线 
  makerjs.model.addCaption(myUsgGapBMakerD,TxtD, [0,0+markShiftThree], [d, 0+markShiftThree]);//D文字标注  
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapBMakerX, new makerjs.paths.Line([-x0,0+markShift], [0, 0+markShift]));//Y轴方向标注短线 
  makerjs.model.addCaption(myUsgGapBMakerX,TxtX, [-x0,0+markShiftThree], [0, 0+markShiftThree]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapBMakerW, new makerjs.paths.Line([0+d+markShift,-w], [0+d+markShift, 0]));//W短线标注
  makerjs.model.addCaption(myUsgGapBMakerW, TxtW, [0+d+markShiftThree,-w], [0+d+markShiftThree, 0]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myUsgGapB"+myHolesNum]=myUsgGapB;
  markers.models["myUsgGapB"+myHolesNum]=myUsgGapB;
  markers.models["myUsgGapBMakerD"+myHolesNum]=myUsgGapBMakerD;
  markers.models["myUsgGapBMakerX"+myHolesNum]=myUsgGapBMakerX;
  markers.models["myUsgGapBMakerW"+myHolesNum]=myUsgGapBMakerW;
  /*****************************************************************************/
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myUsgGapB"+myHolesNum])
  console.log("markers==",markers);
  /*****************************************************************************/
        
        }else if(area==3){        
           //画缺 C位置
        //画U型缺 C位置
        //new makerjs.models.Dome(width, height, radius, bottomless)
   var myUsgGapC = new makerjs.models.RoundRectangle(d, r+w, r)
   delete myUsgGapC.paths.TopLeft;
   delete myUsgGapC.paths.TopRight;
  myUsgGapC.origin=[xc-d,yc-w];
  ////标记U型缺 B位置
  var myUsgGapCMakerD = new makerjs.models.Square(0); //孔的原点       
  myUsgGapCMakerD.origin = [xc-d,yc];
  var myUsgGapCMakerX = new makerjs.models.Square(0); //孔的原点       
  myUsgGapCMakerX.origin = [xc-d,yc];
  var myUsgGapCMakerW = new makerjs.models.Square(0); //孔的原点       
  myUsgGapCMakerW.origin = [xc-d,yc]; 
  /*****************************************************************************/
  /*****************************************************************************/ 
  makerjs.model.addPath(myUsgGapCMakerD, new makerjs.paths.Line([0,0], [0, 0+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myUsgGapCMakerD, new makerjs.paths.Line([0+d,0], [0+d, 0+markShiftFour]));//X轴加长标线
  makerjs.model.addPath(myUsgGapCMakerD, new makerjs.paths.Line([x0+d,0], [x0+d, 0+markShiftFour]));//X轴方向短线
 makerjs.model.addPath(myUsgGapCMakerD, new makerjs.paths.Line([0+r,-w], [d+markShiftFour, -w]));//X轴方向短线
  /*****************************************************************************/  
  makerjs.model.addPath(myUsgGapCMakerD, new makerjs.paths.Line([0,0+markShift], [d, 0+markShift]));//D轴方向标注短线 
  makerjs.model.addCaption(myUsgGapCMakerD,TxtD, [0,0+markShiftThree], [d, 0+markShiftThree]);//D文字标注  
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapCMakerX, new makerjs.paths.Line([0+d,0+markShiftTwo], [0+d+x0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myUsgGapCMakerX,TxtX, [0+d,0+markShiftFour], [0+d+x0, 0+markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapCMakerW, new makerjs.paths.Line([0+d+markShift,-w], [0+d+markShift, 0]));//W短线标注
  makerjs.model.addCaption(myUsgGapCMakerW, TxtW, [0+d+markShiftThree,-w], [0+d+markShiftThree, 0]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myUsgGapC"+myHolesNum]=myUsgGapC;
  markers.models["myUsgGapC"+myHolesNum]=myUsgGapC;
  markers.models["myUsgGapCMakerD"+myHolesNum]=myUsgGapCMakerD;
  markers.models["myUsgGapCMakerX"+myHolesNum]=myUsgGapCMakerX;
  markers.models["myUsgGapCMakerW"+myHolesNum]=myUsgGapCMakerW;
  /*****************************************************************************/
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myUsgGapC"+myHolesNum])
  console.log("markers==",markers);
  /*****************************************************************************/
          
        }else if(area==7){
//画缺 G位置
        //画U型缺 G位置
        //new makerjs.models.Dome(width, height, radius, bottomless)
        var myUsgGapG = new makerjs.models.RoundRectangle(d, r+w, r)
        delete myUsgGapG.paths.BottomLeft;
        delete myUsgGapG.paths.BottomRight;
       myUsgGapG.origin=[xg,yg-r];
       ////标记U型缺 G位置
       var myUsgGapGMakerD = new makerjs.models.Square(0); //孔的原点       
       myUsgGapGMakerD.origin = [xg,yg];
       var myUsgGapGMakerX = new makerjs.models.Square(0); //孔的原点       
       myUsgGapGMakerX.origin = [xg,yg];
       var myUsgGapGMakerW = new makerjs.models.Square(0); //孔的原点       
       myUsgGapGMakerW.origin = [xg,yg]; 
       /*****************************************************************************/
       /*****************************************************************************/ 
       makerjs.model.addPath(myUsgGapGMakerD, new makerjs.paths.Line([0,0], [0, 0-markShiftFour]));//Y轴方向短线
       makerjs.model.addPath(myUsgGapGMakerD, new makerjs.paths.Line([0+d,0], [0+d, 0-markShiftFour]));//X轴加长标线
       makerjs.model.addPath(myUsgGapGMakerD, new makerjs.paths.Line([-x0,0], [-x0, 0-markShiftFour]));//X轴方向短线
      makerjs.model.addPath(myUsgGapGMakerD, new makerjs.paths.Line([0+r,w], [d+markShiftFour, w]));//X轴方向短线
       /*****************************************************************************/  
       makerjs.model.addPath(myUsgGapGMakerD, new makerjs.paths.Line([0,0-markShiftTwo], [d, 0-markShiftTwo]));//D轴方向标注短线 
       makerjs.model.addCaption(myUsgGapGMakerD,TxtD, [0,0-markShiftFour], [d, 0-markShiftFour]);//D文字标注  
       /*****************************************************************************/
       makerjs.model.addPath(myUsgGapGMakerX, new makerjs.paths.Line([-x0,0-markShiftThree], [0, 0-markShiftThree]));//Y轴方向标注短线 
       makerjs.model.addCaption(myUsgGapGMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour]);//Y文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myUsgGapGMakerW, new makerjs.paths.Line([0+d+markShift,0], [0+d+markShift, w]));//W短线标注
       makerjs.model.addCaption(myUsgGapGMakerW, TxtW, [0+d+markShiftThree,0], [0+d+markShiftThree, w]);//W文字标注
       /*****************************************************************************/ 
       plate.models["myUsgGapG"+myHolesNum]=myUsgGapG;
       markers.models["myUsgGapG"+myHolesNum]=myUsgGapG;
       markers.models["myUsgGapGMakerD"+myHolesNum]=myUsgGapGMakerD;
       markers.models["myUsgGapGMakerX"+myHolesNum]=myUsgGapGMakerX;
       markers.models["myUsgGapGMakerW"+myHolesNum]=myUsgGapGMakerW;
       /*****************************************************************************/
        }else if(area==8){
  //画缺 H位置
        //画U型缺 H位置
        //new makerjs.models.Dome(width, height, radius, bottomless)
        var myUsgGapH = new makerjs.models.RoundRectangle(d, r+w, r)
        delete myUsgGapH.paths.BottomLeft;
        delete myUsgGapH.paths.BottomRight;
       myUsgGapH.origin=[xh-r,yh-r];
       ////标记U型缺 A位置
       var myUsgGapHMakerD = new makerjs.models.Square(0); //孔的原点       
       myUsgGapHMakerD.origin = [xh-r,yh];
       var myUsgGapHMakerX = new makerjs.models.Square(0); //孔的原点       
       myUsgGapHMakerX.origin = [xh-r,yh];
       var myUsgGapHMakerW = new makerjs.models.Square(0); //孔的原点       
       myUsgGapHMakerW.origin = [xh-r,yh]; 
       /*****************************************************************************/
       /*****************************************************************************/ 
       makerjs.model.addPath(myUsgGapHMakerD, new makerjs.paths.Line([0,0], [0, 0-markShiftFour]));//Y轴方向短线
       makerjs.model.addPath(myUsgGapHMakerD, new makerjs.paths.Line([0+d,0], [0+d, 0-markShiftFour]));//X轴加长标线
       makerjs.model.addPath(myUsgGapHMakerD, new makerjs.paths.Line([-x0,0], [-x0, 0-markShiftFour]));//X轴方向短线
      makerjs.model.addPath(myUsgGapHMakerD, new makerjs.paths.Line([0+r,w], [d+markShiftFour, w]));//X轴方向短线
       /*****************************************************************************/  
       makerjs.model.addPath(myUsgGapHMakerD, new makerjs.paths.Line([0,0+markShiftTwo], [d, 0+markShiftTwo]));//D轴方向标注短线 
       makerjs.model.addCaption(myUsgGapHMakerD,TxtD, [0,0+markShiftFour], [d, 0+markShiftFour]);//D文字标注  
       /*****************************************************************************/
       makerjs.model.addPath(myUsgGapHMakerX, new makerjs.paths.Line([-x0,0-markShiftThree], [0, 0-markShiftThree]));//Y轴方向标注短线 
       makerjs.model.addCaption(myUsgGapHMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour]);//Y文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myUsgGapHMakerW, new makerjs.paths.Line([0+d+markShift,0], [0+d+markShift, w]));//W短线标注
       makerjs.model.addCaption(myUsgGapHMakerW, TxtW, [0+d+markShiftThree,0], [0+d+markShiftThree, w]);//W文字标注
       /*****************************************************************************/ 
       plate.models["myUsgGapH"+myHolesNum]=myUsgGapH;
       markers.models["myUsgGapH"+myHolesNum]=myUsgGapH;
       markers.models["myUsgGapHMakerD"+myHolesNum]=myUsgGapHMakerD;
       markers.models["myUsgGapHMakerX"+myHolesNum]=myUsgGapHMakerX;
       markers.models["myUsgGapHMakerW"+myHolesNum]=myUsgGapHMakerW;
       /*****************************************************************************/
    
        }else if(area==9){
  //画缺 I位置
        //画U型缺 I位置
        //new makerjs.models.Dome(width, height, radius, bottomless)
        var myUsgGapI = new makerjs.models.RoundRectangle(d, r+w, r)
        delete myUsgGapI.paths.BottomLeft;
        delete myUsgGapI.paths.BottomRight;
       myUsgGapI.origin=[xi-d,yi-r];
       ////标记U型缺 I位置
       var myUsgGapIMakerD = new makerjs.models.Square(0); //孔的原点       
       myUsgGapIMakerD.origin = [xi-d,yi];
       var myUsgGapIMakerX = new makerjs.models.Square(0); //孔的原点       
       myUsgGapIMakerX.origin = [xi-d,yi];
       var myUsgGapIMakerW = new makerjs.models.Square(0); //孔的原点       
       myUsgGapIMakerW.origin = [xi-d,yi]; 
       /*****************************************************************************/
       /*****************************************************************************/ 
       makerjs.model.addPath(myUsgGapIMakerD, new makerjs.paths.Line([0,0], [0, 0-markShiftFour]));//Y轴方向短线
       makerjs.model.addPath(myUsgGapIMakerD, new makerjs.paths.Line([0+d,0], [0+d, 0-markShiftFour]));//X轴加长标线
       makerjs.model.addPath(myUsgGapIMakerD, new makerjs.paths.Line([x0+d,0], [x0+d, 0-markShiftFour]));//X轴方向短线
      makerjs.model.addPath(myUsgGapIMakerD, new makerjs.paths.Line([0+r,w], [d+markShiftFour, w]));//X轴方向短线
       /*****************************************************************************/  
       makerjs.model.addPath(myUsgGapIMakerD, new makerjs.paths.Line([0,0-markShiftTwo], [d, 0-markShiftTwo]));//D轴方向标注短线 
       makerjs.model.addCaption(myUsgGapIMakerD,TxtD, [0,0-markShiftFour], [d, 0-markShiftFour]);//D文字标注  
       /*****************************************************************************/
       makerjs.model.addPath(myUsgGapIMakerX, new makerjs.paths.Line([0+d,0-markShiftThree], [0+d+x0, 0-markShiftThree]));//Y轴方向标注短线 
       makerjs.model.addCaption(myUsgGapIMakerX,TxtX, [0+d,0-markShiftFour], [0+d+x0, 0-markShiftFour]);//Y文字标注
       /*****************************************************************************/
       makerjs.model.addPath(myUsgGapIMakerW, new makerjs.paths.Line([0+d+markShift,0], [0+d+markShift, w]));//W短线标注
       makerjs.model.addCaption(myUsgGapIMakerW, TxtW, [0+d+markShiftThree,0], [0+d+markShiftThree, w]);//W文字标注
       /*****************************************************************************/ 
       plate.models["myUsgGapI"+myHolesNum]=myUsgGapI;
       markers.models["myUsgGapI"+myHolesNum]=myUsgGapI;
       markers.models["myUsgGapIMakerD"+myHolesNum]=myUsgGapIMakerD;
       markers.models["myUsgGapIMakerX"+myHolesNum]=myUsgGapIMakerX;
       markers.models["myUsgGapIMakerW"+myHolesNum]=myUsgGapIMakerW;
       /*****************************************************************************/
       
        }

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default UShapeGap;