//缺3、应力孔
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function StressHoleGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var H=inputParam.H,D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
   w= W*1*myZoom,
   h= H*1*myZoom,
   r=d/2,
   dd=Math.sqrt(r*r-h/2*h/2).toFixed(),
   w0=w-dd,
   h0=(d-h)/2,
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     /*
     d = D*1,
     w= W*1,
     h= H*1,
     r=d/2,
     dd=Math.sqrt(r*r-h/2*h/2).toFixed(),
       x0 = X*1,
       y0 = Y*1;
       */
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //画应力孔缺 A位置   H W D  width=w-d/2    
        /*  
        var myShgGapARect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapARect.origin=[xa-5,ya-h];     
        var myShgGapAHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapAHoles.origin=[xa+w,ya-h/2];
        var myShgGapA=new makerjs.model.combineUnion(myShgGapARect, myShgGapAHoles);
        */
       //20240708 新画法
       var myShgGapA = {
  
         paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xa, ya], [xa, ya-w0]),
           "ArcBottom": new makerjs.paths.Arc([xa, ya-w0], [xa+h, ya-w0],r,true,false),
           "RightLine": new makerjs.paths.Line([xa+h, ya-w0], [xa+h, ya])
         }
         
         };
       //20240708 新画法

     //画应力孔缺标记 A位置 
  var myShgGapAMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapAMaker.origin = [xa,ya];
  var myShgGapAMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapAMakerW.origin = [xa,ya];
  var myShgGapAMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapAMakerH.origin = [xa,ya];
  var myShgGapAMakerX = new makerjs.models.Square(0); //Y的起始点        
  myShgGapAMakerX.origin = [xa,ya];
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([-x0,0], [-x0, markShiftFour]));//Y轴加长标线

  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([-h0,-w-markShiftTwo], [-h0,-w+markShiftTwo]));//Y轴方向短线 W
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([h+h0,-w-markShiftTwo], [h+h0,-w+markShiftTwo]));//Y轴方向短线 W

  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([0,0], [0, 0+markShiftFour]));//H轴加长标线
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([0+h,0], [0+h, 0+markShiftFour]));//H轴加长标线

  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([0-h0,-w], [0-h0-markShiftThree, 0-w]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([-h0,-w], [-h0+d, -w]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapAMaker,TxtD, [-h0,-w-markShift], [-h0+d, -w-markShift]);//D文字标注

  makerjs.model.addPath(myShgGapAMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myShgGapAMakerX,TxtX, [-x0,0+markShiftFour], [0, 0+markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapAMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapAMakerH,TxtH,[0,0+markShiftThree], [h, 0+markShiftThree]);//H文字标注
  
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapAMakerW, new makerjs.paths.Line([-h0-markShift,-w], [-h0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(myShgGapAMakerW, TxtW, [-h0-markShiftTwo,-w], [-h0-markShiftTwo, 0]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myShgGapA"+myHolesNum]=myShgGapA;
  markers.models["myShgGapA"+myHolesNum]=myShgGapA;
  markers.models["myShgGapAMaker"+myHolesNum]=myShgGapAMaker;
  markers.models["myShgGapAMakerW"+myHolesNum]=myShgGapAMakerW;
  markers.models["myShgGapAMakerH"+myHolesNum]=myShgGapAMakerH;
  markers.models["myShgGapAMakerX"+myHolesNum]=myShgGapAMakerX;
  /*****************************************************************************/ 
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapA"+myHolesNum]);
  console.log("markers.models=",markers.models); 
  /*****************************************************************************/
  /*****************************************************************************/ 
      
  }
  else if(area==2){
    //画缺B位置
        //画应力孔缺 B位置   H W D  width=w-d/2  
       //20240708 新画法
       var myShgGapB = {
  
         paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xb, yb], [xb, yb-w0]),
           "ArcBottom": new makerjs.paths.Arc([xb, yb-w0], [xb+h, yb-w0],r,true,false),
           "RightLine": new makerjs.paths.Line([xb+h, yb-w0], [xb+h, yb])
         }
         
         };
         myShgGapB.origin=[0-h/2, 0]; 
       //20240708 新画法
     //画应力孔缺标记 B位置 
  var myShgGapBMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapBMaker.origin = [xb-h/2,yb];
  var myShgGapBMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapBMakerW.origin = [xb-h/2,yb];
  var myShgGapBMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapBMakerH.origin = [xb-h/2,yb];
  var myShgGapBMakerX = new makerjs.models.Square(0); //Y的起始点        
  myShgGapBMakerX.origin = [xb-h/2,yb];
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapBMaker, new makerjs.paths.Line([-x0,0], [-x0, markShiftFour]));//Y轴加长标线

  makerjs.model.addPath(myShgGapBMaker, new makerjs.paths.Line([-h0,-w-markShiftTwo], [-h0,-w+markShiftTwo]));//Y轴方向短线 W
  makerjs.model.addPath(myShgGapBMaker, new makerjs.paths.Line([h+h0,-w-markShiftTwo], [h+h0,-w+markShiftTwo]));//Y轴方向短线 W

  makerjs.model.addPath(myShgGapBMaker, new makerjs.paths.Line([0,0], [0, 0+markShiftFour]));//H轴加长标线
  makerjs.model.addPath(myShgGapBMaker, new makerjs.paths.Line([0+h,0], [0+h, 0+markShiftFour]));//H轴加长标线

  makerjs.model.addPath(myShgGapBMaker, new makerjs.paths.Line([0-h0,-w], [0-h0-markShiftThree, 0-w]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapBMaker, new makerjs.paths.Line([-h0,-w], [-h0+d, -w]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapBMaker,TxtD, [-h0,-w-markShift], [-h0+d, -w-markShift]);//D文字标注

  makerjs.model.addPath(myShgGapBMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myShgGapBMakerX,TxtX, [-x0,0+markShiftFour], [0, 0+markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapBMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapBMakerH,TxtH,[0,0+markShiftThree], [h, 0+markShiftThree]);//H文字标注
  
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapBMakerW, new makerjs.paths.Line([-h0-markShift,-w], [-h0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(myShgGapBMakerW, TxtW, [-h0-markShiftTwo,-w], [-h0-markShiftTwo, 0]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myShgGapB"+myHolesNum]=myShgGapB;
  markers.models["myShgGapB"+myHolesNum]=myShgGapB;
  markers.models["myShgGapBMaker"+myHolesNum]=myShgGapBMaker;
  markers.models["myShgGapBMakerW"+myHolesNum]=myShgGapBMakerW;
  markers.models["myShgGapBMakerH"+myHolesNum]=myShgGapBMakerH;
  markers.models["myShgGapBMakerX"+myHolesNum]=myShgGapBMakerX;
  /*****************************************************************************/ 
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapB"+myHolesNum]);
  console.log("markers.models=",markers.models); 
  /*****************************************************************************/
  /*****************************************************************************/ 
  
            
  }
  else if(area==3){
   //画缺 C位置
        //画应力孔缺 C位置   H W D  width=w-d/2    
        /*  
        var myShgGapCRect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapCRect.origin=[xa-5,ya-h];     
        var myShgGapCHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapCHoles.origin=[xa+w,ya-h/2];
        var myShgGapC=new makerjs.model.combineUnion(myShgGapCRect, myShgGapCHoles);
        */
       //20240708 新画法
       var myShgGapC = {
  
         paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xc, yc], [xc, yc-w0]),
           "ArcBottom": new makerjs.paths.Arc([xc, yc-w0], [xc+h, yc-w0],r,true,false),
           "RightLine": new makerjs.paths.Line([xc+h, yc-w0], [xc+h, yc])
         }
         
         };
       //20240708 新画法
       myShgGapC.origin=[0-h, 0]; 
     //画应力孔缺标记 A位置 
  var myShgGapCMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapCMaker.origin = [xc-h, yc];
  var myShgGapCMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapCMakerW.origin = [xc-h, yc];
  var myShgGapCMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapCMakerH.origin = [xc-h, yc];
  var myShgGapCMakerX = new makerjs.models.Square(0); //Y的起始点        
  myShgGapCMakerX.origin = [xc-h, yc];
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([x0+h,0], [x0+h, markShiftFour]));//Y轴加长标线

  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([-h0,-w-markShiftTwo], [-h0,-w+markShiftTwo]));//Y轴方向短线 W
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([h+h0,-w-markShiftTwo], [h+h0,-w+markShiftTwo]));//Y轴方向短线 W

  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([0,0], [0, 0+markShiftFour]));//H轴加长标线
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([0+h,0], [0+h, 0+markShiftFour]));//H轴加长标线

  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([0-h0,-w], [0-h0-markShiftThree, 0-w]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([-h0,-w], [-h0+d, -w]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapCMaker,TxtD, [-h0,-w-markShift], [-h0+d, -w-markShift]);//D文字标注

  makerjs.model.addPath(myShgGapCMakerX, new makerjs.paths.Line([h,0+markShiftTwo], [h+x0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myShgGapCMakerX,TxtX, [h,0+markShiftThree], [h+x0, 0+markShiftThree]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapCMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapCMakerH,TxtH,[0,0+markShiftThree], [h, 0+markShiftThree]);//H文字标注
  
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapCMakerW, new makerjs.paths.Line([-h0-markShift,-w], [-h0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(myShgGapCMakerW, TxtW, [-h0-markShiftTwo,-w], [-h0-markShiftTwo, 0]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myShgGapC"+myHolesNum]=myShgGapC;
  markers.models["myShgGapC"+myHolesNum]=myShgGapC;
  markers.models["myShgGapCMaker"+myHolesNum]=myShgGapCMaker;
  markers.models["myShgGapCMakerW"+myHolesNum]=myShgGapCMakerW;
  markers.models["myShgGapCMakerH"+myHolesNum]=myShgGapCMakerH;
  markers.models["myShgGapCMakerX"+myHolesNum]=myShgGapCMakerX;
  /*****************************************************************************/ 
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapC"+myHolesNum]);
  console.log("markers.models=",markers.models); 
  /*****************************************************************************/
  /*****************************************************************************/ 
            
  }
  else if(area==7){
  //画缺 G位置
        //画应力孔缺 G位置   H W D  width=w-d/2    
        /*  
        var myShgGapGRect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapGRect.origin=[xa-5,ya-h];     
        var myShgGapGHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapGHoles.origin=[xa+w,ya-h/2];
        var myShgGapG=new makerjs.model.combineUnion(myShgGapGRect, myShgGapGHoles);
        */
       //20240708 新画法
       var myShgGapG = {
  
         paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xg, yg], [xg, yg+w0]),
           "ArcTop": new makerjs.paths.Arc([xg, yg+w0], [xg+h, yg+w0],r,true,true),
           "RightLine": new makerjs.paths.Line([xg+h, yg+w0], [xg+h, yg])
         }
         
         };
       //20240708 新画法
       myShgGapG.origin=[0, 0];
     //画应力孔缺标记 G位置 
  var myShgGapGMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapGMaker.origin = [xg, yg];
  var myShgGapGMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapGMakerW.origin = [xg, yg];
  var myShgGapGMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapGMakerH.origin = [xg, yg];
  var myShgGapGMakerX = new makerjs.models.Square(0); //Y的起始点        
  myShgGapGMakerX.origin = [xg, yg];
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([-x0,0], [-x0, -markShiftFour]));//Y轴加长标线

  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([-h0,+w+markShiftTwo], [-h0,+w-markShiftTwo]));//Y轴方向短线 W
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([h+h0,+w+markShiftTwo], [h+h0,+w-markShiftTwo]));//Y轴方向短线 W

  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([0,0], [0, 0-markShiftFour]));//H轴加长标线
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([0+h,0], [0+h, 0-markShiftFour]));//H轴加长标线

  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([0-h0,+w], [0-h0-markShiftThree, 0+w]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([-h0,+w], [-h0+d, +w]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapGMaker,TxtD, [-h0,+w+markShift], [-h0+d, +w+markShift]);//D文字标注

  makerjs.model.addPath(myShgGapGMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myShgGapGMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapGMakerH, new makerjs.paths.Line([0,0-markShiftThree], [h, 0-markShiftThree]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapGMakerH,TxtH,[0,0-markShiftFour-markShift], [h, 0-markShiftFour-markShift]);//H文字标注
  
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapGMakerW, new makerjs.paths.Line([-h0-markShift,+w], [-h0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(myShgGapGMakerW, TxtW, [-h0-markShiftTwo, 0], [-h0-markShiftTwo,+w]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myShgGapG"+myHolesNum]=myShgGapG;
  markers.models["myShgGapG"+myHolesNum]=myShgGapG;
  markers.models["myShgGapGMaker"+myHolesNum]=myShgGapGMaker;
  markers.models["myShgGapGMakerW"+myHolesNum]=myShgGapGMakerW;
  markers.models["myShgGapGMakerH"+myHolesNum]=myShgGapGMakerH;
  markers.models["myShgGapGMakerX"+myHolesNum]=myShgGapGMakerX;
  /*****************************************************************************/ 
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapG"+myHolesNum]);
  console.log("markers.models=",markers.models); 
  /*****************************************************************************/
  /*****************************************************************************/ 
      
  }
  else if(area==8){
  //画缺 H位置
        //画应力孔缺 H位置   H W D  width=w-d/2    
        /*  
        var myShgGapHRect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapHRect.origin=[xa-5,ya-h];     
        var myShgGapHHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapHHoles.origin=[xa+w,ya-h/2];
        var myShgGapH=new makerjs.model.combineUnion(myShgGapHRect, myShgGapHHoles);
        */
       //20240708 新画法
       var myShgGapH = {
  
         paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xh, yh], [xh, yh+w0]),
           "ArcTop": new makerjs.paths.Arc([xh, yh+w0], [xh+h, yh+w0],r,true,true),
           "RightLine": new makerjs.paths.Line([xh+h, yh+w0], [xh+h, yh])
         }
         
         };
       //20240708 新画法
       myShgGapH.origin=[0-h/2, 0];
     //画应力孔缺标记 H位置 
  var myShgGapHMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapHMaker.origin = [xh-h/2, yh];
  var myShgGapHMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapHMakerW.origin = [xh-h/2, yh];
  var myShgGapHMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapHMakerH.origin = [xh-h/2, yh];
  var myShgGapHMakerX = new makerjs.models.Square(0); //Y的起始点        
  myShgGapHMakerX.origin = [xh-h/2, yh];
  /*****************************************************************************/
  //makerjs.model.addPath(myShgGapHMaker, new makerjs.paths.Line([-x0,0], [-x0, -markShiftFour]));//Y轴加长标线

  makerjs.model.addPath(myShgGapHMaker, new makerjs.paths.Line([-h0,+w+markShiftTwo], [-h0,+w-markShiftTwo]));//Y轴方向短线 W
  makerjs.model.addPath(myShgGapHMaker, new makerjs.paths.Line([h+h0,+w+markShiftTwo], [h+h0,+w-markShiftTwo]));//Y轴方向短线 W

 // makerjs.model.addPath(myShgGapHMaker, new makerjs.paths.Line([0,0], [0, 0-markShiftFour]));//H轴加长标线
  //makerjs.model.addPath(myShgGapHMaker, new makerjs.paths.Line([0+h,0], [0+h, 0-markShiftFour]));//H轴加长标线

  makerjs.model.addPath(myShgGapHMaker, new makerjs.paths.Line([0-h0,+w], [0-h0-markShiftThree, 0+w]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapHMaker, new makerjs.paths.Line([-h0,+w], [-h0+d, +w]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapHMaker,TxtD, [-h0,+w+markShift], [-h0+d, +w+markShift]);//D文字标注

  makerjs.model.addPath(myShgGapHMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myShgGapHMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapHMakerH, new makerjs.paths.Line([0,0+markShiftThree], [h, 0+markShiftThree]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapHMakerH,TxtH,[0,0+markShiftFour+markShift], [h, 0+markShiftFour+markShift]);//H文字标注
  
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapHMakerW, new makerjs.paths.Line([-h0-markShift,+w], [-h0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(myShgGapHMakerW, TxtW, [-h0-markShiftTwo, 0], [-h0-markShiftTwo,+w]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myShgGapH"+myHolesNum]=myShgGapH;
  markers.models["myShgGapH"+myHolesNum]=myShgGapH;
  markers.models["myShgGapHMaker"+myHolesNum]=myShgGapHMaker;
  markers.models["myShgGapHMakerW"+myHolesNum]=myShgGapHMakerW;
  markers.models["myShgGapHMakerH"+myHolesNum]=myShgGapHMakerH;
  markers.models["myShgGapHMakerX"+myHolesNum]=myShgGapHMakerX;
  /*****************************************************************************/ 
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapH"+myHolesNum]);
  console.log("markers.models=",markers.models); 
  /*****************************************************************************/
  /*****************************************************************************/
      
  }
  else if(area==9){
  //画缺 I位置
        //画应力孔缺 I位置   H W D  width=w-d/2    
        /*  
        var myShgGapIRect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapIRect.origin=[xa-5,ya-h];     
        var myShgGapIHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapIHoles.origin=[xa+w,ya-h/2];
        var myShgGapI=new makerjs.model.combineUnion(myShgGapIRect, myShgGapIHoles);
        */
       //20240708 新画法
       var myShgGapI = {
  
         paths: {
           // "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
           //(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean)
           "LeftLine": new makerjs.paths.Line([xi, yi], [xi, yi+w0]),
           "ArcTop": new makerjs.paths.Arc([xi, yi+w0], [xi+h, yi+w0],r,true,true),
           "RightLine": new makerjs.paths.Line([xi+h, yi+w0], [xi+h, yi])
         }
         
         };
       //20240708 新画法
       myShgGapI.origin=[0-h, 0];
     //画应力孔缺标记 I位置 
  var myShgGapIMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapIMaker.origin = [xi-h, yi];
  var myShgGapIMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapIMakerW.origin = [xi-h, yi];
  var myShgGapIMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapIMakerH.origin = [xi-h, yi];
  var myShgGapIMakerX = new makerjs.models.Square(0); //Y的起始点        
  myShgGapIMakerX.origin = [xi-h, yi];
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([h+x0,0], [h+x0, -markShiftFour]));//Y轴加长标线

  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([-h0,+w+markShiftTwo], [-h0,+w-markShiftTwo]));//Y轴方向短线 W
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([h+h0,+w+markShiftTwo], [h+h0,+w-markShiftTwo]));//Y轴方向短线 W

  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([0,0], [0, 0-markShiftFour]));//H轴加长标线
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([0+h,0], [0+h, 0-markShiftFour]));//H轴加长标线

  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([0-h0,+w], [0-h0-markShiftThree, 0+w]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([-h0,+w], [-h0+d, +w]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapIMaker,TxtD, [-h0,+w+markShift], [-h0+d, +w+markShift]);//D文字标注

  makerjs.model.addPath(myShgGapIMakerX, new makerjs.paths.Line([h,0-markShiftTwo], [h+x0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myShgGapIMakerX,TxtX, [h,0-markShiftFour], [h+x0, 0-markShiftFour]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapIMakerH, new makerjs.paths.Line([0,0-markShiftThree], [h, 0-markShiftThree]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapIMakerH,TxtH,[0,0-markShiftFour-markShift], [h, 0-markShiftFour-markShift]);//H文字标注
  
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapIMakerW, new makerjs.paths.Line([-h0-markShift,+w], [-h0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(myShgGapIMakerW, TxtW, [-h0-markShiftTwo, 0], [-h0-markShiftTwo,+w]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myShgGapI"+myHolesNum]=myShgGapI;
  markers.models["myShgGapI"+myHolesNum]=myShgGapI;
  markers.models["myShgGapIMaker"+myHolesNum]=myShgGapIMaker;
  markers.models["myShgGapIMakerW"+myHolesNum]=myShgGapIMakerW;
  markers.models["myShgGapIMakerH"+myHolesNum]=myShgGapIMakerH;
  markers.models["myShgGapIMakerX"+myHolesNum]=myShgGapIMakerX;
  /*****************************************************************************/ 
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapI"+myHolesNum]);
  console.log("markers.models=",markers.models); 
  /*****************************************************************************/
      
  }

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default StressHoleGap;