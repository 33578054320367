//19、三孔4 B
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles2(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
 if(area==1){
      //画孔A位置孔
            //标记三孔4 A位置孔右上
            var myThHoles4ARightup= new makerjs.models.Holes(d/2, [[xa, ya]]);
            var myThHoles4MarkARightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkARightup.origin = [xa, ya];      
            var myThHoles4XMarkARightup = new makerjs.models.Square(0);      
            myThHoles4XMarkARightup.origin =[xa, ya];        
            var myThHoles4YMarkARightup = new makerjs.models.Square(0);      
            myThHoles4YMarkARightup.origin =[xa, ya];      
            var myThHoles4HMarkARightup = new makerjs.models.Square(0);      
            myThHoles4HMarkARightup.origin =[xa, ya];   
      /*****************************************************************************/       
      makerjs.model.addPath(myThHoles4MarkARightup, new makerjs.paths.Line([0,y0], [0,y0+markShiftFour]));//直径标线      
      makerjs.model.addPath(myThHoles4MarkARightup, new makerjs.paths.Line([-x0,y0], [-x0,y0+markShiftFour]));//直径标线
      makerjs.model.addPath(myThHoles4MarkARightup, new makerjs.paths.Line([0,-h], [0, 0]));//直径标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4XMarkARightup, new makerjs.paths.Line([-x0,y0+markShift], [0, y0+markShift]));//X短标线
      makerjs.model.addCaption(myThHoles4XMarkARightup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4YMarkARightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
      makerjs.model.addCaption(myThHoles4YMarkARightup, TxtY, [0-markShift,0], [0-markShift, y0]);//X文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4HMarkARightup, new makerjs.paths.Line([0,-h], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHoles4HMarkARightup, TxtH, [0-markShift,-h], [0-markShift, 0]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔4D位置孔左下
       var myThHoles4ALeftdn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
            var myThHoles4MarkALeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkALeftdn.origin = [xa, ya-h];       
            var myThHoles4WMarkALeftdn = new makerjs.models.Square(0);      
            myThHoles4WMarkALeftdn.origin = [xa, ya-h];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles4WMarkALeftdn, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHoles4WMarkALeftdn, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔4 A位置孔左上
       var myThHoles4ALeftup= new makerjs.models.Holes(d/2, [[xa+w, ya-h]]);
            var myThHoles4MarkALeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkALeftup.origin = [xa+w, ya-h]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles4ALeftdn"+myHolesNum]=myThHoles4ALeftdn;
      markers.models["myThHoles4ALeftdn"+myHolesNum]=myThHoles4ALeftdn;
      markers.models["myThHoles4MarkALeftdn"+myHolesNum]=myThHoles4MarkALeftdn;
      markers.models["myThHoles4WMarkALeftdn"+myHolesNum]=myThHoles4WMarkALeftdn;
      /*****************************************************************************/ 
      plate.models["myThHoles4ARightup"+myHolesNum]=myThHoles4ARightup;
      markers.models["myThHoles4ARightup"+myHolesNum]=myThHoles4ARightup;
      markers.models["myThHoles4MarkARightup"+myHolesNum]=myThHoles4MarkARightup;
      markers.models["myThHoles4XMarkARightup"+myHolesNum]=myThHoles4XMarkARightup;
      markers.models["myThHoles4YMarkARightup"+myHolesNum]=myThHoles4YMarkARightup;
      markers.models["myThHoles4HMarkARightup"+myHolesNum]=myThHoles4HMarkARightup;
      /*****************************************************************************/ 
      plate.models["myThHoles4ALeftup"+myHolesNum]=myThHoles4ALeftup;
      markers.models["myThHoles4ALeftup"+myHolesNum]=myThHoles4ALeftup;
      markers.models["myThHoles4MarkALeftup"+myHolesNum]=myThHoles4MarkALeftup;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
         
    }else if(area==2){
      //画孔B位置孔
            //标记三孔4 B位置孔右上
            var myThHoles4BRightup= new makerjs.models.Holes(d/2, [[xb-w/2, yb]]);
            var myThHoles4MarkBRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkBRightup.origin = [xb-w/2, yb];      
            var myThHoles4YMarkBRightup = new makerjs.models.Square(0);      
            myThHoles4YMarkBRightup.origin =[xb-w/2, yb];      
            var myThHoles4HMarkBRightup = new makerjs.models.Square(0);      
            myThHoles4HMarkBRightup.origin =[xb-w/2, yb];   
      /*****************************************************************************/ 
      
      makerjs.model.addPath(myThHoles4MarkBRightup, new makerjs.paths.Line([0,-h], [0, 0]));//直径标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkBRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkBRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4YMarkBRightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
      makerjs.model.addCaption(myThHoles4YMarkBRightup, TxtY, [0-markShift,0], [0-markShift, y0]);//X文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4HMarkBRightup, new makerjs.paths.Line([0,-h], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHoles4HMarkBRightup, TxtH, [0-markShift,-h], [0-markShift, 0]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔4D位置孔左下
       var myThHoles4BLeftdn= new makerjs.models.Holes(d/2, [[xb-w/2, yb-h]]);
            var myThHoles4MarkBLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkBLeftdn.origin = [xb-w/2, yb-h];       
            var myThHoles4WMarkBLeftdn = new makerjs.models.Square(0);      
            myThHoles4WMarkBLeftdn.origin = [xb-w/2, yb-h];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkBLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkBLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles4WMarkBLeftdn, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHoles4WMarkBLeftdn, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔4 B位置孔左上
       var myThHoles4BLeftup= new makerjs.models.Holes(d/2, [[xb+w/2, yb-h]]);
            var myThHoles4MarkBLeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkBLeftup.origin = [xb+w/2, yb-h]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkBLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkBLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles4BLeftdn"+myHolesNum]=myThHoles4BLeftdn;
      markers.models["myThHoles4BLeftdn"+myHolesNum]=myThHoles4BLeftdn;
      markers.models["myThHoles4MarkBLeftdn"+myHolesNum]=myThHoles4MarkBLeftdn;
      markers.models["myThHoles4WMarkBLeftdn"+myHolesNum]=myThHoles4WMarkBLeftdn;
      /*****************************************************************************/ 
      plate.models["myThHoles4BRightup"+myHolesNum]=myThHoles4BRightup;
      markers.models["myThHoles4BRightup"+myHolesNum]=myThHoles4BRightup;
      markers.models["myThHoles4MarkBRightup"+myHolesNum]=myThHoles4MarkBRightup;
      markers.models["myThHoles4YMarkBRightup"+myHolesNum]=myThHoles4YMarkBRightup;
      markers.models["myThHoles4HMarkBRightup"+myHolesNum]=myThHoles4HMarkBRightup;
      /*****************************************************************************/ 
      plate.models["myThHoles4BLeftup"+myHolesNum]=myThHoles4BLeftup;
      markers.models["myThHoles4BLeftup"+myHolesNum]=myThHoles4BLeftup;
      markers.models["myThHoles4MarkBLeftup"+myHolesNum]=myThHoles4MarkBLeftup;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
         
    }else if(area==3){
            //画孔C位置孔
                  //标记三孔4 C位置孔右上
                  var myThHoles4CRightup= new makerjs.models.Holes(d/2, [[xc, yc]]);
                  var myThHoles4MarkCRightup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles4MarkCRightup.origin = [xc, yc];      
                  var myThHoles4XMarkCRightup = new makerjs.models.Square(0);      
                  myThHoles4XMarkCRightup.origin =[xc, yc];       
                  var myThHoles4YMarkCRightup = new makerjs.models.Square(0);      
                  myThHoles4YMarkCRightup.origin =[xc, yc];      
                  var myThHoles4HMarkCRightup = new makerjs.models.Square(0);      
                  myThHoles4HMarkCRightup.origin =[xc, yc];   
            /*****************************************************************************/             
            makerjs.model.addPath(myThHoles4MarkCRightup, new makerjs.paths.Line([0,y0], [0, y0+markShiftFour]));//直径标线           
            makerjs.model.addPath(myThHoles4MarkCRightup, new makerjs.paths.Line([0+x0,y0], [0+x0, y0+markShiftFour]));//直径标线
            makerjs.model.addPath(myThHoles4MarkCRightup, new makerjs.paths.Line([0,-h], [0, 0]));//直径标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles4MarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles4MarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles4XMarkCRightup, new makerjs.paths.Line([0,y0+markShift], [0+x0, y0+markShift]));//X短标线
            makerjs.model.addCaption(myThHoles4XMarkCRightup, TxtX, [0,y0+markShiftThree], [0+x0, y0+markShiftThree]);//X文字标注
            /*****************************************************************************/    
            makerjs.model.addPath(myThHoles4YMarkCRightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
            makerjs.model.addCaption(myThHoles4YMarkCRightup, TxtY, [0-markShift,0], [0-markShift, y0]);//X文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles4HMarkCRightup, new makerjs.paths.Line([0,-h], [0, 0]));//X短标线
            makerjs.model.addCaption(myThHoles4HMarkCRightup, TxtH, [0-markShift,-h], [0-markShift, 0]);//X文字标注
            /*****************************************************************************/  
            /*****************************************************************************/ 
             //标记三孔4D位置孔左下
             var myThHoles4CLeftdn= new makerjs.models.Holes(d/2, [[xc-w, yc-h]]);
                  var myThHoles4MarkCLeftdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles4MarkCLeftdn.origin = [xc-w, yc-h];       
                  var myThHoles4WMarkCLeftdn = new makerjs.models.Square(0);      
                  myThHoles4WMarkCLeftdn.origin = [xc-w, yc-h];
            /*****************************************************************************/ 
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles4MarkCLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles4MarkCLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            makerjs.model.addPath(myThHoles4WMarkCLeftdn, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
            makerjs.model.addCaption(myThHoles4WMarkCLeftdn, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
            /*****************************************************************************/ 
            /*****************************************************************************/ 
             //标记三孔4 C位置孔左上
             var myThHoles4CLeftup= new makerjs.models.Holes(d/2, [[xc, yc-h]]);
                  var myThHoles4MarkCLeftup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles4MarkCLeftup.origin = [xc, yc-h]; 
            /*****************************************************************************/                        
            makerjs.model.addPath(myThHoles4MarkCLeftup, new makerjs.paths.Line([0,0], [0, y0+h]));//直径标线  
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles4MarkCLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles4MarkCLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            /*****************************************************************************/
            plate.models["myThHoles4CLeftdn"+myHolesNum]=myThHoles4CLeftdn;
            markers.models["myThHoles4CLeftdn"+myHolesNum]=myThHoles4CLeftdn;
            markers.models["myThHoles4MarkCLeftdn"+myHolesNum]=myThHoles4MarkCLeftdn;
            markers.models["myThHoles4WMarkCLeftdn"+myHolesNum]=myThHoles4WMarkCLeftdn;
            /*****************************************************************************/ 
            plate.models["myThHoles4CRightup"+myHolesNum]=myThHoles4CRightup;
            markers.models["myThHoles4CRightup"+myHolesNum]=myThHoles4CRightup;
            markers.models["myThHoles4MarkCRightup"+myHolesNum]=myThHoles4MarkCRightup;
            markers.models["myThHoles4XMarkCRightup"+myHolesNum]=myThHoles4XMarkCRightup;
            markers.models["myThHoles4YMarkCRightup"+myHolesNum]=myThHoles4YMarkCRightup;
            markers.models["myThHoles4HMarkCRightup"+myHolesNum]=myThHoles4HMarkCRightup;
            /*****************************************************************************/ 
            plate.models["myThHoles4CLeftup"+myHolesNum]=myThHoles4CLeftup;
            markers.models["myThHoles4CLeftup"+myHolesNum]=myThHoles4CLeftup;
            markers.models["myThHoles4MarkCLeftup"+myHolesNum]=myThHoles4MarkCLeftup;
            /*****************************************************************************/ 
            /*****************************************************************************/ 
               
    }else if(area==4){
      //画孔D位置孔
            //标记三孔4 D位置孔右上
            var myThHoles4DRightup= new makerjs.models.Holes(d/2, [[xd, yd+h/2]]);
            var myThHoles4MarkDRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkDRightup.origin = [xd, yd+h/2];      
            var myThHoles4XMarkDRightup = new makerjs.models.Square(0);      
            myThHoles4XMarkDRightup.origin =[xd, yd+h/2];        
           // var myThHoles4YMarkDRightup = new makerjs.models.Square(0);      
            //myThHoles4YMarkDRightup.origin =[xd, yd+h/2];      
            var myThHoles4HMarkDRightup = new makerjs.models.Square(0);      
            myThHoles4HMarkDRightup.origin =[xd, yd+h/2];   
      /*****************************************************************************/       
      makerjs.model.addPath(myThHoles4MarkDRightup, new makerjs.paths.Line([0,yd-h/2], [0,yd-h/2+markShiftFour]));//直径标线      
      makerjs.model.addPath(myThHoles4MarkDRightup, new makerjs.paths.Line([-x0,yd-h/2], [-x0,yd-h/2+markShiftFour]));//直径标线
      makerjs.model.addPath(myThHoles4MarkDRightup, new makerjs.paths.Line([0,-h], [0, 0]));//直径标线
      makerjs.model.addPath(myThHoles4MarkDRightup, new makerjs.paths.Line([0,0], [0, yd]));//直径标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkDRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkDRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4XMarkDRightup, new makerjs.paths.Line([-x0,yd-h/2+markShift], [0, yd-h/2+markShift]));//X短标线
      makerjs.model.addCaption(myThHoles4XMarkDRightup, TxtX, [-x0,yd-h/2+markShiftThree], [0, yd-h/2+markShiftThree]);//X文字标注
      /*****************************************************************************/   
     // makerjs.model.addPath(myThHoles4YMarkDRightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
     // makerjs.model.addCaption(myThHoles4YMarkDRightup, TxtY, [0-markShift,0], [0-markShift, y0]);//X文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4HMarkDRightup, new makerjs.paths.Line([0,-h], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHoles4HMarkDRightup, TxtH, [0-markShift,-h], [0-markShift, 0]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔4D位置孔左下
       var myThHoles4DLeftdn= new makerjs.models.Holes(d/2, [[xd+w, yd+h/2]]);
            var myThHoles4MarkDLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkDLeftdn.origin = [xd+w, yd+h/2];       
            var myThHoles4WMarkDLeftdn = new makerjs.models.Square(0);      
            myThHoles4WMarkDLeftdn.origin = [xd+w, yd+h/2];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkDLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkDLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles4WMarkDLeftdn, new makerjs.paths.Line([-w,0], [0, 0]));//W短标线
      makerjs.model.addCaption(myThHoles4WMarkDLeftdn, TxtW, [-w,0+markShift], [0, 0+markShift]);//W文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔4 D位置孔左上
       var myThHoles4DLeftup= new makerjs.models.Holes(d/2, [[xd, yd-h/2]]);
            var myThHoles4MarkDLeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkDLeftup.origin = [xd, yd-h/2]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkDLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkDLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles4DLeftdn"+myHolesNum]=myThHoles4DLeftdn;
      markers.models["myThHoles4DLeftdn"+myHolesNum]=myThHoles4DLeftdn;
      markers.models["myThHoles4MarkDLeftdn"+myHolesNum]=myThHoles4MarkDLeftdn;
      markers.models["myThHoles4WMarkDLeftdn"+myHolesNum]=myThHoles4WMarkDLeftdn;
      /*****************************************************************************/ 
      plate.models["myThHoles4DRightup"+myHolesNum]=myThHoles4DRightup;
      markers.models["myThHoles4DRightup"+myHolesNum]=myThHoles4DRightup;
      markers.models["myThHoles4MarkDRightup"+myHolesNum]=myThHoles4MarkDRightup;
      markers.models["myThHoles4XMarkDRightup"+myHolesNum]=myThHoles4XMarkDRightup;
      //markers.models["myThHoles4YMarkDRightup"+myHolesNum]=myThHoles4YMarkDRightup;
      markers.models["myThHoles4HMarkDRightup"+myHolesNum]=myThHoles4HMarkDRightup;
      /*****************************************************************************/ 
      plate.models["myThHoles4DLeftup"+myHolesNum]=myThHoles4DLeftup;
      markers.models["myThHoles4DLeftup"+myHolesNum]=myThHoles4DLeftup;
      markers.models["myThHoles4MarkDLeftup"+myHolesNum]=myThHoles4MarkDLeftup;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
         
    }else if(area==5){
      //画孔E位置孔
            //标记三孔4 E位置孔右上
            var myThHoles4ERightup= new makerjs.models.Holes(d/2, [[xe-w/2, ye+h/2]]);
            var myThHoles4MarkERightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkERightup.origin = [xe-w/2, ye+h/2]; 
            var myThHoles4HMarkERightup = new makerjs.models.Square(0);      
            myThHoles4HMarkERightup.origin =[xe-w/2, ye+h/2];   
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4MarkERightup, new makerjs.paths.Line([0,-h], [0, 0]));//直径标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkERightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkERightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles4HMarkERightup, new makerjs.paths.Line([0,-h], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHoles4HMarkERightup, TxtH, [0-markShift,-h], [0-markShift, 0]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔4E位置孔左下
       var myThHoles4ELeftdn= new makerjs.models.Holes(d/2, [[xe+w/2, ye+h/2]]);
            var myThHoles4MarkELeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkELeftdn.origin = [xe+w/2, ye+h/2];       
            var myThHoles4WMarkELeftdn = new makerjs.models.Square(0);      
            myThHoles4WMarkELeftdn.origin = [xe+w/2, ye+h/2];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkELeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkELeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles4WMarkELeftdn, new makerjs.paths.Line([-w,0], [0, 0]));//W短标线
      makerjs.model.addCaption(myThHoles4WMarkELeftdn, TxtW, [-w,0+markShift], [0, 0+markShift]);//W文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔4 E位置孔左上
       var myThHoles4ELeftup= new makerjs.models.Holes(d/2, [[xe-w/2, ye-h/2]]);
            var myThHoles4MarkELeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkELeftup.origin = [xe-w/2, ye-h/2]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkELeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkELeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles4ELeftdn"+myHolesNum]=myThHoles4ELeftdn;
      markers.models["myThHoles4ELeftdn"+myHolesNum]=myThHoles4ELeftdn;
      markers.models["myThHoles4MarkELeftdn"+myHolesNum]=myThHoles4MarkELeftdn;
      markers.models["myThHoles4WMarkELeftdn"+myHolesNum]=myThHoles4WMarkELeftdn;
      /*****************************************************************************/ 
      plate.models["myThHoles4ERightup"+myHolesNum]=myThHoles4ERightup;
      markers.models["myThHoles4ERightup"+myHolesNum]=myThHoles4ERightup;
      markers.models["myThHoles4MarkERightup"+myHolesNum]=myThHoles4MarkERightup;
      markers.models["myThHoles4HMarkERightup"+myHolesNum]=myThHoles4HMarkERightup;
      /*****************************************************************************/ 
      plate.models["myThHoles4ELeftup"+myHolesNum]=myThHoles4ELeftup;
      markers.models["myThHoles4ELeftup"+myHolesNum]=myThHoles4ELeftup;
      markers.models["myThHoles4MarkELeftup"+myHolesNum]=myThHoles4MarkELeftup;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
         
    }else if(area==6){
      //画孔F位置孔
            //标记三孔4 F位置孔右上
            var myThHoles4FRightup= new makerjs.models.Holes(d/2, [[xf, yf+h/2]]);
            var myThHoles4MarkFRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkFRightup.origin = [xf, yf+h/2];      
            var myThHoles4XMarkFRightup = new makerjs.models.Square(0);      
            myThHoles4XMarkFRightup.origin =[xf, yf+h/2];  
      /*****************************************************************************/             
      makerjs.model.addPath(myThHoles4MarkFRightup, new makerjs.paths.Line([0,yf-h/2], [0, yf-h/2+markShiftFour]));//直径标线           
      makerjs.model.addPath(myThHoles4MarkFRightup, new makerjs.paths.Line([0+x0,yf-h/2], [0+x0, yf-h/2+markShiftFour]));//直径标线
      makerjs.model.addPath(myThHoles4MarkFRightup, new makerjs.paths.Line([0,0], [0, yf-h/2]));//直径标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4XMarkFRightup, new makerjs.paths.Line([0,yf-h/2+markShift], [x0, yf-h/2+markShift]));//X短标线
      makerjs.model.addCaption(myThHoles4XMarkFRightup, TxtX, [0,yf-h/2+markShiftThree], [x0, yf-h/2+markShiftThree]);//X文字标注
      /*****************************************************************************/ 
       //标记三孔4D位置孔左下
       var myThHoles4FLeftdn= new makerjs.models.Holes(d/2, [[xf-w, yf+h/2]]);
            var myThHoles4MarkFLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkFLeftdn.origin = [xf-w, yf+h/2];       
            var myThHoles4WMarkFLeftdn = new makerjs.models.Square(0);      
            myThHoles4WMarkFLeftdn.origin = [xf-w, yf+h/2];       
            var myThHoles4HMarkFLeftdn = new makerjs.models.Square(0);      
            myThHoles4HMarkFLeftdn.origin = [xf-w, yf+h/2];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkFLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkFLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles4WMarkFLeftdn, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHoles4WMarkFLeftdn, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4HMarkFLeftdn, new makerjs.paths.Line([w,-h], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHoles4HMarkFLeftdn, TxtH, [w-markShift,-h], [w-markShift, 0]);//X文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔4 F位置孔左上
       var myThHoles4FLeftup= new makerjs.models.Holes(d/2, [[xf, yf-h/2]]);
            var myThHoles4MarkFLeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkFLeftup.origin = [xf, yf-h/2]; 
      /*****************************************************************************/                        
      //makerjs.model.addPath(myThHoles4MarkFLeftup, new makerjs.paths.Line([0,0], [0, y0+h]));//直径标线  
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkFLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkFLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      plate.models["myThHoles4FLeftdn"+myHolesNum]=myThHoles4FLeftdn;
      markers.models["myThHoles4FLeftdn"+myHolesNum]=myThHoles4FLeftdn;
      markers.models["myThHoles4MarkFLeftdn"+myHolesNum]=myThHoles4MarkFLeftdn;
      markers.models["myThHoles4WMarkFLeftdn"+myHolesNum]=myThHoles4WMarkFLeftdn;
      markers.models["myThHoles4HMarkFLeftdn"+myHolesNum]=myThHoles4HMarkFLeftdn;      
      /*****************************************************************************/ 
      plate.models["myThHoles4FRightup"+myHolesNum]=myThHoles4FRightup;
      markers.models["myThHoles4FRightup"+myHolesNum]=myThHoles4FRightup;
      markers.models["myThHoles4MarkFRightup"+myHolesNum]=myThHoles4MarkFRightup;
      markers.models["myThHoles4XMarkFRightup"+myHolesNum]=myThHoles4XMarkFRightup;
      /*****************************************************************************/ 
      plate.models["myThHoles4FLeftup"+myHolesNum]=myThHoles4FLeftup;
      markers.models["myThHoles4FLeftup"+myHolesNum]=myThHoles4FLeftup;
      markers.models["myThHoles4MarkFLeftup"+myHolesNum]=myThHoles4MarkFLeftup;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
         
    }else if(area==7){
      //画孔G位置孔
            //标记三孔4 G位置孔右上
            var myThHoles4GRightup= new makerjs.models.Holes(d/2, [[xg, yg+h]]);
            var myThHoles4MarkGRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkGRightup.origin = [xg, yg+h];     
            var myThHoles4WMarkGRightup = new makerjs.models.Square(0);      
            myThHoles4WMarkGRightup.origin =[xg, yg+h];  
      /*****************************************************************************/  
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkGRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkGRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4WMarkGRightup, new makerjs.paths.Line([0,0], [w, 0]));//W短标线
      makerjs.model.addCaption(myThHoles4WMarkGRightup, TxtW, [0,0+markShift], [w, 0+markShift]);//W文字标注
      /*****************************************************************************/ 
       //标记三孔4D位置孔左下
       var myThHoles4GLeftdn= new makerjs.models.Holes(d/2, [[xg+w, yg+h]]);
            var myThHoles4MarkGLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkGLeftdn.origin = [xg+w, yg+h]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkGLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkGLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔4 G位置孔左上
       var myThHoles4GLeftup= new makerjs.models.Holes(d/2, [[xg, yg]]);
       var myThHoles4MarkGLeftup = new makerjs.models.Square(0); //孔的原点       
       myThHoles4MarkGLeftup.origin = [xg, yg]; 
       var myThHoles4HMarkGLeftup = new makerjs.models.Square(0); //孔的原点       
       myThHoles4HMarkGLeftup.origin = [xg, yg]; 
       var myThHoles4XMarkGLeftup = new makerjs.models.Square(0); //孔的原点       
       myThHoles4XMarkGLeftup.origin = [xg, yg]; 
       var myThHoles4YMarkGLeftup = new makerjs.models.Square(0); //孔的原点       
       myThHoles4YMarkGLeftup.origin = [xg, yg]; 
      /*****************************************************************************/                        
      makerjs.model.addPath(myThHoles4MarkGLeftup, new makerjs.paths.Line([-x0,0], [0, 0]));//直径标线                     
      makerjs.model.addPath(myThHoles4MarkGLeftup, new makerjs.paths.Line([0,0], [0, -y0]));//直径标线                      
      makerjs.model.addPath(myThHoles4MarkGLeftup, new makerjs.paths.Line([-x0,0], [-x0-markShiftFour, 0]));//直径标线                    
      makerjs.model.addPath(myThHoles4MarkGLeftup, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftFour, -y0]));//直径标线                      
      makerjs.model.addPath(myThHoles4MarkGLeftup, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftFour]));//直径标线                      
      makerjs.model.addPath(myThHoles4MarkGLeftup, new makerjs.paths.Line([0,-y0], [0, -y0-markShiftFour]));//直径标线  
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkGLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkGLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4XMarkGLeftup, new makerjs.paths.Line([-x0,-y0-markShiftTwo], [0, -y0-markShiftTwo]));//X短标线
      makerjs.model.addCaption(myThHoles4XMarkGLeftup, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4HMarkGLeftup, new makerjs.paths.Line([0,0], [0, h]));//X短标线
      makerjs.model.addCaption(myThHoles4HMarkGLeftup, TxtH, [0-markShift,0], [0-markShift, h]);//X文字标注w
      /*****************************************************************************/    
      makerjs.model.addPath(myThHoles4YMarkGLeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//X短标线
      makerjs.model.addCaption(myThHoles4YMarkGLeftup, TxtY,[-x0-markShiftFour,-y0], [-x0-markShiftFour, 0]);//X文字标注
      /*****************************************************************************/  
      plate.models["myThHoles4GLeftdn"+myHolesNum]=myThHoles4GLeftdn;
      markers.models["myThHoles4GLeftdn"+myHolesNum]=myThHoles4GLeftdn;
      markers.models["myThHoles4MarkGLeftdn"+myHolesNum]=myThHoles4MarkGLeftdn;      
      /*****************************************************************************/ 
      plate.models["myThHoles4GRightup"+myHolesNum]=myThHoles4GRightup;
      markers.models["myThHoles4GRightup"+myHolesNum]=myThHoles4GRightup;
      markers.models["myThHoles4MarkGRightup"+myHolesNum]=myThHoles4MarkGRightup;
      markers.models["myThHoles4WMarkGRightup"+myHolesNum]=myThHoles4WMarkGRightup;      
      /*****************************************************************************/ 
      plate.models["myThHoles4GLeftup"+myHolesNum]=myThHoles4GLeftup;
      markers.models["myThHoles4GLeftup"+myHolesNum]=myThHoles4GLeftup;
      markers.models["myThHoles4MarkGLeftup"+myHolesNum]=myThHoles4MarkGLeftup;
      markers.models["myThHoles4HMarkGLeftup"+myHolesNum]=myThHoles4HMarkGLeftup;
      markers.models["myThHoles4XMarkGLeftup"+myHolesNum]=myThHoles4XMarkGLeftup;
      markers.models["myThHoles4YMarkGLeftup"+myHolesNum]=myThHoles4YMarkGLeftup;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
         
    }else if(area==8){
      //画孔H位置孔
            //标记三孔 H位置孔左上
            var myThHoles4HLeftup= new makerjs.models.Holes(d/2, [[xh-w/2, yh]]);
            var myThHoles4MarkHLeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkHLeftup.origin = [xh-w/2, yh];      
            var myThHoles4YMarkHLeftup = new makerjs.models.Square(0);      
            myThHoles4YMarkHLeftup.origin =[xh-w/2, yh];     
            var myThHoles4HMarkHLeftup = new makerjs.models.Square(0);      
            myThHoles4HMarkHLeftup.origin =[xh-w/2, yh];   
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkHLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkHLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles4YMarkHLeftup, new makerjs.paths.Line([0,0], [0, -y0]));//X短标线
      makerjs.model.addCaption(myThHoles4YMarkHLeftup, TxtY, [0-markShift, -y0], [0-markShift,0]);//X文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles4HMarkHLeftup, new makerjs.paths.Line([0,0], [0, h]));//X短标线
      makerjs.model.addCaption(myThHoles4HMarkHLeftup, TxtH, [0-markShift,0], [0-markShift, h]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔H位置孔右下
       var myThHoles4HRightdn= new makerjs.models.Holes(d/2, [[xh-w/2, yh+h]]);
            var myThHoles4MarkHRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkHRightdn.origin = [xh-w/2, yh+h];       
            var myThHoles4WMarkHRightdn = new makerjs.models.Square(0);      
            myThHoles4WMarkHRightdn.origin = [xh-w/2, yh+h];  
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkHRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkHRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles4WMarkHRightdn, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHoles4WMarkHRightdn, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔 H位置孔右上
       var myThHoles4HRightup= new makerjs.models.Holes(d/2, [[xh+w/2, yh+h]]);
            var myThHoles4MarkHRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles4MarkHRightup.origin = [xh+w/2, yh+h];
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkHRightup, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles4MarkHRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles4MarkHRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles4HRightdn"+myHolesNum]=myThHoles4HRightdn;
      markers.models["myThHoles4HRightdn"+myHolesNum]=myThHoles4HRightdn;
      markers.models["myThHoles4MarkHRightdn"+myHolesNum]=myThHoles4MarkHRightdn;
      markers.models["myThHoles4WMarkHRightdn"+myHolesNum]=myThHoles4WMarkHRightdn;
      /*****************************************************************************/ 
      plate.models["myThHoles4HLeftup"+myHolesNum]=myThHoles4HLeftup;
      markers.models["myThHoles4HLeftup"+myHolesNum]=myThHoles4HLeftup;
      markers.models["myThHoles4MarkHLeftup"+myHolesNum]=myThHoles4MarkHLeftup;
      markers.models["myThHoles4YMarkHLeftup"+myHolesNum]=myThHoles4YMarkHLeftup;
      markers.models["myThHoles4HMarkHLeftup"+myHolesNum]=myThHoles4HMarkHLeftup;
      /*****************************************************************************/ 
      plate.models["myThHoles4HRightup"+myHolesNum]=myThHoles4HRightup;
      markers.models["myThHoles4HRightup"+myHolesNum]=myThHoles4HRightup;
      markers.models["myThHoles4MarkHRightup"+myHolesNum]=myThHoles4MarkHRightup;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
           
    }else if(area==9){
            //画孔I位置孔
                  //标记三孔 I位置孔左上
                  var myThHoles4ILeftup= new makerjs.models.Holes(d/2, [[xi, yi]]);
                  var myThHoles4MarkILeftup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles4MarkILeftup.origin = [xi, yi];      
                  var myThHoles4XMarkILeftup = new makerjs.models.Square(0);      
                  myThHoles4XMarkILeftup.origin =[xi, yi];      
                  var myThHoles4YMarkILeftup = new makerjs.models.Square(0);      
                  myThHoles4YMarkILeftup.origin =[xi, yi];     
                  var myThHoles4HMarkILeftup = new makerjs.models.Square(0);      
                  myThHoles4HMarkILeftup.origin =[xi, yi];   
            /*****************************************************************************/             
            makerjs.model.addPath(myThHoles4MarkILeftup, new makerjs.paths.Line([0,0], [0,-y0]));//直径标线          
            makerjs.model.addPath(myThHoles4MarkILeftup, new makerjs.paths.Line([0,0], [x0,0]));//直径标线          
            makerjs.model.addPath(myThHoles4MarkILeftup, new makerjs.paths.Line([0,-y0], [0,-y0-markShiftFour]));//直径标线            
            makerjs.model.addPath(myThHoles4MarkILeftup, new makerjs.paths.Line([x0,-y0], [x0,-y0-markShiftFour]));//直径标线           
            makerjs.model.addPath(myThHoles4MarkILeftup, new makerjs.paths.Line([x0,0], [x0+markShiftFour,0]));//直径标线            
            makerjs.model.addPath(myThHoles4MarkILeftup, new makerjs.paths.Line([x0,-y0], [x0+markShiftFour,-y0]));//直径标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles4MarkILeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles4MarkILeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles4XMarkILeftup, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X短标线
            makerjs.model.addCaption(myThHoles4XMarkILeftup, TxtX,[0,-y0-markShiftFour], [x0, -y0-markShiftFour]);//X文字标注
            /*****************************************************************************/  
            makerjs.model.addPath(myThHoles4YMarkILeftup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -y0]));//X短标线
            makerjs.model.addCaption(myThHoles4YMarkILeftup, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, -y0]);//X文字标注
            /*****************************************************************************/  
            makerjs.model.addPath(myThHoles4HMarkILeftup, new makerjs.paths.Line([0,0], [0, h]));//X短标线
            makerjs.model.addCaption(myThHoles4HMarkILeftup, TxtH, [0-markShift,0], [0-markShift, h]);//X文字标注
            /*****************************************************************************/  
            /*****************************************************************************/ 
             //标记三孔I位置孔右下
             var myThHoles4IRightdn= new makerjs.models.Holes(d/2, [[xi, yi+h]]);
                  var myThHoles4MarkIRightdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles4MarkIRightdn.origin = [xi, yi+h];       
                  var myThHoles4WMarkIRightdn = new makerjs.models.Square(0);      
                  myThHoles4WMarkIRightdn.origin = [xi, yi+h];  
            /*****************************************************************************/ 
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles4MarkIRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles4MarkIRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            makerjs.model.addPath(myThHoles4WMarkIRightdn, new makerjs.paths.Line([-w,0], [0, 0]));//X短标线
            makerjs.model.addCaption(myThHoles4WMarkIRightdn, TxtW, [-w,0+markShift], [0, 0+markShift]);//X文字标注
            /*****************************************************************************/ 
            /*****************************************************************************/ 
            /*****************************************************************************/ 
             //标记三孔 I位置孔右上
             var myThHoles4IRightup= new makerjs.models.Holes(d/2, [[xi-w, yi+h]]);
                  var myThHoles4MarkIRightup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles4MarkIRightup.origin = [xi-w, yi+h];
            /*****************************************************************************/ 
            //makerjs.model.addPath(myThHoles4MarkIRightup, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles4MarkIRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles4MarkIRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            /*****************************************************************************/
            plate.models["myThHoles4IRightdn"+myHolesNum]=myThHoles4IRightdn;
            markers.models["myThHoles4IRightdn"+myHolesNum]=myThHoles4IRightdn;
            markers.models["myThHoles4MarkIRightdn"+myHolesNum]=myThHoles4MarkIRightdn;
            markers.models["myThHoles4WMarkIRightdn"+myHolesNum]=myThHoles4WMarkIRightdn;
            /*****************************************************************************/ 
            plate.models["myThHoles4ILeftup"+myHolesNum]=myThHoles4ILeftup;
            markers.models["myThHoles4ILeftup"+myHolesNum]=myThHoles4ILeftup;
            markers.models["myThHoles4MarkILeftup"+myHolesNum]=myThHoles4MarkILeftup;
            markers.models["myThHoles4XMarkILeftup"+myHolesNum]=myThHoles4XMarkILeftup;
            markers.models["myThHoles4YMarkILeftup"+myHolesNum]=myThHoles4YMarkILeftup;
            markers.models["myThHoles4HMarkILeftup"+myHolesNum]=myThHoles4HMarkILeftup;
            /*****************************************************************************/ 
            plate.models["myThHoles4IRightup"+myHolesNum]=myThHoles4IRightup;
            markers.models["myThHoles4IRightup"+myHolesNum]=myThHoles4IRightup;
            markers.models["myThHoles4MarkIRightup"+myHolesNum]=myThHoles4MarkIRightup;
            /*****************************************************************************/ 
            /*****************************************************************************/ 
                 
            }
/*****************************************************************************/ 

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default ThreeHoles2;