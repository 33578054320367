//缺1.3、蝴蝶
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ButterflyGap3(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var R1=inputParam.R1,R2=inputParam.R2,W=inputParam.W,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   r1 = R1*1*myZoom,
      r2= R2*1*myZoom,
      w= W*1*myZoom,
      w1= W1*1*myZoom,
      w2= W2*1*myZoom,
      h = H*1*myZoom,
      h1 = H1*1*myZoom,
      dd=r2/Math.sqrt(2),
      wd1=w1-w,
      wd2=w2-w1,
      hd=(h-h1)/2,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        r1 = R1*1,
           r2= R2*1,
           w= W*1,
           w1= W1*1,
           w2= W2*1,
           h = H*1,
           h1 = H1*1,
           dd=r2/Math.sqrt(2),
             x0 = X*1,
             y0 = Y*1;
             */
        if(r1!==0){
         var TxtR1="R1="+R1;
        }
        if(r2!==0){
         var TxtR2="R2="+R2;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(w1!==0){
         var TxtW1=W1;
        }
        if(w2!==0){
         var TxtW2=W2;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
        if(x0!==0){          
         var TxtX=X;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //1、3蝴蝶3 A位置   H W D  width=w-d/2  
        var myBfg3GapARect=new makerjs.models.Rectangle(h1,-w1-wd1); 
        myBfg3GapARect.origin=[xa+hd,ya-wd2+wd1+wd2];     
        var myBfg3GapABoltRectangle=new makerjs.models.BoltRectangle(h,-w2-wd2-wd1,r2);
        myBfg3GapABoltRectangle.origin=[xa,ya+wd1+wd2]; 
      var myBfg3GapA=new makerjs.model.combineUnion(myBfg3GapARect, myBfg3GapABoltRectangle);
     
     //画1、3蝴蝶3标记 A位置 
  var myBfg3GapAMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapAMakerW.origin = [xa,ya];
  var myBfg3GapAMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapAMakerW1.origin = [xa,ya];
  var myBfg3GapAMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapAMakerW2.origin = [xa,ya];
  var myBfg3GapAMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapAMakerH.origin = [xa,ya];
  var myBfg3GapAMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapAMakerH1.origin = [xa,ya];
  var myBfg3GapAMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapAMakerR1.origin = [xa,ya];
  var myBfg3GapAMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapAMakerR2.origin = [xa,ya];
  var myBfg3GapAMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapAMakerX.origin = [xa,ya];
  
  /*****************************************************************************/
  
  
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([-x0,0], [-x0,0+markShiftFour]));//Y轴方向短线  X  
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0,0], [0,0+markShiftFour]));//Y轴方向短线  X  
 makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([h1,0], [h1,0+markShiftFour]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0-markShiftFour,-y0-w1], [0,-y0-w1]));//X轴方向短线  W1
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0-2*markShiftFour,-y0-w], [0,-y0-w]));//X轴方向短线  W
 makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0+h,-w2], [0+h+markShiftFour, -w2]));//X轴加长标线 W2

 makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0,0-w2], [0, 0-w2-markShiftFour]));//X轴加长标线
 makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0+h,0-w2], [0+h, 0-w2-markShiftFour]));//X轴加长标线
  
  makerjs.model.addPath(myBfg3GapAMakerR1, new makerjs.paths.Line([0,0-y0-w], [0-dd-markHolesOneD,0-y0-w-dd-markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapAMakerR1, new makerjs.paths.Line([0-dd-markHolesOneD,0-y0-w-dd-markHolesOneD], [0-dd-markHolesOneD,0-y0-w-dd-markHolesOneD-markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapAMakerR1,TxtR1,[0-markShift-dd-markHolesOneD,0-y0-w-dd-markHolesOneD], [0-markShift-dd-markHolesOneD,0-y0-w-dd-markHolesOneD-markShiftFour]);//R1相关 X轴方向短线下
  
 makerjs.model.addPath(myBfg3GapAMakerR2, new makerjs.paths.Line([h,-w2], [h+dd+markHolesOneD,-w2-dd-markHolesOneD]));//R2相关 X轴方向短线上
 makerjs.model.addPath(myBfg3GapAMakerR2, new makerjs.paths.Line([h+dd+markHolesOneD,-w2-dd-markHolesOneD], [h+dd+markHolesOneD,-w2-dd-markHolesOneD-markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapAMakerR2,TxtR2,[h+dd+markHolesOneD+markShift,-w2-dd-markHolesOneD], [h+dd+markHolesOneD+markShift,-w2-dd-markHolesOneD-markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0-markShift-markShiftFour,0-y0], [0-markShift-markShiftFour, 0-y0-w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapAMakerW,TxtW, [0-markShiftThree-markShiftFour, 0-y0-w], [0-markShiftThree-markShiftFour,0-y0]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapAMakerW1, new makerjs.paths.Line([0-markShift,0-y0], [0-markShift, 0-y0-w1]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapAMakerW1,TxtW1, [0-markShiftThree, 0-y0-w1], [0-markShiftThree,0-y0]);//H1文字标注
  
  
 makerjs.model.addPath(myBfg3GapAMakerW2, new makerjs.paths.Line([0+h+markShiftThree,-w2], [0+h+markShiftThree, 0]));//W2轴方向标注短线 
 makerjs.model.addCaption(myBfg3GapAMakerW2,TxtW2, [0+h+markShiftFour,-w2], [0+h+markShiftFour, 0]);//W2文字标注
  
  makerjs.model.addPath(myBfg3GapAMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0,0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapAMakerX,TxtX,  [-x0,0+markShiftFour], [0,0+markShiftFour]);//Y文字标注
  
  // /*****************************************************************************/
  
  
  makerjs.model.addPath(myBfg3GapAMakerH, new makerjs.paths.Line([0,-y0-w2-markShiftThree], [h, -y0-w2-markShiftThree]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapAMakerH,TxtH, [0,-y0-w2-markShiftFour], [h, -y0-w2-markShiftFour]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapAMakerH1, new makerjs.paths.Line([0,-y0+markShiftF], [h1, -y0+markShiftF]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapAMakerH1,TxtH1, [0,-y0+markShiftTwo], [h1, -y0+markShiftTwo]);//W1文字标注
  
  // /*****************************************************************************/
  /*****************************************************************************/    
  
 plate.models["myBfg3GapA"+myHolesNum]=myBfg3GapA;
markers.models["myBfg3GapA"+myHolesNum]=myBfg3GapA;
  //markers.models["myBfg3GapARect"+myHolesNum]=myBfg3GapARect;
  //markers.models["myBfg3GapABoltRectangle"+myHolesNum]=myBfg3GapABoltRectangle;
  markers.models["myBfg3GapAMakerW"+myHolesNum]=myBfg3GapAMakerW;
  markers.models["myBfg3GapAMakerW1"+myHolesNum]=myBfg3GapAMakerW1;
  markers.models["myBfg3GapAMakerW2"+myHolesNum]=myBfg3GapAMakerW2;
  markers.models["myBfg3GapAMakerH1"+myHolesNum]=myBfg3GapAMakerH1;
  markers.models["myBfg3GapAMakerH"+myHolesNum]=myBfg3GapAMakerH;
  markers.models["myBfg3GapAMakerR1"+myHolesNum]=myBfg3GapAMakerR1;
  markers.models["myBfg3GapAMakerR2"+myHolesNum]=myBfg3GapAMakerR2;
  markers.models["myBfg3GapAMakerX"+myHolesNum]=myBfg3GapAMakerX;
  /*****************************************************************************/
 makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapA"+myHolesNum]); 
  
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapA"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsA"+myHolesNum] = fillets;
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
  
   }else if(area==2){
       //画缺 B位置
        //1、3蝴蝶3 B位置   H W D  width=w-d/2  
        var myBfg3GapBRect=new makerjs.models.Rectangle(h1,-w1-wd1); 
        myBfg3GapBRect.origin=[xb+hd-h1/2,yb-wd2+wd1+wd2];     
        var myBfg3GapBBoltRectangle=new makerjs.models.BoltRectangle(h,-w2-wd2-wd1,r2);
        myBfg3GapBBoltRectangle.origin=[xb-h1/2,yb+wd1+wd2]; 
      var myBfg3GapB=new makerjs.model.combineUnion(myBfg3GapBRect, myBfg3GapBBoltRectangle);
     
     //画1、3蝴蝶3标记 B位置 
  var myBfg3GapBMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapBMakerW.origin = [xb-h1/2,yb];
  var myBfg3GapBMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapBMakerW1.origin = [xb-h1/2,yb];
  var myBfg3GapBMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapBMakerW2.origin = [xb-h1/2,yb];
  var myBfg3GapBMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapBMakerH.origin = [xb-h1/2,yb];
  var myBfg3GapBMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapBMakerH1.origin = [xb-h1/2,yb];
  var myBfg3GapBMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapBMakerR1.origin = [xb-h1/2,yb];
  var myBfg3GapBMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapBMakerR2.origin = [xb-h1/2,yb];
  var myBfg3GapBMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapBMakerX.origin = [xb-h1/2,yb];
  
  /*****************************************************************************/
  
  
  makerjs.model.addPath(myBfg3GapBMakerW, new makerjs.paths.Line([-x0,0], [-x0,0+markShiftFour]));//Y轴方向短线  X  
  makerjs.model.addPath(myBfg3GapBMakerW, new makerjs.paths.Line([0,0], [0,0+markShiftFour]));//Y轴方向短线  X  
 makerjs.model.addPath(myBfg3GapBMakerW, new makerjs.paths.Line([h1,0], [h1,0+markShiftFour]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapBMakerW, new makerjs.paths.Line([0-markShiftFour,-y0-w1], [0,-y0-w1]));//X轴方向短线  W1
  makerjs.model.addPath(myBfg3GapBMakerW, new makerjs.paths.Line([0-2*markShiftFour,-y0-w], [0,-y0-w]));//X轴方向短线  W
 makerjs.model.addPath(myBfg3GapBMakerW, new makerjs.paths.Line([0+h,-w2], [0+h+markShiftFour, -w2]));//X轴加长标线 W2

 makerjs.model.addPath(myBfg3GapBMakerW, new makerjs.paths.Line([0,0-w2], [0, 0-w2-markShiftFour]));//X轴加长标线
 makerjs.model.addPath(myBfg3GapBMakerW, new makerjs.paths.Line([0+h,0-w2], [0+h, 0-w2-markShiftFour]));//X轴加长标线
  
  makerjs.model.addPath(myBfg3GapBMakerR1, new makerjs.paths.Line([0,0-y0-w], [0-dd-markHolesOneD,0-y0-w-dd-markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapBMakerR1, new makerjs.paths.Line([0-dd-markHolesOneD,0-y0-w-dd-markHolesOneD], [0-dd-markHolesOneD,0-y0-w-dd-markHolesOneD-markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapBMakerR1,TxtR1,[0-markShift-dd-markHolesOneD,0-y0-w-dd-markHolesOneD], [0-markShift-dd-markHolesOneD,0-y0-w-dd-markHolesOneD-markShiftFour]);//R1相关 X轴方向短线下
  
 makerjs.model.addPath(myBfg3GapBMakerR2, new makerjs.paths.Line([h,-w2], [h+dd+markHolesOneD,-w2-dd-markHolesOneD]));//R2相关 X轴方向短线上
 makerjs.model.addPath(myBfg3GapBMakerR2, new makerjs.paths.Line([h+dd+markHolesOneD,-w2-dd-markHolesOneD], [h+dd+markHolesOneD,-w2-dd-markHolesOneD-markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapBMakerR2,TxtR2,[h+dd+markHolesOneD+markShift,-w2-dd-markHolesOneD], [h+dd+markHolesOneD+markShift,-w2-dd-markHolesOneD-markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapBMakerW, new makerjs.paths.Line([0-markShift-markShiftFour,0-y0], [0-markShift-markShiftFour, 0-y0-w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapBMakerW,TxtW, [0-markShiftThree-markShiftFour, 0-y0-w], [0-markShiftThree-markShiftFour,0-y0]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapBMakerW1, new makerjs.paths.Line([0-markShift,0-y0], [0-markShift, 0-y0-w1]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapBMakerW1,TxtW1, [0-markShiftThree, 0-y0-w1], [0-markShiftThree,0-y0]);//H1文字标注
  
  
 makerjs.model.addPath(myBfg3GapBMakerW2, new makerjs.paths.Line([0+h+markShiftThree,-w2], [0+h+markShiftThree, 0]));//W2轴方向标注短线 
 makerjs.model.addCaption(myBfg3GapBMakerW2,TxtW2, [0+h+markShiftFour,-w2], [0+h+markShiftFour, 0]);//W2文字标注
  
  makerjs.model.addPath(myBfg3GapBMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0,0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapBMakerX,TxtX,  [-x0,0+markShiftFour], [0,0+markShiftFour]);//Y文字标注
  
  // /*****************************************************************************/
  
  
  makerjs.model.addPath(myBfg3GapBMakerH, new makerjs.paths.Line([0,-y0-w2-markShiftThree], [h, -y0-w2-markShiftThree]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapBMakerH,TxtH, [0,-y0-w2-markShiftFour], [h, -y0-w2-markShiftFour]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapBMakerH1, new makerjs.paths.Line([0,-y0+markShiftF], [h1, -y0+markShiftF]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapBMakerH1,TxtH1, [0,-y0+markShiftTwo], [h1, -y0+markShiftTwo]);//W1文字标注
  
  // /*****************************************************************************/
  /*****************************************************************************/    
  
 plate.models["myBfg3GapB"+myHolesNum]=myBfg3GapB;
markers.models["myBfg3GapB"+myHolesNum]=myBfg3GapB;
  markers.models["myBfg3GapBMakerW"+myHolesNum]=myBfg3GapBMakerW;
  markers.models["myBfg3GapBMakerW1"+myHolesNum]=myBfg3GapBMakerW1;
  markers.models["myBfg3GapBMakerW2"+myHolesNum]=myBfg3GapBMakerW2;
  markers.models["myBfg3GapBMakerH1"+myHolesNum]=myBfg3GapBMakerH1;
  markers.models["myBfg3GapBMakerH"+myHolesNum]=myBfg3GapBMakerH;
  markers.models["myBfg3GapBMakerR1"+myHolesNum]=myBfg3GapBMakerR1;
  markers.models["myBfg3GapBMakerR2"+myHolesNum]=myBfg3GapBMakerR2;
  markers.models["myBfg3GapBMakerX"+myHolesNum]=myBfg3GapBMakerX;
  /*****************************************************************************/
 makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapB"+myHolesNum]); 
  
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapB"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsA"+myHolesNum] = fillets;
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
          
  }else if(area==3){
        //画缺 C位置
        //1、3蝴蝶3 C位置   H W D  width=w-d/2  
        var myBfg3GapCRect=new makerjs.models.Rectangle(-h1,-w1-wd1); 
        myBfg3GapCRect.origin=[xc-hd,yc-wd2+wd1+wd2];     
        var myBfg3GapCBoltRectangle=new makerjs.models.BoltRectangle(-h,-w2-wd2-wd1,r2);
        myBfg3GapCBoltRectangle.origin=[xc,yc+wd1+wd2]; 
      var myBfg3GapC=new makerjs.model.combineUnion(myBfg3GapCRect, myBfg3GapCBoltRectangle);
     
     //画1、3蝴蝶3标记 C位置 
  var myBfg3GapCMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapCMakerW.origin = [xc,yc];
  var myBfg3GapCMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapCMakerW1.origin = [xc,yc];
  var myBfg3GapCMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapCMakerW2.origin = [xc,yc];
  var myBfg3GapCMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapCMakerH.origin = [xc,yc];
  var myBfg3GapCMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapCMakerH1.origin = [xc,yc];
  var myBfg3GapCMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapCMakerR1.origin = [xc,yc];
  var myBfg3GapCMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapCMakerR2.origin = [xc,yc];
  var myBfg3GapCMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapCMakerX.origin = [xc,yc];
  
  /*****************************************************************************/
  
  
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([x0,0], [x0,0+markShiftFour]));//Y轴方向短线  X  
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0,0], [0,0+markShiftFour]));//Y轴方向短线  X  
 makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([-h1,0], [-h1,0+markShiftFour]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0+markShiftFour,-y0-w1], [0,-y0-w1]));//X轴方向短线  W1
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0+2*markShiftFour,-y0-w], [0,-y0-w]));//X轴方向短线  W
 makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0-h,-w2], [0-h-markShiftFour, -w2]));//X轴加长标线 W2

 makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0,0-w2], [0, 0-w2-markShiftFour]));//X轴加长标线
 makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0-h,0-w2], [0-h, 0-w2-markShiftFour]));//X轴加长标线
  
  makerjs.model.addPath(myBfg3GapCMakerR1, new makerjs.paths.Line([0,0-y0-w], [0+dd+markHolesOneD,0-y0-w-dd-markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapCMakerR1, new makerjs.paths.Line([0+dd+markHolesOneD,0-y0-w-dd-markHolesOneD], [0+dd+markHolesOneD,0-y0-w-dd-markHolesOneD-markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapCMakerR1,TxtR1,[0+markShift+dd+markHolesOneD,0-y0-w-dd-markHolesOneD], [0+markShift+dd+markHolesOneD,0-y0-w-dd-markHolesOneD-markShiftFour]);//R1相关 X轴方向短线下
  
 makerjs.model.addPath(myBfg3GapCMakerR2, new makerjs.paths.Line([-h,-w2], [-h-dd-markHolesOneD,-w2-dd-markHolesOneD]));//R2相关 X轴方向短线上
 makerjs.model.addPath(myBfg3GapCMakerR2, new makerjs.paths.Line([-h-dd-markHolesOneD,-w2-dd-markHolesOneD], [-h-dd-markHolesOneD,-w2-dd-markHolesOneD-markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapCMakerR2,TxtR2,[-h-dd-markHolesOneD-markShift,-w2-dd-markHolesOneD], [-h-dd-markHolesOneD-markShift,-w2-dd-markHolesOneD-markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0+markShift+markShiftFour,0-y0], [0+markShift+markShiftFour, 0-y0-w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapCMakerW,TxtW, [0+markShiftThree+markShiftFour, 0-y0-w], [0+markShiftThree+markShiftFour,0-y0]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapCMakerW1, new makerjs.paths.Line([0+markShift,0-y0], [0+markShift, 0-y0-w1]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapCMakerW1,TxtW1, [0+markShiftThree, 0-y0-w1], [0+markShiftThree,0-y0]);//H1文字标注
  
  
 makerjs.model.addPath(myBfg3GapCMakerW2, new makerjs.paths.Line([0-h-markShiftThree,-w2], [0-h-markShiftThree, 0]));//W2轴方向标注短线 
 makerjs.model.addCaption(myBfg3GapCMakerW2,TxtW2, [0-h-markShiftFour,-w2], [0-h-markShiftFour, 0]);//W2文字标注
  
  makerjs.model.addPath(myBfg3GapCMakerX, new makerjs.paths.Line([x0,0+markShiftTwo], [0,0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapCMakerX,TxtX,  [0,0+markShiftFour], [x0,0+markShiftFour]);//Y文字标注
  
  // /*****************************************************************************/
  
  
  makerjs.model.addPath(myBfg3GapCMakerH, new makerjs.paths.Line([0,-y0-w2-markShiftThree], [-h, -y0-w2-markShiftThree]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapCMakerH,TxtH, [-h,-y0-w2-markShiftFour], [0, -y0-w2-markShiftFour]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapCMakerH1, new makerjs.paths.Line([0,-y0+markShiftF], [-h1, -y0+markShiftF]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapCMakerH1,TxtH1, [-h1,-y0+markShiftTwo], [0, -y0+markShiftTwo]);//W1文字标注
  
  // /*****************************************************************************/
  /*****************************************************************************/    
  
 plate.models["myBfg3GapC"+myHolesNum]=myBfg3GapC;
markers.models["myBfg3GapC"+myHolesNum]=myBfg3GapC;
  //markers.models["myBfg3GapCRect"+myHolesNum]=myBfg3GapCRect;
  //markers.models["myBfg3GapCBoltRectangle"+myHolesNum]=myBfg3GapCBoltRectangle;
  markers.models["myBfg3GapCMakerW"+myHolesNum]=myBfg3GapCMakerW;
  markers.models["myBfg3GapCMakerW1"+myHolesNum]=myBfg3GapCMakerW1;
  markers.models["myBfg3GapCMakerW2"+myHolesNum]=myBfg3GapCMakerW2;
  markers.models["myBfg3GapCMakerH1"+myHolesNum]=myBfg3GapCMakerH1;
  markers.models["myBfg3GapCMakerH"+myHolesNum]=myBfg3GapCMakerH;
  markers.models["myBfg3GapCMakerR1"+myHolesNum]=myBfg3GapCMakerR1;
  markers.models["myBfg3GapCMakerR2"+myHolesNum]=myBfg3GapCMakerR2;
  markers.models["myBfg3GapCMakerX"+myHolesNum]=myBfg3GapCMakerX;
  /*****************************************************************************/
 makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapC"+myHolesNum]); 
  
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapC"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsA"+myHolesNum] = fillets;
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/
    
          
  }else if(area==7){
        //画缺 G位置
        //1、3蝴蝶3 G位置   H W D  width=w-d/2  
        var myBfg3GapGRect=new makerjs.models.Rectangle(h1,w1+wd1); 
        myBfg3GapGRect.origin=[xg+hd,yg+wd2-wd1-wd2];     
        var myBfg3GapGBoltRectangle=new makerjs.models.BoltRectangle(h,w2+wd2+wd1,r2);
        myBfg3GapGBoltRectangle.origin=[xg,yg-wd1-wd2]; 
      var myBfg3GapG=new makerjs.model.combineUnion(myBfg3GapGRect, myBfg3GapGBoltRectangle);     
     //画1、3蝴蝶3标记 A位置 
  var myBfg3GapGMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapGMakerW.origin = [xg,yg];
  var myBfg3GapGMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapGMakerW1.origin = [xg,yg];
  var myBfg3GapGMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapGMakerW2.origin = [xg,yg];
  var myBfg3GapGMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapGMakerH.origin = [xg,yg];
  var myBfg3GapGMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapGMakerH1.origin = [xg,yg];
  var myBfg3GapGMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapGMakerR1.origin = [xg,yg];
  var myBfg3GapGMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapGMakerR2.origin = [xg,yg];
  var myBfg3GapGMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapGMakerX.origin = [xg,yg];
  
  /*****************************************************************************/  
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([-x0,0], [-x0,0-markShiftFour]));//Y轴方向短线  X  
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0,0], [0,0-markShiftFour]));//Y轴方向短线  X  
 makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([h1,0], [h1,0-markShiftFour]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0-markShiftFour,y0+w1], [0,y0+w1]));//X轴方向短线  W1
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0-2*markShiftFour,y0+w], [0,y0+w]));//X轴方向短线  W
 makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0+h,+w2], [0+h+markShiftFour, +w2]));//X轴加长标线 W2

 makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0,0+w2], [0, 0+w2+markShiftFour]));//X轴加长标线
 makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0+h,0+w2], [0+h, 0+w2+markShiftFour]));//X轴加长标线
  
  makerjs.model.addPath(myBfg3GapGMakerR1, new makerjs.paths.Line([0,0+y0+w], [0-dd-markHolesOneD,0+y0+w+dd+markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapGMakerR1, new makerjs.paths.Line([0-dd-markHolesOneD,0+y0+w+dd+markHolesOneD], [0-dd-markHolesOneD,0+y0+w+dd+markHolesOneD+markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapGMakerR1,TxtR1,[0-markShift-dd-markHolesOneD,0+y0+w+dd+markHolesOneD], [0-markShift-dd-markHolesOneD,0+y0+w+dd+markHolesOneD+markShiftFour]);//R1相关 X轴方向短线下
  
 makerjs.model.addPath(myBfg3GapGMakerR2, new makerjs.paths.Line([h,+w2], [h+dd+markHolesOneD,+w2+dd+markHolesOneD]));//R2相关 X轴方向短线上
 makerjs.model.addPath(myBfg3GapGMakerR2, new makerjs.paths.Line([h+dd+markHolesOneD,+w2+dd+markHolesOneD], [h+dd+markHolesOneD,+w2+dd+markHolesOneD+markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapGMakerR2,TxtR2,[h+dd+markHolesOneD+markShift,+w2+dd+markHolesOneD], [h+dd+markHolesOneD+markShift,+w2+dd+markHolesOneD+markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0-markShift-markShiftFour,0+y0], [0-markShift-markShiftFour, 0+y0+w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapGMakerW,TxtW,[0-markShiftThree-markShiftFour,0+y0], [0-markShiftThree-markShiftFour, 0+y0+w]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapGMakerW1, new makerjs.paths.Line([0-markShift,0+y0], [0-markShift, 0+y0+w1]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapGMakerW1,TxtW1, [0-markShiftThree,0+y0], [0-markShiftThree, 0+y0+w1]);//H1文字标注
  
  
 makerjs.model.addPath(myBfg3GapGMakerW2, new makerjs.paths.Line([0+h+markShiftTwo,+w2], [0+h+markShiftTwo, 0]));//W2轴方向标注短线 
 makerjs.model.addCaption(myBfg3GapGMakerW2,TxtW2, [0+h+markShiftFour, 0], [0+h+markShiftFour,+w2]);//W2文字标注
  
  makerjs.model.addPath(myBfg3GapGMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0,0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapGMakerX,TxtX,  [-x0,0-markShiftFour], [0,0-markShiftFour]);//Y文字标注  
  // /*****************************************************************************/  
  makerjs.model.addPath(myBfg3GapGMakerH, new makerjs.paths.Line([0,+y0+w2+markShiftThree], [h, +y0+w2+markShiftThree]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapGMakerH,TxtH, [0,+y0+w2+markShiftFour], [h, +y0+w2+markShiftFour]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapGMakerH1, new makerjs.paths.Line([0,+y0-markShiftTwo], [h1, +y0-markShiftTwo]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapGMakerH1,TxtH1, [0,+y0-markShiftFour], [h1, +y0-markShiftFour]);//W1文字标注
  
  // /*****************************************************************************/
  /*****************************************************************************/    
  
 plate.models["myBfg3GapG"+myHolesNum]=myBfg3GapG;
markers.models["myBfg3GapG"+myHolesNum]=myBfg3GapG;
  markers.models["myBfg3GapGMakerW"+myHolesNum]=myBfg3GapGMakerW;
  markers.models["myBfg3GapGMakerW1"+myHolesNum]=myBfg3GapGMakerW1;
  markers.models["myBfg3GapGMakerW2"+myHolesNum]=myBfg3GapGMakerW2;
  markers.models["myBfg3GapGMakerH1"+myHolesNum]=myBfg3GapGMakerH1;
  markers.models["myBfg3GapGMakerH"+myHolesNum]=myBfg3GapGMakerH;
  markers.models["myBfg3GapGMakerR1"+myHolesNum]=myBfg3GapGMakerR1;
  markers.models["myBfg3GapGMakerR2"+myHolesNum]=myBfg3GapGMakerR2;
  markers.models["myBfg3GapGMakerX"+myHolesNum]=myBfg3GapGMakerX;
  /*****************************************************************************/
 makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapG"+myHolesNum]); 
  
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapG"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsA"+myHolesNum] = fillets;
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
      
          
  }else if(area==8){
  //画缺 H位置
        //1、3蝴蝶3 H位置   H W D  width=w-d/2  
        var myBfg3GapHRect=new makerjs.models.Rectangle(h1,w1+wd1); 
        myBfg3GapHRect.origin=[xh+hd-h/2,yh+wd2-wd1-wd2];     
        var myBfg3GapHBoltRectangle=new makerjs.models.BoltRectangle(h,w2+wd2+wd1,r2);
        myBfg3GapHBoltRectangle.origin=[xh-h/2,yh-wd1-wd2]; 
      var myBfg3GapH=new makerjs.model.combineUnion(myBfg3GapHRect, myBfg3GapHBoltRectangle);     
     //画1、3蝴蝶3标记 A位置 
  var myBfg3GapHMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapHMakerW.origin = [xh-h/2,yh];
  var myBfg3GapHMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapHMakerW1.origin = [xh-h/2,yh];
  var myBfg3GapHMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapHMakerW2.origin = [xh-h/2,yh];
  var myBfg3GapHMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapHMakerH.origin = [xh-h/2,yh];
  var myBfg3GapHMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapHMakerH1.origin = [xh-h/2,yh];
  var myBfg3GapHMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapHMakerR1.origin = [xh-h/2,yh];
  var myBfg3GapHMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapHMakerR2.origin = [xh-h/2,yh];
  var myBfg3GapHMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapHMakerX.origin = [xh-h/2,yh];
  
  /*****************************************************************************/  
  makerjs.model.addPath(myBfg3GapHMakerW, new makerjs.paths.Line([-x0,0], [-x0,0-markShiftFour]));//Y轴方向短线  X  
  makerjs.model.addPath(myBfg3GapHMakerW, new makerjs.paths.Line([0,0], [0,0-2*markShiftFour]));//Y轴方向短线  X  
 makerjs.model.addPath(myBfg3GapHMakerW, new makerjs.paths.Line([h1,0], [h1,0-2*markShiftFour]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapHMakerW, new makerjs.paths.Line([0-markShiftFour,y0+w1], [0,y0+w1]));//X轴方向短线  W1
  makerjs.model.addPath(myBfg3GapHMakerW, new makerjs.paths.Line([0-2*markShiftFour,y0+w], [0,y0+w]));//X轴方向短线  W
 makerjs.model.addPath(myBfg3GapHMakerW, new makerjs.paths.Line([0+h,+w2], [0+h+markShiftFour, +w2]));//X轴加长标线 W2

 makerjs.model.addPath(myBfg3GapHMakerW, new makerjs.paths.Line([0,0+w2], [0, 0+w2+markShiftFour]));//X轴加长标线
 makerjs.model.addPath(myBfg3GapHMakerW, new makerjs.paths.Line([0+h,0+w2], [0+h, 0+w2+markShiftFour]));//X轴加长标线
  
  makerjs.model.addPath(myBfg3GapHMakerR1, new makerjs.paths.Line([0,0+y0+w], [0-dd-markHolesOneD,0+y0+w+dd+markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapHMakerR1, new makerjs.paths.Line([0-dd-markHolesOneD,0+y0+w+dd+markHolesOneD], [0-dd-markHolesOneD,0+y0+w+dd+markHolesOneD+markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapHMakerR1,TxtR1,[0-markShift-dd-markHolesOneD,0+y0+w+dd+markHolesOneD], [0-markShift-dd-markHolesOneD,0+y0+w+dd+markHolesOneD+markShiftFour]);//R1相关 X轴方向短线下
  
 makerjs.model.addPath(myBfg3GapHMakerR2, new makerjs.paths.Line([h,+w2], [h+dd+markHolesOneD,+w2+dd+markHolesOneD]));//R2相关 X轴方向短线上
 makerjs.model.addPath(myBfg3GapHMakerR2, new makerjs.paths.Line([h+dd+markHolesOneD,+w2+dd+markHolesOneD], [h+dd+markHolesOneD,+w2+dd+markHolesOneD+markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapHMakerR2,TxtR2,[h+dd+markHolesOneD+markShift,+w2+dd+markHolesOneD], [h+dd+markHolesOneD+markShift,+w2+dd+markHolesOneD+markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapHMakerW, new makerjs.paths.Line([0-markShift-markShiftFour,0+y0], [0-markShift-markShiftFour, 0+y0+w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapHMakerW,TxtW,[0-markShiftThree-markShiftFour,0+y0], [0-markShiftThree-markShiftFour, 0+y0+w]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapHMakerW1, new makerjs.paths.Line([0-markShift,0+y0], [0-markShift, 0+y0+w1]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapHMakerW1,TxtW1, [0-markShiftThree,0+y0], [0-markShiftThree, 0+y0+w1]);//H1文字标注
  
  
 makerjs.model.addPath(myBfg3GapHMakerW2, new makerjs.paths.Line([0+h+markShiftTwo,+w2], [0+h+markShiftTwo, 0]));//W2轴方向标注短线 
 makerjs.model.addCaption(myBfg3GapHMakerW2,TxtW2, [0+h+markShiftFour, 0], [0+h+markShiftFour,+w2]);//W2文字标注
  
  makerjs.model.addPath(myBfg3GapHMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0,0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapHMakerX,TxtX,  [-x0,0-markShiftFour], [0,0-markShiftFour]);//Y文字标注  
  // /*****************************************************************************/  
  makerjs.model.addPath(myBfg3GapHMakerH, new makerjs.paths.Line([0,+y0+w2+markShiftThree], [h, +y0+w2+markShiftThree]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapHMakerH,TxtH, [0,+y0+w2+markShiftFour], [h, +y0+w2+markShiftFour]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapHMakerH1, new makerjs.paths.Line([0,+y0-2*markShiftTwo], [h1, +y0-2*markShiftTwo]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapHMakerH1,TxtH1, [0,+y0-2*markShiftFour], [h1, +y0-2*markShiftFour]);//W1文字标注
  
  // /*****************************************************************************/
  /*****************************************************************************/    
  
 plate.models["myBfg3GapH"+myHolesNum]=myBfg3GapH;
markers.models["myBfg3GapH"+myHolesNum]=myBfg3GapH;
  markers.models["myBfg3GapHMakerW"+myHolesNum]=myBfg3GapHMakerW;
  markers.models["myBfg3GapHMakerW1"+myHolesNum]=myBfg3GapHMakerW1;
  markers.models["myBfg3GapHMakerW2"+myHolesNum]=myBfg3GapHMakerW2;
  markers.models["myBfg3GapHMakerH1"+myHolesNum]=myBfg3GapHMakerH1;
  markers.models["myBfg3GapHMakerH"+myHolesNum]=myBfg3GapHMakerH;
  markers.models["myBfg3GapHMakerR1"+myHolesNum]=myBfg3GapHMakerR1;
  markers.models["myBfg3GapHMakerR2"+myHolesNum]=myBfg3GapHMakerR2;
  markers.models["myBfg3GapHMakerX"+myHolesNum]=myBfg3GapHMakerX;
  /*****************************************************************************/
 makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapH"+myHolesNum]); 
  
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapH"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsA"+myHolesNum] = fillets;
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/         
  }else if(area==9){
        //画缺 I位置
        //1、3蝴蝶3 I位置   H W D  width=w-d/2  
        var myBfg3GapIRect=new makerjs.models.Rectangle(-h1,w1+wd1); 
        myBfg3GapIRect.origin=[xi-hd,yi+wd2-wd1-wd2];     
        var myBfg3GapIBoltRectangle=new makerjs.models.BoltRectangle(-h,w2+wd2+wd1,r2);
        myBfg3GapIBoltRectangle.origin=[xi,yi-wd1-wd2]; 
      var myBfg3GapI=new makerjs.model.combineUnion(myBfg3GapIRect, myBfg3GapIBoltRectangle);
     
     //画1、3蝴蝶3标记 I位置 
  var myBfg3GapIMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapIMakerW.origin = [xi,yi];
  var myBfg3GapIMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapIMakerW1.origin = [xi,yi];
  var myBfg3GapIMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapIMakerW2.origin = [xi,yi];
  var myBfg3GapIMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapIMakerH.origin = [xi,yi];
  var myBfg3GapIMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapIMakerH1.origin = [xi,yi];
  var myBfg3GapIMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapIMakerR1.origin = [xi,yi];
  var myBfg3GapIMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapIMakerR2.origin = [xi,yi];
  var myBfg3GapIMakerX = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapIMakerX.origin = [xi,yi];
  
  /*****************************************************************************/
  
  
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([x0,0], [x0,0-markShiftFour]));//Y轴方向短线  X  
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0,0], [0,0-markShiftFour]));//Y轴方向短线  X  
 makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([-h1,0], [-h1,0-markShiftFour]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0+markShiftFour,+y0+w1], [0,+y0+w1]));//X轴方向短线  W1
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0+2*markShiftFour,+y0+w], [0,+y0+w]));//X轴方向短线  W
 makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0-h,+w2], [0-h-markShiftFour, +w2]));//X轴加长标线 W2

 makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0,0+w2], [0, 0+w2+markShiftFour]));//X轴加长标线
 makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0-h,0+w2], [0-h, 0+w2+markShiftFour]));//X轴加长标线
  
  makerjs.model.addPath(myBfg3GapIMakerR1, new makerjs.paths.Line([0,0+y0+w], [0+dd+markHolesOneD,0+y0+w+dd+markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapIMakerR1, new makerjs.paths.Line([0+dd+markHolesOneD,0+y0+w+dd+markHolesOneD], [0+dd+markHolesOneD,0+y0+w+dd+markHolesOneD+markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapIMakerR1,TxtR1,[0+markShift+dd+markHolesOneD,0+y0+w+dd+markHolesOneD], [0+markShift+dd+markHolesOneD,0+y0+w+dd+markHolesOneD+markShiftFour]);//R1相关 X轴方向短线下
  
 makerjs.model.addPath(myBfg3GapIMakerR2, new makerjs.paths.Line([-h,+w2], [-h-dd-markHolesOneD,+w2+dd+markHolesOneD]));//R2相关 X轴方向短线上
 makerjs.model.addPath(myBfg3GapIMakerR2, new makerjs.paths.Line([-h-dd-markHolesOneD,+w2+dd+markHolesOneD], [-h-dd-markHolesOneD,+w2+dd+markHolesOneD+markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapIMakerR2,TxtR2,[-h-dd-markHolesOneD-markShift,+w2+dd+markHolesOneD], [-h-dd-markHolesOneD-markShift,+w2+dd+markHolesOneD+markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0+markShift+markShiftFour,0+y0], [0+markShift+markShiftFour, 0+y0+w]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapIMakerW,TxtW, [0+markShiftThree+markShiftFour,0+y0], [0+markShiftThree+markShiftFour, 0+y0+w]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapIMakerW1, new makerjs.paths.Line([0+markShift,0+y0], [0+markShift, 0+y0+w1]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapIMakerW1,TxtW1, [0+markShiftThree,0+y0], [0+markShiftThree, 0+y0+w1]);//H1文字标注
  
  
 makerjs.model.addPath(myBfg3GapIMakerW2, new makerjs.paths.Line([0-h-markShiftThree,+w2], [0-h-markShiftThree, 0]));//W2轴方向标注短线 
 makerjs.model.addCaption(myBfg3GapIMakerW2,TxtW2, [0-h-markShiftFour, 0], [0-h-markShiftFour,+w2]);//W2文字标注
  
  makerjs.model.addPath(myBfg3GapIMakerX, new makerjs.paths.Line([x0,0-markShiftTwo], [0,0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapIMakerX,TxtX,  [0,0-markShiftFour], [x0,0-markShiftFour]);//Y文字标注
  
  // /*****************************************************************************/
  
  
  makerjs.model.addPath(myBfg3GapIMakerH, new makerjs.paths.Line([0,+y0+w2+markShiftThree], [-h, +y0+w2+markShiftThree]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapIMakerH,TxtH, [-h,+y0+w2+markShiftFour], [0, +y0+w2+markShiftFour]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapIMakerH1, new makerjs.paths.Line([0,+y0-markShiftF], [-h1, +y0-markShiftF]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapIMakerH1,TxtH1, [-h1,+y0-markShiftThree], [0, +y0-markShiftThree]);//W1文字标注
  
  // /*****************************************************************************/
  /*****************************************************************************/    
  
 plate.models["myBfg3GapI"+myHolesNum]=myBfg3GapI;
markers.models["myBfg3GapI"+myHolesNum]=myBfg3GapI;
  markers.models["myBfg3GapIMakerW"+myHolesNum]=myBfg3GapIMakerW;
  markers.models["myBfg3GapIMakerW1"+myHolesNum]=myBfg3GapIMakerW1;
  markers.models["myBfg3GapIMakerW2"+myHolesNum]=myBfg3GapIMakerW2;
  markers.models["myBfg3GapIMakerH1"+myHolesNum]=myBfg3GapIMakerH1;
  markers.models["myBfg3GapIMakerH"+myHolesNum]=myBfg3GapIMakerH;
  markers.models["myBfg3GapIMakerR1"+myHolesNum]=myBfg3GapIMakerR1;
  markers.models["myBfg3GapIMakerR2"+myHolesNum]=myBfg3GapIMakerR2;
  markers.models["myBfg3GapIMakerX"+myHolesNum]=myBfg3GapIMakerX;
  /*****************************************************************************/
 makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapI"+myHolesNum]); 
  
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapI"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsA"+myHolesNum] = fillets;
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
         
  }

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};


export default ButterflyGap3;