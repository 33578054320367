import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.js';
import './assets/icon/iconfont.css';

import App from './App.vue';
import myutils from './utils/index';
import axios from 'axios'

Vue.prototype.axios=axios;
Vue.prototype.$myutils=myutils;
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
 
  render: h => h(App),
}).$mount('#app')
 