//2、吊轮孔2
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HangWheelHoleTwo(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate=model.plate, cutPlate = model.cutPlate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   d = D*1*myZoom,
    x0 = X*1*myZoom,
    y0 = Y*1*myZoom;
  if(d!==0){
   var TxtD="∮"+D;
  }
  if(x0!==0){          
  var TxtX=X;
  }
  if(y0!==0){          
   var TxtY=Y;
  }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==4){
//画孔 D位置孔
      //标记吊轮孔2 D位置孔
      var myHwhtHolesA= new makerjs.models.Holes(d/2, [[xa, ya]]);
      var myHwhtHolesMarkA = new makerjs.models.Square(0); //孔的原点       
      myHwhtHolesMarkA.origin = [xa, ya];      
      var myHwhtXMarkA = new makerjs.models.Square(0);      
      myHwhtXMarkA.origin =[xa, ya];      
      var myHwhtYMarkA = new makerjs.models.Square(0);      
      myHwhtYMarkA.origin = [xa, ya];
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtHolesMarkA, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHwhtHolesMarkA, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHwhtHolesMarkA, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHwhtHolesMarkA, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtHolesMarkA, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtHolesMarkA, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwhtXMarkA, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtXMarkA, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHwhtYMarkA, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myHwhtYMarkA, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 
 //标记吊轮孔2 G位置孔
  //画孔
     var myHwhtHolesG= new makerjs.models.Holes(d/2, [[xg, yg]]);
      var myHwhtHolesMarkG = new makerjs.models.Square(0); //孔的原点       
      myHwhtHolesMarkG.origin = [xg, yg];      
      var myHwhtXMarkG = new makerjs.models.Square(0);      
      myHwhtXMarkG.origin = [xg, yg];      
      var myHwhtYMarkG = new makerjs.models.Square(0);      
      myHwhtYMarkG.origin = [xg, yg];  
/*****************************************************************************/  
//makerjs.model.addPath(myHwhtHolesMarkR, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftTwo, -y0]));//Y轴加长标线
//makerjs.model.addPath(myHwhtHolesMarkR, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftTwo]));//X轴加长标线
//makerjs.model.addPath(myHwhtXMarkR, new makerjs.paths.Line([0,0], [0, -y0-markShiftTwo]));////从孔圆心出发Y方向横向标线
//makerjs.model.addPath(myHwhtXMarkR, new makerjs.paths.Line([0,0], [-x0-markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtHolesMarkG, new makerjs.paths.Line([0,0], [d, d]));//直径标线
makerjs.model.addCaption(myHwhtHolesMarkG, TxtD, [0, 0], [d+markHolesOneD,d+markHolesOneD]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtXMarkG, new makerjs.paths.Line([0,0], [-x0, 0]));//X短标线
makerjs.model.addCaption(myHwhtXMarkG, TxtX, [-x0, markShift], [0,markShift]);//X文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHwhtYMarkG, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
makerjs.model.addCaption(myHwhtYMarkG, TxtY, [0+markShiftTwo, -y0], [0+markShiftTwo,0]);//Y文字标注
/*****************************************************************************/ 
plate.models["myHwhtHolesA"+myHolesNum]=myHwhtHolesA;
markers.models["myHwhtHolesA"+myHolesNum]=myHwhtHolesA;
markers.models["myHwhtHolesMarkA"+myHolesNum]=myHwhtHolesMarkA;
markers.models["myHwhtXMarkA"+myHolesNum]=myHwhtXMarkA;
markers.models["myHwhtYMarkA"+myHolesNum]=myHwhtYMarkA;
/*****************************************************************************/
plate.models["myHwhtHolesG"+myHolesNum]=myHwhtHolesG;
markers.models["myHwhtHolesG"+myHolesNum]=myHwhtHolesG;
markers.models["myHwhtHolesMarkG"+myHolesNum]=myHwhtHolesMarkG;
markers.models["myHwhtXMarkG"+myHolesNum]=myHwhtXMarkG;
markers.models["myHwhtYMarkG"+myHolesNum]=myHwhtYMarkG;
  }else if(area==6){
      //画孔 C位置孔
      //标记吊轮孔2 C位置孔
      var myHwhtHolesC= new makerjs.models.Holes(d/2, [[xc, yc]]);
      var myHwhtHolesMarkC = new makerjs.models.Square(0); //孔的原点       
      myHwhtHolesMarkC.origin = [xc, yc];      
      var myHwhtXMarkC = new makerjs.models.Square(0);      
      myHwhtXMarkC.origin =[xc, yc];      
      var myHwhtYMarkC = new makerjs.models.Square(0);      
      myHwhtYMarkC.origin = [xc, yc];
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtHolesMarkC, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHwhtHolesMarkC, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHwhtHolesMarkC, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHwhtHolesMarkC, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtHolesMarkC, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtHolesMarkC, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwhtXMarkC, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtXMarkC, TxtX, [0,y0+markShiftTwo], [x0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHwhtYMarkC, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myHwhtYMarkC, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 
 //标记吊轮孔2 I位置孔
  //画孔
     var myHwhtHolesI= new makerjs.models.Holes(d/2, [[xi, yi]]);
      var myHwhtHolesMarkI = new makerjs.models.Square(0); //孔的原点       
      myHwhtHolesMarkI.origin = [xi, yi];      
      var myHwhtXMarkI = new makerjs.models.Square(0);      
      myHwhtXMarkI.origin = [xi, yi];      
      var myHwhtYMarkI = new makerjs.models.Square(0);      
      myHwhtYMarkI.origin = [xi, yi];  
/*****************************************************************************/  
//makerjs.model.addPath(myHwhtHolesMarkR, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftTwo, -y0]));//Y轴加长标线
//makerjs.model.addPath(myHwhtHolesMarkR, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftTwo]));//X轴加长标线
//makerjs.model.addPath(myHwhtXMarkR, new makerjs.paths.Line([0,0], [0, -y0-markShiftTwo]));////从孔圆心出发Y方向横向标线
//makerjs.model.addPath(myHwhtXMarkR, new makerjs.paths.Line([0,0], [-x0-markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtHolesMarkI, new makerjs.paths.Line([0,0], [d, d]));//直径标线
makerjs.model.addCaption(myHwhtHolesMarkI, TxtD, [0, 0], [d+markHolesOneD,d+markHolesOneD]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtXMarkI, new makerjs.paths.Line([0,0], [x0, 0]));//X短标线
makerjs.model.addCaption(myHwhtXMarkI, TxtX, [0, markShift], [x0,markShift]);//X文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHwhtYMarkI, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
makerjs.model.addCaption(myHwhtYMarkI, TxtY, [0+markShiftTwo, -y0], [0+markShiftTwo,0]);//Y文字标注
/*****************************************************************************/ 
plate.models["myHwhtHolesC"+myHolesNum]=myHwhtHolesC;
markers.models["myHwhtHolesC"+myHolesNum]=myHwhtHolesC;
markers.models["myHwhtHolesMarkC"+myHolesNum]=myHwhtHolesMarkC;
markers.models["myHwhtXMarkC"+myHolesNum]=myHwhtXMarkC;
markers.models["myHwhtYMarkC"+myHolesNum]=myHwhtYMarkC;
/*****************************************************************************/
plate.models["myHwhtHolesI"+myHolesNum]=myHwhtHolesI;
markers.models["myHwhtHolesI"+myHolesNum]=myHwhtHolesI;
markers.models["myHwhtHolesMarkI"+myHolesNum]=myHwhtHolesMarkI;
markers.models["myHwhtXMarkI"+myHolesNum]=myHwhtXMarkI;
markers.models["myHwhtYMarkI"+myHolesNum]=myHwhtYMarkI;
  }


myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};


export default HangWheelHoleTwo;

