//角5、地弹簧
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
 //已知相切两圆的圆心坐标及半径，求切点坐标
 function CircleTwo(x1,y1,x2,y2,r1,r2){
    var x=(r1*x2+x1*r2)/(r2+r1);    
     var d=Math.sqrt((r1*r1)-(x-x1)*(x-x1));             
   var Arr = [x,d]; 
   return Arr;
 };
function FloorSpring(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,R1=inputParam.R1,R2=inputParam.R2,W=inputParam.W,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   
   w = W*1*myZoom,
   w1 = W1*1*myZoom,
   w2 = W2*1*myZoom,
   h = H*1*myZoom,
   h1 = H1*1*myZoom,
   r1 = R1*1*myZoom,
   r2 = R2*1*myZoom,
   d = D*1*myZoom,
   r = d/2,
   a=0,//圆心坐标[a,b]
   b=0,//圆心坐标[a,b]  
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;  
     
    /* 
   w = W*1,
   w1 = W1*1,
   w2 = W2*1,
   h = H*1,
   h1 = H1*1,
   r1 = R1*1,
   r2 = R2*1,
   d = D*1,
   r = (D*1)/2,
   a=0,//圆心坐标[a,b]
   b=0,//圆心坐标[a,b]  
     x0 = X*1,
     y0 = Y*1;
     */   
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(r1!==0){
      var TxtR1="R1="+R1;
     }
     if(r2!==0){
      var TxtR2="R2="+R2;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(w1!==0){
      var TxtW1=W1;
     }
     if(w2!==0){
      var TxtW2=W2;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(h1!==0){
      var TxtH1=H1;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){  
 
    console.log("测试坐标=");
  console.log("测试坐标=",CircleTwo(xa+w,ya+r1,xa+w2,ya-r2,r1,r2));
  var arr=[];
  arr=CircleTwo(xa+w,ya+r1,xa+w2,ya-r2,r1,r2);
  a=arr[0]*1;
  b=arr[1]*1;
  var hd=h-(r1-b)
  console.log("测试坐标a=",a);
  console.log("测试坐标b=",b);
  console.log("测试坐标xa+w=",xa+w);
  console.log("测试坐标ya+r1=",ya+r1);
  console.log("测试坐标xa+w2=",xa+w2);
  console.log("测试坐标ya-r2=",ya-r2);
        //画5、地弹簧2 A位置
        /*
  var myFillet2A = {
  
  paths: {
    "TopLine": new makerjs.paths.Line([xa-5, ya+5], [xa+a1, ya+5]),
    "RightLine": new makerjs.paths.Line([xa+a1, ya+5], [xa+a1, ya]),
    "BottomRightArc": new makerjs.paths.Circle([xa+a1, ya-rb], rb),
    "BottomRight2Arc": new makerjs.paths.Circle([xa+a3, ya-b2+ra], ra),
    "BottomLine": new makerjs.paths.Line([xa+a3+5, ya-b2], [xa-5, ya-b2]),
    "LeftLine": new makerjs.paths.Line([xa-5, ya-b2], [xa-5, ya+5])
  }
  
  };
  */
  var myFloorSpringA = {
  paths: {
    "TopLine": new makerjs.paths.Line([xa-5, ya+5], [xa+w2, ya+5]),
    "RightLine": new makerjs.paths.Line([xa+w2, ya+5], [xa+w2, ya]), 
    "BottomRightArc": new makerjs.paths.Arc([xa+w2, ya], [xa+a, ya-hd],r2,false,false),//是否大弧,是否顺时针  
    "BottomLeftArc": new makerjs.paths.Arc([xa+a, ya-hd], [xa+w, ya-h],r1,false,true),//是否大弧,是否顺时针
    "BottomLine": new makerjs.paths.Line([xa+w, ya-h], [xa-5, ya-h]),
    "LeftLine": new makerjs.paths.Line([xa-5, ya-h], [xa-5, ya+5]),
    "RightHoles": new makerjs.paths.Circle([xa+w1, ya-h1], r),
  }
  };
  
  //标记5、地弹簧 A位置
  var myFsAMakerD = new makerjs.models.Square(0); //孔的原点       
  myFsAMakerD.origin = [xa,ya];
  var myFsAMakerH = new makerjs.models.Square(0); //孔的原点       
  myFsAMakerH.origin = [xa,ya];
  var myFsAMakerH1 = new makerjs.models.Square(0); //孔的原点       
  myFsAMakerH1.origin = [xa,ya];
  var myFsAMakerR1 = new makerjs.models.Square(0); //孔的原点       
  myFsAMakerR1.origin = [xa,ya];
  var myFsAMakerR2 = new makerjs.models.Square(0); //孔的原点       
  myFsAMakerR2.origin = [xa,ya];
  var myFsAMakerW = new makerjs.models.Square(0); //孔的原点       
  myFsAMakerW.origin = [xa,ya];
  var myFsAMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myFsAMakerW1.origin = [xa,ya];
  var myFsAMakerW2 = new makerjs.models.Square(0); //孔的原点       
  myFsAMakerW2.origin = [xa,ya];
   
  // /*****************************************************************************/
  // /*****************************************************************************/
  
  makerjs.model.addPath(myFsAMakerW, new makerjs.paths.Line([w,-h], [w,-h-markShiftThree]));//Y轴方向短线  W  right
  
  makerjs.model.addPath(myFsAMakerW, new makerjs.paths.Line([w1,-h1+r], [w1,-h1-d-markShiftThree]));//Y轴方向短线  W1  right
  
  makerjs.model.addPath(myFsAMakerW, new makerjs.paths.Line([w2,-r2], [w2,-r2-h1-markShiftThree]));//Y轴方向短线  W2  right
  
  makerjs.model.addPath(myFsAMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myFsAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线  H
  
  makerjs.model.addPath(myFsAMakerW, new makerjs.paths.Line([w1-r,-h1], [w1+r+markShiftFour, -h1]));//X轴方向短线   H1
  
  makerjs.model.addPath(myFsAMakerW, new makerjs.paths.Line([w2-r,0-r2], [w2+markShiftThree, 0-r2]));//X轴方向短线  R2
  
  makerjs.model.addPath(myFsAMakerW, new makerjs.paths.Line([w2,0], [w2, 0-r2-markShiftFour]));//Y轴方向短线  R2
  
  // /*****************************************************************************/
  // /*****************************************************************************/   
  makerjs.model.addPath(myFsAMakerW, new makerjs.paths.Line([0,0-h-markShift], [0+w,0-h-markShift]));//W短线标注
  makerjs.model.addCaption(myFsAMakerW, TxtW, [0,0-h-markShiftThree], [0+w,0-h-markShiftThree]);//W文字标注 
  
  // /*****************************************************************************/  
  // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsAMakerW1, new makerjs.paths.Line([0,-h-d-markShift], [w1,-h-d-markShift]));//W1短线标注
  makerjs.model.addCaption(myFsAMakerW1, TxtW1, [0,-h-d-markShiftThree], [w1,-h-d-markShiftThree]);//W1文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsAMakerW2, new makerjs.paths.Line([0,-h-h-markShiftThree], [w2,-h-h-markShiftThree]));//W2短线标注
  makerjs.model.addCaption(myFsAMakerW2, TxtW2, [0,-h-h-r-markShiftFour], [w2,-h-h-r-markShiftFour]);//W2文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsAMakerH, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo,-h]));//H短线标注
  makerjs.model.addCaption(myFsAMakerH, TxtH, [0-markShiftFour,-h], [0-markShiftFour,0]);//H文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsAMakerH1, new makerjs.paths.Line([w2+markShift,0], [w2+markShift,-h1]));//H1短线标注
  makerjs.model.addCaption(myFsAMakerH1, TxtH1, [w2+markShiftThree,-h1], [w2+markShiftThree,0]);//H1文字标注  
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsAMakerR2, new makerjs.paths.Line([a-markShiftTwo,0], [a,-hd]));//R2短线标注
  makerjs.model.addCaption(myFsAMakerR2, TxtR2, [a-markShiftThree,-hd+markShiftThree], [a-markShift,-hd+markShift]);//R2文字标注   
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/   
  
  makerjs.model.addPath(myFsAMakerR1, new makerjs.paths.Line([w,-hd], [w+markShift,-hd-markShift]));//R1短线标注
  makerjs.model.addCaption(myFsAMakerR1, TxtR1, [w,-hd], [w+markShift,-hd-markShift]);//R1文字标注    
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsAMakerD, new makerjs.paths.Line([w1,-h1], [w1+markShift+markHolesOneD,-h1-d-markShiftFour-markHolesOneD]));//D短线标注
  makerjs.model.addCaption(myFsAMakerD, TxtD, [w1+markShift+markHolesOneD,-h1-d-markShiftFour-markHolesOneD],[w1+markHolesOneD+markShiftFour,-h1-d-markShiftFour-markHolesOneD]);//D文字标注 
  
  /*****************************************************************************/  
  /*****************************************************************************/ 
  
  plate.models["myFloorSpringA"+myHolesNum]=myFloorSpringA;
  markers.models["myFloorSpringA"+myHolesNum]=myFloorSpringA;
  markers.models["myFsAMakerD"+myHolesNum]=myFsAMakerD;
  markers.models["myFsAMakerH"+myHolesNum]=myFsAMakerH;
  markers.models["myFsAMakerH1"+myHolesNum]=myFsAMakerH1;
  markers.models["myFsAMakerR1"+myHolesNum]=myFsAMakerR1;
  markers.models["myFsAMakerR2"+myHolesNum]=myFsAMakerR2;
  markers.models["myFsAMakerW"+myHolesNum]=myFsAMakerW;
  markers.models["myFsAMakerW1"+myHolesNum]=myFsAMakerW1;
  markers.models["myFsAMakerW2"+myHolesNum]=myFsAMakerW2;
  // /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpringA"+myHolesNum])
  // makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2AMakerR"+myHolesNum])
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
    
        }else if(area==2){
          
        }else if(area==3){
            //画5、地弹簧 C位置 
            console.log("测试坐标=");
  console.log("测试坐标=",CircleTwo(xc-w,yc+r1,xc-w2,yc-r2,r1,r2));
  var arr=[];
  arr=CircleTwo(xc-w,yc+r1,xc-w2,yc-r2,r1,r2);
  a=arr[0]*1;
  b=arr[1]*1;
  var aa=glassW * 1-a;
  var hd=h-(r1-b);
  console.log("测试坐标a=",a);
  console.log("测试坐标b=",b);
  console.log("测试坐标xc-w=",xc-w);
  console.log("测试坐标yc+r1=",yc+r1);
  console.log("测试坐标xc-w2=",xc-w2);
  console.log("测试坐标yc-r2=",yc-r2);
        //画5、地弹簧2 C位置
  var myFloorSpringC = {
  paths: {
    "TopLine": new makerjs.paths.Line([xc-w2, yc+5],[xc+5, yc+5]),
    "RightLine": new makerjs.paths.Line([xc+5, yc+5], [xc+5, yc-h]),
    "BottomLine": new makerjs.paths.Line( [xc+5, yc-h],[xc-w, yc-h]),
    "BottomRightArc": new makerjs.paths.Arc([xc-w, yc-h], [xc-aa, yc-hd],r1,false,true),//是否大弧,是否顺时针  
    "BottomLeftArc": new makerjs.paths.Arc([xc-aa, yc-hd], [xc-w2, yc],r2,false,false),//是否大弧,是否顺时针
    "LeftLine": new makerjs.paths.Line([xc-w2, yc], [xc-w2, yc+5]),
    "RightHoles": new makerjs.paths.Circle([xc-w1, yc-h1], r),
  }
  };
  
  //标记5、地弹簧 C位置
  var myFsCMakerD = new makerjs.models.Square(0); //孔的原点       
  myFsCMakerD.origin = [xc,yc];
  var myFsCMakerH = new makerjs.models.Square(0); //孔的原点       
  myFsCMakerH.origin = [xc,yc];
  var myFsCMakerH1 = new makerjs.models.Square(0); //孔的原点       
  myFsCMakerH1.origin = [xc,yc];
  var myFsCMakerR1 = new makerjs.models.Square(0); //孔的原点       
  myFsCMakerR1.origin = [xc,yc];
  var myFsCMakerR2 = new makerjs.models.Square(0); //孔的原点       
  myFsCMakerR2.origin = [xc,yc];
  var myFsCMakerW = new makerjs.models.Square(0); //孔的原点       C 
  myFsCMakerW.origin = [xc,yc];
  var myFsCMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myFsCMakerW1.origin = [xc,yc];
  var myFsCMakerW2 = new makerjs.models.Square(0); //孔的原点       
  myFsCMakerW2.origin = [xc,yc];
   
  // /*****************************************************************************/
  // /*****************************************************************************/
  
  makerjs.model.addPath(myFsCMakerW, new makerjs.paths.Line([-w,-h], [-w,-h-markShiftThree]));//Y轴方向短线  W  right
  
  makerjs.model.addPath(myFsCMakerW, new makerjs.paths.Line([-w1,-h1+r], [-w1,-h1-d-markShiftThree]));//Y轴方向短线  W1  right
  
  makerjs.model.addPath(myFsCMakerW, new makerjs.paths.Line([-w2,-r2], [-w2,-r2-h1-markShiftThree]));//Y轴方向短线  W2  right
  
  makerjs.model.addPath(myFsCMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myFsCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线  H
  
  makerjs.model.addPath(myFsCMakerW, new makerjs.paths.Line([-w1+r,-h1], [-w1-r-markShiftFour, -h1]));//X轴方向短线   H1
  
  makerjs.model.addPath(myFsCMakerW, new makerjs.paths.Line([-w2+r,0-r2], [-w2-markShiftThree, 0-r2]));//X轴方向短线  R2
  
  makerjs.model.addPath(myFsCMakerW, new makerjs.paths.Line([-w2,0], [-w2, 0-r2-markShiftFour]));//Y轴方向短线  R2
  
  // /*****************************************************************************/
  // /*****************************************************************************/   
  makerjs.model.addPath(myFsCMakerW, new makerjs.paths.Line([0,0-h-markShift], [0-w,0-h-markShift]));//W短线标注
  makerjs.model.addCaption(myFsCMakerW, TxtW, [0-w,0-h-markShiftThree], [0,0-h-markShiftThree]);//W文字标注 
  
  // /*****************************************************************************/  
  // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsCMakerW1, new makerjs.paths.Line([0,-h-d-markShift], [-w1,-h-d-markShift]));//W1短线标注
  makerjs.model.addCaption(myFsCMakerW1, TxtW1, [-w1,-h-d-markShiftThree], [0,-h-d-markShiftThree]);//W1文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsCMakerW2, new makerjs.paths.Line([0,-h-h-markShiftThree], [-w2,-h-h-markShiftThree]));//W2短线标注
  makerjs.model.addCaption(myFsCMakerW2, TxtW2, [-w2,-h-h-r-markShiftFour], [0,-h-h-r-markShiftFour]);//W2文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsCMakerH, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo,-h]));//H短线标注
  makerjs.model.addCaption(myFsCMakerH, TxtH, [0+markShiftFour,-h], [0+markShiftFour,0]);//H文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsCMakerH1, new makerjs.paths.Line([-w2-markShift,0], [-w2-markShift,-h1]));//H1短线标注
  makerjs.model.addCaption(myFsCMakerH1, TxtH1, [-w2-markShiftThree,-h1], [-w2-markShiftThree,0]);//H1文字标注  
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsCMakerR2, new makerjs.paths.Line([-aa+markShiftTwo,0], [-aa,-hd]));//R2短线标注
  makerjs.model.addCaption(myFsCMakerR2, TxtR2, [-aa+markShift,-hd+markShift], [-aa+markShiftThree,-hd+markShiftThree]);//R2文字标注   
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/   
  
  makerjs.model.addPath(myFsCMakerR1, new makerjs.paths.Line([-w,-hd], [-w-markShift,-hd-markShift]));//R1短线标注
  makerjs.model.addCaption(myFsCMakerR1, TxtR1, [-w-markShift,-hd-markShift], [-w,-hd]);//R1文字标注    
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsCMakerD, new makerjs.paths.Line([-w1,-h1], [-w1-markShift-markHolesOneD,-h1-d-markShiftFour-markHolesOneD]));//D短线标注
  makerjs.model.addCaption(myFsCMakerD, TxtD,[-w1-markHolesOneD-markShiftFour,-h1-d-markShiftFour-markHolesOneD], [-w1-markShift-markHolesOneD,-h1-d-markShiftFour-markHolesOneD]);//D文字标注 
  
  /*****************************************************************************/  
  /*****************************************************************************/ 
  
  plate.models["myFloorSpringC"+myHolesNum]=myFloorSpringC;
  markers.models["myFloorSpringC"+myHolesNum]=myFloorSpringC;
  markers.models["myFsCMakerD"+myHolesNum]=myFsCMakerD;
  markers.models["myFsCMakerH"+myHolesNum]=myFsCMakerH;
  markers.models["myFsCMakerH1"+myHolesNum]=myFsCMakerH1;
  markers.models["myFsCMakerR1"+myHolesNum]=myFsCMakerR1;
  markers.models["myFsCMakerR2"+myHolesNum]=myFsCMakerR2;
  markers.models["myFsCMakerW"+myHolesNum]=myFsCMakerW;
  markers.models["myFsCMakerW1"+myHolesNum]=myFsCMakerW1;
  markers.models["myFsCMakerW2"+myHolesNum]=myFsCMakerW2;
  // /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpringC"+myHolesNum])
  // makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2AMakerR"+myHolesNum])
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
    
        
        }else if(area==4){
          
        }else if(area==5){
          
        }else if(area==6){
          
        }else if(area==7){
  
      //画5、地弹簧 G位置
  
      console.log("测试坐标=");
  console.log("测试坐标=",CircleTwo(xg+w,yg-r1,xg+w2,yg+r2,r1,r2));
  var arr=[];
  arr=CircleTwo(xg+w,yg-r1,xg+w2,yg+r2,r1,r2);
  a=arr[0]*1;
  b=arr[1]*1;
  var hd=h-(r1-b)
  console.log("测试坐标a=",a);
  console.log("测试坐标b=",b);
  console.log("测试坐标xg+w=",xg+w);
  console.log("测试坐标yg+r1=",yg+r1);
  console.log("测试坐标xg+w2=",xg+w2);
  console.log("测试坐标yg-r2=",yg-r2);
        //画5、地弹簧2 G位置
  
  var myFloorSpringG = {
  paths: {  
    "LeftLine": new makerjs.paths.Line([xg-5, yg-5], [xg-5, yg+h]),
    "TopLine": new makerjs.paths.Line([xg-5, yg+h], [xg+w, yg+h]),
    "TopLeftArc": new makerjs.paths.Arc([xg+w, yg+h], [xg+a, yg+hd],r1,false,true),//是否大弧,是否顺时针  
    "TopRightArc": new makerjs.paths.Arc([xg+a, yg+hd], [xg+w2, yg],r2,false,false),//是否大弧,是否顺时针
    "RightLine": new makerjs.paths.Line([xg+w2, yg], [xg+w2, yg-5]),
    "BottomLine": new makerjs.paths.Line([xg+w2, yg-5], [xg-5, yg-5]),
    "RightHoles": new makerjs.paths.Circle([xg+w1, yg+h1], r),
  }
  };
  
  //标记5、地弹簧 A位置
  var myFsGMakerD = new makerjs.models.Square(0); //孔的原点       
  myFsGMakerD.origin = [xg,yg];
  var myFsGMakerH = new makerjs.models.Square(0); //孔的原点       
  myFsGMakerH.origin = [xg,yg];
  var myFsGMakerH1 = new makerjs.models.Square(0); //孔的原点       
  myFsGMakerH1.origin = [xg,yg];
  var myFsGMakerR1 = new makerjs.models.Square(0); //孔的原点       
  myFsGMakerR1.origin = [xg,yg];
  var myFsGMakerR2 = new makerjs.models.Square(0); //孔的原点       
  myFsGMakerR2.origin = [xg,yg];
  var myFsGMakerW = new makerjs.models.Square(0); //孔的原点       
  myFsGMakerW.origin = [xg,yg];
  var myFsGMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myFsGMakerW1.origin = [xg,yg];
  var myFsGMakerW2 = new makerjs.models.Square(0); //孔的原点       
  myFsGMakerW2.origin = [xg,yg];
   
  // /*****************************************************************************/
  // /*****************************************************************************/
  
  makerjs.model.addPath(myFsGMakerW, new makerjs.paths.Line([w,h], [w,h+markShiftThree]));//Y轴方向短线  W  right
  
  makerjs.model.addPath(myFsGMakerW, new makerjs.paths.Line([w1,h1-r], [w1,h1+d+markShiftThree]));//Y轴方向短线  W1  right
  
  makerjs.model.addPath(myFsGMakerW, new makerjs.paths.Line([w2,r2], [w2,r2+h1+markShiftThree]));//Y轴方向短线  W2  right
  
  makerjs.model.addPath(myFsGMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myFsGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线  H
  
  makerjs.model.addPath(myFsGMakerW, new makerjs.paths.Line([w1-r,h1], [w1+r+markShiftFour, h1]));//X轴方向短线   H1
  
  makerjs.model.addPath(myFsGMakerW, new makerjs.paths.Line([w2-r,0+r2], [w2+markShiftThree, 0+r2]));//X轴方向短线  R2
  
  makerjs.model.addPath(myFsGMakerW, new makerjs.paths.Line([w2,0], [w2, 0+r2+markShiftFour]));//Y轴方向短线  R2
  
  // /*****************************************************************************/
  // /*****************************************************************************/   
  makerjs.model.addPath(myFsGMakerW, new makerjs.paths.Line([0,0+h+markShift], [0+w,0+h+markShift]));//W短线标注
  makerjs.model.addCaption(myFsGMakerW, TxtW, [0,0+h+markShiftThree], [0+w,0+h+markShiftThree]);//W文字标注 
  
  // /*****************************************************************************/  
  // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsGMakerW1, new makerjs.paths.Line([0,h+d+markShift], [w1,h+d+markShift]));//W1短线标注
  makerjs.model.addCaption(myFsGMakerW1, TxtW1, [0,h+d+markShiftThree], [w1,h+d+markShiftThree]);//W1文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsGMakerW2, new makerjs.paths.Line([0,h+h+markShiftThree], [w2,h+h+markShiftThree]));//W2短线标注
  makerjs.model.addCaption(myFsGMakerW2, TxtW2, [0,h+h+r+markShiftFour], [w2,h+h+r+markShiftFour]);//W2文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsGMakerH, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo,h]));//H短线标注
  makerjs.model.addCaption(myFsGMakerH, TxtH, [0-markShiftFour,h], [0-markShiftFour,0]);//H文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsGMakerH1, new makerjs.paths.Line([w2+markShift,0], [w2+markShift,h1]));//H1短线标注
  makerjs.model.addCaption(myFsGMakerH1, TxtH1, [w2+markShiftThree,h1], [w2+markShiftThree,0]);//H1文字标注  
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsGMakerR2, new makerjs.paths.Line([a-markShiftTwo,0], [a,hd]));//R2短线标注
  makerjs.model.addCaption(myFsGMakerR2, TxtR2, [a-markShiftThree,hd-markShiftThree], [a-markShift,hd-markShift]);//R2文字标注   
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/   
  
  makerjs.model.addPath(myFsGMakerR1, new makerjs.paths.Line([w,hd], [w+markShift,hd+markShift]));//R1短线标注
  makerjs.model.addCaption(myFsGMakerR1, TxtR1, [w,hd], [w+markShift,hd+markShift]);//R1文字标注    
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsGMakerD, new makerjs.paths.Line([w1,h1], [w1+markShift+markHolesOneD,h1+d+markShiftFour+markHolesOneD]));//D短线标注
  makerjs.model.addCaption(myFsGMakerD, TxtD, [w1+markShift+markHolesOneD,h1+d+markShiftFour+markHolesOneD],[w1+markHolesOneD+markShiftFour,h1+d+markShiftFour+markHolesOneD]);//D文字标注 
  
  /*****************************************************************************/  
  /*****************************************************************************/ 
  
  plate.models["myFloorSpringG"+myHolesNum]=myFloorSpringG;
  markers.models["myFloorSpringG"+myHolesNum]=myFloorSpringG;
  markers.models["myFsGMakerD"+myHolesNum]=myFsGMakerD;
  markers.models["myFsGMakerH"+myHolesNum]=myFsGMakerH;
  markers.models["myFsGMakerH1"+myHolesNum]=myFsGMakerH1;
  markers.models["myFsGMakerR1"+myHolesNum]=myFsGMakerR1;
  markers.models["myFsGMakerR2"+myHolesNum]=myFsGMakerR2;
  markers.models["myFsGMakerW"+myHolesNum]=myFsGMakerW;
  markers.models["myFsGMakerW1"+myHolesNum]=myFsGMakerW1;
  markers.models["myFsGMakerW2"+myHolesNum]=myFsGMakerW2;
  // /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpringG"+myHolesNum])
  // makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2AMakerR"+myHolesNum])
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
   
          
        }else if(area==8){
          
        }else if(area==9){
         
         //画5、地弹簧 I位置
  //画5、地弹簧 C位置 
  console.log("测试坐标=");
  console.log("测试坐标=",CircleTwo(xi-w,yi-r1,xi-w2,yi+r2,r1,r2));
  var arr=[];
  arr=CircleTwo(xi-w,yi-r1,xi-w2,yi+r2,r1,r2);
  a=arr[0]*1;
  b=arr[1]*1;
  var aa=glassW * 1-a;
  var hd=h-(r1-b)
  console.log("测试坐标a=",a);
  console.log("测试坐标b=",b);
  console.log("测试坐标xi-w=",xi-w);
  console.log("测试坐标yi+r1=",yi+r1);
  console.log("测试坐标xi-w2=",xi-w2);
  console.log("测试坐标yi-r2=",yi-r2);
        //画5、地弹簧2 I位置
  var myFloorSpringI = {
  paths: {
    "BottomLine": new makerjs.paths.Line([xi+5, yi-5], [xi-w2, yi-5]),
    "LeftLine": new makerjs.paths.Line([xi-w2, yi-5], [xi-w2, yi]), 
    "TopLeftArc": new makerjs.paths.Arc([xi-w2, yi], [xi-aa, yi+hd],r2,false,false),//是否大弧,是否顺时针  
    "TopRightArc": new makerjs.paths.Arc([xi-aa, yi+hd], [xi-w, yi+h],r1,false,true),//是否大弧,是否顺时针
    "TopLine": new makerjs.paths.Line([xi-w, yi+h], [xi+5, yi+h]),
    "RightLine": new makerjs.paths.Line([xi+5, yi+h], [xi+5, yi-5]),
    "RightHoles": new makerjs.paths.Circle([xi-w1, yi+h1], r),
  }
  };
  
  //标记5、地弹簧 I位置
  var myFsIMakerD = new makerjs.models.Square(0); //孔的原点       
  myFsIMakerD.origin = [xi,yi];
  var myFsIMakerH = new makerjs.models.Square(0); //孔的原点       
  myFsIMakerH.origin = [xi,yi];
  var myFsIMakerH1 = new makerjs.models.Square(0); //孔的原点       
  myFsIMakerH1.origin = [xi,yi];
  var myFsIMakerR1 = new makerjs.models.Square(0); //孔的原点       
  myFsIMakerR1.origin = [xi,yi];
  var myFsIMakerR2 = new makerjs.models.Square(0); //孔的原点       
  myFsIMakerR2.origin = [xi,yi];
  var myFsIMakerW = new makerjs.models.Square(0); //孔的原点       C 
  myFsIMakerW.origin = [xi,yi];
  var myFsIMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myFsIMakerW1.origin = [xi,yi];
  var myFsIMakerW2 = new makerjs.models.Square(0); //孔的原点       
  myFsIMakerW2.origin = [xi,yi];
   
  // /*****************************************************************************/
  // /*****************************************************************************/
  
  makerjs.model.addPath(myFsIMakerW, new makerjs.paths.Line([-w,h], [-w,h+markShiftThree]));//Y轴方向短线  W  right
  
  makerjs.model.addPath(myFsIMakerW, new makerjs.paths.Line([-w1,h1-r], [-w1,h1+d+markShiftThree]));//Y轴方向短线  W1  right
  
  makerjs.model.addPath(myFsIMakerW, new makerjs.paths.Line([-w2,r2], [-w2,r2+h1+markShiftThree]));//Y轴方向短线  W2  right
  
  makerjs.model.addPath(myFsIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myFsIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线  H
  
  makerjs.model.addPath(myFsIMakerW, new makerjs.paths.Line([-w1+r,h1], [-w1-r-markShiftFour, h1]));//X轴方向短线   H1
  
  makerjs.model.addPath(myFsIMakerW, new makerjs.paths.Line([-w2+r,0+r2], [-w2-markShiftThree, 0+r2]));//X轴方向短线  R2
  
  makerjs.model.addPath(myFsIMakerW, new makerjs.paths.Line([-w2,0], [-w2, 0+r2+markShiftFour]));//Y轴方向短线  R2
  
  // /*****************************************************************************/
  // /*****************************************************************************/   
  makerjs.model.addPath(myFsIMakerW, new makerjs.paths.Line([0,0+h+markShift], [0-w,0+h+markShift]));//W短线标注
  makerjs.model.addCaption(myFsIMakerW, TxtW, [0-w,0+h+markShiftThree], [0,0+h+markShiftThree]);//W文字标注 
  
  // /*****************************************************************************/  
  // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsIMakerW1, new makerjs.paths.Line([0,h+d+markShift], [-w1,h+d+markShift]));//W1短线标注
  makerjs.model.addCaption(myFsIMakerW1, TxtW1, [-w1,h+d+markShiftThree], [0,h+d+markShiftThree]);//W1文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsIMakerW2, new makerjs.paths.Line([0,h+h+markShiftThree], [-w2,h+h+markShiftThree]));//W2短线标注
  makerjs.model.addCaption(myFsIMakerW2, TxtW2, [-w2,h+h+r+markShiftFour], [0,h+h+r+markShiftFour]);//W2文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsIMakerH, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo,h]));//H短线标注
  makerjs.model.addCaption(myFsIMakerH, TxtH, [0+markShiftFour,h], [0+markShiftFour,0]);//H文字标注 
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsIMakerH1, new makerjs.paths.Line([-w2-markShift,0], [-w2-markShift,h1]));//H1短线标注
  makerjs.model.addCaption(myFsIMakerH1, TxtH1, [-w2-markShiftThree,h1], [-w2-markShiftThree,0]);//H1文字标注  
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsIMakerR2, new makerjs.paths.Line([-aa+markShiftTwo,0], [-aa,hd]));//R2短线标注
  makerjs.model.addCaption(myFsIMakerR2, TxtR2, [-aa+markShift,hd-markShift], [-aa+markShiftThree,hd-markShiftThree]);//R2文字标注   
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/   
  
  makerjs.model.addPath(myFsIMakerR1, new makerjs.paths.Line([-w,hd], [-w-markShift,hd+markShift]));//R1短线标注
  makerjs.model.addCaption(myFsIMakerR1, TxtR1, [-w-markShift,hd+markShift], [-w,hd]);//R1文字标注    
  
  // // /*****************************************************************************/  
  // // /*****************************************************************************/  
   
  makerjs.model.addPath(myFsIMakerD, new makerjs.paths.Line([-w1,h1], [-w1-markShift-markHolesOneD,h1+d+markShiftFour+markHolesOneD]));//D短线标注
  makerjs.model.addCaption(myFsIMakerD, TxtD,[-w1-markHolesOneD-markShiftFour,h1+d+markShiftFour+markHolesOneD], [-w1-markShift-markHolesOneD,h1+d+markShiftFour+markHolesOneD]);//D文字标注 
  
  /*****************************************************************************/  
  /*****************************************************************************/ 
  
  plate.models["myFloorSpringI"+myHolesNum]=myFloorSpringI;
  markers.models["myFloorSpringI"+myHolesNum]=myFloorSpringI;
  markers.models["myFsIMakerD"+myHolesNum]=myFsIMakerD;
  markers.models["myFsIMakerH"+myHolesNum]=myFsIMakerH;
  markers.models["myFsIMakerH1"+myHolesNum]=myFsIMakerH1;
  markers.models["myFsIMakerR1"+myHolesNum]=myFsIMakerR1;
  markers.models["myFsIMakerR2"+myHolesNum]=myFsIMakerR2;
  markers.models["myFsIMakerW"+myHolesNum]=myFsIMakerW;
  markers.models["myFsIMakerW1"+myHolesNum]=myFsIMakerW1;
  markers.models["myFsIMakerW2"+myHolesNum]=myFsIMakerW2;
  // /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpringI"+myHolesNum])
  // makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2AMakerR"+myHolesNum])
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
     
  
  
        }

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};
export default FloorSpring;