//角1、斜角
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ObliqueAngle(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var H=inputParam.H,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   h = H*1*myZoom,
   w= W*1*myZoom,
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     /*
     h = H*1,
     w= W*1,
       x0 = X*1,
       y0 = Y*1;
       */
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画1、斜角 A位置
    var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-5,ya+5],[xa-5,ya-h],[xa,ya-h],[xa+w,ya],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 
  
    }else if(area==2){
      
    }else if(area==3){
      
     //画1、斜角 C位置
     var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc],[xc-w,yc+5],[xc+5,yc+5],[xc+5,yc-h],[xc,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
 
    }else if(area==4){
      
    }else if(area==5){
      
    }else if(area==6){
      
    }else if(area==7){

      //画1、斜角 G位置
     var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

      
    }else if(area==8){
      
    }else if(area==9){
     
       //画1、斜角 I位置
     var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+5,yi-5],[xi+5,yi+h],[xi,yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myOaAngleI"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

    }

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default ObliqueAngle;