
//3、吊轮孔3
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HangWheelHoleThree(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   d = D*1*myZoom,
    x0 = X*1*myZoom,
    y0 = Y*1*myZoom;
  if(d!==0){
   var TxtD="∮"+D;
  }
  if(x0!==0){          
  var TxtX=X;
  }
  if(y0!==0){          
   var TxtY=Y;
  }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
//画孔 A位置孔
      //标记吊轮孔3 A位置孔
      var myHwthHolesA= new makerjs.models.Holes(d/2, [[xa, ya]]);
      var myHwthHolesMarkA = new makerjs.models.Square(0); //孔的原点       
          myHwthHolesMarkA.origin = [xa, ya];      
      var myHwthXMarkA = new makerjs.models.Square(0);      
      myHwthXMarkA.origin =[xa, ya];      
      var myHwthYMarkA = new makerjs.models.Square(0);      
      myHwthYMarkA.origin = [xa, ya]; 
/*****************************************************************************/ 
makerjs.model.addPath(myHwthHolesMarkA, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHwthHolesMarkA, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHwthHolesMarkA, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHwthHolesMarkA, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwthHolesMarkA, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwthHolesMarkA, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwthXMarkA, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwthXMarkA, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHwthYMarkA, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myHwthYMarkA, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
 //标记吊轮孔3  D位置孔
 //画孔
 var myHwthHolesD= new makerjs.models.Holes(d/2, [[xd, yd]]);
      var myHwthHolesMarkD = new makerjs.models.Square(0); //孔的原点       
      myHwthHolesMarkD.origin = [xd, yd];          
      var myHwthYMarkD = new makerjs.models.Square(0);      
      myHwthYMarkD.origin = [xd, yd];  
/*****************************************************************************/
makerjs.model.addPath(myHwthHolesMarkD, new makerjs.paths.Line([-markShift/2,0], [markShift/2, 0]));//短线标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwthHolesMarkD, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwthHolesMarkD, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHwthYMarkD, new makerjs.paths.Line([0,0], [-x0, 0]));//X标线
makerjs.model.addCaption(myHwthYMarkD, TxtX, [-x0, 0+markShift], [0,0+markShift]);//X文字标注
/*****************************************************************************/ 
 //标记吊轮孔3  G位置孔
  //画孔
     var myHwthHolesG= new makerjs.models.Holes(d/2, [[xg, yg]]);
      var myHwthHolesMarkG = new makerjs.models.Square(0); //孔的原点       
      myHwthHolesMarkG.origin = [xg, yg];      
      var myHwthXMarkG = new makerjs.models.Square(0);      
      myHwthXMarkG.origin = [xg, yg];      
      var myHwthYMarkG = new makerjs.models.Square(0);      
      myHwthYMarkG.origin = [xg, yg];  
/*****************************************************************************/  
////makerjs.model.addPath(myHwthHolesMarkG, new makerjs.paths.Line([x0,y0], [x0+markShiftTwo, y0]));//Y轴加长标线
//makerjs.model.addPath(myHwthHolesMarkG, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//X轴加长标线
//makerjs.model.addPath(myHwthXMarkG, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));////从孔圆心出发Y方向横向标线
//makerjs.model.addPath(myHwthXMarkG, new makerjs.paths.Line([0,0], [x0+markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwthHolesMarkG, new makerjs.paths.Line([0,0], [d, d]));//直径标线
makerjs.model.addCaption(myHwthHolesMarkG, TxtD, [0, 0], [d+markHolesOneD,d+markHolesOneD]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwthXMarkG, new makerjs.paths.Line([0,0], [-x0, 0]));//X短标线
makerjs.model.addCaption(myHwthXMarkG, TxtX, [-x0, markShiftTwo], [0,markShiftTwo]);//X文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHwthYMarkG, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
makerjs.model.addCaption(myHwthYMarkG, TxtY, [+markShiftTwo, -y0], [+markShiftTwo,0]);//Y文字标注
/*****************************************************************************/ 
plate.models["myHwthHolesA"+myHolesNum]=myHwthHolesA;
markers.models["myHwthHolesA"+myHolesNum]=myHwthHolesA;
markers.models["myHwthHolesMarkA"+myHolesNum]=myHwthHolesMarkA;
markers.models["myHwthXMarkA"+myHolesNum]=myHwthXMarkA;
markers.models["myHwthYMarkA"+myHolesNum]=myHwthYMarkA;
/*****************************************************************************/
plate.models["myHwthHolesD"+myHolesNum]=myHwthHolesD;
markers.models["myHwthHolesD"+myHolesNum]=myHwthHolesD;
markers.models["myHwthHolesMarkD"+myHolesNum]=myHwthHolesMarkD;
markers.models["myHwthYMarkD"+myHolesNum]=myHwthYMarkD;
/*****************************************************************************/
plate.models["myHwthHolesG"+myHolesNum]=myHwthHolesG;
markers.models["myHwthHolesG"+myHolesNum]=myHwthHolesG;
markers.models["myHwthHolesMarkG"+myHolesNum]=myHwthHolesMarkG;
markers.models["myHwthXMarkG"+myHolesNum]=myHwthXMarkG;
markers.models["myHwthYMarkG"+myHolesNum]=myHwthYMarkG;

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default HangWheelHoleThree;