//缺8、椭圆
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function EllipseGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var Rx=inputParam.Rx,Ry=inputParam.Ry,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   rx= Rx*1*myZoom,
      ry= Ry*1*myZoom, 
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        rx= Rx*1,
        ry= Ry*1, 
          x0 = X*1,
          y0 = Y*1;
          */
        if(rx!==0){
         var TxtRx=Rx;
        }
        if(ry!==0){
         var TxtRy=Ry;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //5、椭圆形缺 A位置 
        var myEgGapAEllipse=new makerjs.models.Ellipse(rx, ry); 
        myEgGapAEllipse.origin=[xa,ya]; 
     //画5、椭圆形缺标记 A位置    
  var myEgGapAMakerRx = new makerjs.models.Square(0); //W的起始点       
  myEgGapAMakerRx.origin = [xa,ya];
  var myEgGapAMakerRy = new makerjs.models.Square(0); //H的起始点        
  myEgGapAMakerRy.origin = [xa,ya];
  var myEgGapAMakerY = new makerjs.models.Square(0); //Y的起始点        
  myEgGapAMakerY.origin = [xa,ya];
  var myEgGapAMakerX = new makerjs.models.Square(0); //Y的起始点        
  myEgGapAMakerX.origin = [xa,ya];
  /*****************************************************************************/
  makerjs.model.addPath(myEgGapAMakerRx, new makerjs.paths.Line([-x0,0], [-x0, y0+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myEgGapAMakerRx, new makerjs.paths.Line([0,0], [0, y0+markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(myEgGapAMakerRx, new makerjs.paths.Line([-rx,0], [-rx, ry+markShiftFour]));//Y轴短标线  Rx
  makerjs.model.addPath(myEgGapAMakerRx, new makerjs.paths.Line([rx,0], [rx, ry+markShiftFour]));//Y轴加长标  Rx
  
  makerjs.model.addPath(myEgGapAMakerRx, new makerjs.paths.Line([-x0,y0], [-x0-markShiftFour, y0]));//X轴加长标线
  makerjs.model.addPath(myEgGapAMakerRx, new makerjs.paths.Line([-x0,0], [-x0-markShiftFour, 0]));//X轴方向短线
  
  makerjs.model.addPath(myEgGapAMakerRx, new makerjs.paths.Line([0,-ry], [rx+markShiftFour, -ry]));//X轴加长标线       Ry
  makerjs.model.addPath(myEgGapAMakerRx, new makerjs.paths.Line([0,+ry], [rx+markShiftFour, +ry]));//X轴方向短线  Ry
  
  makerjs.model.addPath(myEgGapAMakerY, new makerjs.paths.Line([-x0-markShiftTwo,y0], [-x0-markShiftTwo, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myEgGapAMakerY,TxtY, [-x0-markShiftFour, 0],[-x0-markShiftFour,y0] );//Y文字标注
  
  makerjs.model.addPath(myEgGapAMakerX, new makerjs.paths.Line([-x0,y0+markShiftTwo], [0, y0+markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myEgGapAMakerX,TxtX, [-x0,y0+markShiftFour],[0, y0+markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapAMakerRx, new makerjs.paths.Line([-rx,+ry+markShiftTwo], [rx, +ry+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myEgGapAMakerRx, TxtRx, [-rx,+ry+markShiftFour], [rx, +ry+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapAMakerRy, new makerjs.paths.Line([rx+markShiftTwo,-ry], [rx+markShiftTwo,+ry]));////R短线标注直径标线
  makerjs.model.addCaption(myEgGapAMakerRy, TxtRy, [rx+markShiftFour,-ry], [rx+markShiftFour, +ry]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myEgGapAEllipse"+myHolesNum]=myEgGapAEllipse;
  markers.models["myEgGapAEllipse"+myHolesNum]=myEgGapAEllipse;
  markers.models["myEgGapAMakerRx"+myHolesNum]=myEgGapAMakerRx;
  markers.models["myEgGapAMakerRy"+myHolesNum]=myEgGapAMakerRy;
  markers.models["myEgGapAMakerX"+myHolesNum]=myEgGapAMakerX;
  markers.models["myEgGapAMakerY"+myHolesNum]=myEgGapAMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myEgGapAEllipse"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  }else if(area==2){
     //画缺 B位置
        //5、椭圆形缺 B位置 
        var myEgGapBEllipse=new makerjs.models.Ellipse(rx, ry); 
        myEgGapBEllipse.origin=[xb,yb]; 
     //画5、椭圆形缺标记 B位置    
  var myEgGapBMakerRx = new makerjs.models.Square(0); //W的起始点       
  myEgGapBMakerRx.origin = [xb,yb];
  var myEgGapBMakerRy = new makerjs.models.Square(0); //H的起始点        
  myEgGapBMakerRy.origin = [xb,yb];
  var myEgGapBMakerY = new makerjs.models.Square(0); //Y的起始点        
  myEgGapBMakerY.origin = [xb,yb];
  var myEgGapBMakerX = new makerjs.models.Square(0); //Y的起始点        
  myEgGapBMakerX.origin = [xb,yb];
  /*****************************************************************************/
  makerjs.model.addPath(myEgGapBMakerRx, new makerjs.paths.Line([-rx,0], [-rx, ry+markShiftFour]));//Y轴短标线  Rx
  makerjs.model.addPath(myEgGapBMakerRx, new makerjs.paths.Line([rx,0], [rx, ry+markShiftFour]));//Y轴加长标  Rx
  
  makerjs.model.addPath(myEgGapBMakerRx, new makerjs.paths.Line([-x0-rx,0], [-x0-rx-markShiftFour, 0]));//X轴方向短线
  
  makerjs.model.addPath(myEgGapBMakerRx, new makerjs.paths.Line([0,-ry], [rx+markShiftFour, -ry]));//X轴加长标线       Ry
  makerjs.model.addPath(myEgGapBMakerRx, new makerjs.paths.Line([0,+ry], [rx+markShiftFour, +ry]));//X轴方向短线  Ry
  
  makerjs.model.addPath(myEgGapBMakerY, new makerjs.paths.Line([-x0-rx-markShiftTwo,y0], [-x0-rx-markShiftTwo, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myEgGapBMakerY,TxtY, [-x0-rx-markShiftFour, 0],[-x0-rx-markShiftFour,y0] );//Y文字标注
  
  makerjs.model.addPath(myEgGapBMakerX, new makerjs.paths.Line([-x0,y0+markShiftTwo], [0, y0+markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myEgGapBMakerX,TxtX, [-x0,y0+markShiftFour],[0, y0+markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapBMakerRx, new makerjs.paths.Line([-rx,+ry+markShiftTwo], [rx, +ry+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myEgGapBMakerRx, TxtRx, [-rx,+ry+markShiftFour], [rx, +ry+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapBMakerRy, new makerjs.paths.Line([rx+markShiftTwo,-ry], [rx+markShiftTwo,+ry]));////R短线标注直径标线
  makerjs.model.addCaption(myEgGapBMakerRy, TxtRy, [rx+markShiftFour,-ry], [rx+markShiftFour, +ry]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myEgGapBEllipse"+myHolesNum]=myEgGapBEllipse;
  markers.models["myEgGapBEllipse"+myHolesNum]=myEgGapBEllipse;
  markers.models["myEgGapBMakerRx"+myHolesNum]=myEgGapBMakerRx;
  markers.models["myEgGapBMakerRy"+myHolesNum]=myEgGapBMakerRy;
  markers.models["myEgGapBMakerX"+myHolesNum]=myEgGapBMakerX;
  markers.models["myEgGapBMakerY"+myHolesNum]=myEgGapBMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myEgGapBEllipse"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/      
  }else if(area==3){
   //画缺 C位置
        //5、椭圆形缺 C位置 
        var myEgGapCEllipse=new makerjs.models.Ellipse(rx, ry); 
        myEgGapCEllipse.origin=[xc,yc]; 
     //画5、椭圆形缺标记 C位置    
  var myEgGapCMakerRx = new makerjs.models.Square(0); //W的起始点       
  myEgGapCMakerRx.origin = [xc,yc];
  var myEgGapCMakerRy = new makerjs.models.Square(0); //H的起始点        
  myEgGapCMakerRy.origin = [xc,yc];
  var myEgGapCMakerY = new makerjs.models.Square(0); //Y的起始点        
  myEgGapCMakerY.origin = [xc,yc];
  var myEgGapCMakerX = new makerjs.models.Square(0); //Y的起始点        
  myEgGapCMakerX.origin = [xc,yc];
  /*****************************************************************************/
  makerjs.model.addPath(myEgGapCMakerRx, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myEgGapCMakerRx, new makerjs.paths.Line([0,y0], [0, y0+markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(myEgGapCMakerRx, new makerjs.paths.Line([-rx,0], [-rx, 0+ry+markShiftFour]));//Y轴短标线  Rx
  makerjs.model.addPath(myEgGapCMakerRx, new makerjs.paths.Line([rx,0], [rx, 0+ry+markShiftFour]));//Y轴加长标  Rx
  
  makerjs.model.addPath(myEgGapCMakerRx, new makerjs.paths.Line([-x0,y0], [-x0-markShiftFour, y0]));//X轴加长标线
  makerjs.model.addPath(myEgGapCMakerRx, new makerjs.paths.Line([-rx,0], [-rx-markShiftFour, 0]));//X轴方向短线
  
  makerjs.model.addPath(myEgGapCMakerRx, new makerjs.paths.Line([0,-ry], [rx+markShiftFour, -ry]));//X轴加长标线       Ry
  makerjs.model.addPath(myEgGapCMakerRx, new makerjs.paths.Line([0,+ry], [rx+markShiftFour, +ry]));//X轴方向短线  Ry
  
  makerjs.model.addPath(myEgGapCMakerY, new makerjs.paths.Line([-rx-markShiftTwo,y0], [-rx-markShiftTwo, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myEgGapCMakerY,TxtY, [-rx-markShiftFour, 0],[-rx-markShiftFour,y0] );//Y文字标注
  
  makerjs.model.addPath(myEgGapCMakerX, new makerjs.paths.Line([0,y0+markShiftTwo], [x0, y0+markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myEgGapCMakerX,TxtX, [0,y0+markShiftFour],[x0, y0+markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapCMakerRx, new makerjs.paths.Line([-rx,+ry+markShiftTwo], [rx, +ry+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myEgGapCMakerRx, TxtRx, [-rx,+ry+markShiftFour], [rx, +ry+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapCMakerRy, new makerjs.paths.Line([rx+markShiftTwo,-ry], [rx+markShiftTwo,+ry]));////R短线标注直径标线
  makerjs.model.addCaption(myEgGapCMakerRy, TxtRy, [rx+markShiftFour,-ry], [rx+markShiftFour, +ry]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myEgGapCEllipse"+myHolesNum]=myEgGapCEllipse;
  markers.models["myEgGapCEllipse"+myHolesNum]=myEgGapCEllipse;
  markers.models["myEgGapCMakerRx"+myHolesNum]=myEgGapCMakerRx;
  markers.models["myEgGapCMakerRy"+myHolesNum]=myEgGapCMakerRy;
  markers.models["myEgGapCMakerX"+myHolesNum]=myEgGapCMakerX;
  markers.models["myEgGapCMakerY"+myHolesNum]=myEgGapCMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myEgGapCEllipse"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  }else if(area==4){
   //画缺 D位置
        //5、椭圆形缺 D位置 
        var myEgGapDEllipse=new makerjs.models.Ellipse(rx, ry); 
        myEgGapDEllipse.origin=[xd,yd]; 
     //画5、椭圆形缺标记 D位置    
  var myEgGapDMakerRx = new makerjs.models.Square(0); //W的起始点       
  myEgGapDMakerRx.origin = [xd,yd];
  var myEgGapDMakerRy = new makerjs.models.Square(0); //H的起始点        
  myEgGapDMakerRy.origin = [xd,yd];
  var myEgGapDMakerY = new makerjs.models.Square(0); //Y的起始点        
  myEgGapDMakerY.origin = [xd,yd];
  var myEgGapDMakerX = new makerjs.models.Square(0); //Y的起始点        
  myEgGapDMakerX.origin = [xd,yd];
  /*****************************************************************************/
  makerjs.model.addPath(myEgGapDMakerRx, new makerjs.paths.Line([0,0], [0, y0-ry-markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(myEgGapDMakerRx, new makerjs.paths.Line([-rx,0], [-rx, ry+markShiftFour]));//Y轴短标线  Rx
  makerjs.model.addPath(myEgGapDMakerRx, new makerjs.paths.Line([rx,0], [rx, ry+markShiftFour]));//Y轴加长标  Rx
  
  makerjs.model.addPath(myEgGapDMakerRx, new makerjs.paths.Line([0,0-ry], [rx+markShiftFour, -ry]));//X轴加长标线       Ry
  makerjs.model.addPath(myEgGapDMakerRx, new makerjs.paths.Line([0,0+ry], [rx+markShiftFour, +ry]));//X轴方向短线  Ry
  
  makerjs.model.addPath(myEgGapDMakerY, new makerjs.paths.Line([-x0-markShiftTwo,y0], [-x0-markShiftTwo, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myEgGapDMakerY,TxtY, [-x0-markShiftFour, 0],[-x0-markShiftFour,y0] );//Y文字标注
  
  makerjs.model.addPath(myEgGapDMakerX, new makerjs.paths.Line([-x0,y0-ry-markShiftTwo], [0, y0-ry-markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myEgGapDMakerX,TxtX, [-x0,y0-ry-markShiftFour],[0, y0-ry-markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapDMakerRx, new makerjs.paths.Line([-rx,ry+markShiftTwo], [rx, ry+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myEgGapDMakerRx, TxtRx, [-rx,ry+markShiftFour], [rx, ry+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapDMakerRy, new makerjs.paths.Line([rx+markShiftTwo,-ry], [rx+markShiftTwo,+ry]));////R短线标注直径标线
  makerjs.model.addCaption(myEgGapDMakerRy, TxtRy, [rx+markShiftFour,-ry], [rx+markShiftFour, +ry]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myEgGapDEllipse"+myHolesNum]=myEgGapDEllipse;
  markers.models["myEgGapDEllipse"+myHolesNum]=myEgGapDEllipse;
  markers.models["myEgGapDMakerRx"+myHolesNum]=myEgGapDMakerRx;
  markers.models["myEgGapDMakerRy"+myHolesNum]=myEgGapDMakerRy;
  markers.models["myEgGapDMakerX"+myHolesNum]=myEgGapDMakerX;
  markers.models["myEgGapDMakerY"+myHolesNum]=myEgGapDMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myEgGapDEllipse"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/        
  }else if(area==5){ 
     //画缺 E位置
        //5、椭圆形缺 E位置 
        var myEgGapEEllipse=new makerjs.models.Ellipse(rx, ry); 
        myEgGapEEllipse.origin=[xe,ye]; 
     //画5、椭圆形缺标记 E位置    
  var myEgGapEMakerRx = new makerjs.models.Square(0); //W的起始点       
  myEgGapEMakerRx.origin = [xe,ye];
  var myEgGapEMakerRy = new makerjs.models.Square(0); //H的起始点        
  myEgGapEMakerRy.origin = [xe,ye];
  var myEgGapEMakerY = new makerjs.models.Square(0); //Y的起始点        
  myEgGapEMakerY.origin = [xe,ye];
  var myEgGapEMakerX = new makerjs.models.Square(0); //Y的起始点        
  myEgGapEMakerX.origin = [xe,ye];
  /*****************************************************************************/
  makerjs.model.addPath(myEgGapEMakerRx, new makerjs.paths.Line([0-rx,0], [0-rx, ry+markShiftFour]));//Y轴短标线  Rx
  makerjs.model.addPath(myEgGapEMakerRx, new makerjs.paths.Line([0+rx,0], [0+rx, ry+markShiftFour]));//Y轴加长标  Rx
  
  
  makerjs.model.addPath(myEgGapEMakerRx, new makerjs.paths.Line([0,-ry], [0+rx+markShiftFour, -ry]));//X轴加长标线       Ry
  makerjs.model.addPath(myEgGapEMakerRx, new makerjs.paths.Line([0,+ry], [0+rx+markShiftFour, ry]));//X轴方向短线  Ry
  
  makerjs.model.addPath(myEgGapEMakerY, new makerjs.paths.Line([-x0-rx-markShiftTwo,y0], [-x0-rx-markShiftTwo, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myEgGapEMakerY,TxtY, [-x0-rx-markShiftFour, 0],[-x0-rx-markShiftFour,y0] );//Y文字标注
  
  makerjs.model.addPath(myEgGapEMakerX, new makerjs.paths.Line([-x0,y0+markShiftTwo], [0, y0+markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myEgGapEMakerX,TxtX, [-x0,y0+markShiftFour],[0, y0+markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapEMakerRx, new makerjs.paths.Line([0-rx,ry+markShiftTwo], [0+rx, ry+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myEgGapEMakerRx, TxtRx, [0-rx,ry+markShiftFour], [0+rx, ry+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapEMakerRy, new makerjs.paths.Line([0+rx+markShiftTwo,-ry], [0+rx+markShiftTwo,+ry]));////R短线标注直径标线
  makerjs.model.addCaption(myEgGapEMakerRy, TxtRy, [0+rx+markShiftFour,-ry], [0+rx+markShiftFour, +ry]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myEgGapEEllipse"+myHolesNum]=myEgGapEEllipse;
  markers.models["myEgGapEEllipse"+myHolesNum]=myEgGapEEllipse;
  markers.models["myEgGapEMakerRx"+myHolesNum]=myEgGapEMakerRx;
  markers.models["myEgGapEMakerRy"+myHolesNum]=myEgGapEMakerRy;
  markers.models["myEgGapEMakerX"+myHolesNum]=myEgGapEMakerX;
  markers.models["myEgGapEMakerY"+myHolesNum]=myEgGapEMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myEgGapEEllipse"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/   
  }else if(area==6){
   //画缺 F位置
        //5、椭圆形缺 F位置 
        var myEgGapFEllipse=new makerjs.models.Ellipse(rx, ry); 
        myEgGapFEllipse.origin=[xf,yf]; 
     //画5、椭圆形缺标记 F位置    
  var myEgGapFMakerRx = new makerjs.models.Square(0); //W的起始点       
  myEgGapFMakerRx.origin = [xf,yf];
  var myEgGapFMakerRy = new makerjs.models.Square(0); //H的起始点        
  myEgGapFMakerRy.origin = [xf,yf];
  var myEgGapFMakerY = new makerjs.models.Square(0); //Y的起始点        
  myEgGapFMakerY.origin = [xf,yf];
  var myEgGapFMakerX = new makerjs.models.Square(0); //Y的起始点        
  myEgGapFMakerX.origin = [xf,yf];
  /*****************************************************************************/
  makerjs.model.addPath(myEgGapFMakerRx, new makerjs.paths.Line([x0,0], [x0, y0-ry-markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myEgGapFMakerRx, new makerjs.paths.Line([0,0], [0, y0-ry-markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(myEgGapFMakerRx, new makerjs.paths.Line([-rx,0], [-rx, 0+ry+markShiftFour]));//Y轴短标线  Rx
  makerjs.model.addPath(myEgGapFMakerRx, new makerjs.paths.Line([rx,0], [rx, 0+ry+markShiftFour]));//Y轴加长标  Rx
  
  
  makerjs.model.addPath(myEgGapFMakerRx, new makerjs.paths.Line([0,0-ry], [rx+markShiftFour, 0-ry]));//X轴加长标线       Ry
  makerjs.model.addPath(myEgGapFMakerRx, new makerjs.paths.Line([0,0+ry], [rx+markShiftFour, 0+ry]));//X轴方向短线  Ry
  
  
  makerjs.model.addPath(myEgGapFMakerX, new makerjs.paths.Line([0,y0-ry-markShiftTwo], [x0, y0-ry-markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myEgGapFMakerX,TxtX, [0,y0-ry-markShiftFour],[x0, y0-ry-markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapFMakerRx, new makerjs.paths.Line([-rx,ry+markShiftTwo], [rx, ry+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myEgGapFMakerRx, TxtRx, [-rx,ry+markShiftFour], [rx, ry+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapFMakerRy, new makerjs.paths.Line([rx+markShiftTwo,-ry], [rx+markShiftTwo,ry]));////R短线标注直径标线
  makerjs.model.addCaption(myEgGapFMakerRy, TxtRy, [rx+markShiftFour,-ry], [rx+markShiftFour, ry]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myEgGapFEllipse"+myHolesNum]=myEgGapFEllipse;
  markers.models["myEgGapFEllipse"+myHolesNum]=myEgGapFEllipse;
  markers.models["myEgGapFMakerRx"+myHolesNum]=myEgGapFMakerRx;
  markers.models["myEgGapFMakerRy"+myHolesNum]=myEgGapFMakerRy;
  markers.models["myEgGapFMakerX"+myHolesNum]=myEgGapFMakerX;
  markers.models["myEgGapFMakerY"+myHolesNum]=myEgGapFMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myEgGapFEllipse"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/      
  }else if(area==7){
  //画缺 G位置
        //5、椭圆形缺 G位置 
        var myEgGapGEllipse=new makerjs.models.Ellipse(rx, ry); 
        myEgGapGEllipse.origin=[xg,yg]; 
     //画5、椭圆形缺标记 G位置    
  var myEgGapGMakerRx = new makerjs.models.Square(0); //W的起始点       
  myEgGapGMakerRx.origin = [xg,yg];
  var myEgGapGMakerRy = new makerjs.models.Square(0); //H的起始点        
  myEgGapGMakerRy.origin = [xg,yg];
  var myEgGapGMakerY = new makerjs.models.Square(0); //Y的起始点        
  myEgGapGMakerY.origin = [xg,yg];
  var myEgGapGMakerX = new makerjs.models.Square(0); //Y的起始点        
  myEgGapGMakerX.origin = [xg,yg];
  /*****************************************************************************/
  makerjs.model.addPath(myEgGapGMakerRx, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftFour]));//Y轴加长标线  X
  makerjs.model.addPath(myEgGapGMakerRx, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//Y轴短标线       X
  
  makerjs.model.addPath(myEgGapGMakerRx, new makerjs.paths.Line([-rx,0], [-rx, ry+markShiftFour]));//Y轴短标线  Rx
  makerjs.model.addPath(myEgGapGMakerRx, new makerjs.paths.Line([rx,0], [rx, ry+markShiftFour]));//Y轴加长标  Rx
  
  makerjs.model.addPath(myEgGapGMakerRx, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftFour, -y0]));//X轴加长标线  Y
  makerjs.model.addPath(myEgGapGMakerRx, new makerjs.paths.Line([-x0,0], [-x0-markShiftFour, 0]));//X轴方向短线    Y
  
  makerjs.model.addPath(myEgGapGMakerRx, new makerjs.paths.Line([0,-ry], [rx+markShiftFour, -ry]));//X轴加长标线       Ry
  makerjs.model.addPath(myEgGapGMakerRx, new makerjs.paths.Line([0,+ry], [rx+markShiftFour, +ry]));//X轴方向短线  Ry
  
  makerjs.model.addPath(myEgGapGMakerY, new makerjs.paths.Line([-x0-markShiftTwo,-y0], [-x0-markShiftTwo, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myEgGapGMakerY,TxtY, [-x0-markShiftFour, 0],[-x0-markShiftFour,-y0] );//Y文字标注
  
  makerjs.model.addPath(myEgGapGMakerX, new makerjs.paths.Line([-x0,-y0-markShiftTwo], [0, -y0-markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myEgGapGMakerX,TxtX, [-x0,-y0-markShiftFour],[0, -y0-markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapGMakerRx, new makerjs.paths.Line([-rx,+ry+markShiftTwo], [rx, +ry+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myEgGapGMakerRx, TxtRx, [-rx,+ry+markShiftFour], [rx, +ry+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapGMakerRy, new makerjs.paths.Line([rx+markShiftTwo,-ry], [rx+markShiftTwo,+ry]));////R短线标注直径标线
  makerjs.model.addCaption(myEgGapGMakerRy, TxtRy, [rx+markShiftFour,-ry], [rx+markShiftFour, +ry]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myEgGapGEllipse"+myHolesNum]=myEgGapGEllipse;
  markers.models["myEgGapGEllipse"+myHolesNum]=myEgGapGEllipse;
  markers.models["myEgGapGMakerRx"+myHolesNum]=myEgGapGMakerRx;
  markers.models["myEgGapGMakerRy"+myHolesNum]=myEgGapGMakerRy;
  markers.models["myEgGapGMakerX"+myHolesNum]=myEgGapGMakerX;
  markers.models["myEgGapGMakerY"+myHolesNum]=myEgGapGMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myEgGapGEllipse"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/       
  }else if(area==8){
     //画缺 H位置
        //5、椭圆形缺 H位置 
        var myEgGapHEllipse=new makerjs.models.Ellipse(rx, ry); 
        myEgGapHEllipse.origin=[xh,yh]; 
     //画5、椭圆形缺标记 H位置    
  var myEgGapHMakerRx = new makerjs.models.Square(0); //W的起始点       
  myEgGapHMakerRx.origin = [xh,yh];
  var myEgGapHMakerRy = new makerjs.models.Square(0); //H的起始点        
  myEgGapHMakerRy.origin = [xh,yh];
  var myEgGapHMakerY = new makerjs.models.Square(0); //Y的起始点        
  myEgGapHMakerY.origin = [xh,yh];
  var myEgGapHMakerX = new makerjs.models.Square(0); //Y的起始点        
  myEgGapHMakerX.origin = [xh,yh];
  /*****************************************************************************/
  makerjs.model.addPath(myEgGapHMakerRx, new makerjs.paths.Line([-rx,0], [-rx, ry+markShiftFour]));//Y轴短标线  Rx
  makerjs.model.addPath(myEgGapHMakerRx, new makerjs.paths.Line([rx,0], [rx, ry+markShiftFour]));//Y轴加长标  Rx
  
  makerjs.model.addPath(myEgGapHMakerRx, new makerjs.paths.Line([-x0-rx,0], [-x0-rx-markShiftFour, 0]));//X轴方向短线
  
  makerjs.model.addPath(myEgGapHMakerRx, new makerjs.paths.Line([0,-ry], [rx+markShiftFour, -ry]));//X轴加长标线       Ry
  makerjs.model.addPath(myEgGapHMakerRx, new makerjs.paths.Line([0,+ry], [rx+markShiftFour, +ry]));//X轴方向短线  Ry
  
  makerjs.model.addPath(myEgGapHMakerY, new makerjs.paths.Line([-x0-rx-markShiftTwo,-y0], [-x0-rx-markShiftTwo, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myEgGapHMakerY,TxtY, [-x0-rx-markShiftFour, 0],[-x0-rx-markShiftFour,-y0] );//Y文字标注
  
  makerjs.model.addPath(myEgGapHMakerX, new makerjs.paths.Line([-x0,y0+markShiftTwo], [0, y0+markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myEgGapHMakerX,TxtX, [-x0,y0+markShiftFour],[0, y0+markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapHMakerRx, new makerjs.paths.Line([-rx,+ry+markShiftTwo], [rx, +ry+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myEgGapHMakerRx, TxtRx, [-rx,+ry+markShiftFour], [rx, +ry+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapHMakerRy, new makerjs.paths.Line([rx+markShiftTwo,-ry], [rx+markShiftTwo,+ry]));////R短线标注直径标线
  makerjs.model.addCaption(myEgGapHMakerRy, TxtRy, [rx+markShiftFour,-ry], [rx+markShiftFour, +ry]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myEgGapHEllipse"+myHolesNum]=myEgGapHEllipse;
  markers.models["myEgGapHEllipse"+myHolesNum]=myEgGapHEllipse;
  markers.models["myEgGapHMakerRx"+myHolesNum]=myEgGapHMakerRx;
  markers.models["myEgGapHMakerRy"+myHolesNum]=myEgGapHMakerRy;
  markers.models["myEgGapHMakerX"+myHolesNum]=myEgGapHMakerX;
  markers.models["myEgGapHMakerY"+myHolesNum]=myEgGapHMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myEgGapHEllipse"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/        
  }else if(area==9){
  //画缺 I位置
        //5、椭圆形缺 I位置 
        var myEgGapIEllipse=new makerjs.models.Ellipse(rx, ry); 
        myEgGapIEllipse.origin=[xi,yi]; 
     //画5、椭圆形缺标记 I位置    
  var myEgGapIMakerRx = new makerjs.models.Square(0); //W的起始点       
  myEgGapIMakerRx.origin = [xi,yi];
  var myEgGapIMakerRy = new makerjs.models.Square(0); //H的起始点        
  myEgGapIMakerRy.origin = [xi,yi];
  var myEgGapIMakerY = new makerjs.models.Square(0); //Y的起始点        
  myEgGapIMakerY.origin = [xi,yi];
  var myEgGapIMakerX = new makerjs.models.Square(0); //Y的起始点        
  myEgGapIMakerX.origin = [xi,yi];
  /*****************************************************************************/
  makerjs.model.addPath(myEgGapIMakerRx, new makerjs.paths.Line([x0,-y0], [x0, -y0-markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myEgGapIMakerRx, new makerjs.paths.Line([0,-y0], [0, -y0-markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(myEgGapIMakerRx, new makerjs.paths.Line([-rx,0], [-rx, 0+ry+markShiftFour]));//Y轴短标线  Rx
  makerjs.model.addPath(myEgGapIMakerRx, new makerjs.paths.Line([rx,0], [rx, 0+ry+markShiftFour]));//Y轴加长标  Rx
  
  makerjs.model.addPath(myEgGapIMakerRx, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftFour, -y0]));//X轴加长标线
  makerjs.model.addPath(myEgGapIMakerRx, new makerjs.paths.Line([-rx,0], [-rx-markShiftFour, 0]));//X轴方向短线
  
  makerjs.model.addPath(myEgGapIMakerRx, new makerjs.paths.Line([0,-ry], [rx+markShiftFour, -ry]));//X轴加长标线       Ry
  makerjs.model.addPath(myEgGapIMakerRx, new makerjs.paths.Line([0,+ry], [rx+markShiftFour, +ry]));//X轴方向短线  Ry
  
  makerjs.model.addPath(myEgGapIMakerY, new makerjs.paths.Line([-rx-markShiftTwo,-y0], [-rx-markShiftTwo, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myEgGapIMakerY,TxtY, [-rx-markShiftFour, 0],[-rx-markShiftFour,-y0] );//Y文字标注
  
  makerjs.model.addPath(myEgGapIMakerX, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myEgGapIMakerX,TxtX, [0,-y0-markShiftFour],[x0, -y0-markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapIMakerRx, new makerjs.paths.Line([-rx,+ry+markShiftTwo], [rx, +ry+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myEgGapIMakerRx, TxtRx, [-rx,+ry+markShiftFour], [rx, +ry+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myEgGapIMakerRy, new makerjs.paths.Line([rx+markShiftTwo,-ry], [rx+markShiftTwo,+ry]));////R短线标注直径标线
  makerjs.model.addCaption(myEgGapIMakerRy, TxtRy, [rx+markShiftFour,-ry], [rx+markShiftFour, +ry]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myEgGapIEllipse"+myHolesNum]=myEgGapIEllipse;
  markers.models["myEgGapIEllipse"+myHolesNum]=myEgGapIEllipse;
  markers.models["myEgGapIMakerRx"+myHolesNum]=myEgGapIMakerRx;
  markers.models["myEgGapIMakerRy"+myHolesNum]=myEgGapIMakerRy;
  markers.models["myEgGapIMakerX"+myHolesNum]=myEgGapIMakerX;
  markers.models["myEgGapIMakerY"+myHolesNum]=myEgGapIMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myEgGapIEllipse"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/      
  }

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};
export default EllipseGap;