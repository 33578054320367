//7、拉手孔T字
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HandleHoleTShaped(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H1=inputParam.H1,H2=inputParam.H2,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   d = D*1*myZoom,
   w= W*1*myZoom,
   h1= H1*1*myZoom,
   h2= H2*1*myZoom,
   x0 = X*1*myZoom,
   y0 = Y*1*myZoom;
   /*
   d = D*1,
      w= W*1,
      h1= H1*1,
      h2= H2*1,
        x0 = X*1,
        y0 = Y*1;
        */
        if(d!==0){
         var TxtD="∮"+d;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(h2!==0){
         var TxtH2=H2;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标拉手孔T字 A位置孔左上
          var myHhtsHolesALeftup= new makerjs.models.Holes(d/2, [[xa, ya]]);
          var myHhtsHolesMarkALeftup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkALeftup.origin = [xa, ya];      
          var myHhtsHolesXMarkALeftup = new makerjs.models.Square(0);      
          myHhtsHolesXMarkALeftup.origin =[xa, ya];      
          var myHhtsHolesYMarkALeftup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkALeftup.origin = [xa, ya];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkALeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkALeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkALeftup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkALeftup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkALeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkALeftup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 A位置孔左中
     var myHhtsHolesALeftmd= new makerjs.models.Holes(d/2, [[xa+h1, ya]]);
          var myHhtsHolesMarkALeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkALeftmd.origin = [xa+h1, ya];     
          var myHhtsHolesYMarkALeftmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkALeftmd.origin = [xa+h1, ya];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftmd, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
    //makerjs.model.addPath(myHhtsHolesMarkALeftmd, new makerjs.paths.Line([0,0], [-h1, 0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkALeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkALeftmd, new makerjs.paths.Line([0,0], [-h1, 0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkALeftmd, TxtH1, [-h1, markShiftTwo], [0,markShiftTwo]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 A位置孔右中
     var myHhtsHolesARightmd= new makerjs.models.Holes(d/2, [[xa+h1, ya-w]]);
          //标记孔
          var myHhtsHolesMarkARightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkARightmd.origin = [xa+h1, ya-w];      
          var myHhtsHolesXMarkARightmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkARightmd.origin =[xa+h1, ya-w];      
          var myHhtsHolesYMarkARightmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkARightmd.origin = [xa+h1, ya-w];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkARightmd, new makerjs.paths.Line([0,0], [-x0-h1-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkARightmd, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkARightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkARightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkARightmd, new makerjs.paths.Line([-x0-h1-markShiftTwo,0], [-x0-h1-markShiftTwo,w]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkARightmd, TxtW, [-x0-h1-markShiftThree,0], [-x0-h1-markShiftThree,w]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 A位置孔左下
     var myHhtsHolesALeftdn= new makerjs.models.Holes(d/2, [[xa+h1+h2, ya]]);
          //标记孔
          var myHhtsHolesMarkALeftdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkALeftdn.origin = [xa+h1+h2, ya];   
          var myHhtsHolesYMarkALeftdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkALeftdn.origin = [xa+h1+h2, ya];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftdn, new makerjs.paths.Line([0,0], [-h2, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkALeftdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkALeftdn, new makerjs.paths.Line([0,0], [-h2, 0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkALeftdn, TxtH2, [-h2, markShiftTwo], [0,markShiftTwo]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesALeftup"+myHolesNum]=myHhtsHolesALeftup;
    markers.models["myHhtsHolesALeftup"+myHolesNum]=myHhtsHolesALeftup;
    markers.models["myHhtsHolesMarkALeftup"+myHolesNum]=myHhtsHolesMarkALeftup;
    markers.models["myHhtsHolesXMarkALeftup"+myHolesNum]=myHhtsHolesXMarkALeftup;
    markers.models["myHhtsHolesYMarkALeftup"+myHolesNum]=myHhtsHolesYMarkALeftup;
    /*****************************************************************************/
    plate.models["myHhtsHolesALeftmd"+myHolesNum]=myHhtsHolesALeftmd;
    markers.models["myHhtsHolesALeftmd"+myHolesNum]=myHhtsHolesALeftmd;
    markers.models["myHhtsHolesMarkALeftmd"+myHolesNum]=myHhtsHolesMarkALeftmd;
    markers.models["myHhtsHolesYMarkALeftmd"+myHolesNum]=myHhtsHolesYMarkALeftmd;
    /*****************************************************************************/ 
    plate.models["myHhtsHolesALeftdn"+myHolesNum]=myHhtsHolesALeftdn;
    markers.models["myHhtsHolesALeftdn"+myHolesNum]=myHhtsHolesALeftdn;
    markers.models["myHhtsHolesMarkALeftdn"+myHolesNum]=myHhtsHolesMarkALeftdn;
    markers.models["myHhtsHolesYMarkALeftdn"+myHolesNum]=myHhtsHolesYMarkALeftdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    plate.models["myHhtsHolesARightmd"+myHolesNum]=myHhtsHolesARightmd;
    markers.models["myHhtsHolesARightmd"+myHolesNum]=myHhtsHolesARightmd;
    markers.models["myHhtsHolesMarkARightmd"+myHolesNum]=myHhtsHolesMarkARightmd;
    markers.models["myHhtsHolesXMarkARightmd"+myHolesNum]=myHhtsHolesXMarkARightmd;
    /*****************************************************************************/  
    
    }else if(area==3){
    //画孔 C位置孔
          //标拉手孔T字 C位置孔右上
          var myHhtsHolesCRightup= new makerjs.models.Holes(d/2, [[xc, yc]]);
          var myHhtsHolesMarkCRightup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkCRightup.origin = [xc, yc];      
          var myHhtsHolesXMarkCRightup = new makerjs.models.Square(0);      
          myHhtsHolesXMarkCRightup.origin =[xc, yc];      
          var myHhtsHolesYMarkCRightup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkCRightup.origin = [xc, yc];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkCRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkCRightup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkCRightup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkCRightup, TxtX, [0, y0+markShiftFour], [x0,y0+markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkCRightup, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkCRightup, TxtY, [x0+markShiftFour,0], [x0+markShiftFour, y0]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 C位置孔右中
     var myHhtsHolesCRightmd= new makerjs.models.Holes(d/2, [[xc-h1, yc]]);
          var myHhtsHolesMarkCRightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkCRightmd.origin = [xc-h1, yc];     
          var myHhtsHolesYMarkCRightmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkCRightmd.origin = [xc-h1, yc];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightmd, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkCRightmd, new makerjs.paths.Line([0,0], [x0+h1+markShiftThree, 0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkCRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkCRightmd, new makerjs.paths.Line([0,0], [h1, 0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkCRightmd, TxtH1,[0, markShiftTwo], [h1,markShiftTwo]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 C位置孔左中
     var myHhtsHolesCLeftmd= new makerjs.models.Holes(d/2, [[xc-h1, yc-w]]);
          //标记孔
          var myHhtsHolesMarkCLeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkCLeftmd.origin = [xc-h1, yc-w];      
          var myHhtsHolesXMarkCLeftmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkCLeftmd.origin =[xc-h1, yc-w];     
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [x0+h1+markShiftThree, 0]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkCLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkCLeftmd, new makerjs.paths.Line([x0+h1+markShiftTwo,0], [x0+h1+markShiftTwo,w]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkCLeftmd, TxtW,[x0+h1+markShiftFour,0], [x0+h1+markShiftFour,w]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 C位置孔右下
     var myHhtsHolesCRightdn= new makerjs.models.Holes(d/2, [[xc-h1-h2, yc]]);
          //标记孔
          var myHhtsHolesMarkCRightdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkCRightdn.origin = [xc-h1-h2, yc];   
          var myHhtsHolesYMarkCRightdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkCRightdn.origin = [xc-h1-h2, yc];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkCRightdn, new makerjs.paths.Line([0,0], [h2, 0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkCRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkCRightdn, new makerjs.paths.Line([0,0], [h2, 0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkCRightdn, TxtH2, [0,markShiftTwo], [h2, markShiftTwo]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesCRightup"+myHolesNum]=myHhtsHolesCRightup;
    markers.models["myHhtsHolesCRightup"+myHolesNum]=myHhtsHolesCRightup;
    markers.models["myHhtsHolesMarkCRightup"+myHolesNum]=myHhtsHolesMarkCRightup;
    markers.models["myHhtsHolesXMarkCRightup"+myHolesNum]=myHhtsHolesXMarkCRightup;
    markers.models["myHhtsHolesYMarkCRightup"+myHolesNum]=myHhtsHolesYMarkCRightup;
    /*****************************************************************************/
    plate.models["myHhtsHolesCRightmd"+myHolesNum]=myHhtsHolesCRightmd;
    markers.models["myHhtsHolesCRightmd"+myHolesNum]=myHhtsHolesCRightmd;
    markers.models["myHhtsHolesMarkCRightmd"+myHolesNum]=myHhtsHolesMarkCRightmd;
    markers.models["myHhtsHolesYMarkCRightmd"+myHolesNum]=myHhtsHolesYMarkCRightmd;
    /*****************************************************************************/ 
    plate.models["myHhtsHolesCRightdn"+myHolesNum]=myHhtsHolesCRightdn;
    markers.models["myHhtsHolesCRightdn"+myHolesNum]=myHhtsHolesCRightdn;
    markers.models["myHhtsHolesMarkCRightdn"+myHolesNum]=myHhtsHolesMarkCRightdn;
    markers.models["myHhtsHolesYMarkCRightdn"+myHolesNum]=myHhtsHolesYMarkCRightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    plate.models["myHhtsHolesCLeftmd"+myHolesNum]=myHhtsHolesCLeftmd;
    markers.models["myHhtsHolesCLeftmd"+myHolesNum]=myHhtsHolesCLeftmd;
    markers.models["myHhtsHolesMarkCLeftmd"+myHolesNum]=myHhtsHolesMarkCLeftmd;
    markers.models["myHhtsHolesXMarkCLeftmd"+myHolesNum]=myHhtsHolesXMarkCLeftmd;
    /*****************************************************************************/  
   
    }else if(area==2){
    //画孔 B位置孔
          //标拉手孔T字 B位置孔左上
          var myHhtsHolesBLeftup= new makerjs.models.Holes(d/2, [[xb-h1, yb]]);
          var myHhtsHolesMarkBLeftup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkBLeftup.origin = [xb-h1, yb];      
          var myHhtsHolesXMarkBLeftup = new makerjs.models.Square(0);      
          myHhtsHolesXMarkBLeftup.origin =[xb-h1, yb];      
          var myHhtsHolesYMarkBLeftup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkBLeftup.origin = [xb-h1, yb];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkBLeftup, new makerjs.paths.Line([0,0], [h1, 0]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkBLeftup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkBLeftup, new makerjs.paths.Line([0,0], [-xe+h1-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkBLeftup, new makerjs.paths.Line([-xe+h1,y0], [-xe+h1-markShiftFour, y0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkBLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkBLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkBLeftup, new makerjs.paths.Line([-xe+h1-markShiftTwo,0], [-xe+h1-markShiftTwo,y0]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkBLeftup, TxtY, [-xe+h1-markShiftFour,0], [-xe+h1-markShiftFour,y0]);//X文字标注
    /*****************************************************************************/   
    /*****************************************************************************/ 
    
     //标记拉手孔T字 B位置孔左中
     var myHhtsHolesBLeftmd= new makerjs.models.Holes(d/2, [[xb, yb]]);
          var myHhtsHolesMarkBLeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkBLeftmd.origin = [xb, yb];     
          var myHhtsHolesYMarkBLeftmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkBLeftmd.origin = [xb, yb];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkBLeftmd, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线 
    //makerjs.model.addPath(myHhtsHolesMarkBLeftmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkBLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkBLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkBLeftmd, new makerjs.paths.Line([0,0], [-h1, 0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkBLeftmd, TxtH1, [-h1,+markShiftTwo], [0, +markShiftTwo]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 B位置孔右中
     var myHhtsHolesBRightmd= new makerjs.models.Holes(d/2, [[xb, yb-w]]);
          //标记孔
          var myHhtsHolesMarkBRightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkBRightmd.origin = [xb, yb-w];      
          var myHhtsHolesXMarkBRightmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkBRightmd.origin =[xb, yb-w];      
          var myHhtsHolesYMarkBRightmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkBRightmd.origin = [xb, yb-w];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkBRightmd, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkBRightmd, new makerjs.paths.Line([0,0], [-xe-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    //makerjs.model.addPath(myHhtsHolesMarkBRightmd, new makerjs.paths.Line([0,0], [0, ye+markShiftTwo]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkBRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkBRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkBRightmd, new makerjs.paths.Line([-xe-markShiftTwo,0], [-xe-markShiftTwo, w]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkBRightmd, TxtW, [-xe-markShiftFour,0], [-xe-markShiftFour, w]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 B位置孔左下
     var myHhtsHolesBLeftdn= new makerjs.models.Holes(d/2, [[xb+h2, yb]]);
          //标记孔
          var myHhtsHolesMarkBLeftdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkBLeftdn.origin = [xb+h2, yb];   
          var myHhtsHolesYMarkBLeftdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkBLeftdn.origin = [xb+h2, yb];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [-h2,0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
   // makerjs.model.addPath(myHhtsHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [0, h2]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkBLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkBLeftdn, new makerjs.paths.Line([0,0], [-h2,0]));//H2短标线
    makerjs.model.addCaption(myHhtsHolesYMarkBLeftdn, TxtH2, [-h2,markShiftTwo], [0,markShiftTwo]);//H2文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesBLeftup"+myHolesNum]=myHhtsHolesBLeftup;
    markers.models["myHhtsHolesBLeftup"+myHolesNum]=myHhtsHolesBLeftup;
    markers.models["myHhtsHolesMarkBLeftup"+myHolesNum]=myHhtsHolesMarkBLeftup;
    markers.models["myHhtsHolesXMarkBLeftup"+myHolesNum]=myHhtsHolesXMarkBLeftup;
    markers.models["myHhtsHolesYMarkBLeftup"+myHolesNum]=myHhtsHolesYMarkBLeftup;
    /*****************************************************************************/
    plate.models["myHhtsHolesBLeftmd"+myHolesNum]=myHhtsHolesBLeftmd;
    markers.models["myHhtsHolesBLeftmd"+myHolesNum]=myHhtsHolesBLeftmd;
    markers.models["myHhtsHolesMarkBLeftmd"+myHolesNum]=myHhtsHolesMarkBLeftmd;
    markers.models["myHhtsHolesYMarkBLeftmd"+myHolesNum]=myHhtsHolesYMarkBLeftmd;
    /*****************************************************************************/ 
    plate.models["myHhtsHolesBLeftdn"+myHolesNum]=myHhtsHolesBLeftdn;
    markers.models["myHhtsHolesBLeftdn"+myHolesNum]=myHhtsHolesBLeftdn;
    markers.models["myHhtsHolesMarkBLeftdn"+myHolesNum]=myHhtsHolesMarkBLeftdn;
    markers.models["myHhtsHolesYMarkBLeftdn"+myHolesNum]=myHhtsHolesYMarkBLeftdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    plate.models["myHhtsHolesBRightmd"+myHolesNum]=myHhtsHolesBRightmd;
    markers.models["myHhtsHolesBRightmd"+myHolesNum]=myHhtsHolesBRightmd;
    markers.models["myHhtsHolesMarkBRightmd"+myHolesNum]=myHhtsHolesMarkBRightmd;
    markers.models["myHhtsHolesXMarkBRightmd"+myHolesNum]=myHhtsHolesXMarkBRightmd;
    /*****************************************************************************/  
    
    }else if(area==7){
     //画孔 G位置孔
          //标拉手孔T字 G位置孔左上
          var myHhtsHolesGLeftup= new makerjs.models.Holes(d/2, [[xg, yg]]);
          var myHhtsHolesMarkGLeftup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkGLeftup.origin = [xg, yg];      
          var myHhtsHolesXMarkGLeftup = new makerjs.models.Square(0);      
          myHhtsHolesXMarkGLeftup.origin =[xg, yg];      
          var myHhtsHolesYMarkGLeftup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkGLeftup.origin = [xg, yg];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftup, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkGLeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkGLeftup, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftThree, -y0]));//X轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkGLeftup, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftup, new makerjs.paths.Line([0,0], [d, +d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkGLeftup, TxtD, [0,0], [d+markHolesOneD, +d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkGLeftup, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkGLeftup, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkGLeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkGLeftup, TxtY, [-x0-markShiftThree, -y0], [-x0-markShiftThree,0]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 G位置孔左中
     var myHhtsHolesGLeftmd= new makerjs.models.Holes(d/2, [[xg+h1, yg]]);
          var myHhtsHolesMarkGLeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkGLeftmd.origin = [xg+h1, yg];     
          var myHhtsHolesYMarkGLeftmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkGLeftmd.origin = [xg+h1, yg];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
    //makerjs.model.addPath(myHhtsHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [-h1, 0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [d, +d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkGLeftmd, TxtD, [0,0], [d+markHolesOneD, +d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkGLeftmd, new makerjs.paths.Line([0,0], [-h1, 0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkGLeftmd, TxtH1, [-h1, -markShiftTwo], [0,-markShiftTwo]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 G位置孔右中
     var myHhtsHolesGRightmd= new makerjs.models.Holes(d/2, [[xg+h1, yg+w]]);
          //标记孔
          var myHhtsHolesMarkGRightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkGRightmd.origin = [xg+h1, yg+w];      
          var myHhtsHolesXMarkGRightmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkGRightmd.origin =[xg+h1, yg+w];      
          var myHhtsHolesYMarkGRightmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkGRightmd.origin = [xg+h1, yg+w];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGRightmd, new makerjs.paths.Line([0,0], [-x0-h1-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkGRightmd, new makerjs.paths.Line([0,0], [0, -w]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGRightmd, new makerjs.paths.Line([0,0], [d, +d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkGRightmd, TxtD, [0,0], [d+markHolesOneD, +d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkGRightmd, new makerjs.paths.Line([-x0-h1-markShiftTwo,0], [-x0-h1-markShiftTwo,-w]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkGRightmd, TxtW, [-x0-h1-markShiftThree,-w], [-x0-h1-markShiftThree,0]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 G位置孔左下
     var myHhtsHolesGLeftdn= new makerjs.models.Holes(d/2, [[xg+h1+h2, yg]]);
          //标记孔
          var myHhtsHolesMarkGLeftdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkGLeftdn.origin = [xg+h1+h2, yg];   
          var myHhtsHolesYMarkGLeftdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkGLeftdn.origin = [xg+h1+h2, yg];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [-h2, 0]));//从孔圆心出发X方向横向标线
   // makerjs.model.addPath(myHhtsHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkGLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkGLeftdn, new makerjs.paths.Line([0,0], [-h2, 0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkGLeftdn, TxtH2, [-h2, markShiftTwo], [0,markShiftTwo]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesGLeftup"+myHolesNum]=myHhtsHolesGLeftup;
    markers.models["myHhtsHolesGLeftup"+myHolesNum]=myHhtsHolesGLeftup;
    markers.models["myHhtsHolesMarkGLeftup"+myHolesNum]=myHhtsHolesMarkGLeftup;
    markers.models["myHhtsHolesXMarkGLeftup"+myHolesNum]=myHhtsHolesXMarkGLeftup;
    markers.models["myHhtsHolesYMarkGLeftup"+myHolesNum]=myHhtsHolesYMarkGLeftup;
    /*****************************************************************************/
    plate.models["myHhtsHolesGLeftmd"+myHolesNum]=myHhtsHolesGLeftmd;
    markers.models["myHhtsHolesGLeftmd"+myHolesNum]=myHhtsHolesGLeftmd;
    markers.models["myHhtsHolesMarkGLeftmd"+myHolesNum]=myHhtsHolesMarkGLeftmd;
    markers.models["myHhtsHolesYMarkGLeftmd"+myHolesNum]=myHhtsHolesYMarkGLeftmd;
    /*****************************************************************************/ 
    plate.models["myHhtsHolesGLeftdn"+myHolesNum]=myHhtsHolesGLeftdn;
    markers.models["myHhtsHolesGLeftdn"+myHolesNum]=myHhtsHolesGLeftdn;
    markers.models["myHhtsHolesMarkGLeftdn"+myHolesNum]=myHhtsHolesMarkGLeftdn;
    markers.models["myHhtsHolesYMarkGLeftdn"+myHolesNum]=myHhtsHolesYMarkGLeftdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    plate.models["myHhtsHolesGRightmd"+myHolesNum]=myHhtsHolesGRightmd;
    markers.models["myHhtsHolesGRightmd"+myHolesNum]=myHhtsHolesGRightmd;
    markers.models["myHhtsHolesMarkGRightmd"+myHolesNum]=myHhtsHolesMarkGRightmd;
    markers.models["myHhtsHolesXMarkGRightmd"+myHolesNum]=myHhtsHolesXMarkGRightmd;
    /*****************************************************************************/  
   
    }else if(area==8){
      //画孔 H位置孔
            //标拉手孔T字 H位置孔右上
            var myHhtsHolesHRightup= new makerjs.models.Holes(d/2, [[xh-h1, yh]]);
            var myHhtsHolesMarkHRightup = new makerjs.models.Square(0); //孔的原点       
            myHhtsHolesMarkHRightup.origin = [xh-h1, yh];      
            var myHhtsHolesXMarkHRightup = new makerjs.models.Square(0);      
            myHhtsHolesXMarkHRightup.origin =[xh-h1, yh];      
            var myHhtsHolesYMarkHRightup = new makerjs.models.Square(0);      
            myHhtsHolesYMarkHRightup.origin = [xh-h1, yh];  
      
      /*****************************************************************************/ 
      makerjs.model.addPath(myHhtsHolesMarkHRightup, new makerjs.paths.Line([-xe+h1,-y0,], [-xe+h1-markShiftThree,-y0]));//Y轴加长标线
      //makerjs.model.addPath(myHhtsHolesMarkHRightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myHhtsHolesMarkHRightup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myHhtsHolesMarkHRightup, new makerjs.paths.Line([0,0], [-xe+h1-markShiftThree,0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myHhtsHolesMarkHRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myHhtsHolesMarkHRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myHhtsHolesXMarkHRightup, new makerjs.paths.Line([-xe+h1-markShiftTwo,0], [-xe+h1-markShiftTwo,-y0]));//X短标线
      makerjs.model.addCaption(myHhtsHolesXMarkHRightup, TxtY,[-xe+h1-markShiftFour,-y0], [-xe+h1-markShiftFour,0]);//X文字标注
      /*****************************************************************************/   
      /*****************************************************************************/ 
      
       //标记拉手孔T字 H位置孔右中
       var myHhtsHolesHRightmd= new makerjs.models.Holes(d/2, [[xh, yh]]);
            var myHhtsHolesMarkHRightmd = new makerjs.models.Square(0); //孔的原点       
            myHhtsHolesMarkHRightmd.origin = [xh, yh];     
            var myHhtsHolesYMarkHRightmd = new makerjs.models.Square(0);      
            myHhtsHolesYMarkHRightmd.origin = [xh, yh];  
      
      /*****************************************************************************/ 
      makerjs.model.addPath(myHhtsHolesMarkHRightmd, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线 
      //makerjs.model.addPath(myHhtsHolesMarkHRightmd, new makerjs.paths.Line([0,0], [-h1, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myHhtsHolesMarkHRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myHhtsHolesMarkHRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/     
      makerjs.model.addPath(myHhtsHolesYMarkHRightmd, new makerjs.paths.Line([0,0], [-h1, 0]));//Y短标线
      makerjs.model.addCaption(myHhtsHolesYMarkHRightmd, TxtH1, [-h1,markShiftTwo], [0, markShiftTwo]);//Y文字标注
      /*****************************************************************************/ 
      
       //标记拉手孔T字 H位置孔左中
       var myHhtsHoleHLeftmd= new makerjs.models.Holes(d/2, [[xh, yh+w]]);
            //标记孔
            var myHhtsHolesMarkHLeftmd = new makerjs.models.Square(0); //孔的原点       
            myHhtsHolesMarkHLeftmd.origin = [xh, yh+w];      
            var myHhtsHolesXMarkHLeftmd = new makerjs.models.Square(0);      
            myHhtsHolesXMarkHLeftmd.origin =[xh, yh+w];      
            var myHhtsHolesYMarkHLeftmd = new makerjs.models.Square(0);      
            myHhtsHolesYMarkHLeftmd.origin = [xh, yh+w];  
      
      /*****************************************************************************/ 
      makerjs.model.addPath(myHhtsHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [0, -w]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myHhtsHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [-xe-markShiftThree, 0]));//从孔圆心出发X方向横向标线
      //makerjs.model.addPath(myHhtsHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [0, ye+markShiftTwo]));//从孔圆心出发Y方向竖向标线          
      /*****************************************************************************/ 
      makerjs.model.addPath(myHhtsHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myHhtsHolesMarkHLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myHhtsHolesXMarkHLeftmd, new makerjs.paths.Line([-xe-markShiftTwo,0], [-xe-markShiftTwo, -w]));//X短标线
      makerjs.model.addCaption(myHhtsHolesXMarkHLeftmd, TxtW,[-xe-markShiftFour, -w], [-xe-markShiftFour,0]);//X文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      
       //标记拉手孔T字 H位置孔右下
       var myHhtsHolesHRightdn= new makerjs.models.Holes(d/2, [[xh+h2, yh]]);
            //标记孔
            var myHhtsHolesMarkHRightdn = new makerjs.models.Square(0); //孔的原点       
            myHhtsHolesMarkHRightdn.origin = [xh+h2, yh];   
            var myHhtsHolesYMarkHRightdn = new makerjs.models.Square(0);      
            myHhtsHolesYMarkHRightdn.origin = [xh+h2, yh];  
      
      /*****************************************************************************/ 
      //makerjs.model.addPath(myHhtsHolesMarkHRightdn, new makerjs.paths.Line([0,0], [-w-d-markShiftHour, 0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myHhtsHolesMarkHRightdn, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
      //makerjs.model.addPath(myHhtsHolesMarkHRightdn, new makerjs.paths.Line([0,0], [-h2, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myHhtsHolesMarkHRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myHhtsHolesMarkHRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/    
      makerjs.model.addPath(myHhtsHolesYMarkHRightdn, new makerjs.paths.Line([-h2,0], [0,0]));//H2短标线
      makerjs.model.addCaption(myHhtsHolesYMarkHRightdn, TxtH2, [-h2,markShiftTwo], [0,markShiftTwo]);//H2文字标注
      /*****************************************************************************/ 
      plate.models["myHhtsHolesHRightup"+myHolesNum]=myHhtsHolesHRightup;
      markers.models["myHhtsHolesHRightup"+myHolesNum]=myHhtsHolesHRightup;
      markers.models["myHhtsHolesMarkHRightup"+myHolesNum]=myHhtsHolesMarkHRightup;
      markers.models["myHhtsHolesXMarkHRightup"+myHolesNum]=myHhtsHolesXMarkHRightup;
      markers.models["myHhtsHolesYMarkHRightup"+myHolesNum]=myHhtsHolesYMarkHRightup;
      /*****************************************************************************/
      plate.models["myHhtsHolesHRightmd"+myHolesNum]=myHhtsHolesHRightmd;
      markers.models["myHhtsHolesHRightmd"+myHolesNum]=myHhtsHolesHRightmd;
      markers.models["myHhtsHolesMarkHRightmd"+myHolesNum]=myHhtsHolesMarkHRightmd;
      markers.models["myHhtsHolesYMarkHRightmd"+myHolesNum]=myHhtsHolesYMarkHRightmd;
      /*****************************************************************************/ 
      plate.models["myHhtsHoleHLeftmd"+myHolesNum]=myHhtsHoleHLeftmd;
      markers.models["myHhtsHoleHLeftmd"+myHolesNum]=myHhtsHoleHLeftmd;
      markers.models["myHhtsHolesMarkHLeftmd"+myHolesNum]=myHhtsHolesMarkHLeftmd;
      markers.models["myHhtsHolesXMarkHLeftmd"+myHolesNum]=myHhtsHolesXMarkHLeftmd;
      markers.models["myHhtsHolesYMarkHLeftmd"+myHolesNum]=myHhtsHolesYMarkHLeftmd;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      plate.models["myHhtsHolesHRightdn"+myHolesNum]=myHhtsHolesHRightdn;
      markers.models["myHhtsHolesHRightdn"+myHolesNum]=myHhtsHolesHRightdn;
      markers.models["myHhtsHolesMarkHRightdn"+myHolesNum]=myHhtsHolesMarkHRightdn;
      markers.models["myHhtsHolesYMarkHRightdn"+myHolesNum]=myHhtsHolesYMarkHRightdn;
      /*****************************************************************************/  
  
      }else if(area==9){
    //画孔 I位置孔
          //标拉手孔T字 I位置孔右下
          var myHhtsHolesIRightdn= new makerjs.models.Holes(d/2, [[xi, yi]]);
          var myHhtsHolesMarkIRightdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkIRightdn.origin = [xi, yi];      
          var myHhtsHolesXMarkIRightdn = new makerjs.models.Square(0);      
          myHhtsHolesXMarkIRightdn.origin =[xi, yi];      
          var myHhtsHolesYMarkIRightdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkIRightdn.origin = [xi, yi];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightdn, new makerjs.paths.Line([x0,-y0], [x0, -y0-markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkIRightdn, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkIRightdn, new makerjs.paths.Line([x0,-y0], [x0+markShiftThree, -y0]));//X轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkIRightdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkIRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkIRightdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0,-y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkIRightdn, TxtX, [0, -y0-markShiftFour], [x0,-y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkIRightdn, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -y0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkIRightdn, TxtY, [x0+markShiftFour, -y0], [x0+markShiftFour,0]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 I位置孔右中
     var myHhtsHolesIRightmd= new makerjs.models.Holes(d/2, [[xi-h1, yi]]);
          var myHhtsHolesMarkIRightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkIRightmd.origin = [xi-h1, yi];     
          var myHhtsHolesYMarkIRightmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkIRightmd.origin = [xi-h1, yi];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightmd, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
    //makerjs.model.addPath(myHhtsHolesMarkIRightmd, new makerjs.paths.Line([0,0], [h1, 0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkIRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkIRightmd, new makerjs.paths.Line([0,0], [h1, 0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkIRightmd, TxtH1, [0,markShiftTwo], [h1, markShiftTwo]);//Y文字标注
    // /*****************************************************************************/ 
    
     //标记拉手孔T字 I位置孔左中
     var myHhtsHolesILeftmd= new makerjs.models.Holes(d/2, [[xi-h1, yi+w]]);
          //标记孔
          var myHhtsHolesMarkILeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkILeftmd.origin = [xi-h1, yi+w];      
          var myHhtsHolesXMarkILeftmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkILeftmd.origin =[xi-h1, yi+w];     
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkILeftmd, new makerjs.paths.Line([0,0], [0, -w]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkILeftmd, new makerjs.paths.Line([0,0], [h1+x0+markShiftThree, 0]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkILeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkILeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkILeftmd, new makerjs.paths.Line([x0+h1+markShift,-w], [x0+h1+markShift,0]));//W短标线
    makerjs.model.addCaption(myHhtsHolesXMarkILeftmd, TxtW,[x0+h1+markShiftFour,-w], [x0+h1+markShiftFour,0]);//W文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 I位置孔右上
     var myHhtsHolesIRightup= new makerjs.models.Holes(d/2, [[xi-h1-h2, yi]]);
          //标记孔
          var myHhtsHolesMarkIRightup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkIRightup.origin = [xi-h1-h2, yi];   
          var myHhtsHolesYMarkIRightup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkIRightup.origin = [xi-h1-h2, yi];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
   // makerjs.model.addPath(myHhtsHolesMarkIRightup, new makerjs.paths.Line([0,0], [h2, 0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkIRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkIRightup, new makerjs.paths.Line([0,0], [h2, 0]));//H2短标线
    makerjs.model.addCaption(myHhtsHolesYMarkIRightup, TxtH2,[0,markShiftTwo], [h2, markShiftTwo]);//H2文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesIRightdn"+myHolesNum]=myHhtsHolesIRightdn;
    markers.models["myHhtsHolesIRightdn"+myHolesNum]=myHhtsHolesIRightdn;
    markers.models["myHhtsHolesMarkIRightdn"+myHolesNum]=myHhtsHolesMarkIRightdn;
    markers.models["myHhtsHolesXMarkIRightdn"+myHolesNum]=myHhtsHolesXMarkIRightdn;
    markers.models["myHhtsHolesYMarkIRightdn"+myHolesNum]=myHhtsHolesYMarkIRightdn;
    /*****************************************************************************/
    plate.models["myHhtsHolesIRightmd"+myHolesNum]=myHhtsHolesIRightmd;
    markers.models["myHhtsHolesIRightmd"+myHolesNum]=myHhtsHolesIRightmd;
    markers.models["myHhtsHolesMarkIRightmd"+myHolesNum]=myHhtsHolesMarkIRightmd;
    markers.models["myHhtsHolesYMarkIRightmd"+myHolesNum]=myHhtsHolesYMarkIRightmd;
    // /*****************************************************************************/ 
    // /*****************************************************************************/ 
    plate.models["myHhtsHolesILeftmd"+myHolesNum]=myHhtsHolesILeftmd;
    markers.models["myHhtsHolesILeftmd"+myHolesNum]=myHhtsHolesILeftmd;
    markers.models["myHhtsHolesMarkILeftmd"+myHolesNum]=myHhtsHolesMarkILeftmd;
    markers.models["myHhtsHolesXMarkILeftmd"+myHolesNum]=myHhtsHolesXMarkILeftmd;
    // /*****************************************************************************/ 
    plate.models["myHhtsHolesIRightup"+myHolesNum]=myHhtsHolesIRightup;
    markers.models["myHhtsHolesIRightup"+myHolesNum]=myHhtsHolesIRightup;
    markers.models["myHhtsHolesMarkIRightup"+myHolesNum]=myHhtsHolesMarkIRightup;
    markers.models["myHhtsHolesYMarkIRightup"+myHolesNum]=myHhtsHolesYMarkIRightup;
    /*****************************************************************************/  
    } 

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default HandleHoleTShaped;