//角2、圆角
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function Fillet(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var R=inputParam.R,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   R = R*1*myZoom,
      w0= glassW * 1,
      h0= glassH * 1,
  rd=R/Math.sqrt(2),
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        R = R*1,
           w0= glassW * 1,
           h0= glassH * 1,
       rd=R/Math.sqrt(2),
             x0 = X*1,
             y0 = Y*1;
             */
        if(R!==0){
         var TxtR="R="+R;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画2、圆角 A位置

    var myFilletA=new makerjs.models.RoundRectangle(w0+3*R, h0+3*R, R);
        myFilletA.origin=[0,-3*R];
////标记2、圆角 A位置
var myFilletAMakerR = new makerjs.models.Square(0); //孔的原点       
myFilletAMakerR.origin = [xa,ya];

/*****************************************************************************/
/*****************************************************************************/   

makerjs.model.addPath(myFilletAMakerR, new makerjs.paths.Line([R,-R], [R-rd, -R+rd]));//R短线标注
makerjs.model.addCaption(myFilletAMakerR, TxtR, [R,-R-markShift], [R, -R-markShift]);//R文字标注 


/*****************************************************************************/  
/*****************************************************************************/ 


plate.models["myFilletA"+myHolesNum]=myFilletA;
markers.models["myFilletA"+myHolesNum]=myFilletA;
markers.models["myFilletAMakerR"+myHolesNum]=myFilletAMakerR;
/*****************************************************************************/
makerjs.model.combineIntersection(markers.models.myRect, markers.models["myFilletA"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
    }else if(area==2){
      
    }else if(area==3){
      
     //画2、圆角 C位置

    var myFilletC=new makerjs.models.RoundRectangle(w0+3*R, h0+3*R, R);
    myFilletC.origin=[-3*R,-3*R];
////标记2、圆角 A位置
var myFilletCMakerR = new makerjs.models.Square(0); //孔的原点       
myFilletCMakerR.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/   

makerjs.model.addPath(myFilletCMakerR, new makerjs.paths.Line([-R,-R], [-R+rd, -R+rd]));//R短线标注
makerjs.model.addCaption(myFilletCMakerR, TxtR, [-R,-R-markShift], [-R, -R-markShift]);//R文字标注 


/*****************************************************************************/  
/*****************************************************************************/ 


plate.models["myFilletC"+myHolesNum]=myFilletC;
markers.models["myFilletC"+myHolesNum]=myFilletC;
markers.models["myFilletCMakerR"+myHolesNum]=myFilletCMakerR;
/*****************************************************************************/
makerjs.model.combineIntersection(markers.models.myRect, markers.models["myFilletC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
   
    }else if(area==4){
      
    }else if(area==5){
      
    }else if(area==6){
      
    }else if(area==7){

 //画2、圆角 C位置

 var myFilletG=new makerjs.models.RoundRectangle(w0+3*R, h0+3*R, R);
 myFilletG.origin=[0,0];
////标记2、圆角 A位置
var myFilletGMakerR = new makerjs.models.Square(0); //孔的原点       
myFilletGMakerR.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/   

makerjs.model.addPath(myFilletGMakerR, new makerjs.paths.Line([R,R], [R-rd, R-rd]));//R短线标注
makerjs.model.addCaption(myFilletGMakerR, TxtR, [R,R+markShift], [R,R+markShift]);//R文字标注 


/*****************************************************************************/  
/*****************************************************************************/ 


plate.models["myFilletG"+myHolesNum]=myFilletG;
markers.models["myFilletG"+myHolesNum]=myFilletG;
markers.models["myFilletGMakerR"+myHolesNum]=myFilletGMakerR;
/*****************************************************************************/
makerjs.model.combineIntersection(markers.models.myRect, markers.models["myFilletG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
 
      
    }else if(area==8){
      
    }else if(area==9){
     
       //画2、圆角 I位置

 var myFilletI=new makerjs.models.RoundRectangle(w0+3*R, h0+3*R, R);
 myFilletI.origin=[0-3*R,0];
////标记2、圆角 I位置
var myFilletIMakerR = new makerjs.models.Square(0); //孔的原点       
myFilletIMakerR.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/   

makerjs.model.addPath(myFilletIMakerR, new makerjs.paths.Line([0-R+rd,R-rd], [0-R,R]));//R短线标注
makerjs.model.addCaption(myFilletIMakerR, TxtR, [0-R,R+markShift], [0-R,R+markShift]);//R文字标注

/*****************************************************************************/  
/*****************************************************************************/ 

plate.models["myFilletI"+myHolesNum]=myFilletI;
markers.models["myFilletI"+myHolesNum]=myFilletI;
markers.models["myFilletIMakerR"+myHolesNum]=myFilletIMakerR;
/*****************************************************************************/
makerjs.model.combineIntersection(markers.models.myRect, markers.models["myFilletI"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
  

    }

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};
export default Fillet;