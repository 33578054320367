//18、三孔3 D
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles4(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /**
         
   d = D*1,
      w= W*1,
      h= H*1,
        x0 = X*1,
        y0 = Y*1;
         */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
if(area==1){
    //画孔A位置孔
          //标记三孔3 A位置孔右上
          var myThHoles3ARightup= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
          var myThHoles3MarkARightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkARightup.origin = [xa, ya-h];      
          var myThHoles3XMarkARightup = new makerjs.models.Square(0);      
          myThHoles3XMarkARightup.origin =[xa, ya-h];       
          var myThHoles3YMarkARightup = new makerjs.models.Square(0);      
          myThHoles3YMarkARightup.origin =[xa, ya-h];       
          var myThHoles3WMarkARightup = new makerjs.models.Square(0);      
          myThHoles3WMarkARightup.origin =[xa, ya-h];   
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles3MarkARightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles3XMarkARightup, new makerjs.paths.Line([-x0,0], [0, 0]));//X短标线
    makerjs.model.addCaption(myThHoles3XMarkARightup, TxtX,[-x0,0+markShift], [0, 0+markShift]);//X文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myThHoles3WMarkARightup, new makerjs.paths.Line([0,0], [w, 0]));//W短标线
    makerjs.model.addCaption(myThHoles3WMarkARightup, TxtW,[0,0+markShift], [w, 0+markShift]);//W文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔3A位置孔左上
     var myThHoles3ALeftup= new makerjs.models.Holes(d/2, [[xa+w, ya-h]]);
          var myThHoles3MarkALeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkALeftup.origin = [xa+w, ya-h];       
          var myThHoles3HMarkALeftup = new makerjs.models.Square(0);      
          myThHoles3HMarkALeftup.origin = [xa+w, ya-h];
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3HMarkALeftup, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
    makerjs.model.addCaption(myThHoles3HMarkALeftup, TxtH,[0-markShift,0], [0-markShift, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔3 A位置孔右下
     var myThHoles3ARightdn= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
          var myThHoles3MarkARightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkARightdn.origin = [xa+w, ya];      
          var myThHoles3YMarkARightdn = new makerjs.models.Square(0);      
          myThHoles3YMarkARightdn.origin =[xa+w, ya]; 
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkARightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkARightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3YMarkARightdn, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
    makerjs.model.addCaption(myThHoles3YMarkARightdn, TxtY,[0-markShift,0], [0-markShift, y0]);//Y文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles3ALeftup"+myHolesNum]=myThHoles3ALeftup;
    markers.models["myThHoles3ALeftup"+myHolesNum]=myThHoles3ALeftup;
    markers.models["myThHoles3MarkALeftup"+myHolesNum]=myThHoles3MarkALeftup;
    markers.models["myThHoles3HMarkALeftup"+myHolesNum]=myThHoles3HMarkALeftup;
    /*****************************************************************************/ 
    plate.models["myThHoles3ARightup"+myHolesNum]=myThHoles3ARightup;
    markers.models["myThHoles3ARightup"+myHolesNum]=myThHoles3ARightup;
    markers.models["myThHoles3MarkARightup"+myHolesNum]=myThHoles3MarkARightup;
    markers.models["myThHoles3XMarkARightup"+myHolesNum]=myThHoles3XMarkARightup;
    markers.models["myThHoles3WMarkARightup"+myHolesNum]=myThHoles3WMarkARightup;
    /*****************************************************************************/ 
    plate.models["myThHoles3ARightdn"+myHolesNum]=myThHoles3ARightdn;
    markers.models["myThHoles3ARightdn"+myHolesNum]=myThHoles3ARightdn;
    markers.models["myThHoles3MarkARightdn"+myHolesNum]=myThHoles3MarkARightdn;
    markers.models["myThHoles3YMarkARightdn"+myHolesNum]=myThHoles3YMarkARightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     
    }else if(area==2){
      //画孔B位置孔
            //标记三孔3 B位置孔右上
            var myThHoles3BRightup= new makerjs.models.Holes(d/2, [[xb-w/2, yb-h]]);
            var myThHoles3MarkBRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkBRightup.origin = [xb-w/2, yb-h];      
            var myThHoles3WMarkBRightup = new makerjs.models.Square(0);      
            myThHoles3WMarkBRightup.origin =[xb-w/2, yb-h];   
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkBRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkBRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles3WMarkBRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHoles3WMarkBRightup, TxtW,[0,0+markShift], [w, 0+markShift]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔3B位置孔左上
       var myThHoles3BLeftup= new makerjs.models.Holes(d/2, [[xb+w/2, yb-h]]);
            var myThHoles3MarkBLeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkBLeftup.origin = [xb+w/2, yb-h];       
            var myThHoles3HMarkBLeftup = new makerjs.models.Square(0);      
            myThHoles3HMarkBLeftup.origin = [xb+w/2, yb-h];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkBLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkBLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3HMarkBLeftup, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
      makerjs.model.addCaption(myThHoles3HMarkBLeftup, TxtH,[0-markShift,0], [0-markShift, h]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔3 B位置孔右下
       var myThHoles3BRightdn= new makerjs.models.Holes(d/2, [[xb+w/2, yb]]);
            var myThHoles3MarkBRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkBRightdn.origin = [xb+w/2, yb];      
            var myThHoles3YMarkBRightdn = new makerjs.models.Square(0);      
            myThHoles3YMarkBRightdn.origin =[xb+w/2, yb]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkBRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkBRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3YMarkBRightdn, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
      makerjs.model.addCaption(myThHoles3YMarkBRightdn, TxtY,[0-markShift,0], [0-markShift, y0]);//Y文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles3BLeftup"+myHolesNum]=myThHoles3BLeftup;
      markers.models["myThHoles3BLeftup"+myHolesNum]=myThHoles3BLeftup;
      markers.models["myThHoles3MarkBLeftup"+myHolesNum]=myThHoles3MarkBLeftup;
      markers.models["myThHoles3HMarkBLeftup"+myHolesNum]=myThHoles3HMarkBLeftup;
      /*****************************************************************************/ 
      plate.models["myThHoles3BRightup"+myHolesNum]=myThHoles3BRightup;
      markers.models["myThHoles3BRightup"+myHolesNum]=myThHoles3BRightup;
      markers.models["myThHoles3MarkBRightup"+myHolesNum]=myThHoles3MarkBRightup;
      markers.models["myThHoles3WMarkBRightup"+myHolesNum]=myThHoles3WMarkBRightup;
      /*****************************************************************************/ 
      plate.models["myThHoles3BRightdn"+myHolesNum]=myThHoles3BRightdn;
      markers.models["myThHoles3BRightdn"+myHolesNum]=myThHoles3BRightdn;
      markers.models["myThHoles3MarkBRightdn"+myHolesNum]=myThHoles3MarkBRightdn;
      markers.models["myThHoles3YMarkBRightdn"+myHolesNum]=myThHoles3YMarkBRightdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       
    }else if(area==3){
      //画孔C位置孔
            //标记三孔3 C位置孔右上
            var myThHoles3CRightup= new makerjs.models.Holes(d/2, [[xc, yc-h]]);
            var myThHoles3MarkCRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkCRightup.origin = [xc, yc-h];      
            var myThHoles3XMarkCRightup = new makerjs.models.Square(0);      
            myThHoles3XMarkCRightup.origin =[xc, yc-h];       
            var myThHoles3WMarkCRightup = new makerjs.models.Square(0);      
            myThHoles3WMarkCRightup.origin =[xc, yc-h];   
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkCRightup, new makerjs.paths.Line([-w,0], [0, 0]));//X短标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles3XMarkCRightup, new makerjs.paths.Line([0,0], [x0, 0]));//X短标线
      makerjs.model.addCaption(myThHoles3XMarkCRightup, TxtX,[0,0+markShift], [x0, 0+markShift]);//X文字标注
      /*****************************************************************************/    
      //makerjs.model.addPath(myThHoles3WMarkCRightup, new makerjs.paths.Line([-w,0], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHoles3WMarkCRightup, TxtW,[-w,0+markShift], [0, 0+markShift]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔3C位置孔左上
       var myThHoles3CLeftup= new makerjs.models.Holes(d/2, [[xc-w, yc-h]]);
            var myThHoles3MarkCLeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkCLeftup.origin = [xc-w, yc-h];       
            var myThHoles3HMarkCLeftup = new makerjs.models.Square(0);      
            myThHoles3HMarkCLeftup.origin = [xc-w, yc-h];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkCLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkCLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3HMarkCLeftup, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
      makerjs.model.addCaption(myThHoles3HMarkCLeftup, TxtH,[0-markShift,0], [0-markShift, h]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔3 C位置孔右下
       var myThHoles3CRightdn= new makerjs.models.Holes(d/2, [[xc-w, yc]]);
            var myThHoles3MarkCRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkCRightdn.origin = [xc-w, yc];      
            var myThHoles3YMarkCRightdn = new makerjs.models.Square(0);      
            myThHoles3YMarkCRightdn.origin =[xc-w, yc]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkCRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkCRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3YMarkCRightdn, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
      makerjs.model.addCaption(myThHoles3YMarkCRightdn, TxtY,[0-markShift,0], [0-markShift, y0]);//Y文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles3CLeftup"+myHolesNum]=myThHoles3CLeftup;
      markers.models["myThHoles3CLeftup"+myHolesNum]=myThHoles3CLeftup;
      markers.models["myThHoles3MarkCLeftup"+myHolesNum]=myThHoles3MarkCLeftup;
      markers.models["myThHoles3HMarkCLeftup"+myHolesNum]=myThHoles3HMarkCLeftup;
      /*****************************************************************************/ 
      plate.models["myThHoles3CRightup"+myHolesNum]=myThHoles3CRightup;
      markers.models["myThHoles3CRightup"+myHolesNum]=myThHoles3CRightup;
      markers.models["myThHoles3MarkCRightup"+myHolesNum]=myThHoles3MarkCRightup;
      markers.models["myThHoles3XMarkCRightup"+myHolesNum]=myThHoles3XMarkCRightup;
      markers.models["myThHoles3WMarkCRightup"+myHolesNum]=myThHoles3WMarkCRightup;
      /*****************************************************************************/ 
      plate.models["myThHoles3CRightdn"+myHolesNum]=myThHoles3CRightdn;
      markers.models["myThHoles3CRightdn"+myHolesNum]=myThHoles3CRightdn;
      markers.models["myThHoles3MarkCRightdn"+myHolesNum]=myThHoles3MarkCRightdn;
      markers.models["myThHoles3YMarkCRightdn"+myHolesNum]=myThHoles3YMarkCRightdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       
    }else if(area==4){
      //画孔D位置孔
            //标记三孔3 D位置孔右上
            var myThHoles3DRightup= new makerjs.models.Holes(d/2, [[xd, yd+h/2]]);
            var myThHoles3MarkDRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkDRightup.origin = [xd, yd+h/2];      
            var myThHoles3XMarkDRightup = new makerjs.models.Square(0);      
            myThHoles3XMarkDRightup.origin =[xd, yd+h/2];       
            var myThHoles3YMarkDRightup = new makerjs.models.Square(0);      
            myThHoles3YMarkDRightup.origin =[xd, yd+h/2];       
            var myThHoles3WMarkDRightup = new makerjs.models.Square(0);      
            myThHoles3WMarkDRightup.origin =[xd, yd+h/2];   
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles3MarkDRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkDRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkDRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles3XMarkDRightup, new makerjs.paths.Line([-x0,0], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHoles3XMarkDRightup, TxtX,[-x0,0+markShift], [0, 0+markShift]);//X文字标注
      /*****************************************************************************/    
      makerjs.model.addPath(myThHoles3WMarkDRightup, new makerjs.paths.Line([0,0], [w, 0]));//W短标线
      makerjs.model.addCaption(myThHoles3WMarkDRightup, TxtW,[0,0+markShift], [w, 0+markShift]);//W文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔3D位置孔左上
       var myThHoles3DLeftup= new makerjs.models.Holes(d/2, [[xd+w, yd+h/2]]);
            var myThHoles3MarkDLeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkDLeftup.origin = [xd+w, yd+h/2];       
            var myThHoles3HMarkDLeftup = new makerjs.models.Square(0);      
            myThHoles3HMarkDLeftup.origin = [xd+w, yd+h/2];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkDLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkDLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3HMarkDLeftup, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
      makerjs.model.addCaption(myThHoles3HMarkDLeftup, TxtH,[0-markShift,-h], [0-markShift, 0]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔3 D位置孔右下
       var myThHoles3DRightdn= new makerjs.models.Holes(d/2, [[xd+w, yd-h/2]]);
            var myThHoles3MarkDRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkDRightdn.origin = [xd+w, yd-h/2];      
            var myThHoles3YMarkDRightdn = new makerjs.models.Square(0);      
            myThHoles3YMarkDRightdn.origin =[xd+w, yd-h/2]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkDRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkDRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3YMarkDRightdn, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
      makerjs.model.addCaption(myThHoles3YMarkDRightdn, TxtY,[0-markShift,0], [0-markShift, y0]);//Y文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles3DLeftup"+myHolesNum]=myThHoles3DLeftup;
      markers.models["myThHoles3DLeftup"+myHolesNum]=myThHoles3DLeftup;
      markers.models["myThHoles3MarkDLeftup"+myHolesNum]=myThHoles3MarkDLeftup;
      markers.models["myThHoles3HMarkDLeftup"+myHolesNum]=myThHoles3HMarkDLeftup;
      /*****************************************************************************/ 
      plate.models["myThHoles3DRightup"+myHolesNum]=myThHoles3DRightup;
      markers.models["myThHoles3DRightup"+myHolesNum]=myThHoles3DRightup;
      markers.models["myThHoles3MarkDRightup"+myHolesNum]=myThHoles3MarkDRightup;
      markers.models["myThHoles3XMarkDRightup"+myHolesNum]=myThHoles3XMarkDRightup;
      markers.models["myThHoles3WMarkDRightup"+myHolesNum]=myThHoles3WMarkDRightup;
      /*****************************************************************************/ 
      plate.models["myThHoles3DRightdn"+myHolesNum]=myThHoles3DRightdn;
      markers.models["myThHoles3DRightdn"+myHolesNum]=myThHoles3DRightdn;
      markers.models["myThHoles3MarkDRightdn"+myHolesNum]=myThHoles3MarkDRightdn;
      markers.models["myThHoles3YMarkDRightdn"+myHolesNum]=myThHoles3YMarkDRightdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       
    }else if(area==5){
      //画孔E位置孔
            //标记三孔3 E位置孔右上
            var myThHoles3ERightup= new makerjs.models.Holes(d/2, [[xe-w/2, ye+h/2]]);
            var myThHoles3MarkERightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkERightup.origin = [xe-w/2, ye+h/2];      
            var myThHoles3XMarkERightup = new makerjs.models.Square(0);      
            myThHoles3XMarkERightup.origin =[xe-w/2, ye+h/2];       
            var myThHoles3YMarkERightup = new makerjs.models.Square(0);      
            myThHoles3YMarkERightup.origin =[xe-w/2, ye+h/2];       
            var myThHoles3WMarkERightup = new makerjs.models.Square(0);      
            myThHoles3WMarkERightup.origin =[xe-w/2, ye+h/2];   
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles3MarkERightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkERightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkERightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3WMarkERightup, new makerjs.paths.Line([0,0], [w, 0]));//W短标线
      makerjs.model.addCaption(myThHoles3WMarkERightup, TxtW,[0,0+markShift], [w, 0+markShift]);//W文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔3D位置孔左上
       var myThHoles3ELeftup= new makerjs.models.Holes(d/2, [[xe+w/2, ye+h/2]]);
            var myThHoles3MarkELeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkELeftup.origin = [xe+w/2, ye+h/2];       
            var myThHoles3HMarkELeftup = new makerjs.models.Square(0);      
            myThHoles3HMarkELeftup.origin = [xe+w/2, ye+h/2];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkELeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkELeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3HMarkELeftup, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
      makerjs.model.addCaption(myThHoles3HMarkELeftup, TxtH,[0-markShift,-h], [0-markShift, 0]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔3 D位置孔右下
       var myThHoles3ERightdn= new makerjs.models.Holes(d/2, [[xe+w/2, ye-h/2]]);
            var myThHoles3MarkERightdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkERightdn.origin = [xe+w/2, ye-h/2];      
            var myThHoles3YMarkERightdn = new makerjs.models.Square(0);      
            myThHoles3YMarkERightdn.origin =[xe+w/2, ye-h/2]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkERightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkERightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/
      plate.models["myThHoles3ELeftup"+myHolesNum]=myThHoles3ELeftup;
      markers.models["myThHoles3ELeftup"+myHolesNum]=myThHoles3ELeftup;
      markers.models["myThHoles3MarkELeftup"+myHolesNum]=myThHoles3MarkELeftup;
      markers.models["myThHoles3HMarkELeftup"+myHolesNum]=myThHoles3HMarkELeftup;
      /*****************************************************************************/ 
      plate.models["myThHoles3ERightup"+myHolesNum]=myThHoles3ERightup;
      markers.models["myThHoles3ERightup"+myHolesNum]=myThHoles3ERightup;
      markers.models["myThHoles3MarkERightup"+myHolesNum]=myThHoles3MarkERightup;
      markers.models["myThHoles3WMarkERightup"+myHolesNum]=myThHoles3WMarkERightup;
      /*****************************************************************************/ 
      plate.models["myThHoles3ERightdn"+myHolesNum]=myThHoles3ERightdn;
      markers.models["myThHoles3ERightdn"+myHolesNum]=myThHoles3ERightdn;
      markers.models["myThHoles3MarkERightdn"+myHolesNum]=myThHoles3MarkERightdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       
    }else if(area==6){
      //画孔F位置孔
            //标记三孔3 F位置孔右上
            var myThHoles3FRightup= new makerjs.models.Holes(d/2, [[xf, yf+h/2]]);
            var myThHoles3MarkFRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkFRightup.origin = [xf, yf+h/2];      
            var myThHoles3XMarkFRightup = new makerjs.models.Square(0);      
            myThHoles3XMarkFRightup.origin =[xf, yf+h/2];       
            var myThHoles3YMarkFRightup = new makerjs.models.Square(0);      
            myThHoles3YMarkFRightup.origin =[xf, yf+h/2];       
            var myThHoles3WMarkFRightup = new makerjs.models.Square(0);      
            myThHoles3WMarkFRightup.origin =[xf, yf+h/2];   
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles3MarkFRightup, new makerjs.paths.Line([-w,0], [0, 0]));//X短标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles3XMarkFRightup, new makerjs.paths.Line([0,0], [x0, 0]));//X短标线
      makerjs.model.addCaption(myThHoles3XMarkFRightup, TxtX,[0,0+markShift], [x0, 0+markShift]);//X文字标注
      /*****************************************************************************/    
      makerjs.model.addPath(myThHoles3WMarkFRightup, new makerjs.paths.Line([-w,0], [0, 0]));//W短标线
      makerjs.model.addCaption(myThHoles3WMarkFRightup, TxtW,[-w,0+markShift], [0, 0+markShift]);//W文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔3F位置孔左上
       var myThHoles3FLeftup= new makerjs.models.Holes(d/2, [[xf-w, yf+h/2]]);
            var myThHoles3MarkFLeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkFLeftup.origin = [xf-w, yf+h/2];       
            var myThHoles3HMarkFLeftup = new makerjs.models.Square(0);      
            myThHoles3HMarkFLeftup.origin = [xf-w, yf+h/2];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkFLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkFLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3HMarkFLeftup, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
      makerjs.model.addCaption(myThHoles3HMarkFLeftup, TxtH,[0-markShift,-h], [0-markShift, 0]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔3 F位置孔右下
       var myThHoles3FRightdn= new makerjs.models.Holes(d/2, [[xf-w, yf-h/2]]);
            var myThHoles3MarkFRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkFRightdn.origin = [xf-w, yf-h/2];      
            var myThHoles3YMarkFRightdn = new makerjs.models.Square(0);      
            myThHoles3YMarkFRightdn.origin =[xf-w, yf-h/2]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkFRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkFRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3YMarkFRightdn, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
      makerjs.model.addCaption(myThHoles3YMarkFRightdn, TxtY,[0-markShift,0], [0-markShift, y0]);//Y文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles3FLeftup"+myHolesNum]=myThHoles3FLeftup;
      markers.models["myThHoles3FLeftup"+myHolesNum]=myThHoles3FLeftup;
      markers.models["myThHoles3MarkFLeftup"+myHolesNum]=myThHoles3MarkFLeftup;
      markers.models["myThHoles3HMarkFLeftup"+myHolesNum]=myThHoles3HMarkFLeftup;
      /*****************************************************************************/ 
      plate.models["myThHoles3FRightup"+myHolesNum]=myThHoles3FRightup;
      markers.models["myThHoles3FRightup"+myHolesNum]=myThHoles3FRightup;
      markers.models["myThHoles3MarkFRightup"+myHolesNum]=myThHoles3MarkFRightup;
      markers.models["myThHoles3XMarkFRightup"+myHolesNum]=myThHoles3XMarkFRightup;
      markers.models["myThHoles3WMarkFRightup"+myHolesNum]=myThHoles3WMarkFRightup;
      /*****************************************************************************/ 
      plate.models["myThHoles3FRightdn"+myHolesNum]=myThHoles3FRightdn;
      markers.models["myThHoles3FRightdn"+myHolesNum]=myThHoles3FRightdn;
      markers.models["myThHoles3MarkFRightdn"+myHolesNum]=myThHoles3MarkFRightdn;
      markers.models["myThHoles3YMarkFRightdn"+myHolesNum]=myThHoles3YMarkFRightdn;
      /*****************************************************************************/  
       
    }else if(area==7){
       //画孔G位置孔
          //标记三孔3 G位置孔右上
          var myThHoles3GRightup= new makerjs.models.Holes(d/2, [[xg, yg+h]]);
          var myThHoles3MarkGRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkGRightup.origin = [xg, yg+h];      
          var myThHoles3XMarkGRightup = new makerjs.models.Square(0);      
          myThHoles3XMarkGRightup.origin =[xg, yg+h];       
          var myThHoles3YMarkGRightup = new makerjs.models.Square(0);      
          myThHoles3YMarkGRightup.origin =[xg, yg+h];       
          var myThHoles3WMarkGRightup = new makerjs.models.Square(0);      
          myThHoles3WMarkGRightup.origin =[xg, yg+h];   
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles3MarkGRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkGRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkGRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles3XMarkGRightup, new makerjs.paths.Line([-x0,0], [0, 0]));//X短标线
    makerjs.model.addCaption(myThHoles3XMarkGRightup, TxtX,[-x0,0+markShift], [0, 0+markShift]);//X文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myThHoles3WMarkGRightup, new makerjs.paths.Line([0,0], [w, 0]));//W短标线
    makerjs.model.addCaption(myThHoles3WMarkGRightup, TxtW,[0,0+markShift], [w, 0+markShift]);//W文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔3G位置孔左上
     var myThHoles3GLeftup= new makerjs.models.Holes(d/2, [[xg+w, yg+h]]);
          var myThHoles3MarkGLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkGLeftup.origin = [xg+w, yg+h];       
          var myThHoles3HMarkGLeftup = new makerjs.models.Square(0);      
          myThHoles3HMarkGLeftup.origin = [xg+w, yg+h];
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkGLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkGLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3HMarkGLeftup, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
    makerjs.model.addCaption(myThHoles3HMarkGLeftup, TxtH,[0-markShift,-h], [0-markShift, 0]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔3 G位置孔右下
     var myThHoles3GRightdn= new makerjs.models.Holes(d/2, [[xg+w, yg]]);
          var myThHoles3MarkGRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkGRightdn.origin = [xg+w, yg];      
          var myThHoles3YMarkGRightdn = new makerjs.models.Square(0);      
          myThHoles3YMarkGRightdn.origin =[xg+w, yg]; 
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkGRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkGRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3YMarkGRightdn, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
    makerjs.model.addCaption(myThHoles3YMarkGRightdn, TxtY,[0-markShift,-y0], [0-markShift, 0]);//Y文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles3GLeftup"+myHolesNum]=myThHoles3GLeftup;
    markers.models["myThHoles3GLeftup"+myHolesNum]=myThHoles3GLeftup;
    markers.models["myThHoles3MarkGLeftup"+myHolesNum]=myThHoles3MarkGLeftup;
    markers.models["myThHoles3HMarkGLeftup"+myHolesNum]=myThHoles3HMarkGLeftup;
    /*****************************************************************************/ 
    plate.models["myThHoles3GRightup"+myHolesNum]=myThHoles3GRightup;
    markers.models["myThHoles3GRightup"+myHolesNum]=myThHoles3GRightup;
    markers.models["myThHoles3MarkGRightup"+myHolesNum]=myThHoles3MarkGRightup;
    markers.models["myThHoles3XMarkGRightup"+myHolesNum]=myThHoles3XMarkGRightup;
    markers.models["myThHoles3WMarkGRightup"+myHolesNum]=myThHoles3WMarkGRightup;
    /*****************************************************************************/ 
    plate.models["myThHoles3GRightdn"+myHolesNum]=myThHoles3GRightdn;
    markers.models["myThHoles3GRightdn"+myHolesNum]=myThHoles3GRightdn;
    markers.models["myThHoles3MarkGRightdn"+myHolesNum]=myThHoles3MarkGRightdn;
    markers.models["myThHoles3YMarkGRightdn"+myHolesNum]=myThHoles3YMarkGRightdn;
    /*****************************************************************************/  
       
    }else if(area==8){
      //画孔H位置孔
            //标记三孔3 H位置孔左上
            var myThHoles3HLeftup= new makerjs.models.Holes(d/2, [[xh-w/2, yh+h]]);
            var myThHoles3MarkHLeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkHLeftup.origin = [xh-w/2, yh+h];      
            var myThHoles3WMarkHLeftup = new makerjs.models.Square(0);      
            myThHoles3WMarkHLeftup.origin =[xh-w/2, yh+h];   
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkHLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkHLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles3WMarkHLeftup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHoles3WMarkHLeftup, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔3H位置孔右上
       var myThHoles3HRightup= new makerjs.models.Holes(d/2, [[xh+w/2, yh+h]]);
            var myThHoles3MarkHRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkHRightup.origin = [xh+w/2, yh+h];       
            var myThHoles3HMarkHRightup = new makerjs.models.Square(0);      
            myThHoles3HMarkHRightup.origin = [xh+w/2, yh+h]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkHRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkHRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHoles3HMarkHRightup, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
      makerjs.model.addCaption(myThHoles3HMarkHRightup, TxtH, [0-markShift,-h], [0-markShift, 0]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔3 H位置孔左下
       var myThHoles3HLeftdn= new makerjs.models.Holes(d/2, [[xh+w/2, yh]]);
            var myThHoles3MarkHLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkHLeftdn.origin = [xh+w/2, yh];     
            var myThHoles3YMarkHLeftdn = new makerjs.models.Square(0);      
            myThHoles3YMarkHLeftdn.origin = [xh+w/2, yh]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkHLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkHLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3YMarkHLeftdn, new makerjs.paths.Line([0,-y0], [0, 0]));//Y短标线
      makerjs.model.addCaption(myThHoles3YMarkHLeftdn, TxtY, [0-markShift,-y0], [0-markShift, 0]);//Y文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles3HRightup"+myHolesNum]=myThHoles3HRightup;
      markers.models["myThHoles3HRightup"+myHolesNum]=myThHoles3HRightup;
      markers.models["myThHoles3MarkHRightup"+myHolesNum]=myThHoles3MarkHRightup;
      markers.models["myThHoles3HMarkHRightup"+myHolesNum]=myThHoles3HMarkHRightup;
      /*****************************************************************************/ 
      plate.models["myThHoles3HLeftup"+myHolesNum]=myThHoles3HLeftup;
      markers.models["myThHoles3HLeftup"+myHolesNum]=myThHoles3HLeftup;
      markers.models["myThHoles3MarkHLeftup"+myHolesNum]=myThHoles3MarkHLeftup;
      markers.models["myThHoles3WMarkHLeftup"+myHolesNum]=myThHoles3WMarkHLeftup;
      /*****************************************************************************/ 
      plate.models["myThHoles3HLeftdn"+myHolesNum]=myThHoles3HLeftdn;
      markers.models["myThHoles3HLeftdn"+myHolesNum]=myThHoles3HLeftdn;
      markers.models["myThHoles3MarkHLeftdn"+myHolesNum]=myThHoles3MarkHLeftdn;
      markers.models["myThHoles3YMarkHLeftdn"+myHolesNum]=myThHoles3YMarkHLeftdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
     
    }else if(area==9){
            //画孔I位置孔
            //标记三孔3 I位置孔右上
            var myThHoles3IRightup= new makerjs.models.Holes(d/2, [[xi, yi+h]]);
            var myThHoles3MarkIRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkIRightup.origin = [xi, yi+h];      
            var myThHoles3XMarkIRightup = new makerjs.models.Square(0);      
            myThHoles3XMarkIRightup.origin =[xi, yi+h];       
            var myThHoles3WMarkIRightup = new makerjs.models.Square(0);      
            myThHoles3WMarkIRightup.origin =[xi, yi+h];   
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkIRightup, new makerjs.paths.Line([-w,0], [0, 0]));//X短标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkIRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkIRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles3XMarkIRightup, new makerjs.paths.Line([0,0], [x0, 0]));//X短标线
      makerjs.model.addCaption(myThHoles3XMarkIRightup, TxtX,[0,0+markShift], [x0, 0+markShift]);//X文字标注
      /*****************************************************************************/    
      //makerjs.model.addPath(myThHoles3WMarkIRightup, new makerjs.paths.Line([-w,0], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHoles3WMarkIRightup, TxtW,[-w,0+markShift], [0, 0+markShift]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔3I位置孔左上
       var myThHoles3ILeftup= new makerjs.models.Holes(d/2, [[xi-w, yi+h]]);
            var myThHoles3MarkILeftup = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkILeftup.origin = [xi-w, yi+h];       
            var myThHoles3HMarkILeftup = new makerjs.models.Square(0);      
            myThHoles3HMarkILeftup.origin = [xi-w, yi+h];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkILeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkILeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3HMarkILeftup, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
      makerjs.model.addCaption(myThHoles3HMarkILeftup, TxtH,[0-markShift,-h], [0-markShift, 0]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔3 I位置孔右下
       var myThHoles3IRightdn= new makerjs.models.Holes(d/2, [[xi-w, yi]]);
            var myThHoles3MarkIRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles3MarkIRightdn.origin = [xi-w, yi];      
            var myThHoles3YMarkIRightdn = new makerjs.models.Square(0);      
            myThHoles3YMarkIRightdn.origin =[xi-w, yi]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3MarkIRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles3MarkIRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles3YMarkIRightdn, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
      makerjs.model.addCaption(myThHoles3YMarkIRightdn, TxtY,[0-markShift,-y0], [0-markShift, 0]);//Y文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles3ILeftup"+myHolesNum]=myThHoles3ILeftup;
      markers.models["myThHoles3ILeftup"+myHolesNum]=myThHoles3ILeftup;
      markers.models["myThHoles3MarkILeftup"+myHolesNum]=myThHoles3MarkILeftup;
      markers.models["myThHoles3HMarkILeftup"+myHolesNum]=myThHoles3HMarkILeftup;
      /*****************************************************************************/ 
      plate.models["myThHoles3IRightup"+myHolesNum]=myThHoles3IRightup;
      markers.models["myThHoles3IRightup"+myHolesNum]=myThHoles3IRightup;
      markers.models["myThHoles3MarkIRightup"+myHolesNum]=myThHoles3MarkIRightup;
      markers.models["myThHoles3XMarkIRightup"+myHolesNum]=myThHoles3XMarkIRightup;
      markers.models["myThHoles3WMarkIRightup"+myHolesNum]=myThHoles3WMarkIRightup;
      /*****************************************************************************/ 
      plate.models["myThHoles3IRightdn"+myHolesNum]=myThHoles3IRightdn;
      markers.models["myThHoles3IRightdn"+myHolesNum]=myThHoles3IRightdn;
      markers.models["myThHoles3MarkIRightdn"+myHolesNum]=myThHoles3MarkIRightdn;
      markers.models["myThHoles3YMarkIRightdn"+myHolesNum]=myThHoles3YMarkIRightdn;
      /*****************************************************************************/           
            }
/*****************************************************************************/ 

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};
export default ThreeHoles4;