//16、三孔 C
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles3(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔A位置孔
          //标记三孔 A位置孔右上
          var myThHolesARightup= new makerjs.models.Holes(d/2, [[xa, ya]]);
          var myThHolesMarkARightup = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkARightup.origin = [xa, ya];      
          var myThHolesWMarkARightup = new makerjs.models.Square(0);      
          myThHolesWMarkARightup.origin =[xa, ya];       
          var myThHolesXMarkARightup = new makerjs.models.Square(0);      
          myThHolesXMarkARightup.origin =[xa, ya];        
          var myThHolesYMarkARightup = new makerjs.models.Square(0);      
          myThHolesYMarkARightup.origin =[xa, ya];    
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkARightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkARightup, new makerjs.paths.Line([-x0,y0+markShiftTwo], [0, y0+markShiftTwo]));//从孔圆心出发X方向横向标线

    makerjs.model.addPath(myThHolesMarkARightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkARightup, new makerjs.paths.Line([-x0,0], [0, 0]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myThHolesMarkARightup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myThHolesMarkARightup, new makerjs.paths.Line([0,y0], [0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHolesWMarkARightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
    makerjs.model.addCaption(myThHolesWMarkARightup, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHolesXMarkARightup, new makerjs.paths.Line([-x0,y0+markShiftTwo], [0, y0+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHolesXMarkARightup, TxtX, [-x0,y0+markShiftFour], [0, y0+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHolesYMarkARightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
    makerjs.model.addCaption(myThHolesYMarkARightup, TxtY, [0-markShift,0], [0-markShift, y0]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔A位置孔左下
     var myThHolesALeftdn= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
          var myThHolesMarkALeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkALeftdn.origin = [xa+w, ya];       
          var myThHolesHMarkALeftdn = new makerjs.models.Square(0);      
          myThHolesHMarkALeftdn.origin = [xa+w, ya];  
    /*****************************************************************************/ 
    //makerjs.model.addPath(myThHolesMarkALeftdn, new makerjs.paths.Line([0,0], [0, -h]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHolesHMarkALeftdn, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
    makerjs.model.addCaption(myThHolesHMarkALeftdn, TxtH, [0-markShift,-h], [0-markShift, 0]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 A位置孔右下
     var myThHolesARightdn= new makerjs.models.Holes(d/2, [[xa+w, ya-h]]);
          var myThHolesMarkARightdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkARightdn.origin = [xa+w, ya-h];
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkARightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkARightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHolesALeftdn"+myHolesNum]=myThHolesALeftdn;
    markers.models["myThHolesALeftdn"+myHolesNum]=myThHolesALeftdn;
    markers.models["myThHolesMarkALeftdn"+myHolesNum]=myThHolesMarkALeftdn;
    markers.models["myThHolesHMarkALeftdn"+myHolesNum]=myThHolesHMarkALeftdn;
    /*****************************************************************************/ 
    plate.models["myThHolesARightup"+myHolesNum]=myThHolesARightup;
    markers.models["myThHolesARightup"+myHolesNum]=myThHolesARightup;
    markers.models["myThHolesMarkARightup"+myHolesNum]=myThHolesMarkARightup;
    markers.models["myThHolesXMarkARightup"+myHolesNum]=myThHolesXMarkARightup;
    markers.models["myThHolesWMarkARightup"+myHolesNum]=myThHolesWMarkARightup;
    markers.models["myThHolesYMarkARightup"+myHolesNum]=myThHolesYMarkARightup;
    /*****************************************************************************/ 
    plate.models["myThHolesARightdn"+myHolesNum]=myThHolesARightdn;
    markers.models["myThHolesARightdn"+myHolesNum]=myThHolesARightdn;
    markers.models["myThHolesMarkARightdn"+myHolesNum]=myThHolesMarkARightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
  
    }else if(area==2){
      //画孔B位置孔
            //标记三孔 B位置孔右上
            var myThHolesBRightup= new makerjs.models.Holes(d/2, [[xb-w/2, yb]]);
            var myThHolesMarkBRightup = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkBRightup.origin = [xb-w/2, yb];      
            var myThHolesWMarkBRightup = new makerjs.models.Square(0);      
            myThHolesWMarkBRightup.origin =[xb-w/2, yb];       
            var myThHolesYMarkBRightup = new makerjs.models.Square(0);      
            myThHolesYMarkBRightup.origin =[xb-w/2, yb];    
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkBRightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myThHolesMarkBRightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkBRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkBRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesWMarkBRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHolesWMarkBRightup, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesYMarkBRightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
      makerjs.model.addCaption(myThHolesYMarkBRightup, TxtY, [0-markShift,0], [0-markShift, y0]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔B位置孔左下
       var myThHolesBLeftdn= new makerjs.models.Holes(d/2, [[xb+w/2, yb]]);
            var myThHolesMarkBLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkBLeftdn.origin = [xb+w/2, yb];       
            var myThHolesHMarkBLeftdn = new makerjs.models.Square(0);      
            myThHolesHMarkBLeftdn.origin = [xb+w/2, yb];  
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [0, -h]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkBLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesHMarkBLeftdn, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
      makerjs.model.addCaption(myThHolesHMarkBLeftdn, TxtH, [0-markShift,-h], [0-markShift, 0]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔 B位置孔右下
       var myThHolesBRightdn= new makerjs.models.Holes(d/2, [[xb+w/2, yb-h]]);
            var myThHolesMarkBRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkBRightdn.origin = [xb+w/2, yb-h];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkBRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkBRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHolesBLeftdn"+myHolesNum]=myThHolesBLeftdn;
      markers.models["myThHolesBLeftdn"+myHolesNum]=myThHolesBLeftdn;
      markers.models["myThHolesMarkBLeftdn"+myHolesNum]=myThHolesMarkBLeftdn;
      markers.models["myThHolesHMarkBLeftdn"+myHolesNum]=myThHolesHMarkBLeftdn;
      /*****************************************************************************/ 
      plate.models["myThHolesBRightup"+myHolesNum]=myThHolesBRightup;
      markers.models["myThHolesBRightup"+myHolesNum]=myThHolesBRightup;
      markers.models["myThHolesMarkBRightup"+myHolesNum]=myThHolesMarkBRightup;
      markers.models["myThHolesWMarkBRightup"+myHolesNum]=myThHolesWMarkBRightup;
      markers.models["myThHolesYMarkBRightup"+myHolesNum]=myThHolesYMarkBRightup;
      /*****************************************************************************/ 
      plate.models["myThHolesBRightdn"+myHolesNum]=myThHolesBRightdn;
      markers.models["myThHolesBRightdn"+myHolesNum]=myThHolesBRightdn;
      markers.models["myThHolesMarkBRightdn"+myHolesNum]=myThHolesMarkBRightdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
    
    }else if(area==3){
      //画孔C位置孔
            //标记三孔 C位置孔右上
            var myThHolesCRightup= new makerjs.models.Holes(d/2, [[xc, yc]]);
            var myThHolesMarkCRightup = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkCRightup.origin = [xc, yc];      
            var myThHolesWMarkCRightup = new makerjs.models.Square(0);      
            myThHolesWMarkCRightup.origin =[xc, yc];       
            var myThHolesXMarkCRightup = new makerjs.models.Square(0);      
            myThHolesXMarkCRightup.origin =[xc, yc];       
            var myThHolesYMarkCRightup = new makerjs.models.Square(0);      
            myThHolesYMarkCRightup.origin =[xc, yc];    
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkCRightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myThHolesMarkCRightup, new makerjs.paths.Line([-w,0], [0, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesWMarkCRightup, new makerjs.paths.Line([-w,0], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHolesWMarkCRightup, TxtW, [-w,0+markShift], [0, 0+markShift]);//X文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesXMarkCRightup, new makerjs.paths.Line([0,0], [x0, 0]));//X短标线
      makerjs.model.addCaption(myThHolesXMarkCRightup, TxtX, [0,0+markShift], [x0, 0+markShift]);//X文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesYMarkCRightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
      makerjs.model.addCaption(myThHolesYMarkCRightup, TxtY, [0-markShift,0], [0-markShift, y0]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔C位置孔左下
       var myThHolesCLeftdn= new makerjs.models.Holes(d/2, [[xc-w, yc]]);
            var myThHolesMarkCLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkCLeftdn.origin = [xc-w, yc];       
            var myThHolesHMarkCLeftdn = new makerjs.models.Square(0);      
            myThHolesHMarkCLeftdn.origin = [xc-w, yc];  
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkCLeftdn, new makerjs.paths.Line([0,0], [0, -h]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkCLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkCLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesHMarkCLeftdn, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
      makerjs.model.addCaption(myThHolesHMarkCLeftdn, TxtH, [0-markShift,-h], [0-markShift, 0]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔 C位置孔右下
       var myThHolesCRightdn= new makerjs.models.Holes(d/2, [[xc-w, yc-h]]);
            var myThHolesMarkCRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkCRightdn.origin = [xc-w, yc-h];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkCRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkCRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHolesCLeftdn"+myHolesNum]=myThHolesCLeftdn;
      markers.models["myThHolesCLeftdn"+myHolesNum]=myThHolesCLeftdn;
      markers.models["myThHolesMarkCLeftdn"+myHolesNum]=myThHolesMarkCLeftdn;
      markers.models["myThHolesHMarkCLeftdn"+myHolesNum]=myThHolesHMarkCLeftdn;
      /*****************************************************************************/ 
      plate.models["myThHolesCRightup"+myHolesNum]=myThHolesCRightup;
      markers.models["myThHolesCRightup"+myHolesNum]=myThHolesCRightup;
      markers.models["myThHolesMarkCRightup"+myHolesNum]=myThHolesMarkCRightup;
      markers.models["myThHolesWMarkCRightup"+myHolesNum]=myThHolesWMarkCRightup;
      markers.models["myThHolesXMarkCRightup"+myHolesNum]=myThHolesXMarkCRightup;
      markers.models["myThHolesYMarkCRightup"+myHolesNum]=myThHolesYMarkCRightup;
      /*****************************************************************************/ 
      plate.models["myThHolesCRightdn"+myHolesNum]=myThHolesCRightdn;
      markers.models["myThHolesCRightdn"+myHolesNum]=myThHolesCRightdn;
      markers.models["myThHolesMarkCRightdn"+myHolesNum]=myThHolesMarkCRightdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
    
    }else if(area==4){
      //画孔D位置孔
            //标记三孔 D位置孔右上
            var myThHolesDRightup= new makerjs.models.Holes(d/2, [[xd, yd-h/2]]);
            var myThHolesMarkDRightup = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkDRightup.origin = [xd, yd-h/2];      
            var myThHolesWMarkDRightup = new makerjs.models.Square(0);      
            myThHolesWMarkDRightup.origin =[xd, yd-h/2];       
            var myThHolesXMarkDRightup = new makerjs.models.Square(0);      
            myThHolesXMarkDRightup.origin =[xd, yd-h/2];     
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkDRightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkDRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkDRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesWMarkDRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHolesWMarkDRightup, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesXMarkDRightup, new makerjs.paths.Line([-x0,0], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHolesXMarkDRightup, TxtX, [-x0,0+markShift], [0,0+markShift]);//X文字标注
      /*****************************************************************************/  
       //标记三孔D位置孔左下
       var myThHolesDLeftdn= new makerjs.models.Holes(d/2, [[xd+w, yd-h/2]]);
            var myThHolesMarkDLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkDLeftdn.origin = [xd+w, yd-h/2];       
            var myThHolesHMarkDLeftdn = new makerjs.models.Square(0);      
            myThHolesHMarkDLeftdn.origin = [xd+w, yd-h/2];  
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [0, h]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkDLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesHMarkDLeftdn, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
      makerjs.model.addCaption(myThHolesHMarkDLeftdn, TxtH, [0-markShift,0], [0-markShift, h]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔 D位置孔右下
       var myThHolesDRightdn= new makerjs.models.Holes(d/2, [[xd+w, yd+h/2]]);
            var myThHolesMarkDRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkDRightdn.origin = [xd+w, yd+h/2];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkDRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkDRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHolesDLeftdn"+myHolesNum]=myThHolesDLeftdn;
      markers.models["myThHolesDLeftdn"+myHolesNum]=myThHolesDLeftdn;
      markers.models["myThHolesMarkDLeftdn"+myHolesNum]=myThHolesMarkDLeftdn;
      markers.models["myThHolesHMarkDLeftdn"+myHolesNum]=myThHolesHMarkDLeftdn;
      /*****************************************************************************/ 
      plate.models["myThHolesDRightup"+myHolesNum]=myThHolesDRightup;
      markers.models["myThHolesDRightup"+myHolesNum]=myThHolesDRightup;
      markers.models["myThHolesMarkDRightup"+myHolesNum]=myThHolesMarkDRightup;
      markers.models["myThHolesWMarkDRightup"+myHolesNum]=myThHolesWMarkDRightup;
      markers.models["myThHolesXMarkDRightup"+myHolesNum]=myThHolesXMarkDRightup;
      /*****************************************************************************/ 
      plate.models["myThHolesDRightdn"+myHolesNum]=myThHolesDRightdn;
      markers.models["myThHolesDRightdn"+myHolesNum]=myThHolesDRightdn;
      markers.models["myThHolesMarkDRightdn"+myHolesNum]=myThHolesMarkDRightdn;
      /*****************************************************************************/
    }else if(area==5){
      //画孔E位置孔
            //标记三孔 E位置孔右上
            var myThHolesERightup= new makerjs.models.Holes(d/2, [[xe-w/2, ye-h/2]]);
            var myThHolesMarkERightup = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkERightup.origin = [xe-w/2, ye-h/2];      
            var myThHolesWMarkERightup = new makerjs.models.Square(0);      
            myThHolesWMarkERightup.origin =[xe-w/2, ye-h/2];    
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkERightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myThHolesMarkERightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkERightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkERightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesWMarkERightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHolesWMarkERightup, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
      /*****************************************************************************/  
       //标记三孔E位置孔左下
       var myThHolesELeftdn= new makerjs.models.Holes(d/2, [[xe+w/2, ye-h/2]]);
            var myThHolesMarkELeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkELeftdn.origin = [xe+w/2, ye-h/2];       
            var myThHolesHMarkELeftdn = new makerjs.models.Square(0);      
            myThHolesHMarkELeftdn.origin = [xe+w/2, ye-h/2];  
      /*****************************************************************************/ 
      //makerjs.model.addPath(myThHolesMarkELeftdn, new makerjs.paths.Line([0,0], [0, -h]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkELeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkELeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesHMarkELeftdn, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
      makerjs.model.addCaption(myThHolesHMarkELeftdn, TxtH, [0-markShift,0], [0-markShift, h]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔 E位置孔右下
       var myThHolesERightdn= new makerjs.models.Holes(d/2, [[xe+w/2, ye+h/2]]);
            var myThHolesMarkERightdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkERightdn.origin = [xe+w/2, ye+h/2];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkERightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkERightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHolesELeftdn"+myHolesNum]=myThHolesELeftdn;
      markers.models["myThHolesELeftdn"+myHolesNum]=myThHolesELeftdn;
      markers.models["myThHolesMarkELeftdn"+myHolesNum]=myThHolesMarkELeftdn;
      markers.models["myThHolesHMarkELeftdn"+myHolesNum]=myThHolesHMarkELeftdn;
      /*****************************************************************************/ 
      plate.models["myThHolesERightup"+myHolesNum]=myThHolesERightup;
      markers.models["myThHolesERightup"+myHolesNum]=myThHolesERightup;
      markers.models["myThHolesMarkERightup"+myHolesNum]=myThHolesMarkERightup;
      markers.models["myThHolesWMarkERightup"+myHolesNum]=myThHolesWMarkERightup;
      /*****************************************************************************/ 
      plate.models["myThHolesERightdn"+myHolesNum]=myThHolesERightdn;
      markers.models["myThHolesERightdn"+myHolesNum]=myThHolesERightdn;
      markers.models["myThHolesMarkERightdn"+myHolesNum]=myThHolesMarkERightdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
    
    }else if(area==6){
      //画孔F位置孔
            //标记三孔 F位置孔右上
            var myThHolesFRightup= new makerjs.models.Holes(d/2, [[xf, yf-h/2]]);
            var myThHolesMarkFRightup = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkFRightup.origin = [xf, yf-h/2];      
            var myThHolesWMarkFRightup = new makerjs.models.Square(0);      
            myThHolesWMarkFRightup.origin =[xf, yf-h/2];       
            var myThHolesXMarkFRightup = new makerjs.models.Square(0);      
            myThHolesXMarkFRightup.origin =[xf, yf-h/2];    
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkFRightup, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesWMarkFRightup, new makerjs.paths.Line([-w,0], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHolesWMarkFRightup, TxtW, [-w,0+markShift], [0, 0+markShift]);//X文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesXMarkFRightup, new makerjs.paths.Line([0,0], [x0, 0]));//X短标线
      makerjs.model.addCaption(myThHolesXMarkFRightup, TxtX, [0,0+markShift], [x0, 0+markShift]);//X文字标注
      /*****************************************************************************/ 
       //标记三孔F位置孔左下
       var myThHolesFLeftdn= new makerjs.models.Holes(d/2, [[xf-w, yf-h/2]]);
            var myThHolesMarkFLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkFLeftdn.origin = [xf-w, yf-h/2];       
            var myThHolesHMarkFLeftdn = new makerjs.models.Square(0);      
            myThHolesHMarkFLeftdn.origin = [xf-w, yf-h/2];  
      /*****************************************************************************/ 
     // makerjs.model.addPath(myThHolesMarkFLeftdn, new makerjs.paths.Line([0,0], [0, -h]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkFLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkFLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesHMarkFLeftdn, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
      makerjs.model.addCaption(myThHolesHMarkFLeftdn, TxtH,[0-markShift,0], [0-markShift, h]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔 F位置孔右下
       var myThHolesFRightdn= new makerjs.models.Holes(d/2, [[xf-w, yf+h/2]]);
            var myThHolesMarkFRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkFRightdn.origin = [xf-w, yf+h/2];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkFRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkFRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHolesFLeftdn"+myHolesNum]=myThHolesFLeftdn;
      markers.models["myThHolesFLeftdn"+myHolesNum]=myThHolesFLeftdn;
      markers.models["myThHolesMarkFLeftdn"+myHolesNum]=myThHolesMarkFLeftdn;
      markers.models["myThHolesHMarkFLeftdn"+myHolesNum]=myThHolesHMarkFLeftdn;
      /*****************************************************************************/ 
      plate.models["myThHolesFRightup"+myHolesNum]=myThHolesFRightup;
      markers.models["myThHolesFRightup"+myHolesNum]=myThHolesFRightup;
      markers.models["myThHolesMarkFRightup"+myHolesNum]=myThHolesMarkFRightup;
      markers.models["myThHolesWMarkFRightup"+myHolesNum]=myThHolesWMarkFRightup;
      markers.models["myThHolesXMarkFRightup"+myHolesNum]=myThHolesXMarkFRightup;
      /*****************************************************************************/ 
      plate.models["myThHolesFRightdn"+myHolesNum]=myThHolesFRightdn;
      markers.models["myThHolesFRightdn"+myHolesNum]=myThHolesFRightdn;
      markers.models["myThHolesMarkFRightdn"+myHolesNum]=myThHolesMarkFRightdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
    
    }else if(area==7){
      //画孔G位置孔
            //标记三孔 G位置孔右上
            var myThHolesGRightup= new makerjs.models.Holes(d/2, [[xg, yg]]);
            var myThHolesMarkGRightup = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkGRightup.origin = [xg, yg];      
            var myThHolesWMarkGRightup = new makerjs.models.Square(0);      
            myThHolesWMarkGRightup.origin =[xg, yg];       
            var myThHolesXMarkGRightup = new makerjs.models.Square(0);      
            myThHolesXMarkGRightup.origin =[xg, yg];       
            var myThHolesYMarkGRightup = new makerjs.models.Square(0);      
            myThHolesYMarkGRightup.origin =[xg, yg];    
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkGRightup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myThHolesMarkGRightup, new makerjs.paths.Line([-x0,0], [0,0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myThHolesMarkGRightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkGRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkGRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesWMarkGRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
      makerjs.model.addCaption(myThHolesWMarkGRightup, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesXMarkGRightup, new makerjs.paths.Line([-x0,0], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHolesXMarkGRightup, TxtX, [-x0,0+markShift], [0, 0+markShift]);//X文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesYMarkGRightup, new makerjs.paths.Line([0,0], [0, -y0]));//X短标线
      makerjs.model.addCaption(myThHolesYMarkGRightup, TxtY, [0-markShift,-y0], [0-markShift, 0]);//X文字标注
      /*****************************************************************************/  
       //标记三孔G位置孔左下
       var myThHolesGLeftdn= new makerjs.models.Holes(d/2, [[xg+w, yg]]);
            var myThHolesMarkGLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkGLeftdn.origin = [xg+w, yg];       
            var myThHolesHMarkGLeftdn = new makerjs.models.Square(0);      
            myThHolesHMarkGLeftdn.origin = [xg+w, yg];  
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkGLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesHMarkGLeftdn, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
      makerjs.model.addCaption(myThHolesHMarkGLeftdn, TxtH, [0-markShift,0], [0-markShift, h]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔 G位置孔右下
       var myThHolesGRightdn= new makerjs.models.Holes(d/2, [[xg+w, yg+h]]);
            var myThHolesMarkGRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkGRightdn.origin = [xg+w, yg+h];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkGRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkGRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHolesGLeftdn"+myHolesNum]=myThHolesGLeftdn;
      markers.models["myThHolesGLeftdn"+myHolesNum]=myThHolesGLeftdn;
      markers.models["myThHolesMarkGLeftdn"+myHolesNum]=myThHolesMarkGLeftdn;
      markers.models["myThHolesHMarkGLeftdn"+myHolesNum]=myThHolesHMarkGLeftdn;
      /*****************************************************************************/ 
      plate.models["myThHolesGRightup"+myHolesNum]=myThHolesGRightup;
      markers.models["myThHolesGRightup"+myHolesNum]=myThHolesGRightup;
      markers.models["myThHolesMarkGRightup"+myHolesNum]=myThHolesMarkGRightup;
      markers.models["myThHolesWMarkGRightup"+myHolesNum]=myThHolesWMarkGRightup;
      markers.models["myThHolesXMarkGRightup"+myHolesNum]=myThHolesXMarkGRightup;
      markers.models["myThHolesYMarkGRightup"+myHolesNum]=myThHolesYMarkGRightup;
      /*****************************************************************************/ 
      plate.models["myThHolesGRightdn"+myHolesNum]=myThHolesGRightdn;
      markers.models["myThHolesGRightdn"+myHolesNum]=myThHolesGRightdn;
      markers.models["myThHolesMarkGRightdn"+myHolesNum]=myThHolesMarkGRightdn;
      /*****************************************************************************/
    
    }else if(area==8){
      //画孔H位置孔
            //标记三孔 H位置孔左上
            var myThHolesHLeftup= new makerjs.models.Holes(d/2, [[xh-w/2, yh]]);
            var myThHolesMarkHLeftup = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkHLeftup.origin = [xh-w/2, yh];      
            var myThHolesYMarkHLeftup = new makerjs.models.Square(0);      
            myThHolesYMarkHLeftup.origin =[xh-w/2, yh]; 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkHLeftup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
     makerjs.model.addPath(myThHolesMarkHLeftup, new makerjs.paths.Line([w,0], [0, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkHLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkHLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesYMarkHLeftup, new makerjs.paths.Line([0,0], [0, -y0]));//X短标线
      makerjs.model.addCaption(myThHolesYMarkHLeftup, TxtY, [0-markShift, -y0], [0-markShift,0]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔H位置孔右下
       var myThHolesHRightdn= new makerjs.models.Holes(d/2, [[xh+w/2, yh]]);
            var myThHolesMarkHRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkHRightdn.origin = [xh+w/2, yh];       
            var myThHolesHMarkHRightdn = new makerjs.models.Square(0);      
            myThHolesHMarkHRightdn.origin = [xh+w/2, yh];        
            var myThHolesWMarkHRightdn = new makerjs.models.Square(0);      
            myThHolesWMarkHRightdn.origin = [xh+w/2, yh];  
      /*****************************************************************************/ 
      //makerjs.model.addPath(myThHolesMarkHRightdn, new makerjs.paths.Line([0,-h], [0, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkHRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkHRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      makerjs.model.addPath(myThHolesHMarkHRightdn, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
      makerjs.model.addCaption(myThHolesHMarkHRightdn, TxtH, [0-markShift,0], [0-markShift, h]);//Y文字标注
      makerjs.model.addPath(myThHolesWMarkHRightdn, new makerjs.paths.Line([0,0], [-w, 0]));//X短标线
      makerjs.model.addCaption(myThHolesWMarkHRightdn, TxtW, [-w,0+markShift], [0, 0+markShift]);//X文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔 F位置孔左下
       var myThHolesHLeftdn= new makerjs.models.Holes(d/2, [[xh+w/2, yh+h]]);
            var myThHolesMarkHLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkHLeftdn.origin = [xh+w/2, yh+h]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkHLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkHLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHolesHRightdn"+myHolesNum]=myThHolesHRightdn;
      markers.models["myThHolesHRightdn"+myHolesNum]=myThHolesHRightdn;
      markers.models["myThHolesMarkHRightdn"+myHolesNum]=myThHolesMarkHRightdn;
      markers.models["myThHolesHMarkHRightdn"+myHolesNum]=myThHolesHMarkHRightdn;
      markers.models["myThHolesWMarkHRightdn"+myHolesNum]=myThHolesWMarkHRightdn;
      /*****************************************************************************/ 
      plate.models["myThHolesHLeftup"+myHolesNum]=myThHolesHLeftup;
      markers.models["myThHolesHLeftup"+myHolesNum]=myThHolesHLeftup;
      markers.models["myThHolesMarkHLeftup"+myHolesNum]=myThHolesMarkHLeftup;
      markers.models["myThHolesYMarkHLeftup"+myHolesNum]=myThHolesYMarkHLeftup;
      /*****************************************************************************/ 
      plate.models["myThHolesHLeftdn"+myHolesNum]=myThHolesHLeftdn;
      markers.models["myThHolesHLeftdn"+myHolesNum]=myThHolesHLeftdn;
      markers.models["myThHolesMarkHLeftdn"+myHolesNum]=myThHolesMarkHLeftdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
    
    }else if(area==9){
            //画孔I位置孔
            //标记三孔 I位置孔右上
            var myThHolesIRightup= new makerjs.models.Holes(d/2, [[xi, yi]]);
            var myThHolesMarkIRightup = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkIRightup.origin = [xi, yi];      
            var myThHolesWMarkIRightup = new makerjs.models.Square(0);      
            myThHolesWMarkIRightup.origin =[xi, yi];       
            var myThHolesXMarkIRightup = new makerjs.models.Square(0);      
            myThHolesXMarkIRightup.origin =[xi, yi];       
            var myThHolesYMarkIRightup = new makerjs.models.Square(0);      
            myThHolesYMarkIRightup.origin =[xi, yi];    
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkIRightup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myThHolesMarkIRightup, new makerjs.paths.Line([0,0], [x0,0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myThHolesMarkIRightup, new makerjs.paths.Line([-w,0], [0, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkIRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkIRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesWMarkIRightup, new makerjs.paths.Line([-w,0], [0, 0]));//X短标线
      makerjs.model.addCaption(myThHolesWMarkIRightup, TxtW, [-w,0+markShift], [0, 0+markShift]);//X文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesXMarkIRightup, new makerjs.paths.Line([0,0], [x0, 0]));//X短标线
      makerjs.model.addCaption(myThHolesXMarkIRightup, TxtX, [0,0+markShift], [x0, 0+markShift]);//X文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHolesYMarkIRightup, new makerjs.paths.Line([0,0], [0, -y0]));//X短标线
      makerjs.model.addCaption(myThHolesYMarkIRightup, TxtY, [0-markShift,-y0], [0-markShift, 0]);//X文字标注
      /*****************************************************************************/  
      /*****************************************************************************/  
      /*****************************************************************************/ 
       //标记三孔I位置孔左下
       var myThHolesILeftdn= new makerjs.models.Holes(d/2, [[xi-w, yi]]);
            var myThHolesMarkILeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkILeftdn.origin = [xi-w, yi];       
            var myThHolesHMarkILeftdn = new makerjs.models.Square(0);      
            myThHolesHMarkILeftdn.origin = [xi-w, yi];  
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkILeftdn, new makerjs.paths.Line([0,0], [0, h]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkILeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkILeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      makerjs.model.addPath(myThHolesHMarkILeftdn, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
      makerjs.model.addCaption(myThHolesHMarkILeftdn, TxtH, [0-markShift,0], [0-markShift, h]);//Y文字标注
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔 I位置孔右下
       var myThHolesIRightdn= new makerjs.models.Holes(d/2, [[xi-w, yi+h]]);
            var myThHolesMarkIRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHolesMarkIRightdn.origin = [xi-w, yi+h];
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHolesMarkIRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHolesMarkIRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHolesILeftdn"+myHolesNum]=myThHolesILeftdn;
      markers.models["myThHolesILeftdn"+myHolesNum]=myThHolesILeftdn;
      markers.models["myThHolesMarkILeftdn"+myHolesNum]=myThHolesMarkILeftdn;
      markers.models["myThHolesHMarkILeftdn"+myHolesNum]=myThHolesHMarkILeftdn;
      /*****************************************************************************/ 
      plate.models["myThHolesIRightup"+myHolesNum]=myThHolesIRightup;
      markers.models["myThHolesIRightup"+myHolesNum]=myThHolesIRightup;
      markers.models["myThHolesMarkIRightup"+myHolesNum]=myThHolesMarkIRightup;
      markers.models["myThHolesWMarkIRightup"+myHolesNum]=myThHolesWMarkIRightup;
      markers.models["myThHolesXMarkIRightup"+myHolesNum]=myThHolesXMarkIRightup;
      markers.models["myThHolesYMarkIRightup"+myHolesNum]=myThHolesYMarkIRightup;
      /*****************************************************************************/ 
      plate.models["myThHolesIRightdn"+myHolesNum]=myThHolesIRightdn;
      markers.models["myThHolesIRightdn"+myHolesNum]=myThHolesIRightdn;
      markers.models["myThHolesMarkIRightdn"+myHolesNum]=myThHolesMarkIRightdn;
      /*****************************************************************************/ 
          
  }
/*****************************************************************************/ 

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default ThreeHoles3;