//缺5、方缺
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function SquareGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var H=inputParam.H,R=inputParam.R,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   w= W*1*myZoom,
   h= H*1*myZoom,
   r=R*1*myZoom,
   rd=r/Math.sqrt(2), 
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     /*
     w= W*1,
     h= H*1,
     r=R*1,
     rd=r/Math.sqrt(2), 
       x0 = X*1,
       y0 = Y*1;
       */
     if(r!==0){
      var TxtR="R="+R;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //5、方缺 A位置 
        var mySgGapARoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        mySgGapARoundRectangle.origin=[xa,ya-w];
        delete mySgGapARoundRectangle.paths.TopRight; 
        delete mySgGapARoundRectangle.paths.TopLeft;  
        delete mySgGapARoundRectangle.paths.Top; 
       
     //画5、方缺标记 A位置    
  var mySgGapAMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapAMakerW.origin = [xa,ya];
  var mySgGapAMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapAMakerH.origin = [xa,ya];
  var mySgGapAMakerX = new makerjs.models.Square(0); //Y的起始点        
  mySgGapAMakerX.origin = [xa,ya];
  var mySgGapAMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapAMakerR.origin = [xa,ya];
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([-x0,0], [-x0, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([h,0], [h, markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([0-markShiftFour,-w], [0, -w]));//X轴加长标线


  
  makerjs.model.addPath(mySgGapAMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapAMakerH,TxtH, [0,0+markShiftThree], [h, 0+markShiftThree] );//H文字标注
  
  makerjs.model.addPath(mySgGapAMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(mySgGapAMakerX,TxtX, [-x0,0+markShiftFour], [0, 0+markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([0-markShift,-w], [0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(mySgGapAMakerW, TxtW, [0-markShiftFour,-w], [0-markShiftFour, 0]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapAMakerR, new makerjs.paths.Line([h-r,-w+r], [h-r+rd,-w+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapAMakerR, TxtR, [h-r+markHolesOneD,-w+r+r-markHolesOneD], [h-r+rd+r+markHolesOneD, -w+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapARoundRectangle"+myHolesNum]=mySgGapARoundRectangle;
  markers.models["mySgGapARoundRectangle"+myHolesNum]=mySgGapARoundRectangle;
  markers.models["mySgGapAMakerW"+myHolesNum]=mySgGapAMakerW;
  markers.models["mySgGapAMakerH"+myHolesNum]=mySgGapAMakerH;
  markers.models["mySgGapAMakerX"+myHolesNum]=mySgGapAMakerX;
  markers.models["mySgGapAMakerR"+myHolesNum]=mySgGapAMakerR;
  /*****************************************************************************/
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["mySgGapARoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
      
  }else if(area==2){
   //画缺 B位置
        //5、方缺 B位置 
        var mySgGapBRoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        mySgGapBRoundRectangle.origin=[xb-h/2,yb-w];
        delete mySgGapBRoundRectangle.paths.TopRight; 
        delete mySgGapBRoundRectangle.paths.TopLeft;  
        delete mySgGapBRoundRectangle.paths.Top; 
       
     //画5、方缺标记 B位置    
  var mySgGapBMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapBMakerW.origin = [xb-h/2,yb];
  var mySgGapBMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapBMakerH.origin = [xb-h/2,yb];
  var mySgGapBMakerX = new makerjs.models.Square(0); //Y的起始点        
  mySgGapBMakerX.origin = [xb-h/2,yb];
  var mySgGapBMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapBMakerR.origin = [xb-h/2,yb];
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapBMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapBMakerW, new makerjs.paths.Line([-x0,0], [-x0, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapBMakerW, new makerjs.paths.Line([h,0], [h, markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(mySgGapBMakerW, new makerjs.paths.Line([0-markShiftFour,-w], [0, -w]));//X轴加长标线


  
  makerjs.model.addPath(mySgGapBMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapBMakerH,TxtH, [0,0+markShiftThree], [h, 0+markShiftThree] );//H文字标注
  
  makerjs.model.addPath(mySgGapBMakerX, new makerjs.paths.Line([-x0,0+markShiftTwo], [0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(mySgGapBMakerX,TxtX, [-x0,0+markShiftFour], [0, 0+markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapBMakerW, new makerjs.paths.Line([0-markShift,-w], [0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(mySgGapBMakerW, TxtW, [0-markShiftFour,-w], [0-markShiftFour, 0]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapBMakerR, new makerjs.paths.Line([h-r,-w+r], [h-r+rd,-w+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapBMakerR, TxtR, [h-r+markHolesOneD,-w+r+r-markHolesOneD], [h-r+rd+r+markHolesOneD, -w+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapBRoundRectangle"+myHolesNum]=mySgGapBRoundRectangle;
  markers.models["mySgGapBRoundRectangle"+myHolesNum]=mySgGapBRoundRectangle;
  markers.models["mySgGapBMakerW"+myHolesNum]=mySgGapBMakerW;
  markers.models["mySgGapBMakerH"+myHolesNum]=mySgGapBMakerH;
  markers.models["mySgGapBMakerX"+myHolesNum]=mySgGapBMakerX;
  markers.models["mySgGapBMakerR"+myHolesNum]=mySgGapBMakerR;
  /*****************************************************************************/
  //makerjs.model.combineSubtraction(markers.models.myRect, markers.models["mySgGapBRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/        
  }else if(area==3){
  //画缺 C位置
        //5、方缺 C位置 
        var mySgGapCRoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        mySgGapCRoundRectangle.origin=[xc-h,yc-w];
        delete mySgGapCRoundRectangle.paths.TopRight; 
        delete mySgGapCRoundRectangle.paths.TopLeft;  
        delete mySgGapCRoundRectangle.paths.Top; 
       
     //画5、方缺标记 C位置    
  var mySgGapCMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapCMakerW.origin = [xc-h,yc];
  var mySgGapCMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapCMakerH.origin = [xc-h,yc];
  var mySgGapCMakerX = new makerjs.models.Square(0); //Y的起始点        
  mySgGapCMakerX.origin = [xc-h,yc];
  var mySgGapCMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapCMakerR.origin = [xc-h,yc];
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([h+x0,0], [h+x0, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([h,0], [h, markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([0-markShiftFour,-w], [0, -w]));//X轴加长标线
  
  makerjs.model.addPath(mySgGapCMakerH, new makerjs.paths.Line([0,0+markShift], [h, 0+markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapCMakerH,TxtH, [0,0+markShiftThree], [h, 0+markShiftThree] );//H文字标注
  
  makerjs.model.addPath(mySgGapCMakerX, new makerjs.paths.Line([h,0+markShiftTwo], [h+x0, 0+markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(mySgGapCMakerX,TxtX, [h,0+markShiftFour], [h+x0, 0+markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([0-markShift,-w], [0-markShift, 0]));//W短线标注
  makerjs.model.addCaption(mySgGapCMakerW, TxtW, [0-markShiftFour,-w], [0-markShiftFour, 0]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapCMakerR, new makerjs.paths.Line([h-r,-w+r], [h-r+rd,-w+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapCMakerR, TxtR, [h-r+markHolesOneD,-w+r+r-markHolesOneD], [h-r+rd+r+markHolesOneD, -w+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapCRoundRectangle"+myHolesNum]=mySgGapCRoundRectangle;
  markers.models["mySgGapCRoundRectangle"+myHolesNum]=mySgGapCRoundRectangle;
  markers.models["mySgGapCMakerW"+myHolesNum]=mySgGapCMakerW;
  markers.models["mySgGapCMakerH"+myHolesNum]=mySgGapCMakerH;
  markers.models["mySgGapCMakerX"+myHolesNum]=mySgGapCMakerX;
  markers.models["mySgGapCMakerR"+myHolesNum]=mySgGapCMakerR;
  /*****************************************************************************/
     
  }else if(area==7){
  //画缺 G位置
        //5、方缺 G位置 
        var mySgGapGRoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        mySgGapGRoundRectangle.origin=[xg,yg-r];
        delete mySgGapGRoundRectangle.paths.BottomRight; 
        delete mySgGapGRoundRectangle.paths.BottomLeft;  
        delete mySgGapGRoundRectangle.paths.Bottom; 
     //画5、方缺标记 G位置    
  var mySgGapGMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapGMakerW.origin = [xg,yg];
  var mySgGapGMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapGMakerH.origin = [xg,yg];
  var mySgGapGMakerX = new makerjs.models.Square(0); //Y的起始点        
  mySgGapGMakerX.origin = [xg,yg];
  var mySgGapGMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapGMakerR.origin = [xg,yg];
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([-x0,0], [-x0, -markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([h,0], [h, -markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([0-markShiftFour,+w], [0, +w]));//X轴加长标线
  makerjs.model.addPath(mySgGapGMakerH, new makerjs.paths.Line([0,0-markShift], [h, 0-markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapGMakerH,TxtH, [0,0-markShiftThree], [h, 0-markShiftThree] );//H文字标注
  
  makerjs.model.addPath(mySgGapGMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(mySgGapGMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([0-markShift,0], [0-markShift, w]));//W短线标注
  makerjs.model.addCaption(mySgGapGMakerW, TxtW, [0-markShiftFour,0], [0-markShiftFour, w]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapGMakerR, new makerjs.paths.Line([h-r,+w-r], [h-r+rd,+w-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapGMakerR, TxtR, [h-r+markHolesOneD,+w-r-r+markHolesOneD], [h-r+rd+r+markHolesOneD, +w-r+rd+r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapGRoundRectangle"+myHolesNum]=mySgGapGRoundRectangle;
  markers.models["mySgGapGRoundRectangle"+myHolesNum]=mySgGapGRoundRectangle;
  markers.models["mySgGapGMakerW"+myHolesNum]=mySgGapGMakerW;
  markers.models["mySgGapGMakerH"+myHolesNum]=mySgGapGMakerH;
  markers.models["mySgGapGMakerX"+myHolesNum]=mySgGapGMakerX;
  markers.models["mySgGapGMakerR"+myHolesNum]=mySgGapGMakerR;
  /*****************************************************************************/
          
  }else if(area==8){
   //画缺 H位置
        //5、方缺 H位置 
        var mySgGapHRoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        mySgGapHRoundRectangle.origin=[xh-h/2,yh-r];
        delete mySgGapHRoundRectangle.paths.BottomRight; 
        delete mySgGapHRoundRectangle.paths.BottomLeft;  
        delete mySgGapHRoundRectangle.paths.Bottom; 
     //画5、方缺标记 H位置    
  var mySgGapHMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapHMakerW.origin = [xh-h/2,yh];
  var mySgGapHMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapHMakerH.origin = [xh-h/2,yh];
  var mySgGapHMakerX = new makerjs.models.Square(0); //Y的起始点        
  mySgGapHMakerX.origin = [xh-h/2,yh];
  var mySgGapHMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapHMakerR.origin = [xh-h/2,yh];
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapHMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapHMakerW, new makerjs.paths.Line([-x0,0], [-x0, -markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapHMakerW, new makerjs.paths.Line([h,0], [h, -markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(mySgGapHMakerW, new makerjs.paths.Line([0-markShiftFour,+w], [0, +w]));//X轴加长标线
  makerjs.model.addPath(mySgGapHMakerH, new makerjs.paths.Line([0,0-markShift], [h, 0-markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapHMakerH,TxtH, [0,0-markShiftThree], [h, 0-markShiftThree] );//H文字标注
  
  makerjs.model.addPath(mySgGapHMakerX, new makerjs.paths.Line([-x0,0-markShiftTwo], [0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(mySgGapHMakerX,TxtX, [-x0,0-markShiftFour], [0, 0-markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapHMakerW, new makerjs.paths.Line([0-markShift,0], [0-markShift, w]));//W短线标注
  makerjs.model.addCaption(mySgGapHMakerW, TxtW, [0-markShiftFour,0], [0-markShiftFour, w]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapHMakerR, new makerjs.paths.Line([h-r,+w-r], [h-r+rd,+w-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapHMakerR, TxtR, [h-r+markHolesOneD,+w-r-r+markHolesOneD], [h-r+rd+r+markHolesOneD, +w-r+rd+r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapHRoundRectangle"+myHolesNum]=mySgGapHRoundRectangle;
  markers.models["mySgGapHRoundRectangle"+myHolesNum]=mySgGapHRoundRectangle;
  markers.models["mySgGapHMakerW"+myHolesNum]=mySgGapHMakerW;
  markers.models["mySgGapHMakerH"+myHolesNum]=mySgGapHMakerH;
  markers.models["mySgGapHMakerX"+myHolesNum]=mySgGapHMakerX;
  markers.models["mySgGapHMakerR"+myHolesNum]=mySgGapHMakerR;
  /*****************************************************************************/       
  }else if(area==9){
  //画缺 I位置
        //5、方缺 I位置 
        var mySgGapIRoundRectangle=new makerjs.models.RoundRectangle(h,w+r,r); 
        mySgGapIRoundRectangle.origin=[xi-h,yi-r];
        delete mySgGapIRoundRectangle.paths.BottomRight; 
        delete mySgGapIRoundRectangle.paths.BottomLeft;  
        delete mySgGapIRoundRectangle.paths.Bottom; 
     //画5、方缺标记 I位置    
  var mySgGapIMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapIMakerW.origin = [xi-h,yi];
  var mySgGapIMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapIMakerH.origin = [xi-h,yi];
  var mySgGapIMakerX = new makerjs.models.Square(0); //Y的起始点        
  mySgGapIMakerX.origin = [xi-h,yi];
  var mySgGapIMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapIMakerR.origin = [xi-h,yi];
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([h+x0,0], [h+x0, -markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([h,0], [h, -markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([0-markShiftFour,+w], [0, +w]));//X轴加长标线
  makerjs.model.addPath(mySgGapIMakerH, new makerjs.paths.Line([0,0-markShift], [h, 0-markShift]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapIMakerH,TxtH, [0,0-markShiftThree], [h, 0-markShiftThree] );//H文字标注
  
  makerjs.model.addPath(mySgGapIMakerX, new makerjs.paths.Line([h,0-markShiftTwo], [h+x0, 0-markShiftTwo]));//Y轴方向标注短线 
  makerjs.model.addCaption(mySgGapIMakerX,TxtX, [h,0-markShiftFour], [h+x0, 0-markShiftFour] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([0-markShift,0], [0-markShift, w]));//W短线标注
  makerjs.model.addCaption(mySgGapIMakerW, TxtW, [0-markShiftFour,0], [0-markShiftFour, w]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapIMakerR, new makerjs.paths.Line([h-r,+w-r], [h-r+rd,+w-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapIMakerR, TxtR, [h-r+markHolesOneD,+w-r-r+markHolesOneD], [h-r+rd+r+markHolesOneD, +w-r+rd+r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapIRoundRectangle"+myHolesNum]=mySgGapIRoundRectangle;
  markers.models["mySgGapIRoundRectangle"+myHolesNum]=mySgGapIRoundRectangle;
  markers.models["mySgGapIMakerW"+myHolesNum]=mySgGapIMakerW;
  markers.models["mySgGapIMakerH"+myHolesNum]=mySgGapIMakerH;
  markers.models["mySgGapIMakerX"+myHolesNum]=mySgGapIMakerX;
  markers.models["mySgGapIMakerR"+myHolesNum]=mySgGapIMakerR;
  /*****************************************************************************/  
            
  }

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default SquareGap;