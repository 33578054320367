//角7、地弹簧3
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function FloorSpring3(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,R=inputParam.R,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD, 
     
  
   w1 = W1*1*myZoom,
   w2 = W2*1*myZoom,
   h = H*1*myZoom,
   h1 = H1*1*myZoom,
   r1 = R*1*myZoom,
   d = D*1*myZoom,
   r = d/2,
   a=0,//圆心坐标[a,b]
   b=0,//圆心坐标[a,b]  
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     /*  
     w1 = W1*1,
     w2 = W2*1,
     h = H*1,
     h1 = H1*1,
     r1 = R*1,
     d = D*1,
     r = (D*1)/2,
     a=0,//圆心坐标[a,b]
     b=0,//圆心坐标[a,b]  
       x0 = X*1,
       y0 = Y*1;
       */          
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(r1!==0){
      var TxtR1="R="+R;
     }
     if(w1!==0){
      var TxtW1=W1;
     }
     if(w2!==0){
      var TxtW2=W2;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(h1!==0){
      var TxtH1=H1;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;

  var d=Math.sqrt((r1*r1)-(r1-h)*(r1-h));
  var w=w2*1-d*1;
  console.log("测试相关点距离D",d);
var s=Math.sqrt((r1*r1)-(d/2)*(d/2));
console.log("测试相关点距离S",s);
  if(area==1){ 
    //画7、地弹簧3 A位置
    var myFloorSpring3A = {
      paths: {
        "TopLine": new makerjs.paths.Line([xa-5, ya+5], [xa+w2, ya+5]),
        "RightLine": new makerjs.paths.Line([xa+w2, ya+5], [xa+w2, ya]), 
        "BottomRightArc": new makerjs.paths.Arc([xa+w2, ya], [xa+w, ya-h],r1,false,true),//是否大弧,是否顺时针
        "BottomLine": new makerjs.paths.Line([xa+w, ya-h], [xa-5, ya-h]),
        "LeftLine": new makerjs.paths.Line([xa-5, ya-h], [xa-5, ya+5]),
        "RightHoles": new makerjs.paths.Circle([xa+w1, ya-h1], r),
      }
      };
      
      //标记6、地弹簧 A位置
      var myFs3AMakerD = new makerjs.models.Square(0); //孔的原点       
      myFs3AMakerD.origin = [xa,ya];
      var myFs3AMakerH = new makerjs.models.Square(0); //孔的原点       
      myFs3AMakerH.origin = [xa,ya];
      var myFs3AMakerH1 = new makerjs.models.Square(0); //孔的原点       
      myFs3AMakerH1.origin = [xa,ya];
      var myFs3AMakerR1 = new makerjs.models.Square(0); //孔的原点       
      myFs3AMakerR1.origin = [xa,ya];
      var myFs3AMakerW = new makerjs.models.Square(0); //孔的原点       
      myFs3AMakerW.origin = [xa,ya];
      var myFs3AMakerW1 = new makerjs.models.Square(0); //孔的原点       
      myFs3AMakerW1.origin = [xa,ya];
      var myFs3AMakerW2 = new makerjs.models.Square(0); //孔的原点       
      myFs3AMakerW2.origin = [xa,ya];
      // /*****************************************************************************/
      makerjs.model.addPath(myFs3AMakerW, new makerjs.paths.Line([w1,0], [w1,-h1-d-markShiftThree]));//Y轴方向短线  W1  right      
      makerjs.model.addPath(myFs3AMakerW, new makerjs.paths.Line([w2,0], [w2,-h-markShiftFour]));//Y轴方向短线  W2  right      
      makerjs.model.addPath(myFs3AMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
      makerjs.model.addPath(myFs3AMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线  H      
      makerjs.model.addPath(myFs3AMakerW, new makerjs.paths.Line([w1-r,-h1], [w2+r+markShiftFour, -h1]));//X轴方向短线   H1      
      // // /*****************************************************************************/
      makerjs.model.addPath(myFs3AMakerW1, new makerjs.paths.Line([0,-h-d-markShift], [w1,-h-d-markShift]));//W1短线标注
      makerjs.model.addCaption(myFs3AMakerW1, TxtW1, [0,-h-d-markShiftThree], [w1,-h-d-markShiftThree]);//W1文字标注 
      // // // /*****************************************************************************/ 
      makerjs.model.addPath(myFs3AMakerW2, new makerjs.paths.Line([0,-h-markShiftTwo], [w2,-h-markShiftTwo]));//W2短线标注
      makerjs.model.addCaption(myFs3AMakerW2, TxtW2, [0,-h-r-markShiftThree], [w2,-h-r-markShiftThree]);//W2文字标注       
      // // // /*****************************************************************************/ 
      makerjs.model.addPath(myFs3AMakerH, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo,-h]));//H短线标注
      makerjs.model.addCaption(myFs3AMakerH, TxtH, [0-markShiftFour,-h], [0-markShiftFour,0]);//H文字标注 
      // // // /*****************************************************************************/        
      makerjs.model.addPath(myFs3AMakerH1, new makerjs.paths.Line([w1+markShiftTwo,0], [w1+markShiftTwo,-h1]));//H1短线标注
      makerjs.model.addCaption(myFs3AMakerH1, TxtH1, [w1+markShiftFour,-h1], [w1+markShiftFour,0]);//H1文字标注
      // // // /*****************************************************************************/
      makerjs.model.addPath(myFs3AMakerR1, new makerjs.paths.Line([w,r1-h], [w2,r1-h]));//R1短线标注
      makerjs.model.addPath(myFs3AMakerR1, new makerjs.paths.Line([w,r1-h], [w+(d/2),-h+r1-s]));//R1短线标注
      makerjs.model.addCaption(myFs3AMakerR1, TxtR1, [w+markShiftTwo,r1-h+markShift], [w+markShiftFour,r1-h+markShift]);//R1文字标注     
      // // // /*****************************************************************************/ 
      makerjs.model.addPath(myFs3AMakerD, new makerjs.paths.Line([w1,-h1], [w1+markShift+markHolesOneD,-h1-d-markShiftFour-markHolesOneD]));//D短线标注
      makerjs.model.addCaption(myFs3AMakerD, TxtD, [w1+markShift+markHolesOneD,-h1-d-markShiftFour-markHolesOneD],[w1+markHolesOneD+markShiftFour,-h1-d-markShiftFour-markHolesOneD]);//D文字标注 
      /*****************************************************************************/       
        plate.models["myFloorSpring3A"+myHolesNum]=myFloorSpring3A;
      markers.models["myFloorSpring3A"+myHolesNum]=myFloorSpring3A;
      markers.models["myFs3AMakerD"+myHolesNum]=myFs3AMakerD;
      markers.models["myFs3AMakerH"+myHolesNum]=myFs3AMakerH;
      markers.models["myFs3AMakerH1"+myHolesNum]=myFs3AMakerH1;
      markers.models["myFs3AMakerR1"+myHolesNum]=myFs3AMakerR1;
      markers.models["myFs3AMakerW"+myHolesNum]=myFs3AMakerW;
      markers.models["myFs3AMakerW1"+myHolesNum]=myFs3AMakerW1;
      markers.models["myFs3AMakerW2"+myHolesNum]=myFs3AMakerW2;
      // /*****************************************************************************/
      makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpring3A"+myHolesNum])
/*****************************************************************************/ 
    }else if(area==2){
      
    }else if(area==3){
    //画7、地弹簧3 C位置
var myFloorSpring3C = {
paths: {
"TopLine": new makerjs.paths.Line([xc-w2, yc+5],[xc+5, yc+5]),
"RightLine": new makerjs.paths.Line([xc+5, yc+5], [xc+5, yc-h]),
"BottomLine": new makerjs.paths.Line( [xc+5, yc-h],[xc-w, yc-h]),
"BottomLeftArc": new makerjs.paths.Arc([xc-w, yc-h], [xc-w2, yc],r1,false,true),//是否大弧,是否顺时针
"LeftLine": new makerjs.paths.Line([xc-w2, yc], [xc-w2, yc+5]),
"LeftHoles": new makerjs.paths.Circle([xc-w1, yc-h1], r),
}
};

//标记7、地弹簧 C位置
var myFs3CMakerD = new makerjs.models.Square(0); //孔的原点       
myFs3CMakerD.origin = [xc,yc];
var myFs3CMakerH = new makerjs.models.Square(0); //孔的原点       
myFs3CMakerH.origin = [xc,yc];
var myFs3CMakerH1 = new makerjs.models.Square(0); //孔的原点       
myFs3CMakerH1.origin = [xc,yc];
var myFs3CMakerR1 = new makerjs.models.Square(0); //孔的原点       
myFs3CMakerR1.origin = [xc,yc];
var myFs3CMakerW = new makerjs.models.Square(0); //孔的原点       C 
myFs3CMakerW.origin = [xc,yc];
var myFs3CMakerW1 = new makerjs.models.Square(0); //孔的原点       
myFs3CMakerW1.origin = [xc,yc];
var myFs3CMakerW2 = new makerjs.models.Square(0); //孔的原点       
myFs3CMakerW2.origin = [xc,yc];

// /*****************************************************************************/
makerjs.model.addPath(myFs3CMakerW, new makerjs.paths.Line([-w1,0], [-w1,-h1-d-markShiftThree]));//Y轴方向短线  W1  right
makerjs.model.addPath(myFs3CMakerW, new makerjs.paths.Line([-w2,0], [-w2,-h1-markShiftThree]));//Y轴方向短线  W2  right
makerjs.model.addPath(myFs3CMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
makerjs.model.addPath(myFs3CMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线  H
makerjs.model.addPath(myFs3CMakerW, new makerjs.paths.Line([-w1+r,-h1], [-w2-r-markShiftFour, -h1]));//X轴方向短线   H1
// /*****************************************************************************/
makerjs.model.addPath(myFs3CMakerW1, new makerjs.paths.Line([0,-h-d-markShift], [-w1,-h-d-markShift]));//W1短线标注
makerjs.model.addCaption(myFs3CMakerW1, TxtW1, [-w1,-h-d-markShiftThree], [0,-h-d-markShiftThree]);//W1文字标注
// // /*****************************************************************************/  
makerjs.model.addPath(myFs3CMakerW2, new makerjs.paths.Line([0,-h-markShiftTwo], [-w2,-h-markShiftTwo]));//W2短线标注
makerjs.model.addCaption(myFs3CMakerW2, TxtW2, [-w2,-h-r-markShiftThree], [0,-h-r-markShiftThree]);//W2文字标注 
// // /*****************************************************************************/ 
makerjs.model.addPath(myFs3CMakerH, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo,-h]));//H短线标注
makerjs.model.addCaption(myFs3CMakerH, TxtH, [0+markShiftFour,-h], [0+markShiftFour,0]);//H文字标注 
// // /*****************************************************************************/
makerjs.model.addPath(myFs3CMakerH1, new makerjs.paths.Line([-w1-markShiftTwo,0], [-w1-markShiftTwo,-h1]));//H1短线标注
makerjs.model.addCaption(myFs3CMakerH1, TxtH1, [-w1-markShiftFour,-h1], [-w1-markShiftFour,0]);//H1文字标注 
// // /*****************************************************************************/  
makerjs.model.addPath(myFs3CMakerR1, new makerjs.paths.Line([-w,r1-h], [-w2,r1-h]));//R1短线标注
makerjs.model.addPath(myFs3CMakerR1, new makerjs.paths.Line([-w,r1-h], [-w-(d/2),-h+r1-s]));//R1短线标注
makerjs.model.addCaption(myFs3CMakerR1, TxtR1, [-w-markShiftFour,r1-h+markShift],[-w-markShiftTwo,r1-h+markShift]);//R1文字标注
// // /*****************************************************************************/ 
makerjs.model.addPath(myFs3CMakerD, new makerjs.paths.Line([-w1,-h1], [-w1-markShift-markHolesOneD,-h1-d-markShiftFour-markHolesOneD]));//D短线标注
makerjs.model.addCaption(myFs3CMakerD, TxtD,[-w1-markHolesOneD-markShiftFour,-h1-d-markShiftFour-markHolesOneD], [-w1-markShift-markHolesOneD,-h1-d-markShiftFour-markHolesOneD]);//D文字标注 
/*****************************************************************************/ 
plate.models["myFloorSpring3C"+myHolesNum]=myFloorSpring3C;
markers.models["myFloorSpring3C"+myHolesNum]=myFloorSpring3C;
markers.models["myFs3CMakerD"+myHolesNum]=myFs3CMakerD;
markers.models["myFs3CMakerH"+myHolesNum]=myFs3CMakerH;
markers.models["myFs3CMakerH1"+myHolesNum]=myFs3CMakerH1;
markers.models["myFs3CMakerR1"+myHolesNum]=myFs3CMakerR1;
markers.models["myFs3CMakerW"+myHolesNum]=myFs3CMakerW;
markers.models["myFs3CMakerW1"+myHolesNum]=myFs3CMakerW1;
markers.models["myFs3CMakerW2"+myHolesNum]=myFs3CMakerW2;
// /*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpring3C"+myHolesNum])
/*****************************************************************************/       
    }else if(area==4){
      
    }else if(area==5){
      
    }else if(area==6){
      
    }else if(area==7){

  //画7、地弹簧3 G位置

var myFloorSpring3G = {
paths: {  
"LeftLine": new makerjs.paths.Line([xg-5, yg-5], [xg-5, yg+h]),
"TopLine": new makerjs.paths.Line([xg-5, yg+h], [xg+w, yg+h]),
"TopLeftArc": new makerjs.paths.Arc([xg+w, yg+h], [xg+w2, yg],r1,false,true),//是否大弧,是否顺时针
"RightLine": new makerjs.paths.Line([xg+w2, yg], [xg+w2, yg-5]),
"BottomLine": new makerjs.paths.Line([xg+w2, yg-5], [xg-5, yg-5]),
"RightHoles": new makerjs.paths.Circle([xg+w1, yg+h1], r),
}
};

//标记7、地弹簧 G位置
var myFs3GMakerD = new makerjs.models.Square(0); //孔的原点       
myFs3GMakerD.origin = [xg,yg];
var myFs3GMakerH = new makerjs.models.Square(0); //孔的原点       
myFs3GMakerH.origin = [xg,yg];
var myFs3GMakerH1 = new makerjs.models.Square(0); //孔的原点       
myFs3GMakerH1.origin = [xg,yg];
var myFs3GMakerR1 = new makerjs.models.Square(0); //孔的原点       
myFs3GMakerR1.origin = [xg,yg];
var myFs3GMakerW = new makerjs.models.Square(0); //孔的原点       
myFs3GMakerW.origin = [xg,yg];
var myFs3GMakerW1 = new makerjs.models.Square(0); //孔的原点       
myFs3GMakerW1.origin = [xg,yg];
var myFs3GMakerW2 = new makerjs.models.Square(0); //孔的原点       
myFs3GMakerW2.origin = [xg,yg];
// /*****************************************************************************/
makerjs.model.addPath(myFs3GMakerW, new makerjs.paths.Line([w1,0], [w1,h1+d+markShiftThree]));//Y轴方向短线  W1  right
makerjs.model.addPath(myFs3GMakerW, new makerjs.paths.Line([w2,0], [w2,0+h1+markShiftThree]));//Y轴方向短线  W2  right
makerjs.model.addPath(myFs3GMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
makerjs.model.addPath(myFs3GMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线  H
makerjs.model.addPath(myFs3GMakerW, new makerjs.paths.Line([w1-r,h1], [w2+r+markShiftFour, h1]));//X轴方向短线   H1
// /*****************************************************************************/
makerjs.model.addPath(myFs3GMakerW1, new makerjs.paths.Line([0,h+d+markShift], [w1,h+d+markShift]));//W1短线标注
makerjs.model.addCaption(myFs3GMakerW1, TxtW1, [0,h+d+markShiftThree], [w1,h+d+markShiftThree]);//W1文字标注
// // /*****************************************************************************/ 
makerjs.model.addPath(myFs3GMakerW2, new makerjs.paths.Line([0,h+markShiftTwo], [w2,h+markShiftTwo]));//W2短线标注
makerjs.model.addCaption(myFs3GMakerW2, TxtW2, [0,h+r+markShiftThree], [w2,h+r+markShiftThree]);//W2文字标注 
// // /*****************************************************************************/ 
makerjs.model.addPath(myFs3GMakerH, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo,h]));//H短线标注
makerjs.model.addCaption(myFs3GMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour,h]);//H文字标注
// // /*****************************************************************************/  
makerjs.model.addPath(myFs3GMakerH1, new makerjs.paths.Line([w1+markShiftTwo,0], [w1+markShiftTwo,h1]));//H1短线标注
makerjs.model.addCaption(myFs3GMakerH1, TxtH1, [w1+markShiftFour,0], [w1+markShiftFour,h1]);//H1文字标注
// // /*****************************************************************************/ 
makerjs.model.addPath(myFs3GMakerR1, new makerjs.paths.Line([w,-r1+h], [w2,-r1+h]));//R1短线标注
makerjs.model.addPath(myFs3GMakerR1, new makerjs.paths.Line([w,-r1+h], [w+(d/2),+h-r1+s]));//R1短线标注
makerjs.model.addCaption(myFs3GMakerR1, TxtR1,[w+markShiftTwo,-r1+h-markShift], [w+markShiftFour,-r1+h-markShift]);//R1文字标注 
// // /*****************************************************************************/  
makerjs.model.addPath(myFs3GMakerD, new makerjs.paths.Line([w1,h1], [w1+markShift+markHolesOneD,h1+d+markShiftFour+markHolesOneD]));//D短线标注
makerjs.model.addCaption(myFs3GMakerD, TxtD, [w1+markShift+markHolesOneD,h1+d+markShiftFour+markHolesOneD],[w1+markHolesOneD+markShiftFour,h1+d+markShiftFour+markHolesOneD]);//D文字标注 
/*****************************************************************************/ 
plate.models["myFloorSpring3G"+myHolesNum]=myFloorSpring3G;
markers.models["myFloorSpring3G"+myHolesNum]=myFloorSpring3G;
markers.models["myFs3GMakerD"+myHolesNum]=myFs3GMakerD;
markers.models["myFs3GMakerH"+myHolesNum]=myFs3GMakerH;
markers.models["myFs3GMakerH1"+myHolesNum]=myFs3GMakerH1;
markers.models["myFs3GMakerR1"+myHolesNum]=myFs3GMakerR1;
markers.models["myFs3GMakerW"+myHolesNum]=myFs3GMakerW;
markers.models["myFs3GMakerW1"+myHolesNum]=myFs3GMakerW1;
markers.models["myFs3GMakerW2"+myHolesNum]=myFs3GMakerW2;
// /*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpring3G"+myHolesNum])
/*****************************************************************************/      
    }else if(area==8){
      
    }else if(area==9){
     
     //画7、地弹簧 I位置
var myFloorSpring3I = {
paths: {
"BottomLine": new makerjs.paths.Line([xi+5, yi-5], [xi-w2, yi-5]),
"LeftLine": new makerjs.paths.Line([xi-w2, yi-5], [xi-w2, yi]), 
"TopLeftArc": new makerjs.paths.Arc([xi-w2, yi], [xi-w, yi+h],r1,false,true),//是否大弧,是否顺时针
"TopLine": new makerjs.paths.Line([xi-w, yi+h], [xi+5, yi+h]),
"RightLine": new makerjs.paths.Line([xi+5, yi+h], [xi+5, yi-5]),
"RightHoles": new makerjs.paths.Circle([xi-w1, yi+h1], r),
}
};
//标记7、地弹簧 I位置
var myFs3IMakerD = new makerjs.models.Square(0); //孔的原点       
myFs3IMakerD.origin = [xi,yi];
var myFs3IMakerH = new makerjs.models.Square(0); //孔的原点       
myFs3IMakerH.origin = [xi,yi];
var myFs3IMakerH1 = new makerjs.models.Square(0); //孔的原点       
myFs3IMakerH1.origin = [xi,yi];
var myFs3IMakerR1 = new makerjs.models.Square(0); //孔的原点       
myFs3IMakerR1.origin = [xi,yi];
var myFs3IMakerW = new makerjs.models.Square(0); //孔的原点       C 
myFs3IMakerW.origin = [xi,yi];
var myFs3IMakerW1 = new makerjs.models.Square(0); //孔的原点       
myFs3IMakerW1.origin = [xi,yi];
var myFs3IMakerW2 = new makerjs.models.Square(0); //孔的原点       
myFs3IMakerW2.origin = [xi,yi];
// /*****************************************************************************/
makerjs.model.addPath(myFs3IMakerW, new makerjs.paths.Line([-w1,0], [-w1,h1+d+markShiftThree]));//Y轴方向短线  W1  right
makerjs.model.addPath(myFs3IMakerW, new makerjs.paths.Line([-w2,0], [-w2,+h1+markShiftThree]));//Y轴方向短线  W2  right
makerjs.model.addPath(myFs3IMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
makerjs.model.addPath(myFs3IMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线  H
makerjs.model.addPath(myFs3IMakerW, new makerjs.paths.Line([-w1+r,h1], [-w2-r-markShiftFour, h1]));//X轴方向短线   H1
// /*****************************************************************************/ 
makerjs.model.addPath(myFs3IMakerW1, new makerjs.paths.Line([0,h+d+markShift], [-w1,h+d+markShift]));//W1短线标注
makerjs.model.addCaption(myFs3IMakerW1, TxtW1, [-w1,h+d+markShiftThree], [0,h+d+markShiftThree]);//W1文字标注 
// // /*****************************************************************************/ 
makerjs.model.addPath(myFs3IMakerW2, new makerjs.paths.Line([0,+h+markShiftTwo], [-w2,+h+markShiftTwo]));//W2短线标注
makerjs.model.addCaption(myFs3IMakerW2, TxtW2, [-w2,+h+r+markShiftThree], [0,+h+r+markShiftThree]);//W2文字标注 
// // /*****************************************************************************/ 
makerjs.model.addPath(myFs3IMakerH, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo,h]));//H短线标注
makerjs.model.addCaption(myFs3IMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour,h]);//H文字标注 
// // /*****************************************************************************/ 
makerjs.model.addPath(myFs3IMakerH1, new makerjs.paths.Line([-w1-markShiftTwo,0], [-w1-markShiftTwo,h1]));//H1短线标注
makerjs.model.addCaption(myFs3IMakerH1, TxtH1, [-w1-markShiftFour,0], [-w1-markShiftFour,h1]);//H1文字标注 
// // /*****************************************************************************/ 
makerjs.model.addPath(myFs3IMakerR1, new makerjs.paths.Line([-w,-r1+h], [-w2,-r1+h]));//R1短线标注
makerjs.model.addPath(myFs3IMakerR1, new makerjs.paths.Line([-w,-r1+h], [-w-(d/2),+h-r1+s]));//R1短线标注
makerjs.model.addCaption(myFs3IMakerR1, TxtR1, [-w-markShiftFour,-r1+h-markShift],[-w-markShiftTwo,-r1+h-markShift]);//R1文字标注 
// // /*****************************************************************************/  
makerjs.model.addPath(myFs3IMakerD, new makerjs.paths.Line([-w1,h1], [-w1-markShift-markHolesOneD,h1+d+markShiftFour+markHolesOneD]));//D短线标注
makerjs.model.addCaption(myFs3IMakerD, TxtD,[-w1-markHolesOneD-markShiftFour,h1+d+markShiftFour+markHolesOneD], [-w1-markShift-markHolesOneD,h1+d+markShiftFour+markHolesOneD]);//D文字标注 
/*****************************************************************************/ 
plate.models["myFloorSpring3I"+myHolesNum]=myFloorSpring3I;
markers.models["myFloorSpring3I"+myHolesNum]=myFloorSpring3I;
markers.models["myFs3IMakerD"+myHolesNum]=myFs3IMakerD;
markers.models["myFs3IMakerH"+myHolesNum]=myFs3IMakerH;
markers.models["myFs3IMakerH1"+myHolesNum]=myFs3IMakerH1;
markers.models["myFs3IMakerR1"+myHolesNum]=myFs3IMakerR1;
markers.models["myFs3IMakerW"+myHolesNum]=myFs3IMakerW;
markers.models["myFs3IMakerW1"+myHolesNum]=myFs3IMakerW1;
markers.models["myFs3IMakerW2"+myHolesNum]=myFs3IMakerW2;
// /*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpring3I"+myHolesNum])
/*****************************************************************************/ 
    }

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};
export default FloorSpring3;