//缺1、蝴蝶
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ButterflyGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var R=inputParam.R,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   h = H*1*myZoom,
      w= W*1*myZoom,
      d= R*2*myZoom,
      r=d/2,
      dd=(d/2)/Math.sqrt(2),
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        h = H*1,
           w= W*1,
           d= R*2,
           r=d/2,
           dd=(d/2)/Math.sqrt(2),
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="R"+R;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
         var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
      //画缺 A位置
    console.log("dd",dd)
    console.log("myBfgGapARect",X)
          //1、蝴蝶 A位置   H W D  width=w-d/2
          var myBfgGapARect=new makerjs.models.Rectangle(w, h+r); 
          myBfgGapARect.origin=[xa,ya-h+r];     
          var myBfgGapABoltRectangle=new makerjs.models.BoltRectangle(w,h+r,r);
          myBfgGapABoltRectangle.origin=[xa,ya-h+r]; 
          var myBfgGapA=makerjs.model.combineUnion(myBfgGapARect, myBfgGapABoltRectangle); 
    //    //画1、蝴蝶标记 A位置 
    var myBfgGapAMakerW = new makerjs.models.Square(0); //D的起始点       
    myBfgGapAMakerW.origin = [xa,ya];
    var myBfgGapAMakerH = new makerjs.models.Square(0); //W的起始点        
    myBfgGapAMakerH.origin = [xa,ya];
    var myBfgGapAMakerD = new makerjs.models.Square(0); //H的起始点        
    myBfgGapAMakerD.origin = [xa,ya-h+r];
    var myBfgGapAMakerX = new makerjs.models.Square(0); //H的起始点        
    myBfgGapAMakerX.origin = [xa,ya];
    /*****************************************************************************/
    makerjs.model.addPath(myBfgGapAMakerW, new makerjs.paths.Line([0,0], [0, 0+markShiftThree]));//Y轴加长标线
     makerjs.model.addPath(myBfgGapAMakerW, new makerjs.paths.Line([w,0], [w,0+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myBfgGapAMakerW, new makerjs.paths.Line([-x0,0], [-x0, +markShiftFour]));//X轴加长标线
    makerjs.model.addPath(myBfgGapAMakerW, new makerjs.paths.Line([0-markShiftFour,-h+r], [0, -h+r]));//X轴方向短线
    
    makerjs.model.addPath(myBfgGapAMakerD, new makerjs.paths.Line([w,0], [w+dd+markHolesOneD,0-dd-markHolesOneD]));//D相关 X轴方向短线上
    makerjs.model.addCaption(myBfgGapAMakerD,TxtD,[w+markShift,0], [w+markShift+dd+markHolesOneD,0-dd-markHolesOneD]);//D相关 X轴方向短线下
    
    
    makerjs.model.addPath(myBfgGapAMakerH, new makerjs.paths.Line([0-markShift,0-y0], [0-markShift, 0-y0-h+r]));//H相关 Y轴方向短线
    makerjs.model.addCaption(myBfgGapAMakerH,TxtH, [0-markShiftThree, 0-y0-h+r], [0-markShiftThree,0-y0]);//H文字标注
    
    
    makerjs.model.addPath(myBfgGapAMakerX, new makerjs.paths.Line([-x0,0+markShift], [0, 0+markShift]));//Y轴方向标注短线 
    makerjs.model.addCaption(myBfgGapAMakerX,TxtX,  [-x0, 0+markShiftTwo],[0,0+markShiftTwo]);//Y文字标注
    /*****************************************************************************/
    makerjs.model.addPath(myBfgGapAMakerW, new makerjs.paths.Line([0,0+markShiftTwo], [w, 0+markShiftTwo]));//W轴方向标注短线 
    makerjs.model.addCaption(myBfgGapAMakerW,TxtW, [0,0+markShiftFour], [w, 0+markShiftFour]);//W文字标注
    /*****************************************************************************/  
    plate.models["myBfgGapA"+myHolesNum]=myBfgGapA;
    markers.models["myBfgGapA"+myHolesNum]=myBfgGapA;
    markers.models["myBfgGapAMakerW"+myHolesNum]=myBfgGapAMakerW;
    markers.models["myBfgGapAMakerH"+myHolesNum]=myBfgGapAMakerH;
    markers.models["myBfgGapAMakerD"+myHolesNum]=myBfgGapAMakerD;
    markers.models["myBfgGapAMakerX"+myHolesNum]=myBfgGapAMakerX;
    /*****************************************************************************/
    makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfgGapA"+myHolesNum]);
    
    console.log("markers==",markers);
    /*****************************************************************************/ 
   
          }else if(area==2){
            //1、蝴蝶 B位置   H W D  width=w-d/2
            var myBfgGapBRect=new makerjs.models.Rectangle(w, h+r); 
              myBfgGapBRect.origin=[xb-w/2,yb-h+r];     
              var myBfgGapBBoltRectangle=new makerjs.models.BoltRectangle(w,h+r,r);
              myBfgGapBBoltRectangle.origin=[xb-w/2,yb-h+r]; 
              var myBfgGapB=makerjs.model.combineUnion(myBfgGapBRect, myBfgGapBBoltRectangle); 
        //    //画1、蝴蝶标记 D位置 
        var myBfgGapBMakerW = new makerjs.models.Square(0); //D的起始点       
        myBfgGapBMakerW.origin = [xb,yb];
        var myBfgGapBMakerH = new makerjs.models.Square(0); //W的起始点        
        myBfgGapBMakerH.origin = [xb,yb];
        var myBfgGapBMakerD = new makerjs.models.Square(0); //H的起始点        
        myBfgGapBMakerD.origin = [xb,yb-h+r];
        var myBfgGapBMakerY = new makerjs.models.Square(0); //H的起始点        
        myBfgGapBMakerY.origin = [xb,yb];
        /*****************************************************************************/

        
        makerjs.model.addPath(myBfgGapBMakerW, new makerjs.paths.Line([0-w/2,0], [0-w/2, 0+markShiftFour]));//Y轴加长标线
        makerjs.model.addPath(myBfgGapBMakerW, new makerjs.paths.Line([w/2,0], [w/2,0+markShiftFour]));//Y轴方向短线
        makerjs.model.addPath(myBfgGapBMakerW, new makerjs.paths.Line([-w/2,-h+r], [-w/2-markShiftFour,-h+r]));//Y轴方向短线
        
        makerjs.model.addPath(myBfgGapBMakerD, new makerjs.paths.Line([w/2,0], [w/2+dd+markHolesOneD,0-dd-markHolesOneD]));//D相关 X轴方向短线上
        makerjs.model.addCaption(myBfgGapBMakerD,TxtD,[w/2+markShift,0], [w/2+markShift+dd+markHolesOneD,0-dd-markHolesOneD]);//D相关 X轴方向短线下
        
        makerjs.model.addPath(myBfgGapBMakerH, new makerjs.paths.Line([0-w/2-markShift,0], [0-w/2-markShift, -h+r]));//H相关 Y轴方向短线
        makerjs.model.addCaption(myBfgGapBMakerH,TxtH, [0-w/2-markShiftThree, -h+r], [0-w/2-markShiftThree,0]);//H文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myBfgGapBMakerW, new makerjs.paths.Line([-w/2,0+markShiftTwo], [w/2, 0+markShiftTwo]));//W轴方向标注短线 
        makerjs.model.addCaption(myBfgGapBMakerW,TxtW, [-w/2,0+markShiftFour], [w/2, 0+markShiftFour]);//W文字标注
        /*****************************************************************************/  
        plate.models["myBfgGapB"+myHolesNum]=myBfgGapB;
        markers.models["myBfgGapB"+myHolesNum]=myBfgGapB;
        markers.models["myBfgGapBMakerW"+myHolesNum]=myBfgGapBMakerW;
        markers.models["myBfgGapBMakerH"+myHolesNum]=myBfgGapBMakerH;
        markers.models["myBfgGapBMakerD"+myHolesNum]=myBfgGapBMakerD;
        markers.models["myBfgGapBMakerY"+myHolesNum]=myBfgGapBMakerY;
        /*****************************************************************************/
        makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfgGapB"+myHolesNum]);
        console.log("markers==",markers);
        /*****************************************************************************/    
          }else if(area==3){
            //画缺 C位置
          //1、蝴蝶 C位置   H W D  width=w-d/2
          var myBfgGapCRect=new makerjs.models.Rectangle(w, h+r); 
          myBfgGapCRect.origin=[xc-w,yc-h+r];     
          var myBfgGapCBoltRectangle=new makerjs.models.BoltRectangle(w,h+r,r);
          myBfgGapCBoltRectangle.origin=[xc-w,yc-h+r];       
          var myBfgGapC=new makerjs.model.combineUnion(myBfgGapCRect, myBfgGapCBoltRectangle);
    console.log("myBfgGapC=",myBfgGapC);       
       //画1、蝴蝶标记 C位置 
    var myBfgGapCMakerW = new makerjs.models.Square(0); //D的起始点       
    myBfgGapCMakerW.origin = [xc,yc+y0];
    var myBfgGapCMakerH = new makerjs.models.Square(0); //W的起始点        
    myBfgGapCMakerH.origin = [xc,yc+y0];
    var myBfgGapCMakerD = new makerjs.models.Square(0); //H的起始点        
    myBfgGapCMakerD.origin = [xc,yc+y0];
    var myBfgGapCMakerX = new makerjs.models.Square(0); //H的起始点        
    myBfgGapCMakerX.origin = [xc,yc+y0];
    /*****************************************************************************/
    makerjs.model.addPath(myBfgGapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
    makerjs.model.addPath(myBfgGapCMakerW, new makerjs.paths.Line([-w,0], [-w,0+markShiftFour]));//Y轴方向短线
    makerjs.model.addPath(myBfgGapCMakerW, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
    makerjs.model.addPath(myBfgGapCMakerW, new makerjs.paths.Line([x0,0-y0], [x0, 0-y0+markShiftThree]));//X轴方向短线
    makerjs.model.addPath(myBfgGapCMakerW, new makerjs.paths.Line([0,0-y0-h+r], [0+markShiftThree, 0-y0-h+r]));//X轴方向短线
    
    makerjs.model.addPath(myBfgGapCMakerD, new makerjs.paths.Line([-w,0-y0-h+r], [-w-dd-markHolesOneD,0-y0-dd-markHolesOneD]));//D相关 X轴方向短线上
    makerjs.model.addCaption(myBfgGapCMakerD,TxtD,[-w-markShift,0-y0-h+r], [-w-markShift-dd-markHolesOneD,0-y0-dd-markHolesOneD]);//D相关 X轴方向短线下
    
    
    makerjs.model.addPath(myBfgGapCMakerH, new makerjs.paths.Line([0+markShift,0-y0], [0+markShift, 0-y0-h+r]));//H相关 Y轴方向短线
    makerjs.model.addCaption(myBfgGapCMakerH,TxtH,  [0+markShiftThree, 0-y0-h+r],[0+markShiftThree,0-y0]);//H文字标注
    
    makerjs.model.addPath(myBfgGapCMakerX, new makerjs.paths.Line([0,0+markShift], [x0, 0+markShift]));//Y轴方向标注短线 
    makerjs.model.addCaption(myBfgGapCMakerX,TxtX,  [0, 0-y0+markShiftThree],[x0,0+markShiftThree]);//Y文字标注
    /*****************************************************************************/
    makerjs.model.addPath(myBfgGapCMakerW, new makerjs.paths.Line([-w,0+markShiftTwo], [0, 0+markShiftTwo]));//W轴方向标注短线 
    makerjs.model.addCaption(myBfgGapCMakerW,TxtW, [-w,0+markShiftFour], [0, 0+markShiftFour]);//W文字标注
    // /*****************************************************************************/
    plate.models["myBfgGapC"+myHolesNum]=myBfgGapC;
    markers.models["myBfgGapC"+myHolesNum]=myBfgGapC;
    markers.models["myBfgGapCMakerW"+myHolesNum]=myBfgGapCMakerW;
    markers.models["myBfgGapCMakerH"+myHolesNum]=myBfgGapCMakerH;
    markers.models["myBfgGapCMakerD"+myHolesNum]=myBfgGapCMakerD;
    markers.models["myBfgGapCMakerX"+myHolesNum]=myBfgGapCMakerX;
    // /*****************************************************************************/
    makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfgGapC"+myHolesNum]);
    console.log("markers==",markers);
    /*****************************************************************************/             
          }else if(area==7){
             //画缺 G位置
    console.log("dd",dd)
          //1、蝴蝶 G位置   H W D  width=w-d/2       
          var myBfgGapGRect=new makerjs.models.Rectangle(w, h+r); 
          myBfgGapGRect.origin=[xg,yg-r];     
          var myBfgGapGBoltRectangle=new makerjs.models.BoltRectangle(w,h+r,r);
          myBfgGapGBoltRectangle.origin=[xg,yg-r];           
    var myBfgGapG=makerjs.model.combineUnion(myBfgGapGRect, myBfgGapGBoltRectangle);   
       //画1、蝴蝶 G位置 
    var myBfgGapGMakerW = new makerjs.models.Square(0); //D的起始点       
    myBfgGapGMakerW.origin = [xg,yg];
    var myBfgGapGMakerH = new makerjs.models.Square(0); //W的起始点        
    myBfgGapGMakerH.origin = [xg,yg];
    var myBfgGapGMakerD = new makerjs.models.Square(0); //H的起始点        
    myBfgGapGMakerD.origin = [xg,yg-r];
    var myBfgGapGMakerX = new makerjs.models.Square(0); //H的起始点        
    myBfgGapGMakerX.origin = [xg,yg];
    // /*****************************************************************************/
    makerjs.model.addPath(myBfgGapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(myBfgGapGMakerW, new makerjs.paths.Line([w,0], [w,0-markShiftFour]));//Y轴方向短线
    makerjs.model.addPath(myBfgGapGMakerW, new makerjs.paths.Line([-x0,0], [-x0, 0-markShiftFour]));//X轴加长标线
    makerjs.model.addPath(myBfgGapGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
    
    makerjs.model.addPath(myBfgGapGMakerD, new makerjs.paths.Line([w,0+y0+h+r], [w+dd+markHolesOneD,0+y0+h+r+dd+markHolesOneD]));//D相关 X轴方向短线上
    makerjs.model.addCaption(myBfgGapGMakerD,TxtD,[w+markShiftTwo,0+y0+h+r], [w+markShiftTwo+dd+markHolesOneD,0+y0+h+r+dd+markHolesOneD]);//D相关 X轴方向短线下
    
    
    makerjs.model.addPath(myBfgGapGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+y0], [0-markShiftTwo, 0+y0+h]));//H相关 Y轴方向短线
    makerjs.model.addCaption(myBfgGapGMakerH,TxtH, [0-markShiftFour,0+y0], [0-markShiftFour, 0+y0+h]);//H文字标注
    
    makerjs.model.addPath(myBfgGapGMakerX, new makerjs.paths.Line([-x0,0-markShiftThree], [0, 0+y0-markShiftThree]));//Y轴方向标注短线 
    makerjs.model.addCaption(myBfgGapGMakerX,TxtX,  [-x0, 0-markShiftFour],[0,0+y0-markShiftFour]);//Y文字标注
    // /*****************************************************************************/
    makerjs.model.addPath(myBfgGapGMakerW, new makerjs.paths.Line([0,0-markShiftTwo], [w, 0-markShiftTwo]));//W轴方向标注短线 
    makerjs.model.addCaption(myBfgGapGMakerW,TxtW, [0,0-markShiftFour], [w, 0-markShiftFour]);//W文字标注
    // /*****************************************************************************/
    plate.models["myBfgGapG"+myHolesNum]=myBfgGapG;
    markers.models["myBfgGapG"+myHolesNum]=myBfgGapG;
    markers.models["myBfgGapGMakerW"+myHolesNum]=myBfgGapGMakerW;
    markers.models["myBfgGapGMakerH"+myHolesNum]=myBfgGapGMakerH;
    markers.models["myBfgGapGMakerD"+myHolesNum]=myBfgGapGMakerD;
    markers.models["myBfgGapGMakerX"+myHolesNum]=myBfgGapGMakerX;
    /*****************************************************************************/      
    makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfgGapG"+myHolesNum]);
    console.log("markers==",markers);
    /*****************************************************************************/ 
          }else if(area==8){
            //画缺 F位置
              //1、蝴蝶 F位置   H W D  width=w-d/2
              var myBfgGapFRect=new makerjs.models.Rectangle(w+r, h); 
              myBfgGapFRect.origin=[xf-w,yf-h/2];     
              var myBfgGapFBoltRectangle=new makerjs.models.BoltRectangle(w+r,h,r);
              myBfgGapFBoltRectangle.origin=[xf-w,yf-h/2];       
              var myBfgGapF=new makerjs.model.combineUnion(myBfgGapFRect, myBfgGapFBoltRectangle);
              
        console.log("myBfgGapF=",myBfgGapF);     
           //画1、蝴蝶标记 F位置 
        var myBfgGapFMakerW = new makerjs.models.Square(0); //D的起始点       
        myBfgGapFMakerW.origin = [xf,yf];
        var myBfgGapFMakerH = new makerjs.models.Square(0); //W的起始点        
        myBfgGapFMakerH.origin = [xf,yf];
        var myBfgGapFMakerD = new makerjs.models.Square(0); //H的起始点        
        myBfgGapFMakerD.origin = [xf,yf];
        var myBfgGapFMakerY = new makerjs.models.Square(0); //H的起始点        
        myBfgGapFMakerY.origin = [xf,yf];
        /*****************************************************************************/
        makerjs.model.addPath(myBfgGapFMakerW, new makerjs.paths.Line([-w,h/2], [-w, h/2+markShiftFour]));//Y轴加长标线
        makerjs.model.addPath(myBfgGapFMakerW, new makerjs.paths.Line([0,h/2], [0+markShiftFour, h/2]));//X轴方向短线
        makerjs.model.addPath(myBfgGapFMakerW, new makerjs.paths.Line([0,-h/2], [0+markShiftFour, -h/2]));//X轴方向短线
        
        makerjs.model.addPath(myBfgGapFMakerD, new makerjs.paths.Line([-w,0-h/2], [-w-dd-markHolesOneD,0-h/2-dd-markHolesOneD]));//D相关 X轴方向短线上
        makerjs.model.addCaption(myBfgGapFMakerD,TxtD, [-w-markShift-dd-markHolesOneD,0-h/2-dd-markHolesOneD],[-w-markShift,0-h/2]);//D相关 X轴方向短线下
        
        
        makerjs.model.addPath(myBfgGapFMakerH, new makerjs.paths.Line([0+markShift,h/2], [0+markShift, -h/2]));//H相关 Y轴方向短线
        makerjs.model.addCaption(myBfgGapFMakerH,TxtH,[0+markShiftThree,-h/2],  [0+markShiftThree, h/2]);//H文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myBfgGapFMakerW, new makerjs.paths.Line([-w,h/2+markShiftTwo], [0, h/2+markShiftTwo]));//W轴方向标注短线 
        makerjs.model.addCaption(myBfgGapFMakerW,TxtW, [-w,h/2+markShiftFour], [0, h/2+markShiftFour]);//W文字标注
        // /*****************************************************************************/
        plate.models["myBfgGapF"+myHolesNum]=myBfgGapF;
        markers.models["myBfgGapF"+myHolesNum]=myBfgGapF;
        markers.models["myBfgGapFMakerW"+myHolesNum]=myBfgGapFMakerW;
        markers.models["myBfgGapFMakerH"+myHolesNum]=myBfgGapFMakerH;
        markers.models["myBfgGapFMakerD"+myHolesNum]=myBfgGapFMakerD;
        markers.models["myBfgGapFMakerY"+myHolesNum]=myBfgGapFMakerY;
        // /*****************************************************************************/
        makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfgGapF"+myHolesNum]);
         
          }else if(area==9){
         //画缺 G位置
          //1、蝴蝶 I位置   H W D  width=w-d/2      
          var myBfgGapIRect=new makerjs.models.Rectangle(w+r, h); 
          myBfgGapIRect.origin=[xi-w,yi];     
          var myBfgGapIBoltRectangle=new makerjs.models.BoltRectangle(w+r,h,r);
          myBfgGapIBoltRectangle.origin=[xi-w,yi];
    var myBfgGapI=new makerjs.model.combineUnion(myBfgGapIRect, myBfgGapIBoltRectangle);
    console.log("myBfgGapI=",myBfgGapI);   
       //画1、蝴蝶 I位置 
    var myBfgGapIMakerW = new makerjs.models.Square(0); //D的起始点       
    myBfgGapIMakerW.origin = [xi,yi-y0];
    var myBfgGapIMakerH = new makerjs.models.Square(0); //W的起始点        
    myBfgGapIMakerH.origin = [xi,yi-y0];
    var myBfgGapIMakerD = new makerjs.models.Square(0); //H的起始点        
    myBfgGapIMakerD.origin = [xi,yi-y0];
    var myBfgGapIMakerY = new makerjs.models.Square(0); //H的起始点        
    myBfgGapIMakerY.origin = [xi,yi-y0];
    // /*****************************************************************************/
    makerjs.model.addPath(myBfgGapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(myBfgGapIMakerW, new makerjs.paths.Line([-w,0], [-w,0-markShiftFour]));//Y轴方向短线
    makerjs.model.addPath(myBfgGapIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
    makerjs.model.addPath(myBfgGapIMakerW, new makerjs.paths.Line([0,0+y0], [0+markShiftFour, 0+y0]));//X轴方向短线
    makerjs.model.addPath(myBfgGapIMakerW, new makerjs.paths.Line([0,0+y0+h], [0+markShiftFour, 0+y0+h]));//X轴方向短线
    
    makerjs.model.addPath(myBfgGapIMakerD, new makerjs.paths.Line([-w,0+y0], [-w-dd-markHolesOneD,0+y0+dd+markHolesOneD]));//D相关 X轴方向短线上
    makerjs.model.addCaption(myBfgGapIMakerD,TxtD,[-w-markShiftTwo-dd-markHolesOneD,0+y0+dd+markHolesOneD],[-w-markShiftTwo,0+y0] );//D相关 X轴方向短线下
    
    
    makerjs.model.addPath(myBfgGapIMakerH, new makerjs.paths.Line([0+markShiftTwo,0+y0], [0+markShiftTwo, 0+y0+h]));//H相关 Y轴方向短线
    makerjs.model.addCaption(myBfgGapIMakerH,TxtH, [0+markShiftFour,0+y0], [0+markShiftFour, 0+y0+h]);//H文字标注
    
    
    makerjs.model.addPath(myBfgGapIMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0+y0]));//Y轴方向标注短线 
    makerjs.model.addCaption(myBfgGapIMakerY,TxtY,  [0+markShiftFour, 0],[0+markShiftFour,0+y0]);//Y文字标注
    // /*****************************************************************************/
    makerjs.model.addPath(myBfgGapIMakerW, new makerjs.paths.Line([-w,0-markShiftTwo], [0, 0-markShiftTwo]));//W轴方向标注短线 
    makerjs.model.addCaption(myBfgGapIMakerW,TxtW, [-w,0-markShiftFour], [0, 0-markShiftFour]);//W文字标注
    // /*****************************************************************************/
    plate.models["myBfgGapI"+myHolesNum]=myBfgGapI;
    markers.models["myBfgGapI"+myHolesNum]=myBfgGapI;
    markers.models["myBfgGapIMakerW"+myHolesNum]=myBfgGapIMakerW;
    markers.models["myBfgGapIMakerH"+myHolesNum]=myBfgGapIMakerH;
    markers.models["myBfgGapIMakerD"+myHolesNum]=myBfgGapIMakerD;
    markers.models["myBfgGapIMakerY"+myHolesNum]=myBfgGapIMakerY;
    /*****************************************************************************/    
    makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfgGapI"+myHolesNum]);
    /*****************************************************************************/  
          }

/*


if(area==1){
      //画缺 A位置
    console.log("dd",dd)
          //1、蝴蝶 A位置   H W D  width=w-d/2
          var myBfgGapARect=new makerjs.models.Rectangle(w+r, h); 
          myBfgGapARect.origin=[xa-r,ya-h];     
          var myBfgGapABoltRectangle=new makerjs.models.BoltRectangle(w+r,h,r);
          myBfgGapABoltRectangle.origin=[xa-r,ya-h]; 
          var myBfgGapA=makerjs.model.combineUnion(myBfgGapARect, myBfgGapABoltRectangle); 
    //    //画1、蝴蝶标记 A位置 
    var myBfgGapAMakerW = new makerjs.models.Square(0); //D的起始点       
    myBfgGapAMakerW.origin = [xa+x0,ya];
    var myBfgGapAMakerH = new makerjs.models.Square(0); //W的起始点        
    myBfgGapAMakerH.origin = [xa+x0,ya];
    var myBfgGapAMakerD = new makerjs.models.Square(0); //H的起始点        
    myBfgGapAMakerD.origin = [xa+x0,ya];
    var myBfgGapAMakerY = new makerjs.models.Square(0); //H的起始点        
    myBfgGapAMakerY.origin = [xa+x0,ya];
       


    makerjs.model.addPath(myBfgGapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
    makerjs.model.addPath(myBfgGapAMakerW, new makerjs.paths.Line([w,0], [w,0+markShiftFour]));//Y轴方向短线
    makerjs.model.addPath(myBfgGapAMakerW, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
    makerjs.model.addPath(myBfgGapAMakerW, new makerjs.paths.Line([0,0-y0], [0-markShiftThree, 0-y0]));//X轴方向短线
    
    makerjs.model.addPath(myBfgGapAMakerD, new makerjs.paths.Line([w,0-y0], [w+dd+markHolesOneD,0-y0-dd-markHolesOneD]));//D相关 X轴方向短线上
    makerjs.model.addCaption(myBfgGapAMakerD,TxtD,[w+markShift,0-y0], [w+markShift+dd+markHolesOneD,0-y0-dd-markHolesOneD]);//D相关 X轴方向短线下
    
    
    makerjs.model.addPath(myBfgGapAMakerH, new makerjs.paths.Line([0+markShift,0-y0], [0+markShift, 0-y0-h]));//H相关 Y轴方向短线
    makerjs.model.addCaption(myBfgGapAMakerH,TxtH, [0+markShiftThree, 0-y0-h], [0+markShiftThree,0-y0]);//H文字标注
    
    
    makerjs.model.addPath(myBfgGapAMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
    makerjs.model.addCaption(myBfgGapAMakerY,TxtY,  [0-markShiftFour, 0-y0],[0-markShiftFour,0]);//Y文字标注
    
    

    makerjs.model.addPath(myBfgGapAMakerW, new makerjs.paths.Line([0,0+markShiftTwo], [w, 0+markShiftTwo]));//W轴方向标注短线 
    makerjs.model.addCaption(myBfgGapAMakerW,TxtW, [0,0+markShiftFour], [w, 0+markShiftFour]);//W文字标注
  
    

    plate.models["myBfgGapA"+myHolesNum]=myBfgGapA;
    markers.models["myBfgGapA"+myHolesNum]=myBfgGapA;
    markers.models["myBfgGapAMakerW"+myHolesNum]=myBfgGapAMakerW;
    markers.models["myBfgGapAMakerH"+myHolesNum]=myBfgGapAMakerH;
    markers.models["myBfgGapAMakerD"+myHolesNum]=myBfgGapAMakerD;
    markers.models["myBfgGapAMakerY"+myHolesNum]=myBfgGapAMakerY;
   
    

    makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfgGapA"+myHolesNum]);
    
    console.log("markers==",markers);
   
   
          }



*/




myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};


export default ButterflyGap;